import React from 'react';
import { Button, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  DOMAIN_KEY,
  OCPP_CPMS_WS,
  OCPP_REST_API_URL,
} from '../../../helpers/config';

const OcppTabContent = () => {
  const split = OCPP_CPMS_WS.split('/');
  const splicedStr = `${split.slice(1, split.length - 1).join('/')}`;

  const { t } = useTranslation();
  return (
    <div>
      <Row>
        <Col sm='6'>
          <Label htmlFor='jobTitle' className='form-label'>
            {t('AccountSettings.nonSecureWs')}
          </Label>
          <Input
            disabled
            type='text'
            style={{ backgroundColor: 'white' }}
            className='form-control'
            id='jobTitle'
            defaultValue={`ws:/${splicedStr}`}
          />
        </Col>
        <Col sm='6'>
          <Label htmlFor='jobTitle' className='form-label'>
            {t('AccountSettings.secureWs')}
          </Label>
          <Input
            disabled
            style={{ backgroundColor: 'white' }}
            type='text'
            className='form-control'
            id='jobTitle'
            defaultValue={`wss:/${splicedStr}`}
          />
        </Col>
      </Row>
    </div>
  );
};

export default OcppTabContent;
// {`${DOMAIN_KEY}.ocpp.electroop.io `}
