import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import {
  renderField,
  renderSelectField,
  renderPhoneField,
} from 'Components/Common/Fields';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import EditContext from 'Components/Contexts/EditContext';
import SlidingModal from 'Components/Common/SlidingModal';
import i18n from 'i18next';
import DataContext from 'Components/Contexts/DataContext';
import {
  Button,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap';
import { ApplicationsType } from 'declerations/DefaultTypes';
import moment from 'moment';
import { createDriver, editDriver } from 'actions';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'yup-phone';

const formSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .email(i18n.t('Must be proper email'))
      .min(1, i18n.t('Required'))
      .required(i18n.t('Required')),
    fname: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    lname: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    applicationId: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    // phone: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    dob: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    consumerType: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    //   identityNumber: Yup.string()
    //     .min(1, 'Required')
    //     .max(11, i18n.t('Must be exactly 11 characters'))
    //     .required(i18n.t('Required')),
    phone: Yup.string()
      .min(1, 'Required')
      .test('phone', i18n.t('incorretPhoneNumber'), function (value) {
        if (typeof value === 'string' && isValidPhoneNumber(value)) {
          return true;
        }
        return false;
      })
      .required(i18n.t('Required')),
  });

interface AddDriverProps {
  isOpen: boolean;
  isEditing: boolean;
  toggleHandle: () => void;
}

const AddDriver = ({ isOpen, toggleHandle, isEditing }: AddDriverProps) => {
  const context = useContext(DataContext);
  const editContext = useContext(EditContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    email: '',
    fname: '',
    lname: '',
    applicationId: '',
    invoiceType: 'Personal',
    consumerType: 'PERSON',
    taxOffice: '',
    companyName: '',
    identityNumber: '',
    phone: '',
    dob: new Date(),
  });
  useEffect(() => {
    context
      .LoadOcppTags()
      .then(() => context.LoadApplications())
      .then(setLoading(false));
  }, []);
  const [value, setValue] = useState();

  useEffect(() => {
    if (isEditing) {
      const {
        email = '',
        fname = '',
        lname = '',
        application = { _id: '' },
        invoiceType = 'Personal',
        taxOffice = '',
        consumerType = 'PERSON',
        companyName = '',
        identityNumber = '',
        phone = '',
        dob = new Date(),
      } = isEditing
        ? {
            ...editContext.editDriver,
            dob: moment(editContext.editDriver.dob).toDate(),
          }
        : { application: {} };

      setInitialValues({
        email,
        fname,
        lname,
        applicationId: application._id,
        invoiceType,
        taxOffice,
        companyName,
        identityNumber,
        consumerType,
        phone,
        dob,
      });
    } else {
      setInitialValues({
        email: '',
        fname: '',
        lname: '',
        applicationId: '',
        invoiceType: 'Personal',
        taxOffice: '',
        companyName: '',
        consumerType: 'PERSON',
        identityNumber: '',
        phone: '',
        dob: new Date(),
      });
    }
  }, [isEditing, context.editDriver]);

  return (
    <SlidingModal
      toggleHandle={toggleHandle}
      isOpen={isOpen}
      title={isEditing ? t('DriverAdd.editDriver') : t('DriverAdd.addDriver')}
      size='md'
    >
      {!loading ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values) => {
            const { invoiceType } = values;
            if (isEditing) {
              const { _id: id } = editContext.editDriver;
              if (invoiceType === 'Personal') {
                const { applicationId, companyName, taxOffice, ...newValues } =
                  values;
                return editDriver(id, {
                  ...newValues,
                  dob: moment(values.dob).format('YYYY-MM-DD'),
                })
                  .then(() => {
                    toast.success(t('DriverAdd.driverEditedSuccessfully'));
                    return toggleHandle();
                  })
                  .catch(() => toast.error(t('DriverAdd.unabletoEditDriver')));
              }
              if (invoiceType === 'Company') {
                const { applicationId, companyName, taxOffice, ...newValues } =
                  values;
                return editDriver(id, {
                  ...newValues,
                  dob: moment(values.dob).format('YYYY-MM-DD'),
                })
                  .then(() => {
                    toast.success(t('DriverAdd.driverEditedSuccessfully'));
                    return toggleHandle();
                  })
                  .catch(() => toast.error(t('DriverAdd.unabletoEditDriver')));
              }
            }
            if (invoiceType === 'Company') {
              return createDriver({
                ...values,
                dob: moment(values.dob).format('YYYY-MM-DD'),
              })
                .then(() => {
                  toast.success(t('DriverAdd.driverCreatedSuccessfully'));
                  return toggleHandle();
                })
                .catch(() => toast.error(t('DriverAdd.unabletoCreateDriver')));
            }
            const { companyName, taxOffice, ...newValues } = values;

            return createDriver({
              ...newValues,
              dob: moment(values.dob).format('YYYY-MM-DD'),
            })
              .then(() => {
                toast.success(t('DriverAdd.driverCreatedSuccessfully'));
                return toggleHandle();
              })
              .catch(() => toast.error(t('DriverAdd.unabletoCreateDriver')));
          }}
          validationSchema={formSchema}
        >
          {({
            values,
            touched,
            errors,
            setFieldValue,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldTouched,
          }) => {
            useMemo(
              () =>
                Object.keys(errors).forEach((fieldName) => {
                  setFieldTouched(fieldName);
                }),
              [i18n.language]
            );
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='email'
                      label={t('DriverAdd.email')}
                      className='form-control'
                      type='email'
                      meta={{
                        touched: touched.email,
                        error: errors.email && t(errors.email),
                      }}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={renderField}
                      name='fname'
                      label={t('DriverAdd.firstName')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.fname,
                        error: errors.fname && t(errors.fname),
                      }}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={renderField}
                      name='lname'
                      label={t('DriverAdd.lastName')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.lname,
                        error: errors.lname && t(errors.lname),
                      }}
                    />
                  </Col>
                </Row>
                {isEditing && (
                  <Row>
                    <Col>
                      {!isEditing && (
                        <Nav className='justify-content-center' tabs>
                          {values.invoiceType === 'Personal' && (
                            <NavItem>
                              <NavLink
                                active={values.invoiceType === 'Personal'}
                                onClick={() => {
                                  if (isEditing) {
                                    return;
                                  }
                                  setFieldValue('invoiceType', 'Personal');
                                }}
                              >
                                {t('Personal')}
                              </NavLink>
                            </NavItem>
                          )}
                          <NavItem>
                            {values.invoiceType === 'Company' && (
                              <NavLink
                                active={values.invoiceType === 'Company'}
                                onClick={() => {
                                  if (isEditing) {
                                    return;
                                  }
                                  setFieldValue('invoiceType', 'Company');
                                }}
                              >
                                {t('Company')}
                              </NavLink>
                            )}
                          </NavItem>
                        </Nav>
                      )}
                      <TabContent
                        className='py-50'
                        activeTab={values.invoiceType}
                      >
                        <TabPane tabId='Personal'>
                          {values.invoiceType === 'Personal' && (
                            <Row>
                              <Col>
                                <Field
                                  component={renderField}
                                  name='identityNumber'
                                  label={t('DriverAdd.identityNumber')}
                                  className='form-control'
                                  type='text'
                                  meta={{
                                    touched: touched.identityNumber,
                                    error:
                                      errors.identityNumber &&
                                      t(errors.identityNumber),
                                  }}
                                  disabled={initialValues.identityNumber !== ''}
                                />
                              </Col>
                            </Row>
                          )}
                        </TabPane>
                        <TabPane tabId='Company'>
                          {values.invoiceType === 'Company' && (
                            <Row>
                              <Col>
                                <Field
                                  component={renderField}
                                  name='companyName'
                                  label={t('DriverAdd.companyName')}
                                  className='form-control'
                                  type='text'
                                  meta={{
                                    touched: touched.companyName,
                                    error:
                                      errors.companyName &&
                                      t(errors.companyName),
                                  }}
                                  disabled={isEditing === true}
                                />
                                <Field
                                  component={renderField}
                                  name='taxOffice'
                                  label={t('DriverAdd.taxOffice')}
                                  className='form-control'
                                  type='text'
                                  meta={{
                                    touched: touched.taxOffice,
                                    error:
                                      errors.taxOffice && t(errors.taxOffice),
                                  }}
                                  disabled={isEditing}
                                />
                                <Field
                                  component={renderField}
                                  name='identityNumber'
                                  label={t('DriverAdd.taxNumber')}
                                  className='form-control'
                                  type='text'
                                  meta={{
                                    touched: touched.identityNumber,
                                    error:
                                      errors.identityNumber &&
                                      t(errors.identityNumber),
                                  }}
                                  disabled={initialValues.identityNumber !== ''}
                                />
                              </Col>
                            </Row>
                          )}
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    {/* <label htmlFor='expiryDateTime'>
                      {t('DriverAdd.phone')}
                    </label> */}
                    {/* <PhoneInput
                      country='TR'
                      defaultCountry='TR'
                      name='phone'
                      international
                      countryCallingCodeEditable={false}
                      className='form-control phone-input-style'
                      value={value}
                      onChange={(dob: any) => {
                        setFieldValue('phone', dob);
                      }}
                    />
                    <ErrorMessage
                      name='phone'
                      component='div'
                      className='border-color-red text-danger text-sm'
                    /> */}
                    <Field
                      component={renderPhoneField}
                      name='phone'
                      label={t('DriverAdd.phone')}
                      className='form-control phone-input-style'
                      type='text'
                      onChange={(item: any) => {
                        setFieldValue('phone', item);
                      }}
                      meta={{
                        touched: touched.phone,
                        error: errors.phone && t(errors.phone),
                      }}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={renderSelectField}
                      name='consumerType'
                      label={t('DriverAdd.consumerType')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.consumerType,
                        error: errors.consumerType,
                      }}
                    >
                      <option value='PERSON'>{t('DriverAdd.personal')}</option>
                      <option value='SERVICE'>{t('DriverAdd.service')}</option>
                    </Field>
                  </Col>
                </Row>
                <Row className='mb-1'>
                  <Col>
                    <Field
                      component={renderSelectField}
                      name='applicationId'
                      label={t('AddChargingRate.application')}
                      className='form-control'
                      meta={{
                        touched: touched.applicationId,
                        error: errors.applicationId,
                      }}
                      disabled={isEditing === true}
                    >
                      <option value=''>{t('AddChargingRate.selectApp')}</option>
                      {context.applications?.map(
                        (application: ApplicationsType) => (
                          <option key={application._id} value={application._id}>
                            {application.name}
                          </option>
                        )
                      )}
                    </Field>
                  </Col>
                </Row>

                <Row className='mt-3'>
                  <Col className='text-end'>
                    <Button
                      color='secondary'
                      type='button'
                      disabled={isSubmitting}
                      onClick={toggleHandle}
                      className='me-1'
                    >
                      Cancel
                    </Button>

                    <Button
                      color='primary'
                      type='submit'
                      disabled={isSubmitting}
                    >
                      {isEditing ? t('DriverAdd.edit') : t('DriverAdd.add')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Spinner />
      )}
    </SlidingModal>
  );
};

export default AddDriver;
