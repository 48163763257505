import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import * as Icons from 'react-feather';

import DataTable from 'Components/Common/DataTable';
import { renderField, renderSelectField } from 'Components/Common/Fields';
import * as Yup from 'yup';
import {
  Button,
  Row,
  Col,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { toast } from 'react-toastify';
import EditContext from 'Components/Contexts/EditContext';
import SlidingModal from 'Components/Common/SlidingModal';
import i18n from 'i18next';
import DataContext from 'Components/Contexts/DataContext';
import {ApplicationsType, ChargingRatesType, IntegrationTaskConfigType} from 'declerations/DefaultTypes';
import { createChargingRates, editChargingRates } from 'actions';
// import { ChargingRatesType } from 'declerations/DefaultTypes';
import { getLabelCurrency } from 'helpers/Currencies';
import ApplicationContext from 'Components/Contexts/ApplicationContext';
import moment from "moment/moment";
import ChargingRateHistory from "./ChargingRateHistory";

const patternFourDigisAfterComma = /^\d+(\.\d{0,4})?$/;

const formSchema = () =>
  Yup.object().shape({
    title: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    applicationId: Yup.string()
      .min(1, i18n.t('Required'))
      .required(i18n.t('Required')),
    tariffs: Yup.array().when('type', (type) => {
      if (type === 'Duration') {
        return Yup.array().of(
          Yup.object().shape({
            feePerMin: Yup.string()
              .min(0, i18n.t('Required'))
              .required(i18n.t('Required')),
            minKWh: Yup.string()
              .min(0, i18n.t('Required'))
              .required(i18n.t('Required')),
          })
        );
      }
      return Yup.array();
    }),
    feePerKWh: Yup.string().when('type', (type) => {
      if (type === 'Energy') {
        return Yup.number()
          .typeError(i18n.t('ChargingRate.mustBeNumber'))
          .min(0, i18n.t('ChargingRate.higherThanZero'))
          .required(i18n.t('Required'))
          .test(
            'len',
            i18n.t('ChargingRate.4digitsaftercomma'),
            (val: any) => !val || patternFourDigisAfterComma.test(val)
          );
      }
      return Yup.string();
    }),
    basePrice: Yup.number()
      .min(0, i18n.t('ChargingRate.higherThanZero'))
      .required(i18n.t('Required'))
      .typeError(i18n.t('ChargingRate.mustBeNumber')),
  });
interface AddChargingRatesProps {
  isOpen: boolean;
  isEditing: boolean;
  toggleHandle: () => void;
}
const AddChargingRates = ({
  isOpen,
  toggleHandle,
  isEditing,
}: AddChargingRatesProps) => {
  const context = useContext(DataContext);
  const editContext = useContext(EditContext);
  const { selectedApplication } = useContext(ApplicationContext);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [initialValues, setInitialValues] = useState({
    title: '',
    applicationId: '',
    basePrice: 0,
    type: 'Energy',
    feePerKWh: '',
    note: '',
  });

  const { t } = useTranslation();

  useEffect(() => {
    context.LoadApplications().then(() => {
      setLoading(false);
    });
  }, []);

  const loadDataTable = () => {
    if (isEditing && editContext?.editChargingRate)  {
      const { _id: chargingRateId } = editContext.editChargingRate;
      context.LoadChargingRateHistories({chargingRateId}).then(() => {
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (isEditing) {
      const {
        title = '',
        application: { _id: applicationId = '' },
        _id: id = '',
        type = '',
        basePrice = '',
        feePerKWh = '',
        note = '',
      } = isEditing ? editContext.editChargingRate : { application: {} };
      setInitialValues({
        title,
        applicationId,
        basePrice,
        type: type !== '' ? type : 'Duration',
        feePerKWh,
        note,
      });


      loadDataTable();

      setOpenModal(false);
    } else {
      setInitialValues({
        title: '',
        applicationId: selectedApplication?._id || '',
        basePrice: 0,
        type: 'Energy',
        feePerKWh: '',
        note: '',
      });
    }
  }, [isEditing, selectedApplication]);


  const toggleHandleChargingRateHistory = () => {
    setOpenModal(!openModal);
  };


  return (
    <SlidingModal
      toggleHandle={toggleHandle}
      isOpen={isOpen}
      title={t('AddChargingRate.addChargingRate')}
      size='md'
    >
      {!loading && isOpen ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          // eslint-disable-next-line consistent-return
          onSubmit={async (values) => {
            const { type } = values;
            if (isEditing) {
              if (type === 'Duration') {
                const { _id: id } = editContext.editChargingRate;

                return editChargingRates(id, {
                  title: values.title,
                  basePrice: values.basePrice,
                  note: values.note,
                })
                  .then(() => {
                    toast.success(
                      t('AddChargingRate.chargingRateEditedSuccessfully')
                    );
                    return toggleHandle();
                  })
                  .catch(() =>
                    toast.error(t('AddChargingRate.unabletoEditChargingRate'))
                  );
              }
              const { _id: id } = editContext.editChargingRate;
              return editChargingRates(id, {
                title: values.title,
                basePrice: values.basePrice,
                feePerKWh: values.feePerKWh,
                note: values.note,
              })
                .then(() => {
                  toast.success(
                    t('AddChargingRate.chargingRateEditedSuccessfully')
                  );
                  return toggleHandle();
                })
                .catch(() =>
                  toast.error(t('AddChargingRate.unabletoEditChargingRate'))
                );
            }
            if (type === 'Duration') {
              const { feePerKWh, ...newValue } = values;
              createChargingRates({ ...newValue })
                .then(() => {
                  toast.success(
                    t('AddChargingRate.chargingRateCreatedSuccessfully')
                  );
                  setInitialValues({
                    title: '',
                    applicationId: selectedApplication?._id || '',
                    basePrice: 0,
                    type: 'Energy',
                    feePerKWh: '',
                    note: '',
                  });
                  return toggleHandle();
                })
                .catch(() =>
                  toast.error(t('AddChargingRate.unabletoCreatChargingRate'))
                );
            } else {
              const { ...newValue } = values;
              createChargingRates({ ...newValue })
                .then(() => {
                  toast.success(
                    t('AddChargingRate.chargingRateCreatedSuccessfully')
                  );
                  setInitialValues({
                    title: '',
                    applicationId: selectedApplication?._id || '',
                    basePrice: 0,
                    type: 'Energy',
                    feePerKWh: '',
                    note: ''
                  });
                  return toggleHandle();
                })
                .catch(() =>
                  toast.error(t('AddChargingRate.unabletoCreatChargingRate'))
                );
            }
          }}
          validationSchema={formSchema}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            setFieldTouched,
          }) => {
            useMemo(
              () =>
                Object.keys(errors).forEach((fieldName) => {
                  setFieldTouched(fieldName);
                }),
              [i18n.language]
            );
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='title'
                      label={t('AddChargingRate.title*')}
                      className='form-control'
                      meta={{
                        touched: touched.title,
                        error: errors.title,
                      }}
                    />
                  </Col>
                  {/* <Col>
                    <Field
                      component={renderSelectField}
                      name='applicationId'
                      label={t('AddChargingRate.application')}
                      className='form-control'
                      meta={{
                        touched: touched.applicationId,
                        error: errors.applicationId,
                      }}
                    >
                      <option value=''>{t('AddChargingRate.selectApp')}</option>
                      {context.applications.map(
                        (application: ApplicationsType) => (
                          <option key={application._id} value={application._id}>
                            {application.name}
                          </option>
                        )
                      )}
                    </Field>
                  </Col> */}
                </Row>
                {/* {!isEditing && (
                  <Nav className='justify-content-center' tabs>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        active={values.type === 'Duration'}
                        onClick={() => {
                          if (isEditing) {
                            return;
                          }
                          setFieldValue('type', 'Duration');
                        }}
                      >
                        {t('AddChargingRate.duration')}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        active={values.type === 'Energy'}
                        onClick={() => {
                          if (isEditing) {
                            return;
                          }
                          setFieldValue('type', 'Energy');
                        }}
                      >
                        {t('AddChargingRate.energy')}
                      </NavLink>
                    </NavItem>
                  </Nav>
                )} */}
                <TabContent className='py-50' activeTab={values.type}>
                  {/* <TabPane tabId='Duration'>
                    {values.type === 'Duration' && (
                      <>
                        <Row>
                          <Col lg='4' sm='12' xs='12'>
                            <Field
                              component={renderField}
                              name='basePrice'
                              label={
                                t('AddChargingRate.basePrice') +
                                  values.applicationId !==
                                ''
                                  ? `${t(
                                      'AddChargingRate.basePrice'
                                    )}(${getLabelCurrency(
                                      (
                                        context.applications.find(
                                          (x: any) =>
                                            x._id === values.applicationId
                                        ) || {}
                                      ).currency,
                                      'symbol'
                                    )})`
                                  : ''
                              }
                              className='form-control'
                              meta={{
                                touched: touched.basePrice,
                                error: errors.basePrice,
                              }}
                            />
                          </Col>
                        </Row>
                        <FieldArray
                          name='tariffs'
                          render={(arrayHelpers) => {
                            return (
                              values.tariffs &&
                              values.tariffs.map((item, index) => (
                                <>
                                  <Row key={index.toString()}>
                                    <Col>
                                      <label htmlFor='feePerMinutes'>
                                        {t('AddChargingRate.feePerMinutes') +
                                          values.applicationId !==
                                        ''
                                          ? `${t(
                                              'AddChargingRate.feePerMinutes'
                                            )}(${getLabelCurrency(
                                              (
                                                context.applications.find(
                                                  (x: any) =>
                                                    x._id ===
                                                    values.applicationId
                                                ) || {}
                                              ).currency,
                                              'symbol'
                                            )})`
                                          : ''}
                                      </label>
                                      <Field
                                        onChange={handleChange}
                                        name={`tariffs.${index}.feePerMin`}
                                        className='form-control'
                                        placeholder={t(
                                          'AddChargingRate.feePerMinutes'
                                        )}
                                      />
                                      <ErrorMessage
                                        name={`tariffs.${index}.feePerMin`}
                                        component='div'
                                        className='field-error text-danger'
                                      />
                                    </Col>
                                    <Col>
                                      <label htmlFor='minKWh'>
                                        {t('AddChargingRate.minKWh')}
                                      </label>
                                      <Field
                                        disabled={index === 0}
                                        onChange={handleChange}
                                        name={`tariffs.${index}.minKWh`}
                                        className='form-control'
                                        type='number'
                                      />
                                      <ErrorMessage
                                        name={`tariffs.${index}.minKWh`}
                                        component='div'
                                        className='field-error text-danger'
                                      />
                                    </Col>
                                    <Col className='mt-auto'>
                                      <Button
                                        color='danger'
                                        className='me-1'
                                        disabled={
                                          values.tariffs.length <= 1 ||
                                          index === 0
                                        }
                                        onClick={() => {
                                          if (
                                            values.tariffs.length > 1 &&
                                            index !== 0
                                          )
                                            arrayHelpers.remove(index);
                                        }}
                                      >
                                        {t('AddChargingRate.delete')}
                                      </Button>
                                      {values.tariffs.length - 1 === index && (
                                        <Button
                                          className='ml-2'
                                          color='primary'
                                          onClick={() =>
                                            arrayHelpers.push({
                                              feePerMin: '',
                                              minKWh: '',
                                            })
                                          }
                                        >
                                          {t('AddChargingRate.add')}
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                  <hr />
                                </>
                              ))
                            );
                          }}
                        />
                      </>
                    )}
                  </TabPane> */}
                  <TabPane tabId='Energy'>
                    {values.type === 'Energy' && (
                      <Row>
                        <Col>
                          <Field
                            component={renderField}
                            name='basePrice'
                            label={
                              t('AddChargingRate.basePrice') +
                                values.applicationId !==
                              ''
                                ? `${t(
                                    'AddChargingRate.basePrice'
                                  )}(${getLabelCurrency(
                                    (
                                      context.applications.find(
                                        (x: any) =>
                                          x._id === values.applicationId
                                      ) || {}
                                    ).currency,
                                    'symbol'
                                  )})`
                                : ''
                            }
                            className='form-control'
                            meta={{
                              touched: touched.basePrice,
                              error: errors.basePrice,
                            }}
                          />
                        </Col>
                        <Col>
                          <Field
                            component={renderField}
                            name='feePerKWh'
                            label={
                              t('AddChargingRate.feePerkWh') +
                                values.applicationId !==
                              ''
                                ? `${t(
                                    'AddChargingRate.feePerkWh'
                                  )}(${getLabelCurrency(
                                    (
                                      context.applications.find(
                                        (x: any) =>
                                          x._id === values.applicationId
                                      ) || {}
                                    ).currency,
                                    'symbol'
                                  )})`
                                : ''
                            }
                            className='form-control'
                            meta={{
                              touched: touched.feePerKWh,
                              error: errors.feePerKWh,
                            }}
                          />
                        </Col>
                      </Row>
                    )}
                  </TabPane>
                </TabContent>

                { isEditing && (
                    <Row>
                      <Col>
                        <Field
                            component={renderField}
                            name='note'
                            label={t('chargingRateHistory.note')}
                            className='form-control'
                            meta={{
                              touched: false,
                              error: false,
                            }}
                        />
                      </Col>
                    </Row>
                )}


                <Row className='mt-3'>
                  <Col className='text-end'>
                    <Button
                      color='secondary'
                      type='button'
                      disabled={isSubmitting}
                      onClick={toggleHandle}
                      className='me-1'
                    >
                      {t('AddApplication.cancel')}
                    </Button>
                    <Button
                      color='primary'
                      type='submit'
                      disabled={isSubmitting}
                    >
                      {isEditing
                        ? t('AddChargingRate.edit')
                        : t('AddChargingRate.add')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Spinner />
      )}
       {isEditing && (
           <div className='mt-3'>
               <DataTable
                   loading={loading}
                   noDataText={t('')}
                   columns={[
                       {
                           name:  t('chargingRateHistory.status'),
                           selector: (row: any) => row.status,
                           cell: (row: any) => row.status ? t(`chargingRateHistory.statusType.${row.status}`) : '-',
                       },
                       {
                           name:  t('chargingRateHistory.configType'),
                           selector: (row: any) => row.configType,
                           cell: (row: any) => row.configType ? t(`chargingRateHistory.configTypeType.${row.configType}`) : '-',
                       },
                       {
                           name: t('chargingRateHistory.basePrice'),
                           selector: (row: any) => row.afterSnapshot.basePrice,
                           cell: (row: any) =>
                               row.afterSnapshot.basePrice
                                   ? `${row.afterSnapshot.basePrice}
                      ${getLabelCurrency(editContext.editChargingRate?.application?.currency, 'symbol')}`
                                   : '-',
                       },
                       {
                           name: t('chargingRateHistory.feePerKWh'),
                           selector: (row: any) => row.afterSnapshot.feePerKWh,
                           cell: (row: any) =>
                               row.afterSnapshot.feePerKWh
                                   ? `${row.afterSnapshot.feePerKWh}
                    ${getLabelCurrency(editContext.editChargingRate?.application?.currency, 'symbol')}`
                                   : '-',
                       },
                       {
                           name: t('chargingRateHistory.note'),
                           selector: (row: any) => row.note,
                       },
                       {
                           name: t('chargingRateHistory.creator'),
                           selector: (row: any) => row.creator.email,
                           cell: (row: any) => row.creator?.email ? row.creator?.email : '-',
                       },
                       {
                           name: t('chargingRateHistory.createdAt'),
                           selector: (row: any) => row.createdAt,
                           cell: ({ createdAt }: { createdAt: any }) => {
                               return i18n.language === 'en'
                                   ? moment(createdAt).format('lll').toString()
                                   : moment(createdAt).locale('tr').format('lll').toString();
                           },
                       },
                       {
                           name: t('chargingRateHistory.view'),

                           cell: (row: any) => {
                               return (
                                   <div>
                                       <Icons.Eye
                                           className='mr-1'
                                           cursor='pointer'
                                           onClick={() => {
                                               editContext.SetEditChargingRateHistory(row);
                                               setOpenModal(!openModal);
                                           }}
                                       />
                                   </div>
                               );
                           },
                       },
                   ]}
                   data={context.chargingRateHistories || []}
               />
               {openModal && (
                   <ChargingRateHistory
                       toggleModal={toggleHandleChargingRateHistory}
                       isOpen={openModal}
                   />
               )}
           </div>
       )}
    </SlidingModal>
  );
};
export default AddChargingRates;
