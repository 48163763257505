import React, { useEffect, useState } from 'react';

import Chart from 'react-apexcharts';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardText,
  Row,
  Col,
  DropdownItem,
  DropdownMenu,
  UncontrolledButtonDropdown,
  DropdownToggle,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';

type RevenueType = {
  revenue: {
    energyUsage: number[];
  };
  changeEnergyYear: (year: string) => void;
};

const Revenue = ({ revenue, changeEnergyYear }: RevenueType) => {
  const [data, setData] = useState({
    years: ['2023', '2022', '2021', '2019', '2018'],
    totalUsage: '',
  });
  const [selectedYear, setSelectedYear] = useState('2023');

  const { t } = useTranslation();
  useEffect(() => {
    const usage =
      revenue && revenue.energyUsage && revenue.energyUsage.length > 0
        ? revenue.energyUsage.reduce((a, b) => a + b, 0)
        : 0;

    setData({
      years: ['2023', '2022', '2021'],
      totalUsage: numeral(usage).format('0.0,').toString(),
    });
    return () => {};
  }, [revenue]);
  const options = {
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      type: 'line' as const,
    },
    stroke: {
      curve: 'smooth' as const,
      dashArray: [0, 12],
      width: [4, 3],
    },
    legend: {
      show: false,
    },
    colors: ['#25a0e2'],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        inverseColors: false,
        gradientToColors: ['#445F7D', '#ff9f43'],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    markers: {
      size: 5,
      hover: {
        size: 6,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      labels: {
        style: {
          colors: '#b9b9c3',
          fontSize: '1rem',
        },
      },
      axisTicks: {
        show: false,
      },
      categories: t('Home.months', { returnObjects: true }),
      axisBorder: {
        show: false,
      },
      tickPlacement: 'on',
    },
    yaxis: {
      labels: {
        style: {
          colors: '#b9b9c3',
          fontSize: '1rem',
        },
        formatter: function formatter(val: number) {
          return val > 999 ? `${(val / 1000).toFixed(1)}k` : val.toString();
        },
      },
    },
    grid: {
      borderColor: '#e7eef7',
      padding: {
        top: -20,
        bottom: -10,
        left: 20,
      },
    },
    tooltip: {
      x: { show: false },
    },
  };
  const series = [
    {
      name: t('Home.thisMonth'),
      data:
        revenue && revenue.energyUsage
          ? revenue.energyUsage
          : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ];
  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h4'>{t('Home.energyUsage')}</CardTitle>
      </CardHeader>
      <CardHeader className='p-0 border-0 bg-soft-light'>
        <Row className='g-0 text-center'>
          <Col>
            <div className='p-3 h-100 border border-dashed border-start-0 d-flex flex-row text-center justify-content-center'>
              <h5 className='align-items-center px-2 d-flex'>{t('year')}</h5>
              <UncontrolledButtonDropdown>
                <DropdownToggle
                  className='budget-dropdown'
                  outline
                  color='primary'
                  size='md'
                  caret
                >
                  {selectedYear}
                </DropdownToggle>
                <DropdownMenu>
                  {data.years.map((item) => (
                    <DropdownItem
                      className='w-100'
                      key={item}
                      onClick={() => {
                        setSelectedYear(item);
                        changeEnergyYear(item);
                      }}
                    >
                      {item}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </Col>
        
        </Row>
      </CardHeader>
      <CardBody>
        <div className='d-flex justify-content-start mb-3'>
          <div className='mr-2'>
            <h3 className='font-weight-bolder'>
              <span className='font-medium-1 font-weight-bold mr-25'>Kwh </span>
              <span className='text-primary'>{data.totalUsage}</span>
            </h3>
          </div>
        </div>
        <Chart options={options} series={series} type='line' height={200} />
      </CardBody>
    </Card>
  );
};
export default Revenue;
