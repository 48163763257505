/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import DataContext from 'Components/Contexts/DataContext';
import { useTranslation } from 'react-i18next';
import { AlarmsType } from 'declerations/DefaultTypes';
import moment from 'moment-timezone';
// SimpleBar
import SimpleBar from 'simplebar-react';

// import images
import avatar2 from '../../assets/images/users/avatar-2.jpg';
import avatar8 from '../../assets/images/users/avatar-8.jpg';
import avatar3 from '../../assets/images/users/avatar-3.jpg';
import avatar6 from '../../assets/images/users/avatar-6.jpg';
import bell from '../../assets/images/svg/bell.svg';

const NotificationDropdown = () => {
  const context = useContext(DataContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    context.LoadAlarms().then(() => {
      setLoading(false);
    });
  }, []);
  // Dropdown Togglek
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
  const toggleNotificationDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown);
  };

  // Tab
  const [activeTab, setActiveTab] = useState('1');
  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  return (
    <Dropdown
      isOpen={isNotificationDropdown}
      toggle={toggleNotificationDropdown}
      className='topbar-head-dropdown ms-1 header-item'
    >
      <DropdownToggle
        type='button'
        tag='button'
        className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle'
      >
        <i className='bx bx-bell fs-22' />
        <span className='position-absolute topbar-badge translate-middle badge rounded-pill bg-success'>
          {context.alarms?.length}{' '}
        </span>
      </DropdownToggle>
      <DropdownMenu className='dropdown-menu-lg dropdown-menu-end p-0'>
        <div className='dropdown-head bg-primary bg-pattern rounded-top'>
          <div className='p-3'>
            <Row className='align-items-center'>
              <Col>
                <h6 className='m-0 fs-16 fw-semibold text-white'>
                  {' '}
                  {t('Alarms')}{' '}
                </h6>
              </Col>
              <div className='col-auto dropdown-tabs'>
                <span className='badge badge-soft-light fs-13'>
                  {' '}
                  {context.alarms?.length} {t('Alarm.new')}
                </span>
              </div>
            </Row>
          </div>

          {/* <div className='px-2 pt-2'>
            <Nav className='nav-tabs dropdown-tabs nav-tabs-custom'>
              <NavItem>
                <NavLink
                  href='#'
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggleTab('1');
                  }}
                >
                  All (4)
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href='#'
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggleTab('2');
                  }}
                >
                  Messages
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href='#'
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => {
                    toggleTab('3');
                  }}
                >
                  Alerts
                </NavLink>
              </NavItem>
            </Nav>
          </div> */}
        </div>

        <TabContent activeTab={activeTab}>
          <TabPane tabId='1' className='py-2 ps-2'>
            <SimpleBar style={{ maxHeight: '300px' }} className='pe-2'>
              {context.alarms?.length > 0 &&
                context.alarms?.map((alarm: AlarmsType, index: number) => {
                  return (
                    <div
                      key={index.toString()}
                      className='text-reset notification-item d-block dropdown-item position-relative'
                    >
                      <div className='d-flex'>
                        <div className='avatar-xs me-3'>
                          <span className='avatar-title bg-soft-danger text-danger rounded-circle fs-16'>
                            <i className='bx bxs-ev-station' />
                          </span>
                        </div>
                        <div className='flex-1'>
                          <Link to='#' className='stretched-link'>
                            <h6 className='mt-0 mb-2 lh-base'>
                              ChargePoint <b>{alarm.chargePointId}</b>{' '}
                              {t('Alarm.became')} {alarm.issue}{' '}
                              {t('Alarm.oldu')}
                            </h6>
                          </Link>
                          <p className='mb-0 fs-11 fw-medium text-uppercase text-muted'>
                            <span>
                              <i className='mdi mdi-clock-outline' />{' '}
                              {moment(alarm.createdAt).fromNow()}
                            </span>
                          </p>
                        </div>
                        <div className='px-2 fs-15'>
                          <input className='form-check-input' type='checkbox' />
                        </div>
                      </div>
                    </div>
                  );
                })}

              <div className='my-3 text-center'>
                <Link to='/cpo/alarms'>
                  <button
                    onClick={() => toggleNotificationDropdown()}
                    type='button'
                    className='btn btn-soft-success waves-effect waves-light'
                  >
                    {t('Alarm.viewAllNotification')}{' '}
                    <i className='ri-arrow-right-line align-middle' />
                  </button>
                </Link>
              </div>
            </SimpleBar>
          </TabPane>

          <TabPane tabId='3' className='p-4'>
            <div className='w-25 w-sm-50 pt-3 mx-auto'>
              <img src={bell} className='img-fluid' alt='user-pic' />
            </div>
            <div className='text-center pb-5 mt-2'>
              <h6 className='fs-18 fw-semibold lh-base'>
                Hey! You have no any notifications{' '}
              </h6>
            </div>
          </TabPane>
        </TabContent>
      </DropdownMenu>
    </Dropdown>
  );
};

export default NotificationDropdown;
