import React, { useEffect, useState, useMemo } from 'react';

import { ApplicationsType } from 'declerations/DefaultTypes';
import { getApplications } from 'actions';

const applicationContext = React.createContext<any>(null);

export const ApplicationProvider = ({ children }: { children: any }) => {
  const [loading, setLoading] = useState(true);
  const [selectedApplication, setSelectedApplication] = useState<
    ApplicationsType | undefined
  >();
  const SelectApplication = (application: ApplicationsType) => {
    setSelectedApplication(application);
  };
  const values = useMemo(
    () => ({ selectedApplication, SelectApplication }),
    [selectedApplication]
  );
  useEffect(() => {
    getApplications()
      .then((res) => {
        const applicationId = localStorage.getItem('applicationId');
        SelectApplication(
          res?.data?.applications.find((x: any) => x._id === applicationId) ||
            res?.data?.applications[0]
        );
        setLoading(false);
      });
  }, []);
  return (
    <>
      {' '}
      {!loading && (
        <applicationContext.Provider value={values}>
          {children}
        </applicationContext.Provider>
      )}
    </>
  );
};

export default applicationContext;
