import React, { useState, useEffect, useContext, ChangeEvent } from 'react';
import { Input, Label } from 'reactstrap';

interface AutoRefreshType {
  isActive?: boolean;
  onPress?: () => void;
}

const AutoRefresh = ({ isActive, onPress }: AutoRefreshType) => {
  const [selfIsActive, setSelfIsActive] = useState(isActive);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSelfIsActive(e.target.checked);
    onPress?.();
  };

  return (
    <div className='btn-group '>
      <div
        className='form-check-inline form-switch form-switch-md me-0 pe-0'
        dir='ltr'
      >
        <Label
          className='form-check-label noHighlighter '
          htmlFor='autoRefresh'
        >
          {' '}
          Auto Refresh{' '}
        </Label>
        <Input
          type='checkbox'
          className='form-check-input ms-3 pointer'
          id='autoRefresh'
          checked={selfIsActive}
          onChange={(e) => onChange(e)}
        />
      </div>
    </div>
  );
};
AutoRefresh.defaultProps = {
  isActive: true,
  onPress: () => {},
};
export default AutoRefresh;
