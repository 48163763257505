import React, { useState, useContext, useEffect } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import DataContext from 'Components/Contexts/DataContext';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import images
import avatar1 from '../../assets/images/users/avatar-1.jpg';
import { removeToken } from '../../helpers/token';

const ProfileDropdown = () => {
  const { t } = useTranslation();

  // Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  const context = useContext(DataContext);

  useEffect(() => {
    context.LoadProfile();
  }, []);
  const history = useHistory();

  return (
    <Dropdown
      isOpen={isProfileDropdown}
      toggle={toggleProfileDropdown}
      className='ms-sm-3 header-item topbar-user'
    >
      <DropdownToggle tag='button' type='button' className='btn'>
        <span className='d-flex align-items-center'>
          {/* <img
            className='rounded-circle header-profile-user'
            src={avatar1}
            alt='Header Avatar'
          /> */}
          <span className='text-start ms-xl-2'>
            <span className='d-none d-xl-inline-block ms-1 fw-medium user-name-text'>
              {context.profile?.email.split('@')[0]}
            </span>
            <span className='d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text'>
              {context.profile?.email.split('@')[1]}
            </span>
          </span>
        </span>
      </DropdownToggle>
      <DropdownMenu className='dropdown-menu-end'>
        <h6 className='dropdown-header'>{t('welcome')}</h6>
        <DropdownItem href='/pages-profile-settings'>
          {/* <span className='badge bg-soft-success text-success mt-1 float-end'>
            New
          </span> */}
          <i className='mdi mdi-cog-outline text-muted fs-16 align-middle me-1' />{' '}
          <span className='align-middle'>{t('settings')}</span>
        </DropdownItem>
        <DropdownItem
          href='/login'
          onClick={() => {
            removeToken();
          }}
        >
          <i className='mdi mdi-logout text-muted fs-16 align-middle me-1' />{' '}
          <span className='align-middle' data-key='t-logout'>
            {t('logout')}
          </span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
