import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { renderField } from 'Components/Common/Fields';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import EditContext from 'Components/Contexts/EditContext';
import SlidingModal from 'Components/Common/SlidingModal';
import i18n from 'i18next';
import DataContext from 'Components/Contexts/DataContext';
import { createLocation, editLocation } from 'actions';
import { Button, Col, Row, Spinner } from 'reactstrap';
import Select from 'react-select';
import { AmenitiesType, ApplicationsType } from 'declerations/DefaultTypes';
import { getLabelCurrency } from 'helpers/Currencies';
import ApplicationContext from 'Components/Contexts/ApplicationContext';

const formSchema = () =>
  Yup.object().shape({
    isEditing: Yup.boolean(),
    applicationId: Yup.string().when('isEditing', {
      is: false,
      then: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    }),
    title: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    parkingFeePerMin: Yup.mixed().when('isEditing', {
      is: false,
      then: Yup.number().min(0, 'Required').required(i18n.t('Required')),
    }),
    energyCost: Yup.number().min(0, 'Required').required(i18n.t('Required')),
    code: Yup.string().nullable(),
    revenueSharePercentage: Yup.number()

      .positive(i18n.t('AddApplication.mustbeMoreThanZero'))
      .max(100, i18n.t('AddApplication.mustbeLessThanhundred'))
      .transform((value) =>
          // eslint-disable-next-line no-restricted-globals
          isNaN(value) || value === null || value === undefined ? 0 : value
      ),
});

interface AddLocationProps {
  isOpen: boolean;
  isEditing: boolean;
  toggleHandle: () => void;
}

interface InitialValuesProps {
  title: string;
  applicationId: string;
  parkingFeePerMin: string;
  energyCost: string;
  amenities: string[];
  code?: string,
  revenueSharePercentage?: string,
}

const AddLocation = ({ isOpen, toggleHandle, isEditing }: AddLocationProps) => {
  const context = useContext(DataContext);
  const editContext = useContext(EditContext);
  const { selectedApplication } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<InitialValuesProps>({
    title: '',
    applicationId: '',
    parkingFeePerMin: '',
    energyCost: '',
    amenities: []
  });
  useEffect(() => {
    context
      .LoadApplications()
      .then(() => context.LoadAmenities())
      .then(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (isEditing) {
      const {
        title = '',
        application: { _id: applicationId = '' },
        parkingFeePerMin = '',
        dayEnergyCost: energyCost = '',
        amenities = [],
        code = '',
        revenueSharePercentage = '',
      } = isEditing ? editContext.editLocation : { application: {} };
      setInitialValues({
        applicationId,
        title,
        parkingFeePerMin,
        energyCost,
        amenities,
        code,
        revenueSharePercentage,
      });
    } else {
      setInitialValues({
        title: '',
        applicationId: selectedApplication?._id || '',
        parkingFeePerMin: '',
        energyCost: '',
        amenities: [],
        code: '',
        revenueSharePercentage: '',
      });
    }
  }, [isEditing, selectedApplication]);
  return (
    <SlidingModal
      toggleHandle={toggleHandle}
      isOpen={isOpen}
      title={
        isEditing
          ? t('EditLocation.editLocation')
          : t('EditLocation.addLocation')
      }
      size='md'
    >
      {!loading && isOpen ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values) => {
            if (isEditing) {
              const { _id: id } = editContext.editLocation;
              const { title, energyCost, amenities, code, revenueSharePercentage } = values;
              return editLocation(id, {
                title,
                energyCost,
                amenities,
                code,
                revenueSharePercentage
              })
                .then(() => {
                  toast.success(t('EditLocation.locationEditedSuccessfully'));
                  return toggleHandle();
                })
                .catch(() =>
                  toast.error(t('EditLocation.unabletoEditlocation'))
                );
            }
            return createLocation(values)
              .then(async () => {
                toast.success(t('EditLocation.locationCreatedSuccessfully'));
                await setInitialValues({
                  title: '',
                  applicationId: selectedApplication?._id || '',
                  parkingFeePerMin: '',
                  energyCost: '',
                  amenities: [],
                  code: '',
                  revenueSharePercentage: ''
                });
                return toggleHandle();
              })
              .catch(() =>
                toast.error(t('EditLocation.unabletocreatelocation'))
              );
          }}
          validationSchema={formSchema}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => {
            useMemo(
              () =>
                Object.keys(errors).forEach((fieldName) => {
                  setFieldTouched(fieldName);
                }),
              [i18n.language]
            );
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  {/* {!isEditing && (
                    <Col>
                      <label htmlFor='application'>
                        {t('EditLocation.application')}
                      </label>
                      <Select
                        options={context.applications}
                        onBlur={() => setFieldTouched('applicationId')}
                        getOptionLabel={(x) => x.name}
                        getOptionValue={(x) => x._id}
                        placeholder={t('Select')}
                        value={context.applications.find(
                          (x: ApplicationsType) =>
                            x._id === values.applicationId
                        )}
                        onChange={(data) => {
                          setFieldValue('applicationId', data._id);
                        }}
                      />
                      <ErrorMessage
                        name='applicationId'
                        component='div'
                        className='field-error text-danger text-sm'
                      />
                    </Col>
                  )} */}
                  <Col>
                    <Field
                      component={renderField}
                      name='title'
                      label={t('EditLocation.title')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.title,
                        error: errors.title,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                        component={renderField}
                        name='code'
                        label={t('EditLocation.code')}
                        placeholder={t('EditLocation.code')}
                        className='form-control'
                        type='text'
                        meta={{
                          touched: touched.code,
                          error: errors.code,
                        }}
                    />
                  </Col>
                  <Col>
                    <Field
                        component={renderField}
                        name='revenueSharePercentage'
                        label={t('EditLocation.revenueSharePercentage')}
                        className='form-control'
                        type='number'
                        placeholder={t('EditLocation.revenueSharePercentage')}
                        meta={{
                          touched: touched.revenueSharePercentage,
                          error: errors.revenueSharePercentage,
                        }}
                    />
                  </Col>
                </Row>
                <Row>
                  {!isEditing && (
                    <Col>
                      <Field
                        component={renderField}
                        name='parkingFeePerMin'
                        type='number'
                        label={`${t('EditLocation.parkingFee')} ${
                          values.applicationId !== '' && context.applications
                            ? `(${getLabelCurrency(
                                (
                                  context.applications.find(
                                    (x: ApplicationsType) =>
                                      x._id === values.applicationId
                                  ) || {}
                                ).currency,
                                'symbol'
                              )}/${t('EditLocation.min')})`
                            : ''
                        }`}
                        className='form-control'
                        meta={{
                          touched: touched.parkingFeePerMin,
                          error: errors.parkingFeePerMin,
                        }}
                      />
                    </Col>
                  )}
                  <Col>
                    <Field
                      component={renderField}
                      name='energyCost'
                      type='number'
                      label={`${t('EditLocation.energycost')} ${
                        values.applicationId !== '' && context.applications
                          ? `(${getLabelCurrency(
                              (
                                context.applications.find(
                                  (x: ApplicationsType) =>
                                    x._id === values.applicationId
                                ) || {}
                              ).currency,
                              'symbol'
                            )}/kWh)`
                          : ''
                      }`}
                      className='form-control'
                      meta={{
                        touched: touched.energyCost,
                        error: errors.energyCost,
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <label htmlFor='application'>
                      {t('EditLocation.amenities')}
                    </label>
                    <Select
                      isMulti
                      options={context.amenities}
                      onBlur={() => setFieldTouched('amenities')}
                      placeholder={t('Select')}
                      // //x.langData.filter(e => e.lang === i18n.language)
                      getOptionLabel={(x) =>
                        x.langData.find(
                          (y: any) => y.lang === i18n.language.slice(0, 2)
                        )?.value
                      }
                      getOptionValue={(x) =>
                        x.langData.find(
                          (y: any) => y.lang === i18n.language.slice(0, 2)
                        )?.value
                      }
                      value={context.amenities.filter((x: AmenitiesType) => {
                        let flag = false;
                        values.amenities.map((a: any) => {
                          if (a[0].value === x.langData[0].value) {
                            flag = true;
                          }
                          return null;
                        });
                        return flag;
                      })}
                      onChange={(data) => {
                        setFieldValue(
                          'amenities',
                          data.map((x) => x.langData)
                        );
                      }}
                    />
                    <ErrorMessage
                      name='amenities'
                      component='div'
                      className='field-error text-danger text-sm'
                    />
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col className='text-end'>
                    <Button
                      color='secondary'
                      type='button'
                      disabled={isSubmitting}
                      onClick={toggleHandle}
                      className='me-1'
                    >
                      {t('EditLocation.cancel')}
                    </Button>
                    <Button
                      color='primary'
                      type='submit'
                      disabled={isSubmitting}
                    >
                      {isEditing
                        ? t('EditLocation.edit')
                        : t('EditLocation.add')}
                    </Button>
                  </Col>
                </Row>

              </Form>
            );
          }}
        </Formik>
      ) : (
        <Spinner />
      )}
    </SlidingModal>
  );
};

export default AddLocation;
