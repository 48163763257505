import React, { useContext } from 'react';
import {Button, Row, Col, FormGroup, Input, Label} from 'reactstrap';
import { renderField } from 'Components/Common/Fields';
import { Field, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import ModalForm from 'Components/Common/ModalForm';

import 'flatpickr/dist/themes/light.css';
import EditContext from 'Components/Contexts/EditContext';
import {getLabelCurrency} from "../../../helpers/Currencies";
import DataContext from "../../../Components/Contexts/DataContext";


interface ChargingRateHistoryProps {
    isOpen: boolean;
    toggleModal: () => void;
}

const ChargingRateHistory = ({ isOpen, toggleModal }: ChargingRateHistoryProps) => {
    const editContext = useContext(EditContext);
    const context = useContext(DataContext);
    const chargingRateHistory = editContext.editChargingRateHistory;
    const { t } = useTranslation();
    return (
        <ModalForm
            title={t('chargingRateHistory.detail')}
            isOpen={isOpen}
            toggleModal={toggleModal}
        >

                <Row>
                    <Col md={6} sm={12}>
                        <h4>{t('chargingRateHistory.beforeSnapshot')}</h4>
                        <FormGroup >
                            <Label for="beforeSnapshotTitle">{t('AddChargingRate.title')}</Label>
                                <Input  defaultValue={chargingRateHistory.beforeSnapshot?.title}/>
                        </FormGroup>
                        <FormGroup >
                            <Label for="beforeSnapshotBasePrice" >{
                                t('AddChargingRate.basePrice') +
                                chargingRateHistory.applicationId !==
                                ''
                                    ? `${t(
                                        'AddChargingRate.basePrice'
                                    )}(${getLabelCurrency(
                                        (
                                            context.applications.find(
                                                (x: any) =>
                                                    x._id === chargingRateHistory.applicationId
                                            ) || {}
                                        ).currency,
                                        'symbol'
                                    )})`
                                    : ''
                            }</Label>
                                <Input  defaultValue={chargingRateHistory.beforeSnapshot?.basePrice} />
                        </FormGroup>
                        <FormGroup >
                            <Label for="beforeSnapshotFeePerKWh" >{
                                t('AddChargingRate.feePerkWh') +
                                chargingRateHistory.applicationId !==
                                ''
                                    ? `${t(
                                        'AddChargingRate.feePerkWh'
                                    )}(${getLabelCurrency(
                                        (
                                            context.applications.find(
                                                (x: any) =>
                                                    x._id === chargingRateHistory.applicationId
                                            ) || {}
                                        ).currency,
                                        'symbol'
                                    )})`
                                    : ''
                            }</Label>
                            <Input  defaultValue={chargingRateHistory.beforeSnapshot?.feePerKWh} />
                        </FormGroup>
                    </Col>

                    <Col md={6} sm={12}>
                        <h4>{t('chargingRateHistory.afterSnapshot')}</h4>
                        <FormGroup>
                            <Label for="afterSnapshotTitle">{t('AddChargingRate.title')}</Label>
                            <Input  defaultValue={chargingRateHistory.afterSnapshot?.title}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="afterSnapshotBasePrice" >{
                                t('AddChargingRate.basePrice') +
                                chargingRateHistory.applicationId !==
                                ''
                                    ? `${t(
                                        'AddChargingRate.basePrice'
                                    )}(${getLabelCurrency(
                                        (
                                            context.applications.find(
                                                (x: any) =>
                                                    x._id === chargingRateHistory.applicationId
                                            ) || {}
                                        ).currency,
                                        'symbol'
                                    )})`
                                    : ''
                            }</Label>
                            <Input  defaultValue={chargingRateHistory.afterSnapshot?.basePrice}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="beforeSnapshotFeePerKWh" >{
                                t('AddChargingRate.feePerkWh') +
                                chargingRateHistory.applicationId !==
                                ''
                                    ? `${t(
                                        'AddChargingRate.feePerkWh'
                                    )}(${getLabelCurrency(
                                        (
                                            context.applications.find(
                                                (x: any) =>
                                                    x._id === chargingRateHistory.applicationId
                                            ) || {}
                                        ).currency,
                                        'symbol'
                                    )})`
                                    : ''
                            }</Label>
                            <Input  defaultValue={chargingRateHistory.afterSnapshot?.feePerKWh}
                            />
                        </FormGroup>
                    </Col>


                    <Col md={12} sm={12}>
                        <FormGroup>
                            <Label for="exampleEmail">{t('chargingRateHistory.note')}</Label>
                            <Input  defaultValue={chargingRateHistory.note}
                            />
                        </FormGroup>
                     </Col>

                        <Col>
                            <Button
                                block
                                color='primary'
                                type='submit'
                                className='w-20'
                                onClick={() => {
                                   toggleModal();
                                }}
                            >
                                {t('chargingRateHistory.close')}
                            </Button>
                        </Col>


                </Row>

        </ModalForm>
    );
};

export default ChargingRateHistory;
