import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { renderField } from 'Components/Common/Fields';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button, Row, Col, Spinner, FormGroup, Label, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import SlidingModal from 'Components/Common/SlidingModal';
import i18n from 'i18next';
import DataContext from 'Components/Contexts/DataContext';
import {
  createPermission,
  getDomains,
  getRoles,
  getApplications,
  getLocations,
  createRoles,
} from 'actions';
import { PermissionType, grantsType } from 'declerations/DefaultTypes';

let allGrants: any[] = [];

const formSchema = () =>
  Yup.object().shape({
    title: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    role: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    scope: Yup.string().min(1, 'Required').required(i18n.t('Required')),
  });
interface AddPermissionsProps {
  isOpen: boolean;
  toggleHandle: () => void;
}

interface ScopeType {
  label: string;
  value: string;
}

const AddPermissions = ({ isOpen, toggleHandle }: AddPermissionsProps) => {
  const { t } = useTranslation();
  const context = useContext(DataContext);
  const [roles, setRoles] = useState<PermissionType[]>([]);
  const [loading, setLoading] = useState(false);
  const [availableGrants, setAvailableGrants] = useState<grantsType[]>([]);
  const [selectOptions, setSelectOptions] = useState<any>([]);
  const scopeTypes = [
    { label: i18n.t('AddPermission.Application'), value: 'Application' },
    { label: i18n.t('AddPermission.Domain'), value: 'Domain' },
    { label: i18n.t('AddPermission.Location'), value: 'Location' },
  ];
  const initialValues: {
    title: string;
    role: string;
    scope: string;
    grants: {
      resource: string;
      actions: string[];
    }[];
  } = {
    title: '',
    role: '',
    scope: '',
    grants: [],
  };

  useEffect(() => {
    context.LoadProfile();
  }, []);
  useEffect(() => {
    allGrants = availableGrants;
    console.log('availableGrants changed', availableGrants);
  }, [availableGrants]);

  const chooseScope = (scope: string) => {
    let grants: grantsType[] = [];

    switch (scope) {
      case 'Domain':
        setAvailableGrants(context?.profile?.defaultGrants);
        return context?.profile?.defaultGrants;
      case 'Application':
        grants = context.profile?.permissions?.find(
          (p: any) => p.scopeType === 'Application'
        )?.role?.grants;
        if (!grants || grants.length <= 0) {
          grants = context?.profile?.defaultGrants.map((g: grantsType) => {
            if (g.resource === 'domains') {
              return {
                resource: 'domains',
                actions: g.actions.filter((x) => x === 'read'),
              };
            } else if (g.resource === 'applications') {
              return {
                resource: 'applications',
                actions: g.actions.filter((x) => x === 'read'),
              };
            }
            return g;
          });
        }
        setAvailableGrants(grants);
        return grants;
      case 'Location':
        grants = context.profile?.permissions?.find(
          (p: any) => p.scopeType === 'Location'
        )?.role?.grants;
        if (!grants || grants.length <= 0) {
          grants = context?.profile?.defaultGrants.map((g: grantsType) => {
            if (g.resource === 'domains') {
              return {
                resource: 'domains',
                actions: g.actions.filter((x) => x === 'read'),
              };
            } else if (g.resource === 'applications') {
              return {
                resource: 'applications',
                actions: g.actions.filter((x) => x === 'read'),
              };
            } else if (g.resource === 'locations') {
              return {
                resource: 'locations',
                actions: g.actions.filter((x) => x === 'read'),
              };
            }
            return g;
          });
        }
        setAvailableGrants(grants);
        return grants;
      default:
        setAvailableGrants(context?.profile?.defaultGrants);
        return context?.profile?.defaultGrants;
    }
  };

  return (
    <SlidingModal
      toggleHandle={toggleHandle}
      isOpen={isOpen}
      title={t('AddPermission.AddPermission')}
      size='sm'
    >
      {!loading ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values) => {
            let { grants } = values;
            grants = grants.filter((x) => x.actions.length > 0);
            const newValues = { ...values, grants };

            createRoles(newValues)
              .then((response) => {
                toast.success(t('AddPermission.successfullyAdded'));
                return toggleHandle();
              })
              .catch((e) => {
                toast.error(t('AddPermission.couldntCreatePermission'));
              });
          }}
          validationSchema={formSchema}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            errors,
            touched,
            setFieldTouched,
          }) => {
            console.log('errors', errors);
            // console.log('grants', values.grants);.
            // console.log('availablerants', availableGrants);
            useMemo(() => {
              Object.keys(errors).forEach((fieldName) => {
                setFieldTouched(fieldName);
              });
            }, [i18n.language]);
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='title'
                      label={t('AddPermission.title')}
                      placeholder='Domain Admin'
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.title,
                        error: errors.title,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='role'
                      label={t('AddPermission.role')}
                      placeholder='domain/admin'
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.role,
                        error: errors.role,
                      }}
                    />
                  </Col>
                </Row>

                <Row className='mb-2'>
                  <Col>
                    <label htmlFor='scope'>{t('AddPermission.scope')}</label>
                    <Select
                      id='scope'
                      placeholder={t('Select')}
                      onChange={(data: any) => {
                        setFieldValue('scope', data.value);
                        console.log('onchange scope');

                        setFieldValue('grants', [...chooseScope(data.value)]);
                      }}
                      value={{ label: values.scope, value: values.scope }}
                      options={context?.profile?.allowedScopes.map(
                        (x: any) => ({
                          label: x,
                          value: x,
                        })
                      )}
                    />
                    {/* {console.log(context.profile.allowedScopes)} */}
                    <ErrorMessage
                      name='scope'
                      component='div'
                      className='text-danger text-sm'
                    />
                  </Col>
                </Row>
                {availableGrants.map((g: grantsType, index: number) => {
                  return (
                    <Row key={g.resource + index.toString()}>
                      <Col className='my-auto'>
                        <FormGroup className='p-0'>
                          <table className='table'>
                            <thead>
                              <tr>
                                <th>
                                  <Label
                                    className='form-check-label me-2 fs-5'
                                    for={g.resource}
                                  >
                                    {t(
                                      `AddPermission.${g.resource}`,
                                      g.resource
                                    )}
                                    <br />
                                  </Label>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <Row>
                                {g.actions &&
                                  g.actions.length > 0 &&
                                  g.actions.map((action: string) => {
                                    return (
                                      <Col xl={4} key={action} className='my-1'>
                                        <Input
                                          name={`${g.resource}.${action}`}
                                          className='form-check-input pt-1'
                                          type='checkbox'
                                          color='primary'
                                          checked={values.grants
                                            .find(
                                              (x) => x.resource === g.resource
                                            )
                                            ?.actions?.includes(action)}
                                          label={action}
                                          id={`${g.resource}.${action}`}
                                          onChange={(e: any) => {
                                            const newGrants = values.grants.map(
                                              (_x) => {
                                                const x = _x;
                                                if (x.resource === g.resource) {
                                                  if (e.target.checked) {
                                                    if (
                                                      !x.actions.includes(
                                                        action
                                                      )
                                                    ) {
                                                      x.actions = [
                                                        ...x.actions,
                                                        action,
                                                      ];
                                                    }
                                                  } else if (
                                                    x.actions.includes(action)
                                                  ) {
                                                    // x.actions = x.actions.filter(
                                                    //   (y) => y !== action
                                                    // );
                                                    return {
                                                      resource: x.resource,
                                                      actions: x.actions.filter(
                                                        (y) => y !== action
                                                      ),
                                                    };
                                                  }
                                                }
                                                return x;
                                              }
                                            );
                                            setFieldValue(`grants`, newGrants);
                                          }}
                                        />{' '}
                                        <Label
                                          className='form-check-label me-2'
                                          for={`${g.resource}.${action}`}
                                        >
                                          {t(`AddPermission.${action}`, action)}
                                        </Label>
                                      </Col>
                                    );
                                  })}
                              </Row>
                            </tbody>
                          </table>
                        </FormGroup>
                      </Col>
                    </Row>
                  );
                })}

                <Row className='mt-3'>
                  <Col className='text-end'>
                    <Button
                      color='secondary'
                      type='button'
                      disabled={isSubmitting}
                      onClick={toggleHandle}
                      className='me-1'
                    >
                      {t('AddEmployes.cancel')}
                    </Button>
                    <Button
                      color='primary'
                      type='submit'
                      disabled={isSubmitting}
                    >
                      {t('AddEmployes.add')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Spinner />
      )}
    </SlidingModal>
  );
};
export default AddPermissions;
