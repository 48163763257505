import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SlidingModal from 'Components/Common/SlidingModal';
import { MapContainer, TileLayer, Marker, useMap, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import Leaflet, { LatLng, LatLngBounds, latLngBounds } from 'leaflet';
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import EditContext from 'Components/Contexts/EditContext';
import DataTable from 'Components/Common/DataTable';
import DataContext from 'Components/Contexts/DataContext';
import getSocketSvg from 'helpers/Socket';
import { ChargeStationType } from 'declerations/DefaultTypes';
import { ASSET_BASE_URL } from 'helpers/config';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css'; // sass

interface LocationDetailProps {
  isOpen: boolean;
  toggleHandle: () => void;
}
const iconPerson = new Leaflet.Icon({
  iconUrl: `${ASSET_BASE_URL}pin.svg`,
  iconRetinaUrl: `${ASSET_BASE_URL}pin.svg`,
  iconSize: new Leaflet.Point(30, 60),
});
interface IChangeView {
  markers: ChargeStationType[];
}

const LocationDetail = ({ isOpen, toggleHandle }: LocationDetailProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const editContext = useContext(EditContext);
  const selectedLocation = editContext.editLocation;
  const context = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [bounds, setbounds] = useState<LatLngBounds>();
  useEffect(() => {
    setLoading(true);
    context
      .LoadChargeStations({ locationId: selectedLocation?._id })
      .then(async () => {});
  }, [selectedLocation]);

  useEffect(() => {
    if (context.chargeStations) {
      const locationArray = context.chargeStations?.map(
        (x: ChargeStationType) =>
          x?.location && [x?.location[1], x?.location[0]]
      );
      setbounds(Leaflet.latLngBounds([...locationArray]));
      setLoading(false);
    }
  }, [context.chargeStations]);

  const ChangeView = () => {
    const map = useMap();
    if (context.chargeStations.location) {
      map.setView({
        lng: context.chargeStations[0].location[0],
        lat: context.chargeStations[0].location[1],
      });
    }

    const markerBounds = latLngBounds([]);
    context.chargeStations.forEach((item: ChargeStationType) => {
      if (item.location && item.location.length > 1)
        markerBounds.extend([item.location[1], item.location[0]]);
    });
    map.fitBounds(markerBounds); // <===== Error: Bounds are not valid.
    return null;
  };
  return (
    <SlidingModal
      toggleHandle={toggleHandle}
      title={selectedLocation.title}
      isOpen={isOpen}
      size='xl'
    >
      <h5>{t('Location.chargeStationsMap')}</h5>
      <Card>
        <CardBody>
          {!loading && (
            <MapContainer
              zoom={11}
              style={{ width: '100%', height: '400px' }}
              className='markercluster-map'
              center={[38.958552, 35.417003]}
              bounds={bounds}
            >
              {context.chargeStations && context.chargeStations.length > 0 && (
                <ChangeView />
              )}

              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='https://api.maptiler.com/maps/voyager/256/{z}/{x}/{y}.png?key=GZCHDTppR0lsIJy3ghnK'
              />
              <MarkerClusterGroup showCoverageOnHover={false}>
                {context.chargeStations &&
                  context.chargeStations.map(
                    (station: ChargeStationType, index: number) =>
                      station.location &&
                      station.location[0] &&
                      station.location[1] && (
                        <Marker
                          icon={iconPerson}
                          position={[station.location[1], station.location[0]]}
                          key={index.toString()}
                        >
                          <Popup>
                            <div className=''>
                              <div className=''>
                                <h4>{t('Charge Station')}</h4>
                                <div style={{ fontSize: '14px' }}>
                                  <span style={{ color: 'grey' }}>
                                    {station.name}
                                  </span>
                                </div>
                              </div>

                              <div>
                                <h4 style={{ paddingTop: '2px' }}>
                                  {t('Charge Point Id')}
                                </h4>
                                <div style={{ fontSize: '14px' }}>
                                  <span style={{ color: 'grey' }}>
                                    {station.chargePointId}
                                  </span>
                                </div>
                              </div>

                              <div className='my-1'>
                                <Button
                                  className='col-12 align-self-center'
                                  onClick={() => {
                                    history.push(
                                      `/cpo/charge-station/${station._id}`
                                    );
                                  }}
                                >
                                  {t('goDetail')}
                                </Button>
                              </div>
                            </div>
                          </Popup>
                        </Marker>
                      )
                  )}
              </MarkerClusterGroup>
            </MapContainer>
          )}
        </CardBody>
      </Card>
      <Row className='mb-2'>
        <Col>
          <h5>{t('Location.chargeStationsList')}</h5>
        </Col>
        {/* <Col className='text-end'>
          <Button color='primary'>Reset Selected</Button>
        </Col> */}
      </Row>
      <DataTable
        // selectableRows
        noDataText={t('Location.noChargingStation')}
        loading={loading}
        columns={[
          {
            sortable: false,
            maxWidth: '32px',
            width: '32px',
            minWidth: '32px',
            cell: (row: ChargeStationType) => {
              return (
                <div>
                  <span
                    id={`chargeStation${row._id}`}
                    style={{ padding: 8, cursor: 'pointer' }}
                  >
                    <span
                      style={{
                        padding: 4,
                        display: 'inline-block',
                        backgroundColor: (() => {
                          if (!row.isOnline) {
                            return '#dd3333';
                          }
                          return '#22dd22';
                        })(),
                        borderRadius: '50%',
                      }}
                    />
                  </span>
                  <UncontrolledTooltip
                    placement='top'
                    target={`chargeStation${row._id}`}
                  >
                    {t(
                      `ChargeStations.${(() => {
                        if (!row.isOnline) {
                          return 'chargeStationOffline';
                        }
                        return 'chargeStationOnline';
                      })()}`
                    )}
                  </UncontrolledTooltip>
                </div>
              );
            },
          },
          {
            name: t('ChargeStations.CS Id'),
            selector: (row: ChargeStationType) => row.chargePointId,
            sortable: true,
          },
          {
            name: t('ChargeStations.name'),
            selector: (row: ChargeStationType) => row.name,
            sortable: true,
          },
          {
            name: t('ChargeStations.connectors'),
            cell: (props: ChargeStationType) => {
              const { connectors } = props;
              return connectors && connectors.length > 0 ? (
                <div>
                  {connectors.map((x, index) => (
                    <span
                      key={index.toString()}
                      className='me-1'
                      id={`connector${x._id}`}
                    >
                      {getSocketSvg(
                        x.socketType,
                        (() => {
                          if (x.status && x.status.status) {
                            switch (x.status.status) {
                              case 'Available':
                                return '#44aa44';
                              case 'Preparing':
                                return '#44aa44';
                              case 'Finishing':
                                return '#44aa44';
                              case 'Reserved':
                                return '#44aa44';
                              case 'SuspendedEvse':
                                return '#dd3333';
                              case 'SuspendedEV':
                                return '#dd3333';
                              case 'Unavailable':
                                return '#dd3333';
                              case 'Faulted':
                                return '#dd3333';
                              case 'Charging':
                                return '#ccbb44';
                              default:
                                return '#dd3333';
                            }
                          }
                          if (!x.isOnline) {
                            return '#dd3333';
                          }
                          if (x.transactionState === 'Active') {
                            return '#ccbb44';
                          }
                          return '#44aa44';
                        })()
                      )}

                      <UncontrolledTooltip
                        placement='top'
                        target={`connector${x._id}`}
                      >
                        {x.status && x.status.status
                          ? x.status.status
                          : t(
                              `ChargeStations.${(() => {
                                if (!x.isOnline) {
                                  return 'connectorOffline';
                                }
                                if (x.transactionState === 'Active') {
                                  return 'connectorCharging';
                                }
                                return 'connectorAvailable';
                              })()}`
                            )}
                      </UncontrolledTooltip>
                    </span>
                  ))}
                </div>
              ) : (
                <div />
              );
            },
          },
        ]}
        data={context.chargeStations || []}
      />
    </SlidingModal>
  );
};

export default LocationDetail;
