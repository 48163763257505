import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from '../../store/auth/authSlice';

// redux

const Logout = () => {
  const dispatch = useDispatch();

  const { isUserLogout } = useSelector((state) => ({
    isUserLogout: state.Login.isUserLogout,
  }));

  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  if (isUserLogout) {
    return <Redirect to='/login' />;
  }

  return null;
};

export default withRouter(Logout);
