import React, { useEffect, useState } from 'react';
import {
  Card,
  CardTitle,
  CardHeader,
  Row,
  Col,
  UncontrolledTooltip,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import * as Icon from 'react-feather';
import Tippy from '@tippyjs/react';
import { closingDeals } from 'common/data';

type BestPerformanceRankingDataArray = {
  title: string;
  timeline?: number;
  timelineType?: string;
  chargePointId?: number;
  vehicleMake?: string;
  vehicleModel?: string;
  location?: string;
  powerConsumptionInKWh?: number;
  durationInMins?: number;
  chargePointName?: string;
};

type BestPerformanceProps = {
  title: any;
  data: BestPerformanceRankingDataArray[];
};

const BestPerformance = ({ title, data }: BestPerformanceProps) => {
  const { t } = useTranslation();
  const color = ['#c7332e', '#a3bb49', '#be3879', '#e28e36', '#9dfcb6'];
  const [dayOfWeek, setDayOfWeek] = useState<boolean>(false);

  const localData =
    title === 'TIMELINES' && !dayOfWeek
      ? data?.filter((el) => {
          return el && el.timelineType && el.timelineType === 'hour';
        })
      : title === 'TIMELINES' && dayOfWeek
      ? data?.filter((el) => {
          return el && el.timelineType && el.timelineType === 'dayOfWeek';
        })
      : data;
  return (
    <Card style={{ minHeight: '190px' }}>
      <CardHeader style={{ padding: '10px' }}>
        <CardTitle
          className='best-performing-card-title justify-content-betwen noHighlighter'
          // style={{ fontSize: 14, margin: 0, textAlign: 'left' }}
        >
          <div>
            {t('Home.bestPerforming')}{' '}
            {title === 'CARS'
              ? t('Home.cars')
              : title === 'CHARGE STATIONS'
              ? t('Home.chargeStations')
              : title === 'TIMELINES'
              ? t('Home.timelines')
              : ''}
          </div>
          <div className='d-flex flex-row'>
            {title === 'TIMELINES' && (
              <div
                className='me-3'
                aria-hidden='true'
                onClick={() => {
                  setDayOfWeek((prevState) => !prevState);
                }}
                style={{
                  backgroundColor: 'white',
                  color: '#03b2cb',
                  borderWidth: 0,
                  cursor: 'pointer',
                  textAlign: 'right',
                }}
              >
                {t('Home.days_hours')}
              </div>
            )}
            <div>
              <span
                className='hamburger-icon me-0 '
                style={{ cursor: 'default' }}
              >
                <span />
                <span />
                <span />
              </span>
            </div>
          </div>
        </CardTitle>
      </CardHeader>
      <div className='d-flex flex-column'>
        {localData?.map((el, ind) => {
          if (title === 'TIMELINES' && ind < 5) {
            return (
              <div className='best-performing-row noHighlighter' key={ind}>
                <div id='left-row'>
                  <div
                    className='rounded-circle'
                    style={{
                      height: 8,
                      width: 8,
                      backgroundColor: color[ind],
                      marginRight: 2,
                    }}
                  >
                    {' '}
                  </div>
                  <div style={{ paddingLeft: 8 }}>
                    {el.timelineType === 'hour'
                      ? `${el.timeline}`
                      : `${
                          el.timeline === 1
                            ? t('Days.sunday')
                            : el.timeline === 2
                            ? t('Days.monday')
                            : el.timeline === 3
                            ? t('Days.tuesday')
                            : el.timeline === 4
                            ? t('Days.wednesday')
                            : el.timeline === 5
                            ? t('Days.thursday')
                            : el.timeline === 6
                            ? t('Days.friday')
                            : el.timeline === 7
                            ? t('Days.saturday')
                            : ''
                        }`}
                  </div>
                </div>
                <span id='right-row'>
                  <span id='right-row-text'>
                    {el.powerConsumptionInKWh?.toFixed(0)}{' '}
                  </span>
                  <span className='unitName'>kWh</span>
                </span>
              </div>
            );
          }
          if (title === 'CHARGE STATIONS' && ind < 5) {
            return (
              <div className='best-performing-row noHighlighter' key={ind}>
                <span id='left-row'>
                  <span
                    className='rounded-circle'
                    style={{
                      height: 8,
                      width: 8,
                      backgroundColor: color[ind],
                      marginRight: 6,
                    }}
                  >
                    {' '}
                  </span>
                  <span id={`chargeStation${el.chargePointId}`}>
                    {el.chargePointId}
                  </span>
                </span>
                <span id='right-row'>
                  <span id='right-row-text'>
                    {el.powerConsumptionInKWh?.toFixed(0)}{' '}
                  </span>
                  <span className='unitName'>kWh</span>
                </span>{' '}
                {el.chargePointName ? (
                  <UncontrolledTooltip
                    placement='top'
                    // target='cpName'
                    target={`chargeStation${el.chargePointId}`}
                  >
                    {el.chargePointName}
                  </UncontrolledTooltip>
                ) : null}
              </div>
            );
          }
          if (title === 'CARS' && ind < 5) {
            return (
              <div className='best-performing-row noHighlighter' key={ind}>
                <span id='left-row'>
                  <span
                    className='rounded-circle'
                    style={{
                      height: 8,
                      width: 8,
                      backgroundColor: color[ind],
                      marginRight: 6,
                    }}
                  >
                    {' '}
                  </span>
                  <span>{`${el.vehicleMake} ${el.vehicleModel}`}</span>
                </span>
                <span id='right-row'>
                  <span id='right-row-text'>
                    {el.powerConsumptionInKWh?.toFixed(0)}{' '}
                  </span>
                  <span className='unitName'>kWh</span>
                </span>
              </div>
            );
          }
          return null;
        })}
      </div>
    </Card>
  );
};

export default BestPerformance;
