import { APIClient } from '../helpers/api_helper';

const api = new APIClient();
export const postPushNotification = (params: object) => {
  const apiUrl = `/persons/send-push`;
  return api.create(apiUrl, params);
};

export const getPersons = () => {
  const apiUrl = 'persons/lookup';

  return api.get(apiUrl);
};
