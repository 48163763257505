import React from 'react';
import { THEME } from '../helpers/config';

/**
 * The theme components only imports it's theme CSS-file. These components are lazy
 * loaded, to enable "code splitting" (in order to avoid the themes being bundled together)
 */
const Theme = React.lazy(() => import('./theme'));
const GioTheme = React.lazy(() => import('./gio-theme'));

const ThemeSelector: React.FC = ({ children }) => (
  <>
    {/* Conditionally render theme, based on the current client context */}
    <React.Suspense fallback={() => null}>
      {THEME === 'default' && <Theme>{children}</Theme>}
      {THEME === 'gio' && <GioTheme>{children}</GioTheme>}
      {console.log('THeme', THEME)}
    </React.Suspense>
    {/* Render children immediately! */}
  </>
);

export default ThemeSelector;
