import React from 'react';
import { Card, CardBody, Button, Popover, PopoverBody } from 'reactstrap';
import getSocketSvg from 'helpers/Socket';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import Tippy from '@tippyjs/react';
import i18n from 'i18n';

const CardWidget = ({
  label,
  twocounter,
  popoverdes,
  badge,
  icon,
  prefix,
  suffix,
  counter,
  counter2,
  counter3,
  value,
  separator,
  decimals,
  url,
  last,
  bgColor,
  labelClass,
  counterClass,
  iconClass,
  iconv2,
  label2,
  label3,
  previousCount,
  readyToCalculate,
  appliedDate,
  formattingFn,
  onClick,
}: {
  label: string;
  label2?: string | undefined;
  label3?: string | undefined;
  badge: string;
  popoverdes?: string;
  icon: any;
  twocounter?: number | undefined;
  prefix?: string;
  suffix?: string;
  counter?: number | undefined;
  counter2?: number | undefined;
  counter3?: number | undefined;
  value?: string | undefined;
  separator?: string;
  decimals?: number;
  url?: string;
  last?: boolean;
  bgColor?: string;
  labelClass?: string;
  counterClass?: string;
  iconClass?: string;
  iconv2?: any;
  previousCount?: any;
  appliedDate?: any;
  readyToCalculate?: boolean | undefined;
  formattingFn?: ((val: number) => string) | undefined;
  onClick?: () => void;
}) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const increaseDecreaseStatus = React.useRef<string>();
  const increaseDecreaseProspect = React.useRef<string>();

  const { t } = useTranslation();

  const startDate = appliedDate?.startDate?.slice(0, 10).replaceAll('-', '.');
  const endDate = appliedDate?.endDate?.slice(0, 10).replaceAll('-', '.');

  // Increase - decrease settings
  if (readyToCalculate) {
    if (counter === 0 || previousCount === 0) {
      increaseDecreaseStatus.current = 'Calculate failed';
    } else {
      if (typeof counter === 'number' && counter > previousCount) {
        const increaseProspect: number = (counter * 100) / previousCount - 100;
        const fixedIncreasedProspect = increaseProspect.toFixed(0);

        increaseDecreaseStatus.current = 'Increase';
        increaseDecreaseProspect.current = `${fixedIncreasedProspect}%`;
      }
      if (typeof counter === 'number' && previousCount > counter) {
        const decreaseProspect: number = 100 - (counter * 100) / previousCount;
        const fixedDecreasedProspect = decreaseProspect.toFixed(0);

        increaseDecreaseStatus.current = 'Decrease';
        increaseDecreaseProspect.current = `${fixedDecreasedProspect}%`;
      }
    }
  }

  const toggle = () => setPopoverOpen(!popoverOpen);
  return (
    <Card className={`card-animate ${bgColor}`} onClick={onClick}>
      {!label2 && !label3 ? (
        <CardBody>
          <div>
            <div className='d-flex flex-row justify-content-between align-items-center noHighlighter'>
              <div className='avatar-xs flex-shrink-0 noHighlighter'>
                {icon}
              </div>
              {increaseDecreaseStatus.current !== 'Calculate failed' ? ( // if AC/DC
                <Tippy
                  content={
                    <p
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#133b50',
                        color: 'white',
                        borderRadius: 8,
                        padding: 6,
                      }}
                      className='noHighlighter'
                    >
                      {label} <br /> {startDate} - {endDate} <br />{' '}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <div>{t('Home.was')}</div>
                        <div style={{ fontWeight: 'bold', marginLeft: 4 }}>
                          {prefix || ''} {previousCount}
                          {suffix ? ` ${suffix}` : null}.
                        </div>
                      </div>
                    </p>
                  }
                >
                  <div
                    className='d-flex flex-row justify-content-between align-items-center card-animate increase-decrease-card noHighlighter'
                    style={{ padding: 8, cursor: 'default' }}
                  >
                    <div className='me-2'>
                      {increaseDecreaseStatus.current === 'Increase'
                        ? getSocketSvg('IncreaseIcon', 'inherit')
                        : increaseDecreaseStatus.current === 'Decrease'
                        ? getSocketSvg('DecreaseIcon', 'inherit')
                        : null}
                    </div>
                    <div
                      style={{
                        fontWeight: '600',
                        color:
                          increaseDecreaseStatus.current === 'Increase'
                            ? '#56BC9F'
                            : '#E26F6B',
                      }}
                      className='fw-medium'
                    >
                      {increaseDecreaseProspect.current}
                    </div>
                  </div>
                </Tippy>
              ) : null}
            </div>
            <div className='mt-3'>
              <p
                className={`fw-medium fs-5 mb-0 text-${labelClass} noHighlighter`}
              >
                {label}{' '}
                <span className='ml-2 mt-0 noHighlighter'>
                  {iconv2 ? (
                    <>
                      <Button
                        id='Popover'
                        type='button'
                        style={{
                          backgroundColor: 'transparent',
                          border: 'none',
                          padding: '0px',
                        }}
                        onMouseOver={toggle}
                        onMouseOut={toggle}
                      >
                        {iconv2}
                      </Button>
                      <Popover
                        placement='bottom'
                        isOpen={popoverOpen}
                        target='Popover'
                      >
                        <PopoverBody
                          style={{
                            fontSize: '13px',
                            fontWeight: '400',
                            color: '#878a99',
                          }}
                        >
                          {popoverdes}
                        </PopoverBody>
                      </Popover>
                    </>
                  ) : (
                    ''
                  )}
                </span>
              </p>

              <h5 className={`mt-0 ff-secondary fw-semibold ${counterClass}`}>
                <span className='counter-value noHighlighter'>
                  {counter &&
                    (formattingFn ? (
                      <CountUp
                        start={0}
                        delay={0}
                        prefix={prefix || ''}
                        suffix={suffix || ''}
                        separator={separator}
                        end={counter}
                        decimals={decimals}
                        duration={2}
                        formattingFn={formattingFn}
                      />
                    ) : (
                      <CountUp
                        start={0}
                        delay={0}
                        prefix={prefix || ''}
                        suffix={suffix || ''}
                        separator={separator}
                        end={counter}
                        decimals={decimals}
                        duration={2}
                      />
                    ))}
                  {value} {twocounter ? '/ ' : twocounter === 0 ? '/ ' : ''}
                  {twocounter &&
                    (formattingFn ? (
                      <CountUp
                        start={0}
                        delay={0}
                        prefix={prefix || ''}
                        suffix={suffix || ''}
                        separator={separator}
                        end={twocounter}
                        decimals={decimals}
                        duration={2}
                        formattingFn={formattingFn}
                      />
                    ) : (
                      <CountUp
                        start={0}
                        delay={0}
                        prefix={prefix || ''}
                        suffix={suffix || ''}
                        separator={separator}
                        end={twocounter}
                        decimals={decimals}
                        duration={2}
                      />
                    ))}
                </span>
              </h5>
            </div>
          </div>
        </CardBody>
      ) : (
        <CardBody>
          <div>
            <div
              className='d-flex flex-row justify-content-between align-items-center noHighlighter'
              style={{ marginBottom: '-4px' }}
            >
              <div className='avatar-xs flex-shrink-0 noHighlighter'>
                {icon}
              </div>
            </div>
            <div className='mt-3 d-flex flex-row justify-content-between'>
              <div className='mt-1'>
                <p
                  className={`fw-medium fs-5 mb-0 text-${labelClass} noHighlighter`}
                >
                  {label}{' '}
                </p>

                <h5 className={`mt-0  ${counterClass}`}>
                  <span className='counter-value noHighlighter'>
                    {counter &&
                      (formattingFn ? (
                        <CountUp
                          start={0}
                          delay={0}
                          prefix={prefix || ''}
                          suffix={suffix || ''}
                          separator={separator}
                          end={counter}
                          decimals={decimals}
                          duration={2}
                          formattingFn={formattingFn}
                        />
                      ) : (
                        <CountUp
                          start={0}
                          delay={0}
                          prefix={prefix || ''}
                          suffix={suffix || ''}
                          separator={separator}
                          end={counter}
                          decimals={decimals}
                          duration={2}
                        />
                      ))}
                    {value} {twocounter ? '/ ' : twocounter === 0 ? '/ ' : ''}
                    {twocounter &&
                      (formattingFn ? (
                        <CountUp
                          start={0}
                          delay={0}
                          prefix={prefix || ''}
                          suffix={suffix || ''}
                          separator={separator}
                          end={twocounter}
                          decimals={decimals}
                          duration={2}
                          formattingFn={formattingFn}
                        />
                      ) : (
                        <CountUp
                          start={0}
                          delay={0}
                          prefix={prefix || ''}
                          suffix={suffix || ''}
                          separator={separator}
                          end={twocounter}
                          decimals={decimals}
                          duration={2}
                        />
                      ))}
                  </span>
                </h5>
              </div>
              <div>
                {label2 !== '' && (
                  <div className='mt-1'>
                    <p
                      className={`fw-medium fs-5 mb-0 text-${labelClass} noHighlighter`}
                    >
                      {label2}{' '}
                    </p>

                    <h5 className={`mt-0  ${counterClass}`}>
                      <span className='counter-value noHighlighter'>
                        {counter2 &&
                          (formattingFn ? (
                            <CountUp
                              start={0}
                              delay={0}
                              prefix={prefix || ''}
                              suffix={suffix || ''}
                              separator={separator}
                              end={counter2}
                              decimals={decimals}
                              duration={2}
                              formattingFn={formattingFn}
                            />
                          ) : (
                            <CountUp
                              start={0}
                              delay={0}
                              prefix={prefix || ''}
                              suffix={suffix || ''}
                              separator={separator}
                              end={counter2}
                              decimals={decimals}
                              duration={2}
                            />
                          ))}
                        {value}
                      </span>
                    </h5>
                  </div>
                )}
              </div>
              <div>
                {label3 !== '' && (
                  <div className='mt-1'>
                    <p
                      className={`fw-medium fs-5 mb-0 text-${labelClass} noHighlighter`}
                    >
                      {label3}{' '}
                    </p>

                    <h5
                      className={`mt-0 ff-secondary fs-0 fw-semibold ${counterClass}`}
                    >
                      <span className='counter-value noHighlighter'>
                        {counter3 &&
                          (formattingFn ? (
                            <CountUp
                              start={0}
                              delay={0}
                              prefix={prefix || ''}
                              suffix={suffix || ''}
                              separator={separator}
                              end={counter3}
                              decimals={decimals}
                              duration={2}
                              formattingFn={formattingFn}
                            />
                          ) : (
                            <CountUp
                              start={0}
                              delay={0}
                              prefix={prefix || ''}
                              suffix={suffix || ''}
                              separator={separator}
                              end={counter3}
                              decimals={decimals}
                              duration={2}
                            />
                          ))}
                        {value}
                      </span>
                    </h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </CardBody>
      )}
    </Card>
  );
};

CardWidget.defaultProps = {
  popoverdes: ' ',
  iconv2: '',
  bgColor: '',
  labelClass: 'muted',
  counterClass: '',
  iconClass: 'primary',
  twocounter: '',
  prefix: '',
  suffix: '',
  label2: '',
  label3: '',
  appliedDate: '',
  readyToCalculate: false,
  previousCount: undefined,
  value: undefined,
  counter: undefined,
  counter2: undefined,
  counter3: undefined,
  separator: undefined,
  decimals: undefined,
  url: undefined,
  last: undefined,
  formattingFn: undefined,
  onClick: undefined,
};

export default CardWidget;
