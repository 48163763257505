import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import ReactApexChart from 'react-apexcharts';

import { getLabelCurrency } from '../../../../helpers/Currencies';

type RevenueReportType = {
  revenueReport: {
    expenses: number[];
    earnings: number[];
    currency: string;
  };
  changeYear: (year: string) => void;
};

const RevenueReport = ({ revenueReport, changeYear }: RevenueReportType) => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    years: ['2023', '2022', '2021', '2019', '2018'],
    price: 0,
    earnings: 0,
    expenses: 0,
    budget: 0,
  });
  const [selectedYear, setSelectedYear] = useState('2023');
  useEffect(() => {
    const currencySymbol = getLabelCurrency(revenueReport.currency, 'symbol');

    const earnings =
      revenueReport &&
      revenueReport.earnings &&
      revenueReport.earnings.length > 0
        ? revenueReport.earnings.reduce((a, b) => a + b, 0)
        : 0;

    const expenses =
      revenueReport &&
      revenueReport.expenses &&
      revenueReport.expenses.length > 0
        ? revenueReport.expenses.reduce((a, b) => a + b, 0)
        : 0;
    setData({
      earnings,
      expenses,
      years: ['2023', '2022', '2021'],
      price: earnings, // numeral(earnings).format('0.0,').toString() + currencySymbol,
      budget: earnings + expenses,
      // numeral(earnings - expenses)
      //   .format('0.0,')
      //   .toString() + currencySymbol,
    });
    return () => {};
  }, [revenueReport]);
  const revenueOptions = {
    chart: {
      stacked: false,
      type: 'line' as const,
      toolbar: { show: false },
    },
    stroke: {
      curve: 'smooth' as const,
      dashArray: [0, 0, 0],
      colors: ['transparent'],
      width: [4, 4, 4],
    },
    fill: {
      opacity: [1, 1, 1],
    },
    markers: {
      size: [0, 4, 0],
      strokeWidth: 2,
      hover: {
        size: 4,
      },
    },
    yaxis: {
      labels: {
        formatter: function format(y: number) {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(2)}`;
          }
          return y;
        },
      },
    },
    xaxis: {
      categories: t('Home.months', { returnObjects: true }),

      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },

    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 0,
        right: -2,
        bottom: 15,
        left: 10,
      },
    },
    legend: {
      show: true,
      horizontalAlign: 'center' as const,
      offsetX: 0,
      offsetY: -5,
      markers: {
        width: 9,
        height: 9,
        radius: 6,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '45%',
        barHeight: '80%',
      },
    },
    colors: ['#042848', '#50ABF8', '#9DFCB6'],
    tooltip: {
      shared: true,
      y: {
        formatter: function format(y: number) {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(2)} ${getLabelCurrency(
              revenueReport.currency,
              'symbol'
            )}`;
          }
          return y;
        },
      } as const,
    },
  };
  const revenueSeries = [
    {
      name: t('Home.endorsement'),
      type: 'bar',
      data:
        revenueReport && revenueReport.earnings
          ? revenueReport.earnings
          : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // [95, 177, 284, 256, 105, 63, 168, 218, 72, 95, 177, 284],
    },
    {
      name: t('Home.revenue'),
      type: 'bar',
      data:
        revenueReport && revenueReport.earnings && revenueReport.expenses
          ? revenueReport.earnings.map(
              (x, index) => x + revenueReport.expenses[index]
            )
          : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // [95, 177, 284, 256, 105, 63, 168, 218, 72, 95, 177, 284],
    },
    {
      name: t('Home.expense'),
      type: 'bar',
      data:
        revenueReport && revenueReport.expenses
          ? revenueReport.expenses.map((x) => x * -1)
          : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // [-145, -80, -60, -180, -100, -60, -85, -75, -100, -145, -80, -60],
    },
  ];

  return (
    <Row>
      <Col className='revenue-report-wrapper' md='12' xs='12'>
        <Card className='card-revenue-budget'>
          <CardHeader>
            <Row className='p-0'>
              <Col className='p-1 d-flex justify-items-center align-items-center'>
                <h4 className='card-title mb-0 title-margin-clearfix'>
                  {' '}
                  {t('Home.revenueReport')}
                </h4>
              </Col>
              <Col className='d-flex justify-content-end'>
                <UncontrolledButtonDropdown>
                  {/* <DropdownToggle
                    className='budget-dropdown'
                    outline
                    color='primary'
                    size='md'
                    caret
                  >
                    {selectedYear}
                  </DropdownToggle> */}
                  {data.years.map((item) => (
                    <DropdownItem
                      className='w-100 chartYearsButton'
                      key={item}
                      onClick={() => {
                        setSelectedYear(item);
                        changeYear(item);
                      }}
                    >
                      {item}
                    </DropdownItem>
                  ))}
                </UncontrolledButtonDropdown>
              </Col>
            </Row>
          </CardHeader>

          <CardHeader className='p-0 border-1 bg-soft-light'>
            <div className='max-width-card-header'>
              <div className='p-2 pe-4 d-flex text-start chartValueBox'>
                <p className='text-muted mb-0 endorsementColor'>
                  {t('Home.endorsement')}
                </p>
                <h5 className='mb-1'>
                  <span className='counter-value chartValue' data-target='9851'>
                    <CountUp
                      start={0}
                      end={data.budget}
                      formattingFn={(val) =>
                        `${numeral(val).format('0.0,').toString()} TL`
                      }
                      duration={4}
                    />
                  </span>
                  {/* {getLabelCurrency(revenueReport.currency, 'symbol')} */}
                </h5>
              </div>
              {/* <Col>
                <div className='p-3 border border-dashed border-start-0'>
                  <h5 className='mb-1'>
                    <span className='counter-value'>
                      <CountUp
                        start={0}
                        end={data.budget}
                        formattingFn={(val) =>
                          `${numeral(val)
                            .format('0.0,')
                            .toString()} ${getLabelCurrency(
                            revenueReport.currency || 'TRY',
                            'symbol'
                          )}`
                        }
                        decimals={2}
                        duration={4}
                      />
                       {data.budget} 
                    </span>
                  </h5>
                  <p className='text-muted mb-0'>{t('Home.totalEarnings')}</p>
                </div>
              </Col> */}
              <div className='p-2 pe-4 d-flex flex-column-reverse text-start chartValueBox'>
                <p className='text-muted mb-0 expensesColor'>
                  {t('Home.totalExpenses')}
                </p>
                <h5 className='mb-1'>
                  <span
                    className='counter-value chartValue'
                    data-target='228.89'
                  >
                    <CountUp
                      start={0}
                      end={data.expenses}
                      formattingFn={(val) =>
                        `${numeral(val).format('0.0,').toString()} TL`
                      }
                      decimals={2}
                      duration={4}
                    />
                  </span>
                  {/* {getLabelCurrency(revenueReport.currency, 'symbol')} */}
                </h5>
              </div>
              <div className='p-2 pe-4 d-flex flex-column-reverse text-start chartValueBox'>
                <p className='text-muted mb-0 revenueColor'>
                  {t('Home.totalRevenue')}
                </p>
                <h5 className='mb-1'>
                  <span
                    className='counter-value chartValue'
                    data-target='228.89'
                  >
                    <CountUp
                      start={0}
                      end={data.price}
                      formattingFn={(val) =>
                        `${numeral(val).format('0.0,').toString()} TL`
                      }
                      decimals={2}
                      duration={4}
                    />
                  </span>
                </h5>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md='12' xs='12'>
                <ReactApexChart
                  options={revenueOptions}
                  series={revenueSeries}
                  type='line'
                  height={350}
                  className='apex-charts'
                />
              </Col>
            </Row>

            {/* <Chart
            id='revenue-report-chart'
            type='bar'
            height='230'
            options={revenueOptions}
            series={revenueSeries}
          /> */}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default RevenueReport;
