import { APIClient } from '../helpers/api_helper';

const api = new APIClient();
export const getUsers = () => {
  const apiUrl = 'persons';

  return api.get(apiUrl);
};

export const getUsersLookup = () => {
  const apiUrl = 'persons/lookup';

  return api.get(apiUrl);
};

export const updateUser = (params: { id: string } & object) => {
  const { id, ...otherParams } = params;
  const apiUrl = `persons/${id}`;
  return api.update(apiUrl, otherParams);
};
