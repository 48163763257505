import React, { useRef, useState, useEffect } from 'react';

import GoogleMap from 'google-map-react';
import { Row, Col, Button, Input, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import ModalForm from 'Components/Common/ModalForm';
import { func } from 'prop-types';
import * as Icons from 'react-feather';

let marker: any = null;
const API_KEY = 'AIzaSyAWxT6Eh7YwPhMEkoZEtntIqIQsf1-si_Q';

interface ChooseFromMapType {
  isOpen: boolean;
  toggleModal: () => void;
  lat: number;
  long: number;
  changeLatLong: (lat: number, long: number) => void;
  changeAddress: (
    address: string,
    city: string,
    country: string,
    zipcode: string
  ) => void;
}

const ChooseFromMap = ({
  isOpen,
  toggleModal,
  lat: _lat,
  long: _long,
  changeLatLong,
  changeAddress,
}: ChooseFromMapType) => {
  const mapRef = useRef<any>();
  const [loaded, setloaded] = useState(false);
  const [lat, setlat] = useState(_lat);
  const [long, setlong] = useState(_long);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [zipcode, setZipcode] = useState('');

  const { t } = useTranslation();

  const handleChange = (address_: string) => {
    setAddress(address_);
  };
  const onError = (status: string, clearSuggestions: () => void) => {
    clearSuggestions();
  };
  const handleSelect = (address_: string) => {
    if (address_) {
      geocodeByAddress(address_)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          if (latLng != null) {
            setlat(latLng.lat);
            setlong(latLng.lng);
          }
        })
        .catch((error) => console.error('Error', error));
    }
  };
  const reverseGeocodeCoordinates = () => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&sensor=false&key=${API_KEY}`
    )
      .then((response) => response.json())
      .then((response) => {
        setAddress(response.results[0].formatted_address);
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i += 1
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j += 1
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case 'administrative_area_level_1':
                setCity(response.results[0].address_components[i].long_name);
                break;
              case 'country':
                setCountry(response.results[0].address_components[i].long_name);
                break;
              case 'postal_code':
                setZipcode(response.results[0].address_components[i].long_name);
                break;
              default:
                break;
            }
          }
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (loaded && mapRef.current) {
      if (marker) {
        marker.setMap(null);
        if (lat && long) {
          reverseGeocodeCoordinates();
        }
      }
      marker = new mapRef.current.maps_.Marker({
        position: {
          lat: lat && long ? lat : 41.0055005,
          lng: lat && long ? long : 28.7319952,
        },
        map: mapRef.current.map_,
      });
    }
    return () => {};
  }, [loaded, lat, long]);

  return (
    <div>
      <ModalForm
        title={t('ChargeConnection.chooseFromMap')}
        size='lg'
        isOpen={isOpen}
        toggleModal={toggleModal}
      >
        <div
          className='w-100'
          style={{ height: '500px', position: 'relative' }}
        >
          <PlacesAutocomplete
            value={address}
            onError={onError}
            // inputProps={mailingAddressProps}
            onChange={handleChange}
            // searchOptions={searchOptions}
            onSelect={handleSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => {
              return (
                <div
                  className='d-inline-flex flex-column col-12'
                  style={{
                    position: 'absolute',
                    zIndex: '1',
                    marginTop: '30px',
                  }}
                >
                  <FormGroup className='form-label-group position-relative has-icon-right w-50 mx-auto d-flex'>
                    <Input
                      style={{
                        borderRadius: 0,
                        // borderTopLeftRadius: 10,
                        border: 0,
                      }}
                      // eslint-disable-next-line
                      {...getInputProps({
                        placeholder: address || t('searchPlaceholder'),
                        className: 'location-search-input pr-5',
                      })}
                    />
                    <div
                      onClick={() => {
                        handleChange('');
                      }}
                      aria-hidden='true'
                      className='form-control-position text-white text-md cursor-pointer  text-center'
                      style={{
                        width: 50,
                        paddingTop: 7,
                        // borderTopRightRadius: 10,
                        backgroundColor: '#60c3f7',
                        cursor: 'pointer',
                      }}
                    >
                      {t('clear')}
                    </div>
                  </FormGroup>

                  <div
                    className='autocomplete-dropdown-container col-6 mx-auto'
                    style={{
                      marginTop: -17,
                      backgroundColor: 'white',
                      borderBottomRightRadius: 10,
                      borderBottomLeftRadius: 10,
                    }}
                  >
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      const style = suggestion.active
                        ? {
                            backgroundColor: '#60c3f7',
                            color: '#fff',
                            cursor: 'pointer',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 10,
                            borderBottom: '1px',
                            paddingBottom: 10,
                            borderBottomWidth: '1 px',
                            borderRadiusBottom: '2px',
                            borderColor: '#909090',
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                          }
                        : {
                            backgroundColor: '#ffffff',
                            cursor: 'pointer',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 10,
                            paddingBottom: 10,
                            borderBottomWidth: '1 px',
                            borderColor: '#909090',
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: 10,
                          };
                      return (
                        <div
                          // eslint-disable-next-line
                          {...getSuggestionItemProps(suggestion, {
                            className: 'col-12',
                            style,
                          })}
                        >
                          -&nbsp;
                          <span style={{ fontSize: '16px' }}>
                            {suggestion.description}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            }}
          </PlacesAutocomplete>
          <GoogleMap
            ref={mapRef}
            bootstrapURLKeys={{ key: API_KEY }}
            defaultCenter={
              lat && long
                ? { lat, lng: long }
                : { lat: 41.0055005, lng: 28.7319952 }
            }
            defaultZoom={11}
            center={
              lat && long
                ? { lat, lng: long }
                : { lat: 41.0055005, lng: 28.7319952 }
            }
            yesIWantToUseGoogleMapApiInternals
            onClick={(props) => {
              // changeLatLong(props.lat, props.lng);
              setlat(props.lat);
              // eslint-disable-next-line
              setlong(props?.lng);
            }}
            onGoogleApiLoaded={() => setloaded(true)}
          />
        </div>
        <Row className='mt-1'>
          <Col className='text-end'>
            <Button
              color='secondary'
              type='button'
              onClick={toggleModal}
              className='me-1'
            >
              {t('ChargeConnection.cancel')}
            </Button>
            <Button
              style={{ backgroundColor: '#60c3f7', border: 'none' }}
              type='button'
              onClick={() => {
                changeLatLong(lat, long);
                toggleModal();
                changeAddress(address, city, country, zipcode);
              }}
            >
              {t('ChargeConnection.submit')}
            </Button>
          </Col>
        </Row>
      </ModalForm>
    </div>
  );
};

export default ChooseFromMap;
