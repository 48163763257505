import React, { useEffect, useState, useContext } from 'react';
import { Button, Row, Col, Card, CardBody } from 'reactstrap';
import SlidingModal from 'Components/Common/SlidingModal';
import DataTable from 'Components/Common/DataTable';
import DataContext from 'Components/Contexts/DataContext';
import { useTranslation } from 'react-i18next';
import * as Icons from 'react-feather';
import Container from 'Components/Common/Container';
import CardInfoText from 'Components/Common/CardInfoText';
import SearchInput from 'Components/Common/SearchInput';
import EditContext from 'Components/Contexts/EditContext';
import ExportBlock from 'laxyExcel/ExportComponents';
import { getLabelCurrency } from 'helpers/Currencies';
import { ChargingRatesType } from 'declerations/DefaultTypes';
import ApplicationContext from 'Components/Contexts/ApplicationContext';
import AutoRefresh from 'Components/Common/AutoRefresh';
import { AUTO_REFRESH_TIME } from 'helpers/config';
import AddChargingRates from './AddChargingRate/AddChargingRates';

type Tariffs = {
  feePerMin: number;
  minKWh: number;
};
const ChargingRates = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const context = useContext(DataContext);
  const [isActiveAutoRefresh, setIsActiveAutoRefresh] = useState(true);
  const applicationContext = useContext(ApplicationContext);
  const [addIsOpen, setAddIsOpen] = useState(false);
  const [filtered, setFiltered] = useState('');
  const [selectedChargingRate, setSelectedChargingRate] =
    useState<ChargingRatesType | null>(null);

  const editContext = useContext(EditContext);
  const [isOpenEdit, setisOpenEdit] = useState(false);

  const mappers = {
    basePrice: (row: any) => {
      if (row?.basePrice) {
        return `${row?.basePrice}`;
      }
      return `-`;
    },
    feePerKWh: (row: any) => {
      if (row?.feePerKWh) {
        return `${row?.feePerKWh}`;
      }
      return `-`;
    },
  };

  const loadDataTable = () => {
    if (context.profile) {
      if (context.profile.allowedScopes.includes('Domain')) {
        if (!applicationContext.selectedApplication?._id) return;
      }
    }
    context
      .LoadChargingRates({
        applicationId: applicationContext.selectedApplication?._id,
      })
      .then(() => {
        return context.LoadApplications();
      })
      .then(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    loadDataTable();
  }, [applicationContext.selectedApplication]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isActiveAutoRefresh) {
        loadDataTable();
      }
    }, AUTO_REFRESH_TIME);
    return () => clearInterval(intervalId);
  }, [isActiveAutoRefresh, applicationContext.selectedApplication]);

  const search = (rowData: ChargingRatesType[] | undefined) => {
    if (!rowData) {
      return [];
    }
    return rowData.filter(
      (row: ChargingRatesType) =>
        row?.title?.toLowerCase().indexOf(filtered.toLowerCase()) > -1
    );
  };
  const headers = [
    'title',
    'application.name',
    'type',
    'basePrice',
    'feePerKWh',
  ].map((key) => ({ key,
    value: `chargingRates.${key}`,
    mapper: mappers[key as keyof typeof mappers]
  }));


  return (
    <Container
      title={t('tariffs')}
      rightSide={
        <AutoRefresh
          isActive={isActiveAutoRefresh}
          onPress={() => {
            setIsActiveAutoRefresh(!isActiveAutoRefresh);
          }}
        />
      }
    >
      <Row className='mb-2 d-flex justify-content-end'>
        <Col xl={3} sm={12} className='d-flex'>
          <SearchInput onChange={(text) => setFiltered(text)} />
        </Col>
        <Col xl={6} sm={12}>
          {' '}
        </Col>
        <Col
          xl={3}
          sm={12}
          className='d-flex justify-content-end '
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <ExportBlock
            data={context.chargingRates}
            header={headers}
            name={t('tariffs')}
          />
          <Button
            className='ms-1'
            aria-hidden='true'
            color='primary'
            onClick={() => {
              setisOpenEdit(false);
              setAddIsOpen(!addIsOpen);
            }}
          >
            <Icons.Plus className='me-2 p-0' size='20' />
            {t('Add')}
          </Button>
        </Col>
      </Row>
      <div>
        <DataTable
          loading={loading}
          noDataText={t('ChargingRate.thereArenoChargingRateCreated')}
          onRowClicked={(row: ChargingRatesType) => {
            if (row?.type === 'Duration') {
              setIsOpen(true);
              setSelectedChargingRate(row);
            }
          }}
          columns={[
            {
              name: t('ChargingRate.title'),
              selector: (row: ChargingRatesType) => row.title,
            },
            {
              name: t('ChargingRate.application'),
              selector: (row: ChargingRatesType) => row.application.name,
            },
            {
              name: t('ChargingRate.type'),
              selector: (row: ChargingRatesType) => row.type,
              cell: (row: ChargingRatesType) =>
                row?.type
                  ? row?.type === 'Duration'
                    ? t('AddChargingRate.duration')
                    : t('AddChargingRate.energy')
                  : '-',
            },
            {
              name: t('ChargingRate.basePrice'),
              selector: (row: ChargingRatesType) => row.basePrice,
              cell: (row: ChargingRatesType) =>
                row.basePrice
                  ? `${row.basePrice} 
                    ${getLabelCurrency(row.application.currency, 'symbol')}`
                  : '-',
            },
            {
              name: t('ChargingRate.feePerkWh'),
              selector: (row: ChargingRatesType) => row.feePerKWh,
              cell: (row: ChargingRatesType) =>
                row.feePerKWh
                  ? `${row.feePerKWh} 
                    ${getLabelCurrency(row.application.currency, 'symbol')}`
                  : '-',
            },
            {
              name: t('ChargeStations.action'),
              cell: (row: ChargingRatesType) => {
                return (
                  <div>
                    <Icons.Edit3
                      className='mr-1'
                      cursor='pointer'
                      onClick={() => {
                        editContext.SetEditChargingRate(row);
                        setisOpenEdit(true);
                        setAddIsOpen(!addIsOpen);
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
          data={search(context.chargingRates) || []}
        />
      </div>
      {isOpen && (
        <SlidingModal isOpen={isOpen} toggleHandle={() => setIsOpen(false)}>
          <Card>
            <CardBody>
              {selectedChargingRate?.tariffs.map(
                (e: Tariffs, index: number) => (
                  <Row
                    className='justify-content-center'
                    key={index.toString()}
                  >
                    <Col xs={12} md={6}>
                      <ul className='list-group list-group-flush'>
                        <CardInfoText
                          title={`${e?.minKWh}  kWh`}
                          value={`${e?.feePerMin} kWh
                    ${selectedChargingRate?.application?.currency} / ${t(
                            'min'
                          )}`}
                        />
                      </ul>
                    </Col>
                  </Row>
                )
              )}
            </CardBody>
          </Card>
        </SlidingModal>
      )}

      <AddChargingRates
        isOpen={addIsOpen}
        isEditing={isOpenEdit}
        toggleHandle={() => {
          setAddIsOpen(false);
          setisOpenEdit(false);
          context.LoadChargingRates({
            applicationId: applicationContext.selectedApplication?._id,
          });
        }}
      />
    </Container>
  );
};
export default ChargingRates;
