/* eslint-disable no-else-return */
import React, { useState, useEffect, useContext } from 'react';
import ApplicationContext from 'Components/Contexts/ApplicationContext';
import { Row, Col } from 'reactstrap';
import DataTable from 'Components/Common/DataTable';
import DataContext from 'Components/Contexts/DataContext';
import { useTranslation } from 'react-i18next';
import Container from 'Components/Common/Container';
import moment from 'moment';
import ExportBlock from 'laxyExcel/ExportComponents';
import AutoRefresh from 'Components/Common/AutoRefresh';
import { AUTO_REFRESH_TIME } from 'helpers/config';
import { AlarmsType } from '../../declerations/DefaultTypes';
import 'moment/locale/tr';
import 'moment/locale/en-nz';
import i18n from '../../i18n';

const Alarms = () => {
  const { t } = useTranslation();
  const applicationContext = useContext(ApplicationContext);
  const context = useContext(DataContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [isActiveAutoRefresh, setIsActiveAutoRefresh] = useState(true);
  const [data, setdata] = useState<any>([]);

  const loadDataTable = () => {
    if (context.profile) {
      if (context.profile.allowedScopes.includes('Domain')) {
        if (!applicationContext.selectedApplication?._id) return;
      }
    }
    context
      .LoadAlarms({
        applicationId: applicationContext.selectedApplication?._id,
      })
      .then(() => {
        return context.LoadApplications();
      })
      .then(() => {
        setLoading(false);
      });
  };


  
  useEffect(() => {
    loadDataTable();
  }, [applicationContext.selectedApplication]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isActiveAutoRefresh) {
        loadDataTable();
      }
    }, AUTO_REFRESH_TIME);
    return () => clearInterval(intervalId);
  }, [isActiveAutoRefresh, applicationContext.selectedApplication]);
  const mappers = {
    createdAt: (row: any) => {
      if (i18n.language === 'en') {
        return `${moment(row?.createdAt).format('lll').toString()}`;
      } else {
        return `${moment(row?.createdAt)
          .locale('tr')
          .format('lll')
          .toString()}`;
      }
    },
    updatedAt: (row: any) => {
      if (i18n.language === 'en') {
        return `${moment(row?.updatedAt).format('lll').toString()}`;
      } else {
        return `${moment(row?.updatedAt)
          .locale('tr')
          .format('lll')
          .toString()}`;
      }
    },
  };
  const headers = [
    'chargePointId',
    'connectorId',
    'issue',
    'status',
    'createdAt',
    'updatedAt',
  ].map((key) => ({
    key,
    value: `alarms.${key}`,
    mapper: mappers[key as keyof typeof mappers],
  }));
  return (
    <Container
      title={t('Alarms')}
      rightSide={
        <AutoRefresh
          isActive={isActiveAutoRefresh}
          onPress={() => {
            setIsActiveAutoRefresh(!isActiveAutoRefresh);
          }}
        />
      }
    >
      <Row className='mb-2 d-flex justify-content-end'>
        <Col xl={3} sm={12} className=' d-flex justify-content-end'>
          <ExportBlock
            data={context.alarms}
            header={headers}
            name={t('Alarms')}
          />
        </Col>
      </Row>
      <div>
        <DataTable
          loading={loading}
          noDataText={t('Alarm.thereAreNoAlarms')}
          columns={[
            {
              name: t('Alarm.connectorId'),
              selector: (row: AlarmsType) =>
                row.connectorId ? row.connectorId : '-',
            },
            {
              name: t('Alarm.chargePointId'),
              selector: (row: AlarmsType) =>
                row.chargePointId ? row.chargePointId : '-',
            },
            {
              name: t('Alarm.createdAt'),
              selector: (row: AlarmsType) => row.createdAt,
              cell: (row: AlarmsType) =>
                i18n.language === 'en'
                  ? moment(row.createdAt).locale('en').format('lll').toString()
                  : moment(row.createdAt).locale('tr').format('lll').toString(),
            },
            {
              name: t('Alarm.updatedAt'),
              selector: (row: AlarmsType) => row.updatedAt,
              cell: (row: AlarmsType) =>
                i18n.language === 'en'
                  ? moment(row.updatedAt).locale('en').format('lll').toString()
                  : moment(row.updatedAt).locale('tr').format('lll').toString(),
            },
            {
              name: t('Alarm.issue'),
              selector: (row: AlarmsType) => (row.issue ? row.issue : '-'),
            },
            {
              name: t('Alarm.errorReason'),
              selector: (row: any) =>
                row.connectorStatus?.vendorErrorCode
                  ? row?.connectorStatus?.vendorErrorCode
                  : '-',
            },
            {
              name: t('Alarm.status'),
              selector: (row: AlarmsType) => (row.status ? row.status : '-'),
            },

            // {
            //   name: t('Driver.actions'),
            //   cell: (props: AlarmsType) => {
            //     return (
            //       <div>
            //         <i
            //           className='mr-1 mdi mdi-eye pointer'
            //           aria-hidden
            //           onClick={() => {
            //             setIsOpen(true);
            //             // this.props.history.push(`/drivers/${props._id}`, {
            //             //   driver: props,
            //             // });
            //           }}
            //         />
            //       </div>
            //     );
            //   },
            // },
          ]}
          data={context.alarms || []}
        />
      </div>
    </Container>
  );
};

export default Alarms;
