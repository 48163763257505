import React, { useState, useEffect, useContext } from 'react';
import * as Icons from 'react-feather';
import { Edit3 } from 'react-feather';
import DataTable from 'Components/Common/DataTable';
import DataContext from 'Components/Contexts/DataContext';
import { useTranslation } from 'react-i18next';
import Container from 'Components/Common/Container';
import {IntegrationTaskConfigType} from 'declerations/DefaultTypes';
import EditIntegrationTaskConfig from "./EditIntegrationTaskConfig";
import EditContext from "../../Components/Contexts/EditContext";

const Adminrfids = () => {
  const { t } = useTranslation();
  const context = useContext(DataContext);
  const editContext = useContext(EditContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [addIsOpen, setAddIsOpen] = useState(false);
  const [isOpenEdit, setisOpenEdit] = useState(false);

  // todo use type interface in loadDataTable
  const loadDataTable = () => {
    context.LoadIntegrationTaskConfig().then(() => {
      setLoading(false);
    });
  };
  useEffect(() => {
    loadDataTable();
  }, []);
  return (
    <Container title={t('Integration Task Config')}>
      <div>
        <DataTable
          loading={loading}
          noDataText={t('integrationTaskConfigs.thereArenoCreated')}
          columns={[
            {
              name:  t('integrationTaskConfigs.id'),
              selector: (row: IntegrationTaskConfigType) => row._id,
            },
            {
              name: t('integrationTaskConfigs.title'),
              selector: (row: IntegrationTaskConfigType) => row.title,
            },
            {
              name: t('integrationTaskConfigs.taskType'),
              selector: (row: IntegrationTaskConfigType) => row.taskType,
              cell: (row: IntegrationTaskConfigType) => row.taskType,
            },
            {
              name: t('OCPPtags.action'),

              cell: (row: IntegrationTaskConfigType) => {
                return (
                    <div>
                      <Edit3
                          className='mr-1'
                          cursor='pointer'
                          onClick={() => {
                            editContext.SetEditIntegrationTaskConfigType(row);
                            setisOpenEdit(true);
                            setAddIsOpen(true);
                          }}
                      />
                    </div>
                );
              },
            },
          ]}
          data={context.IntegrationTaskConfigs || []}
        />
      </div>
        {editContext.editIntegrationTaskConfigType && (
            <EditIntegrationTaskConfig
                isOpen={addIsOpen}
                isEditing={isOpenEdit}
                toggleHandle={() => {
                    setAddIsOpen(false);
                    setisOpenEdit(false);
                    context.LoadIntegrationTaskConfig();
                }}
            />
        )}
    </Container>
  );
};
export default Adminrfids;
