/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import { TransactionType } from 'declerations/DefaultTypes';
import { t } from 'i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';
import CardInfoText from 'Components/Common/CardInfoText';
import moment from 'moment-timezone';
import ExportBlock from 'laxyExcel/ExportComponents';
import numeral from 'numeral';
import 'moment/locale/tr';
import 'moment/locale/en-nz';
import i18n from '../../../i18n';

interface PaymentDetailProps {
  selectedPayment: TransactionType | null;
}

const CdrDetail = ({ selectedPayment }: PaymentDetailProps) => {
  const mappers = {
    startDate: (row: any) => {
      if (row?.timezone) {
        return `${moment(row?.startDate)
          .tz(row?.timezone)
          .format('lll')
          .toString()}`;
      }
      return `${moment(row?.startDate).format('lll').toString()}`;
    },
    endDate: (row: any) => {
      if (row?.timezone) {
        return `${moment(row?.endDate)
          .tz(row?.timezone)
          .format('lll')
          .toString()}`;
      }
      return `${moment(row?.endDate).format('lll').toString()}`;
    },
    idTagName: (row: any) => {
      if (row?.idTagName != null) {
        return `${row?.idTagName}`;
      }
      return `${row?.idTag}`;
    },
    partyId: (row: any) => {
      return `${row.application?.name}`;
    },
    sessionId: (row: any) => {
      return `${row.transactionId}`;
    },
    cdrToken: (row: any) => {
      return `${row.idTag}`;
    },
    authMethod: (row: any) => {
      if (row?.consumer?.ocppTagId) {
        return `${row?.consumer?.ocppTagId}`;
      }
      return `-`;
    },
    authRef: (row: any) => {
      return `${row?.idTag}`;
    },
    cdrLocation: (row: any) => {
      return `${row?.cpLocation?.title}`;
    },
    currency: (row: any) => {
      return `${row?.paymentProfile?.currency}`;
    },
    tariff: (row: any) => {
      return `${row?.paymentProfile?.serviceTariff?.title}`;
    },
    chargingPeriod: (row: any) => {
      return `1`;
    },
    signedData: (row: any) => {
      return `-`;
    },
    totalCost: (row: any) => {
      return `${row?.paymentProfile?.totalFee} ${row?.paymentProfile?.currency}`;
    },
    totalFixedCost: (row: any) => {
      if (row.paymentProfile?.serviceTariff?.basePrice != null) {
        return `${row?.paymentProfile?.serviceTariff?.basePrice} ${row?.paymentProfile?.currency} `;
      }
      return `-`;
    },
    totalEnergy: (row: any) => {
      return `${row.paymentProfile?.powerConsumptionInKWh} kW`;
    },
    totalEnergyCost: (row: any) => {
      const aa =
        row?.paymentProfile?.powerConsumptionInKWh *
        row?.paymentProfile?.energyTariff?.ratePerKWh;
      if (row.paymentProfile?.powerConsumptionInKWh != null) {
        return `${numeral(aa).format('0,0.0').toString()} ${
          row?.paymentProfile?.currency
        }`;
      }

      return `-`;
    },
    totalTime: (row: any) => {
      if (row.paymentProfile?.durationInMins) {
        return `${row?.paymentProfile?.durationInMins} /dk`;
      }
      return `-`;
    },
    totalParkingTime: (row: any) => {
      if (row.paymentProfile?.parkingDuraionInMins) {
        return `${row?.paymentProfile?.parkingDuraionInMins} /dk`;
      }
      return `-`;
    },
    totalParkingCost: (row: any) => {
      if (row.paymentProfile?.parkingFee != null) {
        return `${row?.paymentProfile?.parkingFee} ${row?.paymentProfile?.currency}`;
      }
      return `-`;
    },
    invoiceReferenceId: (row: any) => {
      if (row?.paymentProfile?.paymentId != null) {
        return `${row?.paymentProfile?.paymentId} `;
      }
      return `-`;
    },
    lastUpdated: (row: any) => {
      if (row?.timezone) {
        return `${moment(row?.updatedAt)
          .tz(row?.timezone)
          .format('lll')
          .toString()}`;
      }
      return `${moment(row?.updatedAt).format('lll').toString()}`;
    },
    totalFee: (row: any) => {
      return `${row?.paymentProfile?.totalFee} ${row?.paymentProfile?.currency}`;
    },
  };
  const headers = [
    'partyId',
    'startDate',
    'endDate',
    'sessionId',
    'cdrToken',
    'authMethod',
    'authRef',
    'cdrLocation',
    'currency',
    'tariff',
    'chargingPeriod',
    'signedData',
    'totalFixedCost',
    'totalEnergy',
    'totalEnergyCost',
    'totalTime',
    'totalParkingTime',
    'totalParkingCost',
    // 'totalReservationCost',
    // 'remark',
    'invoiceReferenceId',
    // 'credit',
    // 'creditReferenceId',
    'lastUpdated',
    'totalCost',
  ].map((key) => ({
    key,
    value: `payments.${key}`,
    mapper: mappers[key as keyof typeof mappers],
  }));

  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <ul className='list-group list-group-flush'>
                <CardInfoText title={t('Payment.countryCode')} value='TR' />
                <CardInfoText
                  title={t('Payment.partyId')}
                  value={selectedPayment?.application?.name}
                />
                <CardInfoText
                  title={t('Payment.startDate')}
                  value={
                    selectedPayment?.inProgress
                      ? '-'
                      : selectedPayment?.timezone
                      ? i18n.language === 'en'
                        ? moment(selectedPayment.startDate)
                            .tz(selectedPayment.timezone)
                            .format('lll')
                            .toString()
                        : moment(selectedPayment.startDate)
                            .tz(selectedPayment.timezone)
                            .locale('tr')
                            .format('lll')
                            .toString()
                      : i18n.language === 'en'
                      ? moment(selectedPayment?.startDate)
                          .format('lll')
                          .toString()
                      : moment(selectedPayment?.startDate)
                          .locale('tr')
                          .format('lll')
                          .toString()
                  }
                />
                <CardInfoText
                  title={t('Payment.endDate')}
                  value={
                    selectedPayment?.inProgress
                      ? '-'
                      : selectedPayment?.timezone
                      ? i18n.language === 'en'
                        ? moment(selectedPayment.endDate)
                            .tz(selectedPayment.timezone)
                            .format('lll')
                            .toString()
                        : moment(selectedPayment.endDate)
                            .tz(selectedPayment.timezone)
                            .locale('tr')
                            .format('lll')
                            .toString()
                      : i18n.language === 'en'
                      ? moment(selectedPayment?.endDate)
                          .format('lll')
                          .toString()
                      : moment(selectedPayment?.endDate)
                          .locale('tr')
                          .format('lll')
                          .toString()
                  }
                />
                <CardInfoText
                  title={t('Payment.cdrLocation')}
                  value={selectedPayment?.cpLocation?.title}
                />
                <CardInfoText
                  title={t('Payment.currency')}
                  value={selectedPayment?.paymentProfile?.currency}
                />
                <CardInfoText
                  title={t('Payment.tariff')}
                  value={selectedPayment?.paymentProfile?.serviceTariff?.title}
                />
                <CardInfoText
                  title={t('Payment.totalParkingTime')}
                  value={
                    selectedPayment?.paymentProfile?.parkingDuraionInMins
                      ? `${selectedPayment?.paymentProfile?.parkingDuraionInMins} dk`
                      : null
                  }
                />
                <CardInfoText
                  title={t('Payment.totalParkingCost')}
                  value={
                    selectedPayment?.paymentProfile?.parkingFee != null
                      ? `${selectedPayment?.paymentProfile?.parkingFee} ${selectedPayment?.paymentProfile?.currency}
                 `
                      : ' '
                  }
                />
                <CardInfoText
                  title={t('Payment.totalReservationCost')}
                  value={null}
                />
                <CardInfoText
                  title={t('Payment.lastUpdated')}
                  value={
                    selectedPayment?.inProgress
                      ? '-'
                      : selectedPayment?.timezone
                      ? i18n.language === 'en'
                        ? moment(selectedPayment?.updatedAt)
                            .tz(selectedPayment.timezone)
                            .format('lll')
                            .toString()
                        : moment(selectedPayment?.updatedAt)
                            .tz(selectedPayment.timezone)
                            .locale('tr')
                            .format('lll')
                            .toString()
                      : i18n.language === 'en'
                      ? moment(selectedPayment?.updatedAt)
                          .format('lll')
                          .toString()
                      : moment(selectedPayment?.updatedAt)
                          .locale('tr')
                          .format('lll')
                          .toString()
                  }
                />
              </ul>
            </Col>
            <Col>
              <ul className='list-group list-group-flush'>
                <CardInfoText
                  title={t('Payment.sessionId')}
                  value={
                    selectedPayment?.paymentProfile.paymentId !== null
                      ? selectedPayment?.paymentProfile.paymentId
                      : '-'
                  }
                />
                <CardInfoText
                  title={t('Payment.paymentGetawayMethod')}
                  value={
                    selectedPayment?.paymentProfile.paymentGatewayMethod !==
                    null
                      ? selectedPayment?.paymentProfile.paymentGatewayMethod
                      : '-'
                  }
                />
                <CardInfoText
                  title={t('Payment.cdrToken')}
                  value={selectedPayment?.idTag}
                />
                <CardInfoText
                  title={t('Payment.authMethod')}
                  value={selectedPayment?.consumer?.ocppTagId}
                />
                <CardInfoText
                  title={t('Payment.authRef')}
                  value={selectedPayment?.idTag}
                />
                <CardInfoText
                  title={t('Payment.invoiceReferenceId')}
                  value={selectedPayment?.paymentProfile?.paymentId}
                />
                <CardInfoText
                  title={t('Payment.totalEnergy')}
                  value={`${selectedPayment?.paymentProfile?.powerConsumptionInKWh} kW`}
                />
                <CardInfoText
                  title={t('Payment.totalEnergyCost')}
                  value={
                    selectedPayment?.paymentProfile?.powerConsumptionInKWh !=
                    null
                      ? `${
                          // eslint-disable-next-line no-unsafe-optional-chaining
                          selectedPayment?.paymentProfile
                            ?.powerConsumptionInKWh *
                          // eslint-disable-next-line no-unsafe-optional-chaining
                          selectedPayment?.paymentProfile?.energyTariff
                            ?.ratePerKWh
                        } ${selectedPayment?.paymentProfile?.currency}`
                      : ' '
                  }
                />
                <CardInfoText
                  title={t('Payment.totalFixedCost')}
                  value={
                    selectedPayment?.paymentProfile?.serviceTariff?.basePrice !=
                    null
                      ? `${selectedPayment?.paymentProfile?.serviceTariff?.basePrice} 
                  ${selectedPayment?.paymentProfile?.currency} `
                      : ' '
                  }
                />
                <CardInfoText
                  title={t('Payment.totalFee')}
                  value={`${selectedPayment?.paymentProfile?.totalFee} ${selectedPayment?.paymentProfile?.currency}`}
                />
              </ul>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default CdrDetail;
