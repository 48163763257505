import React, { useState, useEffect, useContext } from 'react';
import SelectBox from 'Components/Common/SelectBox';
import {
  Row,
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { lazyExcelGenerator, lazyPDFGenerator } from './Generators';

interface ExportBlockType {
  data: any;
  header: any;
  name: any;
  size?:any;
}
const ExportBlock = ({ data, header, name, size }: ExportBlockType) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState<{
    state: string;
  }>({ state: 'null' });
  const changeFilter = (value: string) => {
    switch (value) {
      case 'pdf':
        setFilter({ state: 'pdf' });
        lazyPDFGenerator(data, header, name, size);
        break;
      case 'excel':
        setFilter({ state: 'excel' });
        lazyExcelGenerator(data, header, name);
        break;
      default:
        setFilter({ state: 'null' });

        break;
    }
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <ButtonDropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle caret>Export</DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => changeFilter('pdf')}>
          Pdf Export
        </DropdownItem>
        <DropdownItem onClick={() => changeFilter('excel')}>
          Excel Export
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};
ExportBlock.defaultProps={
  size: [297, 420],
}
export default ExportBlock;

//  <SelectBox
//         value={filter.state}
//         label='Export'
//         onChange={(value) => changeFilter(value)}
//       >
//         <option value='null'>Choose the Options</option>
//         <option value='pdf'>Pdf Export</option>
//         <option value='excel'>Excel Export</option>
//       </SelectBox>
