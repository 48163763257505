import * as yup from 'yup';

const hasGrant = (defaultGrants, resource) => {
  let result = false;
  // eslint-disable-next-line consistent-return
  defaultGrants?.forEach((grant) => {
    if (grant.resource.includes(resource)) {
      result = true;
    }
  });

  return result;
};

export default hasGrant;
