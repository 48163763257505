import React from 'react';
import { Redirect } from 'react-router-dom';
import { THEME } from 'helpers/config';
import InfoComp from '../Components/Common/GIO/info_comp';

// login
import Login from '../pages/AuthenticationInner/Login/CoverSignIn';
import LoginGio from '../pages/AuthenticationInner/GIO/Login/CoverSignIn';
import Logout from '../pages/Authentication/Logout';
// pages
import Cpo from '../pages/Cpo/Cpo';
import Adminrfids from '../pages/CPM/Adminrfids/Adminrfids';
import Bookings from '../pages/CPM/Booking/Booking';
import ChargeStations from '../pages/CPM/ChargeStations/ChargeStations';
import Locations from '../pages/Locations/Locations';
import Roles from '../pages/Roles/Permission';
import Permissions from '../pages/Permission/Permission';
import Transactions from '../pages/Transactions/Transactions';
import Drivers from '../pages/CRM/Drivers/Drivers';
import Employees from '../pages/CRM/Employees/Employees';
import Vehicles from '../pages/CRM/Vehicles/Vehicles';
import ChargingRates from '../pages/ChargingRates/ChargingRates';
import HomeGio from '../pages/GIO/Home/index';
import Home from '../pages/Home/index';
import Alarms from '../pages/Alarms/Alarms';
import Payment from '../pages/Payment/Payment';
import ChargeStationDetail from '../pages/CPM/ChargeStations/ChargeStationDetails';
import AccountSettings from '../pages/AccountSetting/index';
import Cdr from '../pages/CPM/CDR/Cdr';
import Session from '../pages/Sessions/Session';
import ForgotPassword from '../pages/Authentication/ForgotPassword/ResetPassword';
import ResetPassword from '../pages/Authentication/ForgotPassword/CheckEmail';
import TaskConfig from '../pages/TaskConfig/TaskConfig';
import ReportAll from '../pages/Report/Report';
import Report from '../pages/CPM/Report/Report';
import Integrationtaskconfigs from '../pages/IntegrationTaskConfigs/IntegrationTaskConfig';
import IntegrationTasks from '../pages/IntegrationTask';

const authProtectedRoutes = [
  { path: '/overview', component: THEME === 'gio' ? HomeGio : Home },
  { path: '/cpos', component: Cpo },
  { path: '/cpo/authorization', component: Adminrfids },
  { path: '/cpo/charge-station', component: ChargeStations },
  { path: '/cpo/cdr', component: Cdr },
  { path: '/bookings', component: Bookings },
  { path: '/info-comp', component: InfoComp },
  { path: '/cpo/charging-site', component: Locations },
  { path: '/roles', component: Roles },
  { path: '/permissions', component: Permissions },
  { path: '/cpo/transaction', component: Transactions },
  { path: '/drivers', component: Drivers },
  { path: '/emra', component: TaskConfig },
  { path: '/employees', component: Employees },
  { path: '/vehicles', component: Vehicles },
  { path: '/cpo/tariffs', component: ChargingRates },
  { path: '/cpo/alarms', component: Alarms },
  { path: '/CDR', component: Payment },
  // { path: '/session', component: Session },
  { path: '/cpo/charge-station/:id', component: ChargeStationDetail },
  { path: '/cpo/report', component: Report },
  { path: '/report', component: ReportAll },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: '/pages-profile-settings', component: AccountSettings },
  { path: '/integrationtaskconfigs', component: Integrationtaskconfigs },
  { path: '/integrationtasks', component: IntegrationTasks },

  {
    path: '/',
    exact: true,
    component: () => <Redirect to='/overview' />,
  },
];

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/reset-password', component: ForgotPassword },
  { path: '/forgot-password', component: ResetPassword },
  { path: '/login', component: THEME === 'gio' ? LoginGio : Login },
];

export { authProtectedRoutes, publicRoutes };
