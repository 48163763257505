import React, { useState } from 'react';
import { Alert, Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { renderField } from 'Components/Common/Fields';
import { Link } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { emailVerifiy } from '../../../actions/forgotPassword';
import AuthSlider from '../../AuthenticationInner/authCarousel';
import ParticlesAuth from '../../AuthenticationInner/ParticlesAuth';
import SuccesMailVeritification from './SuccesMailVeritification';
import { TENANT_ENV_URL } from '../../../helpers/config';

const formSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t('Must be proper email'))
    .min(1, i18n.t('Required'))
    .required(i18n.t('Required')),
});
// eslint-disable-next-line react/prop-types
interface CheckEmailType {
  toggleModal: () => void;
  setEmailData: (email: string) => void;
}
const CheckEmail = ({ toggleModal, setEmailData }: CheckEmailType) => {
  const { t } = useTranslation();
  const [middleScreen, setMiddleScreen] = useState(false);

  return (
    <ParticlesAuth>
      <div className='auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100'>
        <div className='auth-page-content overflow-hidden pt-lg-5'>
          <Container>
            <Row>
              <Col lg={12}>
                <Card className='overflow-hidden border-0'>
                  <Row className='g-0'>
                    <AuthSlider />
                    <Col lg={6}>
                      <div className='p-lg-5 p-4'>
                        <div className='mt-4'>
                          <div className='auth-page-content'>
                            <Row className=''>
                              <Col>
                                <Card className='mt-4'>
                                  <CardBody className='p-4'>
                                    {!middleScreen ? (
                                      <>
                                        <div className='text-center mt-2'>
                                          <h5 className='text-primary'>
                                            {t('ForgotPassword.fogotPassword')}?
                                          </h5>
                                        </div>
                                        <Alert
                                          className='alert-borderless alert-warning text-center mb-2 mx-2'
                                          role='alert'
                                        >
                                          {t('ForgotPassword.enterYourEmail')}
                                        </Alert>
                                        <div className='p-2'>
                                          <Formik
                                            initialValues={{
                                              email: '',
                                              returnLink: `${TENANT_ENV_URL}.electroop.io/reset-password`,
                                            }}
                                            onSubmit={async (values) => {
                                              emailVerifiy(values)
                                                .then((res: any) => {
                                                  setMiddleScreen(
                                                    !middleScreen
                                                  );
                                                  toast.success('Succesfully');
                                                })
                                                .catch(() =>
                                                  toast.error(
                                                    t(
                                                      'ForgotPassword.wrogEmail'
                                                    )
                                                  )
                                                );
                                            }}
                                            validationSchema={formSchema}
                                          >
                                            {({
                                              touched,
                                              errors,
                                              handleSubmit,
                                              isSubmitting,
                                            }) => {
                                              return (
                                                <Form onSubmit={handleSubmit}>
                                                  <Row>
                                                    <Field
                                                      component={renderField}
                                                      name='email'
                                                      label='Email'
                                                      className='form-control'
                                                      type='email'
                                                      meta={{
                                                        touched: touched.email,
                                                        error:
                                                          errors.email &&
                                                          t(errors.email),
                                                      }}
                                                    />
                                                  </Row>
                                                  <Row className='mt-3'>
                                                    <Col className='text-end'>
                                                      <Button
                                                        className='btn btn-success w-100'
                                                        color='primary'
                                                        type='submit'
                                                        disabled={isSubmitting}
                                                      >
                                                        {t(
                                                          'ForgotPassword.sendResetLink'
                                                        )}
                                                      </Button>
                                                    </Col>
                                                  </Row>
                                                </Form>
                                              );
                                            }}
                                          </Formik>
                                        </div>
                                      </>
                                    ) : (
                                      <div>
                                        <SuccesMailVeritification />
                                      </div>
                                    )}
                                  </CardBody>
                                </Card>

                                {!middleScreen && (
                                  <div className='mt-4 text-center'>
                                    <p className='mb-0'>
                                      {t(
                                        'ForgotPassword.WaitIRememberPassword'
                                      )}
                                      <Link
                                        to='/auth-signin-basic'
                                        className='fw-semibold text-primary text-decoration-underline'
                                      >
                                        {t('ForgotPassword.clikHere')}
                                      </Link>
                                    </p>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </ParticlesAuth>
  );
};
export default CheckEmail;
