import React from 'react';
import ReactTooltip from 'react-tooltip';

const CardInfoText = ({ title, value }: { title: any; value: any }) => {
  const uid = `${Math.floor(Math.random() * Date.now())}`;

  return (
    <li className='list-group-item d-flex justify-content-between'>
      <b>{title}: </b>{' '}
      {typeof value === 'string' || typeof value === 'number' ? (
        <span className='text-right text-truncate' data-tip={value}>
          {value || '-'}
        </span>
      ) : (
        <span className='text-right text-truncate'>{value || '-'}</span>
      )}
      <ReactTooltip className='extraClass' effect='solid' />
    </li>
  );
};

export default CardInfoText;
