import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import {
  getDashboard,
  getStatisticsCount,
  getRevenueReport,
  getRevenue,
  getBestPerforming,
} from '../../../actions/chargeStations';
import EnergyUsage from './Components/EnergyUsage';
import ConnectorRadialChart from './Components/ConnectorRadialChart';
import RevenueReport from './Components/RevenueReports';
import ChargerStatus from './Components/ChargerStatus';
import StatsCards from './Components/StatsCards';
import Location from './Components/Location';
import BestPerformance from './Components/BestPerformance';
import MiniMap from './Components/MiniMap';


type BestPerformanceRankingDataArray = {
  title: string;
  hour?: number;
  chargePointId?: number;
  vehicleMake?: string;
  vehicleModel?: string;
  location?: string;
  powerConsumptionInKWh?: number;
  durationInMins?: number;
};

type BestPerformanceRankingData = {
  title: string;
  data: BestPerformanceRankingDataArray[];
};

const Home = () => {
  const { t } = useTranslation();
  const [stations, setStations] = useState({});
  const [revenueReport, setRevenueReport] = useState({
    expenses: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    earnings: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    currency: 'TRY',
  });
  const [connectors, setConnectors] = useState([0, 0, 0, 0, 0]);
  const [revenue, setRevenue] = useState({
    energyUsage: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  });
  const [revenueYear, setRevenueYear] = useState('2023');
  const [energyUsageYear, setEnergyUsageYear] = useState('2023');
  const [connector, setConnector] = useState<
    | {
        charging: number;
        faulted: number;
        online: number;
        reserved: number;
        total: number;
        totalAC: number;
        totalDC: number;
      }
    | undefined
  >();
  const baseDate = new Date();
  let startDate: Date | string = new Date(baseDate.getFullYear(), 0, 1);
  let endDate: Date | string = new Date(
    baseDate.getFullYear(),
    baseDate.getMonth(),
    baseDate.getDate() + 1
  );
  startDate.setUTCHours(23, 59, 59, 999);
  endDate.setUTCHours(23, 59, 59, 999);
  startDate = startDate.toISOString();
  endDate = endDate.toISOString();
  const [bestPerformingRank, setBestPerformingRank] = useState<
    BestPerformanceRankingData[] | undefined
  >([
    { title: 'TIMELINES', data: [] },
    { title: 'CARS', data: [] },
    { title: 'CHARGE STATIONS', data: [] },
    { title: 'LOCATIONS', data: [] },
  ]);

  function initBestPorforming(): void {
    getBestPerforming({ startDate, endDate, lang: i18n.language }).then(
      (res) => {
        const timelines = bestPerformingRank?.find(
          (prevState) => prevState.title === `TIMELINES`
        );

        if (timelines) {
          timelines.data = res.data.bestPerformingTimeline.concat(
            res.data.bestPerformingTimelineDayOfWeek
          );
        }

        const chargeStations = bestPerformingRank?.find(
          (prevState) => prevState.title === `CHARGE STATIONS`
        );
        if (chargeStations)
          chargeStations.data = res.data.bestPerformingChargeStation;

        const cars = bestPerformingRank?.find(
          (prevState) => prevState.title === `CARS`
        );
        if (cars) cars.data = res.data.bestPerformingCars;

        const locations = bestPerformingRank?.find(
          (prevState) => prevState.title === `LOCATIONS`
        );
        if (locations) locations.data = res.data.bestPerformingLocation;

        if (chargeStations && timelines && cars && locations)
          setBestPerformingRank([timelines, cars, chargeStations, locations]);
      }
    );
  }



  useEffect(() => {
    getRevenueReport({ year: revenueYear }).then((response) => {
      setRevenueReport(response?.data);
    });
    getRevenue({ year: 2023 }).then((response) => {
      setRevenue(response?.data);
    });
    getDashboard()
      .then((response) => {
        if (response && response.data) {
          const connectorData = response.data.connectors;
          setConnector(connectorData);

          const connectorsArray = [
            connectorData.online,
            connectorData.charging,
            connectorData.reserved,
            connectorData.faulted,
          ];

          let offlineConnectors =
            connectorsArray && connectorsArray.length > 0
              ? connectorsArray.reduce((a, b) => a + b, 0)
              : 0;

          offlineConnectors =
            connectorData.total - offlineConnectors > 0
              ? connectorData.total - offlineConnectors
              : 0;

          connectorsArray.push(offlineConnectors);
          setConnectors([
            connectorData.online,
            connectorData.charging,
            connectorData.reserved,
            connectorData.faulted,
            offlineConnectors,
          ]);
        }
      })
      .then();
    initBestPorforming();
    i18n.on('languageChanged', () => {
      initBestPorforming();
    });
  }, []);

  const changeYear = (year: string) => {
    setRevenueYear(year);
    getRevenueReport({ year }).then((response) => {
      setRevenueReport(response?.data);
    });
  };
  const changeYearEnergyUsage = (year: string) => {
    setEnergyUsageYear(year);
    getRevenue({ year }).then((response) => {
      setRevenue(response?.data);
    });
  };
  return (
    <div className='page-content px-0 clearPadding'>
      <Container fluid className='px-0 overflow-hidden'>
        <Row className='px-0 goBack'>
          <Col className='px-0 pt-0'>
            <Location />
          </Col>
        </Row>
        {/* <Row className='mt-3'>
          <BreadCrumb title={t('Overview')} pageTitle=' ' />
        </Row> */}
        <div className='indexContent px-3'>
          <StatsCards />
          <hr className='mt-0' />
          <Row>
            <Col>
              <RevenueReport
                revenueReport={revenueReport}
                changeYear={changeYear}
              />
            </Col>
            <Col>
              <MiniMap />
              {/* <ConnectorRadialChart connectors={connectors} /> */}
            </Col>
          </Row>
          <hr className='mt-0' />
          <Row>
            <Col xl={12} sm={12} md={12}>
              <ChargerStatus connectors={connectors} connector={connector} />
            </Col>
          </Row>
          <hr className='mt-0' />

          <Row>
            {bestPerformingRank?.map((el, ind) =>
              el.title !== 'LOCATIONS' ? (
                <Col key={ind} sm={12} md={12} lg={12} xl={4}>
                  <BestPerformance title={el.title} data={el.data} />
                </Col>
              ) : null
            )}
          </Row>
          <hr />
          <Row>
            <Col xl={12} sm={12} md={12}>
              <EnergyUsage
                revenue={revenue}
                changeYear={changeYearEnergyUsage}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Home;
