import { APIClient } from '../helpers/api_helper';

const api = new APIClient();
export const getTariffs = (id: string) => {
  const apiUrl = `locations/${id}/tariffs`;

  return api.get(apiUrl);
};

export const createTariff = (id: string, params: object) => {
  const apiUrl = `locations/${id}/tariffs`;
  return api.create(apiUrl, params);
};
export const editTariff = (id: string, tariffId: string, params: object) => {
  const apiUrl = `locations/${id}/tariffs/${tariffId}`;
  return api.update(apiUrl, params);
};
