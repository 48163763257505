import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import getSocketSvg from 'helpers/Socket';
import numeral from 'numeral';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import { ReactComponent as IncreaseIcon } from 'assets/images/card/increase_icon.svg';

const CardWidget = ({
  label,
  twocounter,
  popoverdes,
  icon,
  prefix,
  suffix,
  counter,
  counter2,
  counter3,
  value,
  separator,
  decimals,
  url,
  last,
  bgColor,
  cardVersion,
  labelClass,
  cardType,
  counterClass,
  iconClass,
  previousCount,
  readyToCalculate,
  appliedDate,
  formattingFn,
}: {
  label: string;
  badge: string;
  cardVersion?: string;
  twocounter?: number | undefined;
  cardType?: string;
  popoverdes?: string;
  icon: any;
  prefix?: string;
  suffix?: string;
  counter?: number | undefined;
  counter2?: number | undefined;
  counter3?: number | undefined;
  value?: string | undefined;
  separator?: string;
  decimals?: number;
  url?: string;
  last?: boolean;
  bgColor?: string;
  labelClass?: string;
  counterClass?: string;
  iconClass?: string;
  iconv2?: any;
  previousCount?: any;
  appliedDate?: any;
  readyToCalculate?: boolean | undefined;
  formattingFn?: ((val: number) => string) | undefined;
}) => {
  const { t } = useTranslation();
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const increaseDecreaseStatus = React.useRef<string>();
  const increaseDecreaseProspect = React.useRef<string>();

  const startDate = appliedDate?.startDate?.slice(0, 10).replaceAll('-', '.');
  const endDate = appliedDate?.endDate?.slice(0, 10).replaceAll('-', '.');

  if (readyToCalculate) {
    if (counter === 0 || previousCount === 0) {
      increaseDecreaseStatus.current = 'Calculate failed';
    } else {
      if (typeof counter === 'number' && counter > previousCount) {
        const increaseProspect: number = (counter * 100) / previousCount - 100;
        const fixedIncreasedProspect = increaseProspect.toFixed(0);
        increaseDecreaseStatus.current = 'Increase';
        increaseDecreaseProspect.current = `${fixedIncreasedProspect}%`;
      }
      if (typeof counter === 'number' && previousCount > counter) {
        const decreaseProspect: number = 100 - (counter * 100) / previousCount;
        const fixedDecreasedProspect = decreaseProspect.toFixed(0);
        increaseDecreaseStatus.current = 'Decrease';
        increaseDecreaseProspect.current = `${fixedDecreasedProspect}%`;
      }
    }
  }
  const toggle = () => setPopoverOpen(!popoverOpen);

  return (
    <Card className={`card-animate ${bgColor} ${cardVersion} ${cardType}`}>
      <CardBody>
        {cardVersion ? (
          <div className='d-flex justify-content-between flex-column w-100'>
            <div className='d-flex flex-row justify-content-between align-items-start'>
              <div className='baseIcon'>{icon}</div>
              <div>
                {/* <IncreaseIcon />
                <span className='increase_text'>+2.32 %</span> */}
                {increaseDecreaseStatus.current !== 'Calculate failed' ? ( // if AC/DC
                  <Tippy
                    content={
                      <p
                        style={{
                          textAlign: 'center',
                          backgroundColor: '#133b50',
                          color: 'white',
                          borderRadius: 8,
                          padding: 6,
                        }}
                        className='noHighlighter'
                      >
                        {label} <br /> {startDate} - {endDate} <br />{' '}
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                          }}
                        >
                          <div>{t('Home.was')}</div>
                          <div style={{ fontWeight: 'bold', marginLeft: 4 }}>
                            {prefix || ''} {previousCount}
                            {suffix ? ` ${suffix}` : null}.
                          </div>
                        </div>
                      </p>
                    }
                  >
                    <div
                      className='d-flex flex-row justify-content-between align-items-center card-animate increase-decrease-card noHighlighter'
                      style={{ padding: 8, cursor: 'default' }}
                    >
                      <div className='me-2'>
                        {increaseDecreaseStatus.current === 'Increase'
                          ? getSocketSvg('IncreaseIcon', 'inherit')
                          : increaseDecreaseStatus.current === 'Decrease'
                          ? getSocketSvg('DecreaseIcon', 'inherit')
                          : null}
                      </div>
                      <div
                        style={{
                          fontWeight: '600',
                        }}
                        className='increase_text'
                      >
                        {increaseDecreaseProspect.current}
                      </div>
                    </div>
                  </Tippy>
                ) : null}
              </div>
            </div>
            <div className='mt-3'>
              <div className='mt-3'>
                <h4>{label}</h4>
                {counter2 ? (
                  <div>
                    <h2 className={`${counterClass}`}>
                      <span className='counter-value'>
                        {counter &&
                          (formattingFn ? (
                            <CountUp
                              start={0}
                              prefix={prefix || ''}
                              suffix={suffix || ''}
                              separator={separator}
                              end={counter}
                              decimals={decimals}
                              duration={2}
                              formattingFn={formattingFn}
                            />
                          ) : (
                            <CountUp
                              start={0}
                              prefix={prefix || ''}
                              suffix={suffix || ''}
                              separator={separator}
                              end={counter}
                              decimals={decimals}
                              duration={2}
                            />
                          ))}
                        {value} {' '}
                        {counter2 ? '/ ' : counter2 === 0 ? '/ ' : ''}
                        {counter2 &&
                          (formattingFn ? (
                            <CountUp
                              start={0}
                              prefix={prefix || ''}
                              suffix={suffix || ''}
                              separator={separator}
                              end={counter2}
                              decimals={decimals}
                              duration={2}
                              formattingFn={formattingFn}
                            />
                          ) : (
                            <CountUp
                              start={0}
                              prefix={prefix || ''}
                              suffix={suffix || ''}
                              separator={separator}
                              end={counter2}
                              decimals={decimals}
                              duration={2}
                            />
                          ))}
                        {value} {counter3 ? '/ ' : counter3 === 0 ? '/ ' : ''}
                        {counter3 &&
                          (formattingFn ? (
                            <CountUp
                              start={0}
                              delay={0}
                              prefix={prefix || ''}
                              suffix={suffix || ''}
                              separator={separator}
                              end={counter3}
                              decimals={decimals}
                              duration={2}
                              formattingFn={formattingFn}
                            />
                          ) : (
                            <CountUp
                              start={0}
                              delay={0}
                              prefix={prefix || ''}
                              suffix={suffix || ''}
                              separator={separator}
                              end={counter3}
                              decimals={decimals}
                              duration={2}
                            />
                          ))}
                      </span>
                    </h2>
                  </div>
                ) : (
                  <div>
                    <h2 className={`${counterClass}`}>
                      <span className='counter-value'>
                        {counter &&
                          (formattingFn ? (
                            <CountUp
                              start={0}
                              prefix={prefix || ''}
                              suffix={suffix || ''}
                              separator={separator}
                              end={counter}
                              decimals={decimals}
                              duration={2}
                              formattingFn={formattingFn}
                            />
                          ) : (
                            <CountUp
                              start={0}
                              prefix={prefix || ''}
                              suffix={suffix || ''}
                              separator={separator}
                              end={counter}
                              decimals={decimals}
                              duration={2}
                            />
                          ))}
                        {value}
                      </span>
                    </h2>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-between'>
            <div>
              <p className={`fw-medium mb-0 text-${labelClass}`}>{label}</p>
              <h2 className={`mt-4 ff-secondary fw-semibold ${counterClass}`}>
                <span className='counter-value'>
                  {counter &&
                    (formattingFn ? (
                      <CountUp
                        start={0}
                        prefix={prefix || ''}
                        suffix={suffix || ''}
                        separator={separator}
                        end={counter}
                        decimals={decimals}
                        duration={2}
                        formattingFn={formattingFn}
                      />
                    ) : (
                      <CountUp
                        start={0}
                        prefix={prefix || ''}
                        suffix={suffix || ''}
                        separator={separator}
                        end={counter}
                        decimals={decimals}
                        duration={2}
                      />
                    ))}
                  {value}
                </span>
              </h2>
            </div>
            <div>
              <div className='avatar-sm flex-shrink-0'>
                <span
                  className={`avatar-title rounded-circle fs-2 bg-soft-${iconClass}`}
                >
                  {icon}
                </span>
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

CardWidget.defaultProps = {
  bgColor: '',
  cardVersion: '',
  twocounter: '',
  popoverdes: ' ',
  iconv2: '',
  cardType: '',
  labelClass: 'muted',
  counterClass: '',
  iconClass: 'primary',
  prefix: '',
  suffix: '',
  value: undefined,
  counter: undefined,
  counter2: undefined,
  counter3: undefined,
  separator: undefined,
  decimals: undefined,
  appliedDate: '',
  readyToCalculate: false,
  url: undefined,
  last: undefined,
  formattingFn: undefined,
  previousCount: undefined,
};

export default CardWidget;
