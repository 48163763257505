import React from 'react';
import Leaflet from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { ChargeStationType } from 'declerations/DefaultTypes';
import { ASSET_BASE_URL } from 'helpers/config';

const iconPerson = new Leaflet.Icon({
  iconUrl: `${ASSET_BASE_URL}pin.svg`,
  iconRetinaUrl: `${ASSET_BASE_URL}pin.svg`,
  iconSize: new Leaflet.Point(30, 60),
});

export const Map = ({ station }: { station: ChargeStationType }) => {
  return (
    <div>
      {station &&
        station.location &&
        station.location[0] &&
        station.location[1] && (
          <MapContainer
            center={[station.location[1], station.location[0]]}
            zoom={11}
            style={{ height: '300px' }}
            attributionControl={false}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url='https://api.maptiler.com/maps/voyager/256/{z}/{x}/{y}.png?key=GZCHDTppR0lsIJy3ghnK'
            />
            <Marker
              position={[station?.location?.[1], station.location?.[0]]}
              icon={iconPerson}
            />
          </MapContainer>
        )}
    </div>
  );
};

export default Map;
