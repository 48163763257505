import { APIClient } from '../helpers/api_helper';

const api = new APIClient();

export const emailVerifiy = (params: object) => {
  const apiUrl = `email-verify`;
  return api.create(apiUrl, params);
};

export const resetPassword = (params: object) => {
  const apiUrl = `reset-password`;
  return api.create(apiUrl, params);
};
export const verifyToken = (params?: object) => {
  const apiUrl = `verify-token`;
  return api.create(apiUrl, params);
};
