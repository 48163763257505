import React, { useEffect, useState } from 'react';
import Leaflet from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Button, Container } from 'reactstrap';
import 'react-leaflet-markercluster/dist/styles.min.css';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { ASSET_BASE_URL } from 'helpers/config';
import { getChargeStations } from 'actions/chargeStations';
import { ChargeStationType } from 'declerations/DefaultTypes';
import BreadCrumb from 'Components/Common/BreadCrumb';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import hotelIcon from '../../../../assets/images/svg/mapIcon/hotelIcon.svg';
import timeIcon from '../../../../assets/images/svg/mapIcon/time.svg';
import chargerIcon from '../../../../assets/images/svg/mapIcon/chargers.svg';

const iconPerson = new Leaflet.Icon({
  iconUrl: `${ASSET_BASE_URL}pin.svg`,
  iconRetinaUrl: `${ASSET_BASE_URL}pin.svg`,
  iconSize: new Leaflet.Point(30, 60),
});
type Location = number[];
const Locations = () => {
  const [chargeStations, setChargeStations] = useState<
    ChargeStationType[] | undefined
  >();
  const [location, setLocation] = useState<Location | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    getChargeStations({}).then((res) => {
      setChargeStations(res.data.chargePoints);
      setLocation(res.data?.chargePoints[0]?.location);
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <Container fluid className='px-0'>
        {!loading && location && location.length >= 2 && (
          <MapContainer
            center={[38.958552, 35.417003]}
            zoom={6}
            style={{ height: '500px' }}
            attributionControl={false}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url='https://api.maptiler.com/maps/voyager/256/{z}/{x}/{y}.png?key=GZCHDTppR0lsIJy3ghnK'
            />
            <MarkerClusterGroup showCoverageOnHover={false}>
              {chargeStations?.map((e: ChargeStationType, index: number) =>
                e?.location?.[1] && e.location?.[0] ? (
                  <Marker
                    position={[e?.location?.[1], e.location?.[0]]}
                    icon={iconPerson}
                    key={index}
                  >
                    <Popup>
                      <div className=''>
                        <div className='d-flex justify-content-between aling-items-center mapCardTitleSection'>
                          <img className='d-none' src={hotelIcon} alt='' />
                          <h4 className='mapCardTitleTitle pe-0 ps-0'>
                            {e?.name ? e.name : ''}
                          </h4>
                          <div className='typecircle'>
                            <span className='denem'>DC</span>
                          </div>
                        </div>
                        <div
                          className='card-under-inf'
                          style={{
                            fontFamily: 'GeneralSans-Medium',
                            fontSize: 13,
                            fontWeight: 400,
                            marginBottom: 20,
                          }}
                        >
                          {e?.address?.address ? e.address.address : ''}
                        </div>

                        <div className='my-1 d-flex justify-content-between'>
                          <div className='d-none'>
                            <div className='icondiv'>
                              <img src={timeIcon} alt='' />
                              <span className='icon'>00:00 - 24:00</span>
                            </div>
                            <div>
                              <img src={chargerIcon} alt='' />
                              <span className='icon'>Açık Kullanım</span>
                            </div>
                          </div>
                          <Button
                            className='col-12 w-100 align-self-center gio_primary_button'
                            onClick={() => {
                              history.push(
                                `/cpo/charge-station/${e.chargePointId}`
                              );
                            }}
                          >
                            {t('goDetail')}
                          </Button>
                        </div>
                      </div>
                      {/* <div className=''>
                        <div className=''>
                          <h4>{t('Charge Station')}</h4>
                          <div style={{ fontSize: '14px' }}>
                            <span style={{ color: 'grey' }}>{e.name}</span>
                          </div>
                        </div>

                        <div>
                          <h4 style={{ paddingTop: '2px' }}>
                            {t('Charge Point Id')}
                          </h4>
                          <div style={{ fontSize: '14px' }}>
                            <span style={{ color: 'grey' }}>
                              {e.chargePointId}
                            </span>
                          </div>
                        </div>

                        <div className='my-1'>
                          <Button
                            className='col-12 align-self-center'
                            onClick={() => {
                              history.push(`/cpo/charge-station/${e._id}`);
                            }}
                          >
                            {t('goDetail')}
                          </Button>
                        </div>
                      </div> */}
                    </Popup>
                  </Marker>
                ) : null
              )}
            </MarkerClusterGroup>
          </MapContainer>
        )}
      </Container>
    </div>
  );
};
export default Locations;
