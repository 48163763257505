import React, { useState } from 'react';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ModalForm from 'Components/Common/ModalForm';

interface OtpModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  checkOtpVerification: (connectorId: number, otp: string) => void;
  connectorId: number;
}

const OtpModal = ({
  isOpen,
  toggleModal,
  checkOtpVerification,
  connectorId,
}: OtpModalProps) => {
  const { t } = useTranslation();
  const [value, setvalue] = useState('');
  return (
    <ModalForm
      title={t('ChargeConnection.OTPVerification')}
      isOpen={isOpen}
      size='md'
      toggleModal={toggleModal}
      modalFooter={
        <Button
          color='primary'
          onClick={() => checkOtpVerification(connectorId, value)}
        >
          {t('ChargeConnection.Confirm')}
        </Button>
      }
    >
      <FormGroup>
        <Label>{t('ChargeConnection.OTPCode')}</Label>
        <Input
          type='text'
          value={value}
          onChange={(e) => {
            setvalue(e.target.value);
          }}
        />
      </FormGroup>
    </ModalForm>
  );
};

export default OtpModal;
