import { APIClient } from '../helpers/api_helper';

const api = new APIClient();

export const getIntegrationTaskConfig = (filter?: object) => {
  const apiUrl = `integrationtaskconfigs`;

  return api.get(apiUrl, filter);
};

export const editIntegrationTaskConfig = (type: string, params: object) => {
  const apiUrl = `integrationtaskconfigs/${type}`;
  return api.update(apiUrl, params);
};

export const createIntegrationTaskConfig = (id: string, params: object) => {
  const apiUrl = `integrationtaskconfigs/${id}`;
  return api.update(apiUrl, params);
};
