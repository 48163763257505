/* eslint-disable no-else-return */
import React, { useContext, useEffect, useState } from 'react';
import Container from 'Components/Common/Container';
import DataTable from 'Components/Common/DataTable';
import DataContext from 'Components/Contexts/DataContext';
import {
  TransactionType,
  ChargeStationType,
  LocationType,
} from 'declerations/DefaultTypes';
import { getLabelCurrency } from 'helpers/Currencies';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import SelectBox from 'Components/Common/SelectBox';
import ExportBlock from 'laxyExcel/ExportComponents';
import ApplicationContext from 'Components/Contexts/ApplicationContext';
import AutoRefresh from 'Components/Common/AutoRefresh';
import { AUTO_REFRESH_TIME } from 'helpers/config';
import CdrDetail from './CdrDetail';

const Cdr = () => {
  const context = useContext(DataContext);
  const { t } = useTranslation();
  const applicationContext = useContext(ApplicationContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [filtered, setFiltered] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isActiveAutoRefresh, setIsActiveAutoRefresh] = useState(true);
  const [selectedPayment, setSelectedPayment] =
    useState<TransactionType | null>(null);
  const [filter, setFilter] = useState<{
    startDate: string | undefined;
    endDate: string | undefined;
    applicationId: string | undefined;
    locationId: string | undefined;
    chargePointId: string | undefined;
    state: string | undefined;
    user: string | undefined;
  }>({
    applicationId: applicationContext?.selectedApplication?._id || 'null',
    locationId: 'null',
    chargePointId: 'null',
    state: 'null',
    user: 'null',
    endDate: 'null',
    startDate: 'null',
  });

  const changeFilter = (filterType: string, value: string) => {
    switch (filterType) {
      case 'applicationId':
        setFilter({
          ...filter,
          applicationId: value,
          locationId: 'null',
          chargePointId: 'null',
        });
        break;
      case 'locationId':
        setFilter({ ...filter, locationId: value, chargePointId: 'null' });
        break;
      case 'chargePointId':
        setFilter({ ...filter, chargePointId: value });
        break;
      case 'user':
        setFilter({ ...filter, user: value });
        break;
      case 'state':
        setFilter({ ...filter, state: value });
        break;
      default:
        break;
    }
  };
  const loadDataTable = () => {
    context
      .LoadTransactions(filter)
      .then(() => {
        return context.LoadApplications();
      })
      .then(() => {
        context.LoadLocations({ applicationId: filter.applicationId });
        context.LoadChargeStations(filter);
        setLoading(false);
      })
      .then(() => {
        context.LoadDrivers();
      });
  };
  useEffect(() => {
    loadDataTable();
  }, [filter]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isActiveAutoRefresh) {
        loadDataTable();
      }
    }, AUTO_REFRESH_TIME);

    return () => clearInterval(intervalId);
  }, [isActiveAutoRefresh, filter]);

  useEffect(() => {
    if (applicationContext.selectedApplication?._id)
      changeFilter(
        'applicationId',
        applicationContext.selectedApplication?._id
      );
  }, [applicationContext.selectedApplication]);

  const search = (rowData: TransactionType[] | undefined) => {
    if (!rowData) {
      return [];
    }
    return rowData.filter(
      (row: TransactionType) =>
        row?.chargePointId?.toLowerCase().indexOf(filtered.toLowerCase()) >
          -1 ||
        row?.connectorSocketType
          ?.toLowerCase()
          .indexOf(filtered.toLowerCase()) > -1 ||
        (row.consumer &&
          `${row?.consumer?.fname?.toLowerCase()} ${row?.consumer?.lname?.toLowerCase()}`.indexOf(
            filtered.toLowerCase()
          ) > -1) ||
        (row.consumer &&
          row?.consumer?.email?.toLowerCase().indexOf(filtered.toLowerCase()) >
            -1)
    );
  };
  const mappers = {
    startDate: (row: any) => {
      if (row?.timezone) {
        return `${moment(row?.startDate)
          .tz(row?.timezone)
          .format('lll')
          .toString()}`;
      }
      return `${moment(row?.startDate).format('lll').toString()}`;
    },

    endDate: (row: any) => {
      if (row.inProgress) {
        return '-';
      } else {
        if (row?.timezone) {
          return `${moment(row?.endDate)
            .tz(row?.timezone)
            .format('lll')
            .toString()}`;
        }
        return `${moment(row?.endDate).format('lll').toString()}`;
      }
    },
    idTagName: (row: any) => {
      if (row?.idTagName != null) {
        return `${row?.idTagName}`;
      }
      return `${row?.idTag}`;
    },
    totalFee: (row: any) => {
      return `${row?.paymentProfile?.totalFee} ${row?.paymentProfile?.currency}`;
    },
  };
  const headers = [
    'startDate',
    'endDate',
    'transactionId',
    'cpLocation.title',
    'serviceTariff.title',
    'totalFee',
  ].map((key: string) => ({
    key,
    value: `payments.${key}`,
    mapper: mappers[key as keyof typeof mappers],
  }));

  return (
    <Container
      title={t('chargeDetailRecords')}
      rightSide={
        <AutoRefresh
          isActive={isActiveAutoRefresh}
          onPress={() => {
            setIsActiveAutoRefresh(!isActiveAutoRefresh);
          }}
        />
      }
    >
      <Row>
        <Col xl={2} sm={12} xs={12}>
          <SelectBox
            value={filter.locationId}
            label={t('Transaction.location')}
            onChange={(value) => changeFilter('locationId', value)}
          >
            <option value='null'>{t('all')}</option>
            {context.locations &&
              context.locations.length > 0 &&
              context.locations.map((item: LocationType) => (
                <option key={item._id} value={item._id}>
                  {item.title}
                </option>
              ))}
          </SelectBox>
        </Col>
        <Col xl={2} sm={12} xs={12} className='m-0'>
          <SelectBox
            value={filter.chargePointId}
            label={t('Transaction.chargePoint')}
            onChange={(value) => changeFilter('chargePointId', value)}
          >
            <option value='null'>{t('all')}</option>
            {context.chargeStations &&
              context.chargeStations.length > 0 &&
              context.chargeStations.map((item: ChargeStationType) => (
                <option key={item.chargePointId} value={item.chargePointId}>
                  {item.name}
                </option>
              ))}
          </SelectBox>
        </Col>
        <Col xl={6} sm={12} xs={12}>
          {' '}
        </Col>
        <Col
          xl={2}
          sm={12}
          xs={12}
          style={{
            flexDirection: 'row-reverse',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ExportBlock
            data={context.transactions}
            header={headers}
            name={t('chargeDetailRecords')}
          />
        </Col>
      </Row>

      <Row>
        <Col xl={12} lg={12} sm={12}>
          <DataTable
            loading={loading}
            noDataText={t('Payment.thereareNoCdrCreated')}
            onRowClicked={(row, event) => {
              setSelectedPayment(row);
              setIsOpen(true);
            }}
            columns={[
              {
                name: t('Payment.Id'),
                cell: (row: any, index: number) => {
                  return index + 1;
                },
              },
              {
                name: t('Payment.startDate'),
                selector: (row: TransactionType) => row.startDate,
                grow: 1.6,
                sortable: true,
                cell: (row: TransactionType) =>
                  row?.timezone
                    ? moment(row?.startDate)
                        .tz(row?.timezone)
                        .format('lll')
                        .toString()
                    : moment(row.startDate).format('lll').toString(),
              },
              {
                name: t('Payment.endDate'),
                selector: (row: TransactionType) => row.endDate,
                grow: 1.6,
                sortable: true,
                cell: (row: TransactionType) =>
                  row.inProgress
                    ? '-'
                    : row?.timezone
                    ? moment(row.endDate)
                        .tz(row.timezone)
                        .format('lll')
                        .toString()
                    : moment(row.endDate).format('lll').toString(),
              },
              {
                name: t('Payment.sessionId'),
                selector: (row: TransactionType) =>
                  row.paymentProfile.paymentId !== null
                    ? row?.paymentProfile.paymentId
                    : '-',
              },

              {
                name: t('Payment.cdrLocation'),
                selector: (row: TransactionType) => row.cpLocation.title,
              },
              {
                name: t('Payment.tariff'),
                selector: (row: TransactionType) => row.serviceTariff?.title,
              },
              {
                name: t('Payment.idTagName'),
                selector: (row: TransactionType) => row.idTagName,
                cell: (row: TransactionType) =>
                  row?.idTagName != null ? row.idTagName : row.idTag,
              },
              {
                name: t('Payment.totalFee'),
                sortable: true,
                selector: (row: TransactionType) => row.paymentProfile.totalFee,
                cell: (row: TransactionType) => (
                  <div className='text-primary'>
                    {row.paymentProfile && row.paymentProfile.totalFee
                      ? `${row.paymentProfile.totalFee.toFixed(
                          2
                        )} ${getLabelCurrency(
                          row.paymentProfile.currency,
                          'symbol'
                        )}`
                      : `0 ${getLabelCurrency(
                          row.paymentProfile.currency,
                          'symbol'
                        )}`}
                  </div>
                ),
              },
            ]}
            data={search(context.transactions) || []}
          />
        </Col>
      </Row>
    </Container>
  );
};
export default Cdr;
