import React from 'react';
import { useTranslation } from 'react-i18next';
import CardInfoText from 'Components/Common/CardInfoText';
import { ChargeStationType } from 'declerations/DefaultTypes';
import { Badge, Col, Row } from 'reactstrap';

export const Info = ({ station }: { station: ChargeStationType }) => {
  const { t } = useTranslation();

  const {
    isOnline,
    chargePointId,
    chargePointModel,
    chargePointVendor,
    firmwareVersion,
    chargePointSerialNumber,
    chargeBoxSerialNumber,
    ocppProtocol,
    meterType,
    meterSerialNumber,
    heartbeatInterval,
    iccid,
  } = station;

  return (
    <Row>
      <Col xs={12} md={6}>
        <CardInfoText
          title={t('ChargeStationDetail.connectivityStatus')}
          value={
            isOnline ? (
              <Badge
                pill
                color='success'
                className=' d-flex justify-content-center align-items-center h-100 badge-glow badge-md w-100'
              >
                {t('ChargeStationDetail.online')}
              </Badge>
            ) : (
              <Badge
                pill
                color='dark'
                className=' d-flex justify-content-center align-items-center h-100 badge-glow badge-md w-100'
              >
                {t('ChargeStationDetail.offline')}
              </Badge>
            )
          }
        />
        <CardInfoText
          title={t('ChargeStationDetail.OCPPChargePointID')}
          value={chargePointId}
        />
        <CardInfoText
          title={t('ChargeStationDetail.vendor')}
          value={chargePointVendor}
        />
        <CardInfoText
          title={t('ChargeStationDetail.model')}
          value={chargePointModel}
        />
        <CardInfoText
          title={t('ChargeStationDetail.firmware')}
          value={firmwareVersion}
        />
        <CardInfoText
          title={t('ChargeStationDetail.chargePointSerialNumber')}
          value={chargePointSerialNumber}
        />
      </Col>
      <Col xs={12} md={6}>
        <CardInfoText
          title={t('ChargeStationDetail.chargeBoxSerialNumber')}
          value={chargeBoxSerialNumber}
        />
        <CardInfoText
          title={t('ChargeStationDetail.ocppProtocol')}
          value={ocppProtocol}
        />
        <CardInfoText
          title={t('ChargeStationDetail.meterType')}
          value={meterType}
        />
        <CardInfoText
          title={t('ChargeStationDetail.meterSerialNumber')}
          value={meterSerialNumber}
        />
        <CardInfoText
          title={t('ChargeStationDetail.heartbeatInterval')}
          value={heartbeatInterval}
        />
        <CardInfoText title={t('ChargeStationDetail.ICCID')} value={iccid} />
      </Col>
    </Row>
  );
};

export default Info;
