import React, { useState, useMemo } from 'react';

const EditAutoRefresh = React.createContext<any>(null);

export const AutoRefreshProvider = ({ children }: { children: any }) => {
  const [tabName, setTabName] = useState<string | undefined>('overview');
  const [isActiveAutoRefresh, setIsActiveAutoRefresh] = useState(true);

  const setTabNames = async (value: string) => {
    return setTabName(value);
  };
  const IsActiveAutoRefresh = async (value: boolean) => {
    return setIsActiveAutoRefresh(value);
  };

  const values = useMemo(
    () => ({
      tabName,
      setTabNames,
      isActiveAutoRefresh,
      IsActiveAutoRefresh,
    }),
    [tabName, isActiveAutoRefresh]
  );

  return (
    <EditAutoRefresh.Provider value={values}>
      {children}
    </EditAutoRefresh.Provider>
  );
};
export default EditAutoRefresh;
