/* eslint no-param-reassign: ["off", { "props": false }] */
import React, { useContext } from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
// eslint-disable-next-line import/no-extraneous-dependencies
import { History } from 'history';
import { login } from '../../actions/login';
import { setToken, setRefreshToken, removeToken } from '../../helpers/token';
import { setAuthorization } from '../../helpers/api_helper';
import i18n from '../../i18n';
import { DOMAIN_KEY } from '../../helpers/config';

interface AuthState {
  error: string;
  loading: boolean;
  isUserLogout: boolean;
}

const initialState: AuthState = {
  error: '',
  loading: false,
  isUserLogout: false,
};
const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};
export const loginUser = createAsyncThunk(
  'login/loginUser',
  async (
    {
      user,
      history,
    }: {
      user: any;
      history: History;
    },
    { rejectWithValue }
  ) => {
    await login(user)
      .then(
        async (response: {
          isSuccess?: boolean;
          data: { token: { access_token: string; refresh_token: string } };
        }) => {
          let loggedInUser;
          if (response && response.isSuccess) {
            loggedInUser = response.data.token.access_token;
            const refreshToken = response.data.token.refresh_token;
            await setToken(loggedInUser);
            await setRefreshToken(refreshToken);
            setAuthorization(loggedInUser);
            toast.success(
              i18n.language === 'tr'
                ? `${capitalize(DOMAIN_KEY)} ${i18n.t('Welcome to')} `
                : `${i18n.t('Welcome to')} ${capitalize(DOMAIN_KEY)}`
            );

            history.push('/overview');
            return true;
          }
          toast.error(i18n.t('Username or password incorrect'));
          return rejectWithValue(true);
        }
      )
      .catch((err) => {
        if (err?.message === 'BLOCKED') toast.error(i18n.t('BlockedAccount'));
        else toast.error(i18n.t('Username or password incorrect'));
        return rejectWithValue(true);
      });
  }
);
export const logoutUser = createAsyncThunk('login/logoutUser', async () => {
  await removeToken();
  return true;
});
export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(loginUser.rejected, (state) => {
        state.loading = false;
        state.isUserLogout = false;
      });
    builder.addCase(logoutUser.fulfilled, (state) => {
      state.isUserLogout = true;
    });
  },
});

export default loginSlice.reducer;
