import React, { useEffect, useState } from 'react';
import { Card, CardTitle, CardHeader, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import * as Icon from 'react-feather';
import Tippy from '@tippyjs/react';
import NextButton from '../../../assets/images/next-button.png';

type BestPerformanceRankingDataArray = {
  title: string;
  timeline?: number;
  timelineType?: string;
  chargePointId?: number;
  vehicleMake?: string;
  vehicleModel?: string;
  location?: string;
  powerConsumptionInKWh?: number;
  durationInMins?: number;
  chargePointName?: string;
};

type BestPerformanceProps = {
  title: any;
  data: BestPerformanceRankingDataArray[];
};

const BestPerformance = ({ title, data }: BestPerformanceProps) => {
  const { t } = useTranslation();
  const [dayOfWeek, setDayOfWeek] = useState<boolean>(false);

  const localData =
    title === 'TIMELINES' && !dayOfWeek
      ? data?.filter((el) => {
          return el && el.timelineType && el.timelineType === 'hour';
        })
      : title === 'TIMELINES' && dayOfWeek
      ? data?.filter((el) => {
          return el && el.timelineType && el.timelineType === 'dayOfWeek';
        })
      : data;

  return (
    <>
      {' '}
      {title === 'LOCATIONS' ? (
        <Card style={{ height: 360 }}>
          <CardHeader className='m-1'>
            <CardTitle tag='h4' style={{ fontSize: 16, margin: 0 }}>
              {t('Home.bestPerforming')} {t('Home.locations')}
              <Tippy
                content={
                  <p
                    style={{
                      textAlign: 'center',
                      backgroundColor: '#133b50',
                      color: 'white',
                      borderRadius: 8,
                      padding: 6,
                      marginBottom: -5,
                    }}
                  >
                    {t('Home.bestPerformingLocationsTooltip')}
                  </p>
                }
              >
                <Icon.Info
                  strokeWidth={2.5}
                  className='text-primary'
                  style={{
                    width: 14,
                    marginTop: -5,
                    marginLeft: 10,
                  }}
                />
              </Tippy>
            </CardTitle>
          </CardHeader>
          <div className='d-flex flex-column'>
            {localData?.map((el, ind) => {
              if (title === 'LOCATIONS' && ind < 7) {
                const mins = el?.durationInMins;
                const hours = typeof mins === 'number' ? mins / 60 : 0;

                return (
                  <div className='best-performing-row' key={ind}>
                    <div id='left-row'>
                      <div
                        className='rounded-circle'
                        style={{
                          height: 8,
                          width: 8,
                          backgroundColor: '#3498db',
                        }}
                      >
                        {' '}
                      </div>
                      <div style={{ paddingLeft: 8 }}>{`${el.location}`}</div>
                    </div>
                    <div style={{ textAlign: 'right', width: 100 }}>
                      {hours.toFixed(0)} {t('Home.hours')}
                    </div>
                    <div id='right-row'>
                      <div>{el.powerConsumptionInKWh} KWh</div>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </Card>
      ) : (
        <Card>
          <CardHeader className='m-1 d-flex flex-row align-items-center justify-content-between'>
            <CardTitle tag='h4' style={{ fontSize: 16, margin: 0 }}>
              {t('Home.bestPerforming')}{' '}
              {title === 'CARS'
                ? t('Home.cars')
                : title === 'CHARGE STATIONS'
                ? t('Home.chargeStations')
                : title === 'TIMELINES'
                ? t('Home.timelines')
                : ''}
            </CardTitle>
            {title === 'TIMELINES' && (
              <div
                aria-hidden='true'
                onClick={() => {
                  setDayOfWeek((prevState) => !prevState);
                }}
                style={{
                  backgroundColor: 'white',
                  color: '#03b2cb',
                  borderWidth: 0,
                  cursor: 'pointer',
                }}
              >
                {t('Home.days_hours')}
              </div>
            )}
          </CardHeader>

          <div className='d-flex flex-column'>
            {localData?.map((el, ind) => {
              if (title === 'TIMELINES' && ind < 5) {
                return (
                  <div className='best-performing-row' key={ind}>
                    <div id='left-row'>
                      <div
                        className='rounded-circle'
                        style={{
                          height: 8,
                          width: 8,
                          backgroundColor: '#3498db',
                        }}
                      >
                        {' '}
                      </div>
                      <div style={{ paddingLeft: 8 }}>
                        {el.timelineType === 'hour'
                          ? `${el.timeline}`
                          : `${
                              el.timeline === 1
                                ? t('Days.sunday')
                                : el.timeline === 2
                                ? t('Days.monday')
                                : el.timeline === 3
                                ? t('Days.tuesday')
                                : el.timeline === 4
                                ? t('Days.wednesday')
                                : el.timeline === 5
                                ? t('Days.thursday')
                                : el.timeline === 6
                                ? t('Days.friday')
                                : el.timeline === 7
                                ? t('Days.saturday')
                                : ''
                            }`}
                      </div>
                    </div>
                    <div id='right-row'>
                      <div>{el.powerConsumptionInKWh?.toFixed(2)} KWh</div>
                    </div>
                  </div>
                );
              }
              if (title === 'CARS' && ind < 5) {
                return (
                  <div className='best-performing-row' key={ind}>
                    <div id='left-row'>
                      <div
                        className='rounded-circle'
                        style={{
                          height: 8,
                          width: 8,
                          backgroundColor: '#3498db',
                        }}
                      >
                        {' '}
                      </div>
                      <div
                        style={{ paddingLeft: 8 }}
                      >{`${el.vehicleMake} ${el.vehicleModel}`}</div>
                    </div>
                    <div id='right-row'>
                      <div>{el.powerConsumptionInKWh?.toFixed(2)} KWh</div>
                    </div>
                  </div>
                );
              }
              if (title === 'CHARGE STATIONS' && ind < 5) {
                return (
                  <div className='best-performing-row' key={ind}>
                    <div id='left-row'>
                      <div
                        className='rounded-circle'
                        style={{
                          height: 8,
                          width: 8,
                          backgroundColor: '#3498db',
                        }}
                      >
                        {' '}
                      </div>
                      <Tippy
                        duration={[300, 0]}
                        content={
                          <p
                            style={{
                              textAlign: 'center',
                              backgroundColor: '#133b50',
                              color: 'white',
                              borderRadius: 8,
                              padding: 6,
                              marginBottom: -5,
                            }}
                          >
                            {el.chargePointName}
                          </p>
                        }
                      >
                        <div style={{ paddingLeft: 8 }}>{el.chargePointId}</div>
                      </Tippy>
                    </div>
                    <div id='right-row'>
                      <div>{el.powerConsumptionInKWh?.toFixed(2)} KWh</div>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </Card>
      )}
    </>
  );
};

export default BestPerformance;
