import React from 'react';
import { Spinner } from 'reactstrap';
import DataTable, { createTheme } from 'react-data-table-component-br';
import { useTranslation } from 'react-i18next';

createTheme(
  'electroop',
  {
    text: {
      primary: '#7D8898',
      secondary: '#7D8898',
    },
    background: {
      default: '#ffffff',
    },
    // context: {
    //   background: '#cb4b16',
    //   text: '#FFFFFF',
    // },
    divider: {
      default: '#E3E7F2',
    },
    // action: {
    //   button: 'rgba(0,0,0,.54)',
    //   hover: 'rgba(0,0,0,.08)',
    //   disabled: 'rgba(0,0,0,.12)',
    // },
  },
  'light'
);

interface DataTableProps {
  loading: boolean | undefined;
  noDataText: string;
  columns: any;
  data: any;
  pointerOnHover?: boolean;
  onRowClicked?: (row: any, event: any) => void;
  onChangePage?: (row: any, event: any) => void;
  onChangeRowsPerPage?: (row: any, event: any) => void;
  expandableRows?: boolean;
  expandableRowsComponent?: any;
  selectableRows?: boolean;
  autoRefresh?: boolean;
  autoRefreshFunction?: any;
  pagination?: boolean;
  paginationServer?: boolean;
  paginationPerPage?: number | undefined;
  paginationTotalRows?: number | undefined;
  paginationResetDefaultPage?: boolean;
  paginationRowsPerPageOptions?: Array<number>;
  expandableRowDisabled?: any;
}

export const CustomDataTable = ({
  loading,
  noDataText,
  columns,
  data,
  pointerOnHover,
  expandableRows,
  expandableRowsComponent,
  expandableRowDisabled,
  onRowClicked,
  onChangeRowsPerPage,
  selectableRows,
  autoRefresh,
  autoRefreshFunction,
  pagination,
  paginationPerPage,
  paginationServer,
  paginationResetDefaultPage,
  paginationTotalRows,
  onChangePage,
  paginationRowsPerPageOptions,
}: DataTableProps) => {
  const { t } = useTranslation();
  return (
    <DataTable
      selectableRows={selectableRows}
      pointerOnHover={pointerOnHover}
      onRowClicked={onRowClicked}
      responsive
      noHeader
      theme='electroop'
      expandableRows={expandableRows}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      paginationResetDefaultPage={paginationResetDefaultPage}
      paginationTotalRows={paginationTotalRows}
      paginationServer={paginationServer}
      expandableRowDisabled={expandableRowDisabled}
      expandableRowsComponent={expandableRowsComponent}
      paginationPerPage={paginationPerPage}
      noDataComponent={
        <div>
          {loading ? (
            <Spinner color='primary' className='m-4' />
          ) : (
            <div>{noDataText}</div>
          )}
        </div>
      }
      paginationRowsPerPageOptions={paginationRowsPerPageOptions}
      autoRefresh={autoRefresh}
      autoRefreshFunction={autoRefreshFunction}
      pagination={pagination}
      paginationComponentOptions={{
        rowsPerPageText: t('Rows per page'),
        rangeSeparatorText: t('of'),
      }}
      columns={columns}
      data={loading ? [] : data}
    />
  );
};

CustomDataTable.defaultProps = {
  pointerOnHover: false,
  expandableRows: false,
  expandableRowsComponent: undefined,
  selectableRows: false,
  paginationPerPage: undefined,
  pagination: true,
  autoRefresh: false,
  autoRefreshFunction: () => {},
  expandableRowDisabled: false,
  onRowClicked: (row: any, event: any) => {},
  paginationTotalRows: undefined,
  paginationServer: false,
  paginationResetDefaultPage: false,
  onChangePage: (row: any, event: any) => {},
  onChangeRowsPerPage: (row: any, event: any) => {},
  paginationRowsPerPageOptions: [10, 20, 30, 50, 100],
};

export default CustomDataTable;
