import { APIClient } from '../helpers/api_helper';

const api = new APIClient();

// export const remoteStartTransaction = (params: {
//   chargePointId: string;
//   payload: object;
// }) => {
//   const { chargePointId, payload } = params;
//   const apiUrl = `/ocpp/${chargePointId}/RemoteStartTransaction`;
//   return api.createOcppRequest(apiUrl, payload);
// };

// export const remoteStopTransaction = (params: {
//   chargePointId: string;
//   payload: object;
// }) => {
//   const { chargePointId, payload } = params;
//   const apiUrl = `/ocpp/${chargePointId}/RemoteStopTransaction`;
//   return api.createOcppRequest(apiUrl, payload);
// };

export const reset = (params: { chargePointId: string; payload: object }) => {
  const { chargePointId, payload } = params;
  const apiUrl = `/ocpp/${chargePointId}/Reset`;
  return api.createOcppRequest(apiUrl, payload);
};

export const changeAvailability = (params: {
  chargePointId: string;
  payload: object;
}) => {
  const { chargePointId, payload } = params;
  const apiUrl = `/ocpp/${chargePointId}/ChangeAvailability`;
  return api.createOcppRequest(apiUrl, payload);
};

// export const unlockConnector = (params: {
//   chargePointId: string;
//   payload: object;
// }) => {
//   const { chargePointId, payload } = params;
//   const apiUrl = `/ocpp/${chargePointId}/UnlockConnector`;
//   return api.createOcppRequest(apiUrl, payload);
// };

export const changeConfiguration = (params: {
  chargePointId: string;
  payload?: object;
}) => {
  const { chargePointId, payload } = params;
  const apiUrl = `/ocpp/${chargePointId}/ChangeConfiguration`;
  return api.createOcppRequest(apiUrl, payload);
};

export const getConfiguration = (params: {
  chargePointId: string;
  payload?: object;
}) => {
  const { chargePointId, payload } = params;
  const apiUrl = `/ocpp/${chargePointId}/GetConfiguration`;
  return api.createOcppRequest(apiUrl, payload);
};
