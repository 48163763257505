/* eslint-disable */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
// import logo
import { Container } from 'reactstrap';

import FallbackImage from 'Components/Common/FallbackImage';
import { ASSET_BASE_URL } from 'helpers/config';

import logoSm from '../../assets/images/logo-sm.png';
import logoLight from '../../assets/images/logo-light.png';
import LogoWhite from '../../assets/images/logoWhite.png';
import LogoDark from '../../assets/images/logoDark.png';
import Logo from '../../assets/images/logo.png';
// Import Components
import SidebarContent from './SidebarContent';
import TwoColumnLayout from '../TwoColumnLayout';
import HorizontalLayout from '../HorizontalLayout';

const Sidebar = ({ layoutType }) => {
  useEffect(() => {
    const verticalOverlay = document.getElementsByClassName('vertical-overlay');
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener('click', function () {
        document.body.classList.remove('vertical-sidebar-enable');
      });
    }
  });

  const addEventListenerOnSmHoverMenu = () => {
    // add listener Sidebar Hover icon on change layout from setting
    if (
      document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover'
    ) {
      document.documentElement.setAttribute(
        'data-sidebar-size',
        'sm-hover-active'
      );
    } else if (
      document.documentElement.getAttribute('data-sidebar-size') ===
      'sm-hover-active'
    ) {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
    } else {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
    }
  };
  return (
    <>
      <div className='app-menu navbar-menu'>
        <div className='navbar-brand-box'>
          {/* <Link to='/' className='logo logo-dark'>
            <span className='logo-sm'>
              <img src={LogoDark} alt='' height='22' />
            </span>
            <span className='logo-lg'>
              <img src={LogoDark} alt='' height='17' />
            </span>
          </Link> */}

          <Link to='/' className='logo logo-light w-100 '>
            {/* <span className='logo-sm ml-0 '>
              <img src={LogoWhite} alt='' height='40' />
            </span> */}
            <div className='w-100 d-flex'>
              <span className='logo-lg ml-0 '>
                <FallbackImage
                  src={`${ASSET_BASE_URL}logo.png`}
                  fallback={LogoWhite}
                />
                {/* <img src={Logo} alt='' height='80' /> */}
              </span>
            </div>
          </Link>

          {/* <h4
            style={{
              marginTop: '10px',
              color: 'white',
              display: 'block',
            }}
          >
            ELECTROOP
          </h4> */}
          <button
            onClick={addEventListenerOnSmHoverMenu}
            type='button'
            className='btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover'
            id='vertical-hover'
          >
            <i className='ri-record-circle-line' />
          </button>
        </div>
        {layoutType === 'horizontal' ? (
          <div id='scrollbar'>
            <Container fluid>
              <div id='two-column-menu' />
              <ul className='navbar-nav' id='navbar-nav'>
                <HorizontalLayout />
              </ul>
            </Container>
          </div>
        ) : layoutType === 'twocolumn' ? (
          <TwoColumnLayout />
        ) : (
          <>
            <SimpleBar id='scrollbar' className='h-100 pb-5'>
              <Container fluid>
                <div id='two-column-menu' />
                <ul className='navbar-nav' id='navbar-nav'>
                  <SidebarContent layoutType={layoutType} />
                </ul>
              </Container>
            </SimpleBar>
          </>
        )}
        <div className='sidebarBackground'></div>
      </div>
      <div className='vertical-overlay' />
    </>
  );
};

export default Sidebar;
