/* eslint-disable object-shorthand */
/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';

import Chart from 'react-apexcharts';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  UncontrolledButtonDropdown,
  DropdownItem,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';

type RevenueType = {
  revenue: {
    energyUsage: number[];
  };
  changeYear: (year: string) => void;
};

const Revenue = ({ revenue, changeYear }: RevenueType) => {
  const [data, setData] = useState({
    totalUsage: '',
  });
  const { t } = useTranslation();
  const year = ['2021', '2022', '2023'];
  const [selectedYear, setSelectedYear] = useState('2023');

  useEffect(() => {
    const usage =
      revenue && revenue.energyUsage && revenue.energyUsage.length > 0
        ? revenue.energyUsage.reduce((a, b) => a + b, 0)
        : 0;

    setData({
      totalUsage: numeral(usage).format('0.0,').toString(),
    });
    return () => {};
  }, [revenue]);
  const options = {
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      type: 'line' as const,
    },
    stroke: {
      curve: 'smooth' as const,
      dashArray: [0, 12],
      width: [4, 3],
    },
    legend: {
      show: false,
    },
    colors: ['#042848'],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        inverseColors: false,
        gradientToColors: ['#445F7D', '#ff9f43'],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    markers: {
      size: 6,
      hover: {
        size: 6,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      labels: {
        style: {
          colors: '#042848',
          fontSize: '1rem',
        },
      },
      axisTicks: {
        show: false,
      },
      categories: t('Home.months', { returnObjects: true }),
      axisBorder: {
        show: false,
      },
      tickPlacement: 'on',
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#b9b9c3',
          fontSize: '1rem',
        },
        formatter: function formatter(val: number) {
          return val > 999 ? `${(val / 1000).toFixed(0)}k` : val.toString();
        },
      },
    },

    tooltip: {
      enabled: true,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      custom: function aa({ series, seriesIndex, dataPointIndex, w }: any) {
        return (
          '<div class="arrow_box">' +
          '<span class="arrow_text">' +
          (series[seriesIndex][dataPointIndex] > 999
            ? `${(series[seriesIndex][dataPointIndex] / 1000).toFixed(0)}k`
            : series[seriesIndex][dataPointIndex].toString()) +
          ' kWh' +
          '</span>' +
          '</div>'
        );
      },
    },
    optionsYears: {
      style: {
        fontSize: '12px',
        backgroundColor: 'red',
        color: 'blue',
      },
    },
  };
  const series = [
    {
      name: '',
      data:
        revenue && revenue.energyUsage
          ? revenue.energyUsage
          : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ];
  return (
    <Card>
      <CardHeader>
        <CardTitle tag='h4'>
          <div className='d-flex justify-content-between'>
            <h4 className='card-title mb-0 title-margin-clearfix'>
              {t('Home.energyUsage')}
            </h4>
            <div>
              <UncontrolledButtonDropdown>
                {/* <DropdownToggle
                    className='budget-dropdown'
                    outline
                    color='primary'
                    size='md'
                    caret
                  >
                    {selectedYear}
                  </DropdownToggle> */}
                {year.map((item: any) => (
                  <DropdownItem
                    className='w-100 chartYearsButton'
                    key={item}
                    onClick={(e: any) => {
                      setSelectedYear(item);
                      changeYear(item);
                    }}
                  >
                    {item}
                  </DropdownItem>
                ))}
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </CardTitle>
      </CardHeader>
      <CardHeader className='p-0 border-1 bg-soft-light mb-0'>
        <div className='max-width-card-header'>
          <div className='p-2 pe-4 d-flex text-start chartValueBox '>
            <p className='text-muted  mb-0 endorsementColor'>
              {t('total')} Kwh
            </p>
            <h5 className='mb-1'>
              <span className='text-muted mb-0  chartValue'>
                {data.totalUsage}
              </span>
            </h5>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <Chart
          className='customChart'
          options={options}
          series={series}
          type='line'
          height={200}
        />
      </CardBody>
    </Card>
  );
};
export default Revenue;
