import { APIClient } from '../helpers/api_helper';
import { DOMAIN_KEY } from '../helpers/config';

const api = new APIClient();

export const getTaskConfig = (id?: string, params?: any) => {
  // parentConfigId=null
  const apiUrl = `tenants/taskconfigs`;
  return api.getGeneralTest(apiUrl, params);
};
export const updateTaskConfig = (id?: string, params?: any) => {
  const apiUrl = `tenants/taskconfigs/${id}`;
  return api.updateGeneralTest(apiUrl, params);
};
export const getTaskConfigDetails = (id: string) => {
  const apiUrl = `tenants/taskconfigs/${id}?populate=children`;
  return api.getGeneralTest(apiUrl);
};
export default getTaskConfig;
