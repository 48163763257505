import React from 'react';
import { ReactComponent as CHAdeMO } from 'assets/images/svg/connector-icons/chademo.svg';
import { ReactComponent as AcType2 } from 'assets/images/svg/connector-icons/actype2.svg';
import { ReactComponent as CCS2 } from 'assets/images/svg/connector-icons/CCS2.svg';
import { ReactComponent as TeslaSuperCharge } from 'assets/images/svg/connector-icons/teslasupercharger.svg';
import { ReactComponent as TransactionsIcon } from 'assets/images/card/transactions.svg';
import { ReactComponent as IncreaseIcon } from 'assets/images/card/increase_icon.svg';
import { ReactComponent as DecreaseIcon } from 'assets/images/card/increaseDown.svg';
import { ReactComponent as DriverIcon } from 'assets/images/card/drivers.svg';
import { ReactComponent as EnergyIcon } from 'assets/images/card/energy.svg';
import { ReactComponent as RevenueIcon } from 'assets/images/card/revenue.svg';
import { ReactComponent as StationsIcon } from 'assets/images/card/stations.svg';
import { ReactComponent as OverviewIcon } from 'assets/images/menu/overview.svg';
import { ReactComponent as CpoIcon } from 'assets/images/menu/cpo.svg';
import { ReactComponent as EmspIcon } from 'assets/images/menu/emsp.svg';
import { ReactComponent as CrmIcon } from 'assets/images/menu/crm.svg';
import { ReactComponent as FullScreenIcon } from 'assets/images/menu/fullscreen.svg';
import { ReactComponent as UnPaid } from 'assets/images/card/unPaid.svg';
import { ReactComponent as NewUsers } from 'assets/images/card/newUsers.svg';
import { ReactComponent as Duration } from 'assets/images/card/duration.svg';
import { ReactComponent as CCS } from 'assets/images/svg/connector-icons/CCS.svg';
import { ReactComponent as Schuko } from 'assets/images/svg/connector-icons/Schuko.svg';

import { ReactComponent as DriverIconGio } from 'assets/images/card/Gio/drivers.svg';
import { ReactComponent as EnergyIconGio } from 'assets/images/card/Gio/energy.svg';
import { ReactComponent as IncreaseIconGio } from 'assets/images/card/Gio/increase_icon.svg';
import { ReactComponent as RevenueIconGio } from 'assets/images/card/Gio/revenue.svg';
import { ReactComponent as StationsIconGio } from 'assets/images/card/Gio/stations.svg';
import { ReactComponent as TransactionsIconGio } from 'assets/images/card/Gio/transactions.svg';

import { THEME } from './config';

export const getSocketSvg = (name: string, color: string) => {
  switch (name) {
    case 'CCS':
      return <CCS fill={color}  />;
    case 'CCS2':
      return <CCS2 fill={color} />;
    case 'CHAdeMO':
      return <CHAdeMO fill={color} stroke={color} />;
    case 'Tesla Supercharger':
      return <TeslaSuperCharge fill={color} stroke={color} />;
    case 'Type 2':
    case 'AC Type-2':
      return <AcType2 fill={color} />;
    case 'TransactionsIcon':
      return THEME === 'gio' ? (
        <TransactionsIconGio fill={color} />
      ) : (
        <TransactionsIcon fill={color} />
      );
    case 'IncreaseIcon':
      return THEME === 'gio' ? (
        <IncreaseIconGio fill={color} />
      ) : (
        <IncreaseIcon fill={color} />
      );
    case 'DecreaseIcon':
      return <DecreaseIcon fill={color} />;

    case 'DriverIcon':
      return THEME === 'gio' ? (
        <DriverIconGio fill={color} />
      ) : (
        <DriverIcon fill={color} />
      );
    case 'EnergyIcon':
      return THEME === 'gio' ? (
        <EnergyIconGio fill={color} />
      ) : (
        <EnergyIcon fill={color} />
      );
    case 'RevenueIcon':
      return THEME === 'gio' ? (
        <RevenueIconGio fill={color} />
      ) : (
        <RevenueIcon fill={color} />
      );
    case 'StationsIcon':
      return THEME === 'gio' ? (
        <StationsIconGio fill={color} />
      ) : (
        <StationsIcon fill={color} />
      );
    case 'OverviewIcon':
      return <OverviewIcon fill={color} />;
    case 'CpoIcon':
      return <CpoIcon fill={color} />;
    case 'EmspIcon':
      return <EmspIcon fill={color} />;
    case 'CrmIcon':
      return <CrmIcon fill={color} />;
    case 'FullScreenIcon':
      return <FullScreenIcon fill={color} />;
    case 'NewUsers':
      return <NewUsers fill={color} />;
    case 'Duration':
      return <Duration fill={color} />;
    case 'UnPaid':
      return <UnPaid fill={color} />;
    case 'Schuko':
      return <Schuko fill={color} />;
    default:
      return <CCS2 fill={color} />;
  }
};

export default getSocketSvg;
