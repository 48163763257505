export const setToken = (token: string) => {
  return localStorage.setItem('JWT', token);
};
export const removeToken = () => {
  return localStorage.removeItem('JWT');
};
export const setRefreshToken = (token: string) => {
  return localStorage.setItem('refresh_token', token);
};
export const getRefreshToken = () => {
  return localStorage.getItem('refresh_token');
};
export const removeRefreshToken = (token: string) => {
  return localStorage.removeItem('refresh_token');
};

export const getToken = () => {
  return localStorage.getItem('JWT');
};

export const setItem = (name: string, item: any) => {
  return localStorage.setItem(name, item);
};

export const getItem = (name: string) => {
  return localStorage.getItem(name);
};
export const removeItem = (name: string) => {
  return localStorage.removeItem(name);
};

export default setToken;
