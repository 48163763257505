import { APIClient } from '../helpers/api_helper';

const api = new APIClient();

export const getIntegrationTasks = (filter?: object) => {
  const apiUrl = `integrationtasks`;

  return api.get(apiUrl, filter);
};

export const editIntegrationTasks = (type: string, params: object) => {
  const apiUrl = `integrationtaskconfigs/${type}`;
  return api.update(apiUrl, params);
};

// export const createIntegrationTasks = (id: string, params: object) => {
//   const apiUrl = `integrationtaskconfigs/${id}`;
//   return api.update(apiUrl, params);
// };
