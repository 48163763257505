import { getChargeStations, getMarkerCluster } from 'actions';
import {
  ChargePointsMarkerType,
  ChargeStationType,
} from 'declerations/DefaultTypes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker,
  Annotation,
} from 'react-simple-maps';
import ReactTooltip from 'react-tooltip';
import TurkeyMap from '../../../../assets/json/turkeyMap.json';
import Marker1 from '../../../../assets/images/svg/mapMarkers/marker1.webp';
import Marker2 from '../../../../assets/images/svg/mapMarkers/marker2.webp';
import Marker3 from '../../../../assets/images/svg/mapMarkers/marker3.webp';
import Marker4 from '../../../../assets/images/svg/mapMarkers/marker4.webp';

const markerImage = [Marker1, Marker2, Marker3, Marker4];

const MiniMap = () => {
  const [chargeStations, setChargeStations] = useState<
    ChargePointsMarkerType[] | undefined
  >();
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const [content, setContent] = useState(-1);

  useEffect(() => {
    getMarkerCluster({
      latitude: 40.9950252,
      longitude: 38.583863289620304,
      distanceInKm: 1000000,
      cluster: {
        zoom: 6,
        minZoom: 0,
        maxZoom: 6,
        radius: 0.3,
        extent: 1,
        bbox: [25.66851, 35.817497, 44.834987, 42.107601],
      },
    }).then((res) => {
      setChargeStations(res.data.chargePoints);
      setLoading(false);
    });
  }, []);
  return (
    <>
      <ComposableMap width={400} height={400} style={{ height: '90%' }}>
        <ZoomableGroup
          disablePanning
          disableZooming
          onMoveStart={({ coordinates, zoom }) => {
            console.log(coordinates, zoom);
          }}
          center={[35.30279827619835, 38.583863289620304]}
          zoom={6.908211124411305}
          maxZoom={6.708211124411305}
          minZoom={6.708211124411305}
        >
          <Geographies geography={TurkeyMap}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  width={400}
                  height={400}
                  key={geo.rsmKey}
                  geography={geo}
                  fill='#bdc0d6'
                />
              ))
            }
          </Geographies>
          {chargeStations?.map(
            (e: ChargePointsMarkerType, index: number) =>
              e?.geometry?.coordinates?.[1] &&
              e.geometry?.coordinates?.[0] && (
                <>
                  <Marker
                    onMouseEnter={() => {
                      console.log('entering');
                      setContent(index);
                    }}
                    onMouseLeave={() => {
                      setContent(-1);
                    }}
                    coordinates={[
                      e?.geometry?.coordinates?.[0],
                      e.geometry.coordinates?.[1],
                    ]}
                  >
                    <svg
                      preserveAspectRatio='xMaxYMax meet'
                      width={3}
                      height={3}
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <defs>
                        <style>{'.cls-4{fill:#4ba3bb}'}</style>
                      </defs>
                      <g id='Icons'>
                        <path
                          d='M21 10c0 8-9 13-9 13s-9-5-9-13a8.693 8.693 0 0 1 .33-2.4 8.995 8.995 0 0 1 17.34 0A8.693 8.693 0 0 1 21 10Z'
                          style={{
                            fill: '#3a7f94',
                          }}
                        />
                        <path
                          d='M20.67 7.6C19.08 14.07 12 18 12 18S4.92 14.07 3.33 7.6a8.995 8.995 0 0 1 17.34 0Z'
                          style={{
                            fill: '#3a7f94',
                          }}
                        />
                        <circle
                          cx={12}
                          cy={10}
                          r={4}
                          style={{
                            fill: '#4ba3bb',
                          }}
                        />
                      </g>
                      <g data-name='Layer 4' id='Layer_4'>
                        <path
                          className='cls-4'
                          d='M2 10c0 8.491 9.126 13.658 9.514 13.874a1 1 0 0 0 .972 0C12.874 23.658 22 18.491 22 10a10 10 0 0 0-20 0Zm10-8a8.009 8.009 0 0 1 8 8c0 6.274-6.2 10.68-8 11.83-1.8-1.15-8-5.556-8-11.83a8.009 8.009 0 0 1 8-8Z'
                        />
                        <path
                          className='cls-4'
                          d='M12 15a5 5 0 1 0-5-5 5.006 5.006 0 0 0 5 5Zm0-8a3 3 0 1 1-3 3 3 3 0 0 1 3-3Z'
                        />
                      </g>
                    </svg>

                    {/* <circle r={1} fill='#F53' /> */}
                  </Marker>
                  {index === content && (
                    <Annotation
                      subject={[
                        e?.geometry?.coordinates?.[0],
                        e.geometry.coordinates?.[1],
                      ]}
                      dx={0}
                      dy={-18}
                      curve={0.5}
                      connectorProps={{
                        stroke: '#50abf8',
                        strokeWidth: 0.2,
                        strokeLinecap: 'square',
                      }}
                    >
                      {/* <text
                      x='8'
                      textAnchor='end'
                      alignmentBaseline='middle'
                      fontSize={2}
                      stroke='#50abf8'
                      strokeWidth='0.1rem'
                      width={5}
                      height={3}
                    >
                      {e.properties?.point_count?.toString() || '1'} Station
                    </text> */}
                      <text
                        x='4'
                        y='-2'
                        textAnchor='end'
                        alignmentBaseline='middle'
                        fill='#50abf8'
                        fontSize={2}
                        width={5}
                        style={{ backgroundColor: '#000' }}
                        height={3}
                      >
                        {e.properties?.point_count?.toString() || '1'} Station
                      </text>
                    </Annotation>
                  )}
                </>
              )
          )}
        </ZoomableGroup>
      </ComposableMap>
      {/* <ReactTooltip
        id='minimap'
        getContent={() => {
          return null;
        }}
      >
        {content}
      </ReactTooltip> */}
    </>
  );
};

export default MiniMap;
