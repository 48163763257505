import { APIClient } from '../helpers/api_helper';

const api = new APIClient();

export const getTransactions = (filter?: object) => {
  const apiUrl = `transactions`;
  return api.get(apiUrl, filter);
};
export const getTransactionsExport = (filter?: object) => {
  const apiUrl = `transactions/export`;
  return api.get(apiUrl, filter);
};

export const getProgressTransactions = () => {
  const apiUrl = `transactions/?inProgress=1`;
  return api.get(apiUrl);
};
export const getTransactionsLog = (transactionId?: number) => {
  const apiUrl = `transactions/${transactionId}/payments`;
  return api.get(apiUrl);
};
export const getServiceLog = (transactionId?: number) => {
  const apiUrl = `transactions/${transactionId}/service-logs`;
  return api.get(apiUrl);
};
export const getLastTransaction = (params?: object) => {
  const apiUrl = `transactions/lastid`;
  return api.create(apiUrl, params);
};
export const getRefund = (params?: object) => {
  const apiUrl = `transactions/refund`;
  return api.create(apiUrl, params);
};
export const getPayments = () => {
  const apiUrl = `transactions?paymentSuccess=1`;
  return api.get(apiUrl);
};
export const getTransactionsById = (id?: string) => {
  const apiUrl = `transactions?chargePointId=${id}`;
  return api.get(apiUrl);
};
export const getTransactionsDowntimes = (id?: number) => {
  const apiUrl = `transactions/${id}/downtimes`;
  return api.get(apiUrl);
};
export const getForceStop = (params: object) => {
  const apiUrl = `transactions/force-stop`;
  return api.create(apiUrl, params);
};
export const getClosePayment = (id?: number) => {
  const apiUrl = `transactions/${id}/close`;
  return api.update(apiUrl);
};
export const getForcePay = (id: number, params: object) => {
  const apiUrl = `transactions/${id}/force-pay`;
  return api.update(apiUrl, params);
};
export const getForcePay3d = (id: string, params: object) => {
  const apiUrl = `transactions/${id}/force-pay-threeds`;
  return api.update(apiUrl, params);
};
export const removePaymentMethod = (consumerId: string, cardId: string) => {
  const apiUrl = `consumers/${consumerId}/cards/${cardId}`;
  return api.delete(apiUrl);
};
export const getTransactionsId = (id: number) => {
  const apiUrl = `transactions/${id}`;
  return api.get(apiUrl);
};
export const getCdrs = (filter?: object) => {
  const apiUrl = `cdrs`;
  return api.get(apiUrl, filter);
};
