import React, { useEffect, useState, useMemo } from 'react';
import { getDrivers } from 'actions/drivers';
import {
  DriverType,
  ApplicationsType,
  EmployeeType,
  VehicleType,
  ChargeStationType,
  AlarmsType,
  ChargingRatesType,
  TransactionType,
  LocationType,
  SocketType,
  OcppTagType,
  RepairLogType,
  PermissionType,
  DomainsType,
  RoleType,
  AmenitiesType,
  ProfileType,
  EmrasType,
  IntegrationTaskConfigType,
  IntegrationTasks,
} from 'declerations/DefaultTypes';
import {
  getApplications,
  getChargeStations,
  getChargingRates,
  getEmployees,
  getVehicles,
  getAlarms,
  getTransactions,
  getLocations,
  getSocketTypes,
  getOcppTags,
  getRepairLog,
  getPermissions,
  getDomains,
  getRoles,
  getAmenities,
  getProfile,
  getBrands,
  getModels,
  getVariants,
  getTransactionsExport,
  getTaskConfig,
  getEmspChargePoints,
  getEmspEmras,
  getIntegrationTaskConfig,
  getChargingRateHistories,
  getIntegrationTasks
} from 'actions';
import i18n from 'i18n';
import _ from 'lodash';

const dataContext = React.createContext<any>(null);

export const DataProvider = ({ children }: { children: any }) => {
  const [drivers, setDriver] = useState<DriverType[] | undefined>();
  const [applications, setApplications] = useState<
    ApplicationsType[] | undefined
  >();
  const [employees, setEmployees] = useState<EmployeeType[] | undefined>();
  const [vehicles, setVehicles] = useState<VehicleType[] | undefined>();
  const [chargeStations, setChargeStations] = useState<
    ChargeStationType[] | undefined
  >();
  const [chargingRates, setChargingRates] = useState<
    ChargingRatesType[] | undefined
  >();
  const [chargingRateHistories, setChargingRateHistories] = useState<
    any[] | undefined
  >();
  const [transactions, setTransactions] = useState<
    TransactionType[] | undefined
  >();
  const [transactionsExport, setTransactionsExport] = useState<
    TransactionType[] | undefined
  >();
  const [driverTransactions, setDriverTransactions] = useState<
    TransactionType[] | undefined
  >();
  const [alarms, setAlarms] = useState<AlarmsType[] | undefined>();
  const [locations, setLocation] = useState<LocationType[] | undefined>();
  const [socketTypes, setSocketTypes] = useState<SocketType[] | undefined>();

  const [ocppTags, setocppTags] = useState<OcppTagType[] | undefined>();
  const [repairLogs, setRepairLogs] = useState<RepairLogType[] | undefined>();

  const [permissions, setPermissions] = useState<
    PermissionType[] | undefined
  >();
  const [domains, setDomains] = useState<DomainsType[] | undefined>();
  const [roles, setRoles] = useState<RoleType[] | undefined>();
  const [amenities, setAmenities] = useState<AmenitiesType[] | undefined>();
  const [profile, setProfile] = useState<ProfileType[] | undefined>();
  const [brands, setBrands] = useState<object[] | undefined>([]);
  const [models, setModels] = useState<object[] | undefined>([]);
  const [variants, setVariants] = useState<object[] | undefined>();
  const [emspCP, setEmspCP] = useState<object[] | undefined>();
  const [configurations, setConfigurations] = useState<object[] | undefined>(
    []
  );
  const [taskConfig, setTaskConfig] = useState<object[] | undefined>();

  const [emras, setEmras] = useState<EmrasType[] | undefined>();
  const [IntegrationTaskConfigs, setIntegrationTaskConfigs] = useState<
    IntegrationTaskConfigType[] | undefined
  >();

  const [integrationTasks, setIntegrationTasks] = useState<
    IntegrationTasks[] | undefined
  >();


  const LoadEmras = async () => {
    return getEmspEmras().then((res: any) => {
      setEmras(res.data);
    });
  };

  const LoadEmspChargePoints = async () => {
    return getEmspChargePoints().then((res: any) => {
      setEmspCP(res.data.chargePoints);
    });
  };

  const LoadTaskConfig = async () => {
    return getTaskConfig().then((res) => {
      setTaskConfig(res.data?.data);
    });
  };

  const LoadBrands = async () => {
    return getBrands().then((res) => {
      const brandsArray = res?.data?.brands?.map((x: string) => ({
        label: x,
        value: x,
      }));
      return setBrands(brandsArray);
    });
  };
  const LoadModels = async (brand: string) => {
    return getModels(brand).then((res) => {
      const modelsArray = res?.data?.models?.map((x: string) => ({
        label: x,
        value: x,
      }));
      return setModels(modelsArray);
    });
  };
  const LoadVariants = async (brand: string, model: string) => {
    return getVariants(brand, model).then((res) => {
      if (res?.data?.variants?.length > 0 && res?.data?.variants[0] !== null) {
        const modelsArray = res?.data?.variants?.map((x: string) => ({
          label: x,
          value: x,
        }));
        return setVariants(modelsArray);
      }
      return setVariants([
        { label: i18n.t('AddChargeStations.noVariant'), value: 'NULL' },
      ]);
    });
  };
  const LoadDomains = async () => {
    return getDomains().then((res) => {
      setDomains(res?.data?.domains);
    });
  };
  const LoadDrivers = async (filter?: object) => {
    return getDrivers(filter).then((res) => {
      setDriver(res.data?.consumers);
    });
  };
  const LoadAlarms = async (filter?: object) => {
    return getAlarms(filter).then((res) => {
      setAlarms(res.data?.logs);
    });
  };
  const LoadEmployees = async (filter?: object) => {
    return getEmployees(filter).then((res) => {
      setEmployees(res.data?.tenants);
    });
  };

  const LoadPermissions = async () => {
    return getPermissions().then((res) => {
      setPermissions(res.data?.permissions);
    });
  };
  const LoadApplications = async () => {
    return getApplications().then((res) => {
      setApplications(res.data?.applications);
    });
  };
  const LoadVehicles = async (filter?: object) => {
    return getVehicles(filter).then((res) => {
      setVehicles(res.data?.vehicles);
    });
  };
  const LoadRoles = async (filter?: object) => {
    return getRoles(filter).then((res) => {
      setRoles(res.data?.roles);
    });
  };
  const LoadChargeStations = async (filter?: object) => {
    // await getChargeStations(filter).then((res) => {
    //   return setChargeStations(res.data.chargePoints);
    // });
    return getChargeStations(filter).then(async (res) => {
      const newChargeStation = await res?.data?.chargePoints.filter(
        (x: ChargeStationType) => x.isDeleted !== true
      );
      setChargeStations(_.orderBy(newChargeStation, 'isOnline', 'desc'));
    });
  };

  const LoadChargingRates = async (filter?: object) => {
    return getChargingRates(filter).then(async (res) => {
      const newChargingRates = await res?.data.filter(
        (x: ChargingRatesType) => x.type === 'Energy'
      );
      setChargingRates(newChargingRates);
    });
  };

  const LoadChargingRateHistories = async (filter?: any) => {
    return getChargingRateHistories(filter).then(async (res) => {
      setChargingRateHistories(res?.data?.histories);
    });
  };

  const LoadTransactions = async (filter?: object) => {
    return getTransactions(filter).then((res) => {
      setTransactions(res.data?.transactions);
    });
  };

  const LoadTransactionsExport = async (filter?: object) => {
    return getTransactionsExport(filter).then((res) => {
      setTransactionsExport(res.data?.transactions);
    });
  };

  const LoadDriverTransactions = async (filter?: object) => {
    return getTransactions(filter).then((res) => {
      setDriverTransactions(res.data?.transactions);
    });
  };

  const LoadLocations = async (filter?: object) => {
    return getLocations(filter).then((res) => {
      setLocation(res.data?.locations);
    });
  };

  const LoadSocketTypes = async () => {
    return getSocketTypes().then((res) => {
      return setSocketTypes(res.data?.levels);
    });
  };

  const LoadOcppTags = async (filter?: object) => {
    return getOcppTags(filter).then((res) => {
      // console.log(res.data[0].keys);
      return setocppTags(res.data[0]?.keys);
    });
  };

  const LoadRepairLogs = async (id: string) => {
    return getRepairLog(id).then((res) => {
      return setRepairLogs(res.data?.logs);
    });
  };
  const LoadAmenities = async () => {
    return getAmenities().then((res) => {
      return setAmenities(res.data?.amenities);
    });
  };

  const LoadIntegrationTaskConfig = async (filter?: object) => {
    return getIntegrationTaskConfig(filter).then((res) => {
      return setIntegrationTaskConfigs(res.data?.integrationTaskConfigs);
    });
  };

  const LoadIntegrationTasks = async (filter?: object) => {
    return getIntegrationTasks(filter).then((res) => {
      console.log('res', res);
      return setIntegrationTasks(res.data?.integrationTasks?.dag_runs);
    });
  };

  const LoadProfile = async () => {
    return getProfile().then((response) => {
      const data = response.data.tenant || response.data.consumer;
      setProfile(data);
      return data;
    });
  };

  const values = useMemo(
    () => ({
      drivers,
      LoadDrivers,
      applications,
      LoadApplications,
      employees,
      LoadEmployees,
      vehicles,
      LoadVehicles,
      chargeStations,
      LoadChargeStations,
      chargingRates,
      LoadChargingRates,
      chargingRateHistories,
      LoadChargingRateHistories,
      alarms,
      LoadAlarms,
      transactions,
      LoadTransactions,
      transactionsExport,
      LoadTransactionsExport,
      driverTransactions,
      LoadDriverTransactions,
      locations,
      LoadLocations,
      socketTypes,
      LoadSocketTypes,
      ocppTags,
      LoadOcppTags,
      repairLogs,
      LoadRepairLogs,
      permissions,
      LoadPermissions,
      domains,
      LoadDomains,
      roles,
      LoadRoles,
      amenities,
      LoadAmenities,
      profile,
      LoadProfile,
      brands,
      LoadBrands,
      models,
      LoadModels,
      variants,
      LoadVariants,
      taskConfig,
      LoadTaskConfig,
      emspCP,
      LoadEmspChargePoints,
      emras,
      LoadEmras,
      IntegrationTaskConfigs,
      LoadIntegrationTaskConfig,
      integrationTasks,
      LoadIntegrationTasks,
    }),
    [
      drivers,
      applications,
      employees,
      vehicles,
      chargeStations,
      chargingRates,
      chargingRateHistories,
      alarms,
      transactions,
      transactionsExport,
      locations,
      socketTypes,
      ocppTags,
      repairLogs,
      permissions,
      domains,
      roles,
      amenities,
      profile,
      brands,
      models,
      variants,
      taskConfig,
      emspCP,
      emras,
      IntegrationTaskConfigs,
      integrationTasks,
    ]
  );

  useEffect(() => {
    // LoadDrivers();
    // LoadApplications();
  }, []);

  return <dataContext.Provider value={values}>{children}</dataContext.Provider>;
};

export default dataContext;
