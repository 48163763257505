import i18n from 'i18next';
import { toast } from 'react-toastify';
import { OCPP_CPMS_WS } from './config';

let singletonInstance: WebSocketHelper;

type ResolverType<T> = (result: T) => void;
type PromiseWithResolve<T> = Promise<T> & { resolve: ResolverType<T> };
type ResponseType = { type: string; data: any; chargePointId: string };
type ResponseHandlerType = (e: ResponseType) => void;
type SubscriberMap = { [k: string]: Set<ResponseHandlerType> };

class WebSocketHelper {
  private ws!: WebSocket;

  private wsReady!: PromiseWithResolve<boolean>;

  private messageSubscribersByChargePointId: SubscriberMap = {};

  public static getInstance() {
    if (!singletonInstance) {
      singletonInstance = new WebSocketHelper();
    }
    return singletonInstance;
  }

  private constructor() {
    this.init();
    // this.onError('fake Error');
  }

  private init() {
    let resolve: ResolverType<boolean>;
    this.wsReady = new Promise((innerResolve) => {
      resolve = innerResolve;
    }) as PromiseWithResolve<boolean>;
    this.wsReady.resolve = (res) => {
      const fakeResolve = (res2: boolean) => {
        this.wsReady = Promise.resolve(res2) as PromiseWithResolve<boolean>;
        this.wsReady.resolve = fakeResolve;
      };
      this.wsReady.resolve = fakeResolve;
      resolve(res);
    };
    this.ws = new WebSocket(OCPP_CPMS_WS);
    this.ws.onopen = this.onOpen;
    this.ws.onmessage = this.onMessage;
    this.ws.onerror = this.onError;
    this.ws.onclose = this.onClose;
  }

  private onOpen = () => {
    Object.keys(this.messageSubscribersByChargePointId).forEach(
      (chargePointId) => {
        this.ws.send(
          JSON.stringify({
            type: 'SUBSCRIBE',
            data: { chargePointId },
          })
        );
      }
    );
    this.wsReady.resolve(true);
  };

  // eslint-disable-next-line class-methods-use-this
  private onError = (e: any) => {
    toast.error(i18n.t('ChargeStationDetail.oppsSomethingWentWrong'));
    console.log(`Error: ${e}`);
  };

  private onClose = (e: { code: any; reason: any }) => {
    this.wsReady.resolve(false);
    // retry after 1 sec.
    setTimeout(() => this.init(), 1000);
    console.log(e.code, e.reason);
  };

  private onMessage = (e: { data: string }) => {
    try {
      const response = JSON.parse(e?.data);
      const { chargePointId } = response;
      this.messageSubscribersByChargePointId[chargePointId].forEach(
        (onMessageHandler) => {
          onMessageHandler(response);
        }
      );
    } catch (err) {
      console.error('Error when handling websocket message', e, err);
    }
  };

  public subscribeToChargePointEvents = (
    chargePointId: string,
    onMessageHandler: ResponseHandlerType
  ) => {
    if (!chargePointId) {
      throw new Error('chargePointId cannot be empty');
    }
    if (!this.messageSubscribersByChargePointId[chargePointId]) {
      this.messageSubscribersByChargePointId[chargePointId] = new Set();
    }
    this.messageSubscribersByChargePointId[chargePointId].add(onMessageHandler);
    if (this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(
        JSON.stringify({
          type: 'SUBSCRIBE',
          data: { chargePointId },
        })
      );
    }
    return () =>
      this.unsubscribeToChargePointEvents(chargePointId, onMessageHandler);
  };

  public unsubscribeToChargePointEvents = (
    chargePointId: string,
    onMessageHandler: ResponseHandlerType
  ) => {
    if (!chargePointId) {
      throw new Error('chargePointId cannot be empty');
    }
    if (this.messageSubscribersByChargePointId[chargePointId]) {
      this.messageSubscribersByChargePointId[chargePointId].delete(
        onMessageHandler
      );
      if (this.messageSubscribersByChargePointId[chargePointId].size === 0) {
        delete this.messageSubscribersByChargePointId[chargePointId];
        this.ws.send(
          JSON.stringify({
            type: 'UNSUBSCRIBE',
            data: { chargePointId },
          })
        );
      }
    }
  };
}

export default WebSocketHelper;
