import { getDriverById } from 'actions';
import SlidingModal from 'Components/Common/SlidingModal';
import EditContext from 'Components/Contexts/EditContext';
import { DriverType } from 'declerations/DefaultTypes';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap';
import DriverInfo from './components/DriverInfo';
import DriverPaymentInfo from './components/DriverPaymentInfo';
import DriverTransactions from './components/DriverTransactions';
import DriverVehicleInfo from './components/DriverVehicleInfo';

interface DriverDetailProps {
  toggleHandle: () => void;
  isOpen: boolean;
}

const DriverDetail = ({ toggleHandle, isOpen }: DriverDetailProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [active, setActive] = useState('1');
  const [loading, setLoading] = useState(false);
  const editContext = useContext(EditContext);
  const driverId = editContext.editDriver?._id;
  const driver: DriverType = editContext.editDriver;

  useEffect(() => {
    if (driverId) {
      getDriverById(driverId).then((res) => {
        if (res.data && res.data.consumer) {
          editContext.SetEditDriver(res.data.consumer);
          setLoading(true);
        } else {
          toast.error(t('Driver.notFound'));
        }
      });
    }
  }, [driverId]);

  return (
    <SlidingModal
      toggleHandle={toggleHandle}
      isOpen={isOpen}
      title={`${driver?.fname} ${driver?.lname}`}
      size='xl'
    >
      <Nav className='justify-content-center' tabs>
        <NavItem>
          <NavLink
            active={active === '1'}
            onClick={() => {
              setActive('1');
            }}
          >
            {t('Driver.driverInfo')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={active === '2'}
            onClick={() => {
              setActive('2');
            }}
          >
            {t('Driver.paymentInfo')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={active === '3'}
            onClick={() => {
              setActive('3');
            }}
          >
            {t('Driver.vehicleInfo')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={active === '4'}
            onClick={() => {
              setActive('4');
            }}
          >
            {t('Driver.transaction')}
          </NavLink>
        </NavItem>
      </Nav>
      {loading && !editContext.editDriver ? (
        <Spinner />
      ) : (
        <TabContent className='py-50' activeTab={active}>
          <TabPane tabId='1'>
            {active === '1' && <DriverInfo driver={driver} />}
          </TabPane>
          <TabPane tabId='2'>
            {active === '2' && <DriverPaymentInfo driver={driver} />}
          </TabPane>
          <TabPane tabId='3'>
            {active === '3' && <DriverVehicleInfo driver={driver} />}
          </TabPane>
          <TabPane tabId='4'>
            {active === '4' && <DriverTransactions driver={driver} />}
          </TabPane>
        </TabContent>
      )}
    </SlidingModal>
  );
};

export default DriverDetail;
