import { APIClient } from '../helpers/api_helper';

const api = new APIClient();

export const getOrders = (filter: object) => {
  const apiUrl = `/bookings`;

  return api.get(apiUrl, filter);
};

export const createBluedotStation = (params: object) => {
  const apiUrl = `/stations/bluedot`;
  return api.create(apiUrl, params);
};
