const BASE_URL = process.env.REACT_APP_BASE_URL;
const URL_PATH = process.env.REACT_APP_URL_PATH;
const SERVER_URL = `${BASE_URL}${URL_PATH}`;
const GENERAL_SERVER_URL = `${BASE_URL}${URL_PATH?.split('/')
  .slice(0, -2)
  .join('/')}/`;
const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
let [tenant, env] = window.location.hostname.split('.');
if (!['uat', 'dev'].includes(env)) {
  env = 'prod';
}
// Uncomment below line while developing

 tenant = 'gioev';

// Choose Theme default => 0 GIO => 1
const themes = ['default', 'gio'];
const THEME = themes[1];

/* eslint prefer-const: "off" */
const OCPP_CPMS_WS = `wss://${tenant}.${process.env.REACT_APP_OCPP_CPMS_WS}`;

const OCPP_REST_API_URL = `https://${tenant}.${process.env.REACT_APP_OCPP_REST}api`;

const TENANT_ENV_URL = `https://${tenant}${env === 'prod' ? '' : `.${env}`}`;

const DOMAIN_KEY = tenant;

// const ASSET_BASE_URL = `https://${process.env.REACT_APP_ASSETS_BASE}${env}/${tenant}/`;
const ASSET_BASE_URL = `https://${process.env.REACT_APP_ASSETS_BASE}prod/${tenant}/`;

const TENANT_TITLE = `${tenant.substr(0, 1).toUpperCase()}${tenant
  .substr(1)
  .toLowerCase()}`;

const THEME_CONFIG_URL = `${ASSET_BASE_URL}themeConfig.jsonp?${Math.floor(
  Math.random() * 1000000
)}`;

const AUTO_REFRESH_TIME = 60 * 1000;

export {
  ASSET_BASE_URL,
  BASE_URL,
  OCPP_CPMS_WS,
  OCPP_REST_API_URL,
  URL_PATH,
  SERVER_URL,
  GOOGLE_MAP_API_KEY,
  TENANT_TITLE,
  THEME_CONFIG_URL,
  DOMAIN_KEY,
  GENERAL_SERVER_URL,
  AUTO_REFRESH_TIME,
  TENANT_ENV_URL,
  THEME,
};
