import React from 'react';
import { Alert, Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import succesEmail from '../../../assets/lottie/emailSuccessVertification.json';

const SuccessMessage = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: succesEmail,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const { t } = useTranslation();
  return (
    <Row>
      <Col lg={12}>
        <Card className='overflow-hidden border-0'>
          <Row className='justify-content-center g-0'>
            <Col lg={12}>
              {/* <div className='p-lg-5 p-4 text-center'>
                <div className='avatar-lg mx-auto mt-2'>
                  <div className='avatar-title bg-light text-success display-3 rounded-circle'>
                    <span
                      className='iconify'
                      data-icon='line-md:email-opened-twotone-alt'
                    />
                  </div>
                </div>
                <div className='mt-4 pt-2'>
                  <h4>{t('ForgotPassword.wellDone')}</h4>
                  <p className='text-muted mx-4'>
                    Lutfen mailinizi kontrol ediniz
                  </p>
                  <div className='mt-4'> */}
              {/* <Link
                      to='/auth-signin-basic'
                      className='btn btn-success w-100'
                    >
                      {t('ForgotPassword.backtoLogin')}
                    </Link> */}
              {/* </div>
                </div>
              </div> */}
              <Lottie options={defaultOptions} height={300} width={400} />
              <div className='mt-4 text-center'>
                <div className='mt-4 pt-2'>
                  {/* <h4>{t('ForgotPassword.wellDone')}</h4> */}
                  <p className='text-muted mx-4'>
                  {t('ForgotPassword.checkMailMessage')}
                  </p>
                </div>
              </div>
              {/* <div className='mt-4 text-center'>
                <h5 className='mb-0'>
                  {/* {t('ForgotPassword.checkYourEmail')} 
                </h5>
              </div> */}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
export default SuccessMessage;
