import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import ReactApexChart from 'react-apexcharts';

import { getLabelCurrency } from '../../../helpers/Currencies';

type RevenueReportType = {
  revenueReport: {
    expenses: number[];
    earnings: number[];
    currency: string;
  };
  changeYear: (year: string) => void;
};

const RevenueReport = ({ revenueReport, changeYear }: RevenueReportType) => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    years: ['2023', '2022', '2021', '2019', '2018'],
    price: 0,
    earnings: 0,
    expenses: 0,
    budget: 0,
  });
  const [selectedYear, setSelectedYear] = useState('2023');
  useEffect(() => {
    const currencySymbol = getLabelCurrency(revenueReport.currency, 'symbol');

    const earnings =
      revenueReport &&
      revenueReport.earnings &&
      revenueReport.earnings.length > 0
        ? revenueReport.earnings.reduce((a, b) => a + b, 0)
        : 0;

    const expenses =
      revenueReport &&
      revenueReport.expenses &&
      revenueReport.expenses.length > 0
        ? revenueReport.expenses.reduce((a, b) => a + b, 0)
        : 0;

    setData({
      earnings,
      expenses,
      years: ['2023', '2022', '2021'],
      price: earnings, // numeral(earnings).format('0.0,').toString() + currencySymbol,
      budget: earnings + expenses,
      // numeral(earnings - expenses)
      //   .format('0.0,')
      //   .toString() + currencySymbol,
    });
  }, [revenueReport]);

  const revenueOptions = {
    chart: {
      stacked: false,
      type: 'line' as const,
      toolbar: { show: false },
    },
    stroke: {
      curve: 'smooth' as const,
      dashArray: [0, 3, 0],
      width: [0, 1, 0],
    },
    fill: {
      opacity: [1, 0.1, 1],
    },
    markers: {
      size: [0, 4, 0],
      strokeWidth: 2,
      hover: {
        size: 4,
      },
    },
    yaxis: {
      labels: {
        formatter: function format(y: number) {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(2)}`;
          }
          return y;
        },
      },
    },
    xaxis: {
      categories: t('Home.months', { returnObjects: true }),

      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },

    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 0,
        right: -2,
        bottom: 15,
        left: 10,
      },
    },
    legend: {
      show: true,
      horizontalAlign: 'center' as const,
      offsetX: 0,
      offsetY: -5,
      markers: {
        width: 9,
        height: 9,
        radius: 6,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '30%',
        barHeight: '80%',
      },
    },
    colors: ['#25a0e2', '#25a0e21a', '#25a0e280'],
    tooltip: {
      shared: true,
      y: {
        formatter: function format(y: number) {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(2)} ${getLabelCurrency(
              revenueReport.currency,
              'symbol'
            )}`;
          }
          return y;
        },
      } as const,
    },
  };
  const revenueSeries = [
    {
      name: t('Home.endorsement'),
      type: 'bar',
      data:
        revenueReport && revenueReport.earnings
          ? revenueReport.earnings
          : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // [95, 177, 284, 256, 105, 63, 168, 218, 72, 95, 177, 284],
    },
    {
      name: t('Home.revenue'),
      type: 'area',
      data:
        revenueReport && revenueReport.earnings && revenueReport.expenses
          ? revenueReport.earnings.map(
              (x, index) => x + revenueReport.expenses[index]
            )
          : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // [5631.29, 5341.23, 10837.32, 12376.43, 15191.25, 12114.74, 32884.95, 8787.25, 0, 0, 0, 0] = 103164.45999999999
    },
    {
      name: t('Home.expense'),
      type: 'bar',
      data:
        revenueReport && revenueReport.expenses
          ? revenueReport.expenses.map((x) => x * -1)
          : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // [-2240.27, -1115.19, -3082.04, -3893.58, -6256.8, -5308.53, -11912.39, -3665.63, 0, 0, 0, 0] = 37474.43
    },
  ];

  return (
    <Row>
      <Col className='revenue-report-wrapper' md='12' xs='12'>
        <Card className='card-revenue-budget'>
          <CardHeader>
            <h4 className='card-title mb-0'> {t('Home.revenueReport')}</h4>
          </CardHeader>

          <CardHeader className='p-0 border-0 bg-soft-light'>
            <Row className='g-0 text-center'>
              <Col>
                <div className='p-3 h-100 border border-dashed border-start-0 d-flex flex-row text-center justify-content-center'>
                  <h5 className='align-items-center px-2 d-flex'>
                    {t('year')}
                  </h5>
                  <UncontrolledButtonDropdown>
                    <DropdownToggle
                      className='budget-dropdown'
                      outline
                      color='primary'
                      size='md'
                      caret
                    >
                      {selectedYear}
                    </DropdownToggle>
                    <DropdownMenu>
                      {data.years.map((item) => (
                        <DropdownItem
                          className='w-100'
                          key={item}
                          onClick={() => {
                            setSelectedYear(item);
                            changeYear(item);
                          }}
                        >
                          {item}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </div>
              </Col>
              <Col>
                <div className='p-3 border border-dashed border-start-0'>
                  <h5 className='mb-1'>
                    <span className='counter-value' data-target='9851'>
                      <CountUp
                        start={0}
                        end={data.earnings}
                        formattingFn={(val) =>
                          `${numeral(val)
                            .format('0.0,')
                            .toString()} ${getLabelCurrency(
                            revenueReport.currency || 'TRY',
                            'symbol'
                          )}`
                        }
                        duration={4}
                        decimals={2}
                      />
                    </span>
                    {/* {getLabelCurrency(revenueReport.currency, 'symbol')} */}
                  </h5>
                  <p className='text-muted mb-0'>{t('Home.endorsement')}</p>
                </div>
              </Col>
              {/* <Col>
                <div className='p-3 border border-dashed border-start-0'>
                  <h5 className='mb-1'>
                    <span className='counter-value'>
                      <CountUp
                        start={0}
                        end={data.budget}
                        formattingFn={(val) =>
                          `${numeral(val)
                            .format('0.0,')
                            .toString()} ${getLabelCurrency(
                            revenueReport.currency || 'TRY',
                            'symbol'
                          )}`
                        }
                        decimals={2}
                        duration={4}
                      />
                       {data.budget} 
                    </span>
                  </h5>
                  <p className='text-muted mb-0'>{t('Home.totalEarnings')}</p>
                </div>
              </Col> */}
              <Col>
                <div className='p-3 border border-dashed border-start-0'>
                  <h5 className='mb-1'>
                    <span className='counter-value' data-target='228.89'>
                      <CountUp
                        start={0}
                        end={data.expenses}
                        formattingFn={(val) =>
                          `${numeral(val)
                            .format('0.0,')
                            .toString()} ${getLabelCurrency(
                            revenueReport.currency || 'TRY',
                            'symbol'
                          )}`
                        }
                        decimals={2}
                        duration={4}
                      />
                    </span>
                    {/* {getLabelCurrency(revenueReport.currency, 'symbol')} */}
                  </h5>
                  <p className='text-muted mb-0'>{t('Home.totalExpenses')}</p>
                </div>
              </Col>
              <Col>
                <div className='p-3 border border-dashed border-start-0  border-end-0 '>
                  <h5 className='mb-1'>
                    <span className='counter-value' data-target='228.89'>
                      <CountUp
                        start={0}
                        end={data.budget}
                        formattingFn={(val) =>
                          `${numeral(val)
                            .format('0.0,')
                            .toString()} ${getLabelCurrency(
                            revenueReport.currency || 'TRY',
                            'symbol'
                          )}`
                        }
                        decimals={2}
                        duration={4}
                      />
                    </span>
                  </h5>
                  <p className='text-muted mb-0'>{t('Home.totalRevenue')}</p>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md='12' xs='12'>
                <ReactApexChart
                  options={revenueOptions}
                  series={revenueSeries}
                  type='line'
                  height={350}
                  className='apex-charts'
                />
              </Col>
            </Row>

            {/* <Chart
            id='revenue-report-chart'
            type='bar'
            height='230'
            options={revenueOptions}
            series={revenueSeries}
          /> */}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default RevenueReport;
