/* eslint-disable no-else-return */
import Container from 'Components/Common/Container';
import DataTable from 'Components/Common/DataTable';
import DataContext from 'Components/Contexts/DataContext';
import EditContext from 'Components/Contexts/EditContext';
import { TransactionType } from 'declerations/DefaultTypes';
import { getLabelCurrency } from 'helpers/Currencies';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import ExportBlock from 'laxyExcel/ExportComponents';
import AutoRefresh from 'Components/Common/AutoRefresh';
import { AUTO_REFRESH_TIME } from 'helpers/config';
import { getCdrs } from 'actions';
import PaymentDetail from './PaymentDetail';
import 'moment/locale/tr';
import 'moment/locale/en-nz';
import i18n from '../../i18n';

const CDR = () => {
  const context = useContext(DataContext);
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [filtered, setFiltered] = useState('');
  const [isActiveAutoRefresh, setIsActiveAutoRefresh] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] =
    useState<TransactionType | null>(null);
  const [data, setData] = useState<TransactionType[] | undefined>();
  const [count, setCount] = useState<number>(0);
  const [reset, setReset] = useState<boolean>(false);
  const [PerRows, setPerRows] = useState<number>(100);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const [filter, setFilter] = useState<{
    startDate: string | undefined;
    endDate: string | undefined;
    applicationId: string | undefined;
    locationId: string | undefined;
    chargePointId: string | undefined;
    state: string | undefined;
    user: string | undefined;
    limit: number | undefined;
    offset: number | undefined;
  }>({
    applicationId: 'null',
    locationId: 'null',
    chargePointId: 'null',
    state: 'null',
    user: 'null',
    endDate: 'null',
    startDate: 'null',
    limit: PerRows,
    offset: 0,
  });

  const loadDataTable = () => {
    getCdrs(filter).then((res) => {
      setData(res.data.transactions);
      setCount(res.data.count);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  };
  useEffect(() => {
    loadDataTable();
  }, [filter]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isActiveAutoRefresh) {
        loadDataTable();
      }
    }, AUTO_REFRESH_TIME);
    return () => clearInterval(intervalId);
  }, [isActiveAutoRefresh]);

  const search = (rowData: TransactionType[] | undefined) => {
    if (!rowData) {
      return [];
    }
    return rowData.filter(
      (row: TransactionType) =>
        row?.chargePointId?.toLowerCase().indexOf(filtered.toLowerCase()) >
          -1 ||
        row?.connectorSocketType
          ?.toLowerCase()
          .indexOf(filtered.toLowerCase()) > -1 ||
        (row.consumer &&
          `${row?.consumer?.fname?.toLowerCase()} ${row?.consumer?.lname?.toLowerCase()}`.indexOf(
            filtered.toLowerCase()
          ) > -1) ||
        (row.consumer &&
          row?.consumer?.email?.toLowerCase().indexOf(filtered.toLowerCase()) >
            -1)
    );
  };
  const mappers = {
    endDate: (row: any) => {
      if (row.inProgress) {
        return '-';
      } else {
        if (row?.timezone) {
          if (i18n.language === 'en') {
            return `${moment(row?.endDate)
              .tz(row?.timezone)
              .format('lll')
              .toString()}`;
          } else {
            return `${moment(row?.endDate)
              .tz(row?.timezone)
              .locale('tr')
              .format('lll')
              .toString()}`;
          }
        }
        if (i18n.language === 'en') {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          `${moment(row.endDate).format('lll').toString()}`;
        }
        return `${moment(row.endDate).locale('tr').format('lll').toString()}`;
      }
    },
    startDate: (row: any) => {
      if (row?.timezone) {
        if (i18n.language === 'en') {
          return `${moment(row?.startDate)
            .tz(row?.timezone)
            .format('lll')
            .toString()}`;
        } else {
          return `${moment(row?.startDate)
            .tz(row?.timezone)
            .locale('tr')
            .format('lll')
            .toString()}`;
        }
      }
      if (i18n.language === 'en') {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        `${moment(row.startDate).format('lll').toString()}`;
      }
      return `${moment(row.startDate).locale('tr').format('lll').toString()}`;
    },
    idTagName: (row: any) => {
      if (row?.idTagName != null) {
        return `${row?.idTagName}`;
      }
      return `${row?.idTag}`;
    },
    totalFee: (row: any) => {
      return `${row?.paymentProfile?.totalFee} ${row?.paymentProfile?.currency}`;
    },
  };
  const headers = [
    'startDate',
    'endDate',
    'transactionId',
    'cpLocation.title',
    'serviceTariff.title',
    'totalFee',
  ].map((key: string) => ({
    key,
    value: `payments.${key}`,
    mapper: mappers[key as keyof typeof mappers],
  }));

  const pagination = (e: number) => {
    setPageNumber(e);
    const control = count / PerRows;
    const flag = Math.ceil(control);
    if (e <= flag) {
      setFilter({
        ...filter,
        offset: PerRows && (e - 1) * PerRows,
        limit: PerRows,
      });
    } else if (e === flag) {
      setFilter({
        ...filter,
        offset: count,
        limit: PerRows,
      });
    }
  };

  const handlePerRowsChange = (e: number) => {
    setPerRows(e);
    setReset(!reset);
    setFilter({
      ...filter,
      offset:
        PerRows && pageNumber === 0 ? pageNumber * e : (pageNumber - 1) * e,
      limit: e,
    });
  };

  return (
    <Container
      title={t('chargeDetailRecords')}
      rightSide={
        <AutoRefresh
          isActive={isActiveAutoRefresh}
          onPress={() => {
            setIsActiveAutoRefresh(!isActiveAutoRefresh);
          }}
        />
      }
    >
      <Row className='mb-2 d-flex justify-content-end'>
        <Col xl={3} sm={12} className=' d-flex justify-content-end'>
          <ExportBlock
            data={data}
            header={headers}
            name={t('chargeDetailRecords')}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={12} lg={12} sm={12}>
          <DataTable
            loading={loading}
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={PerRows}
            onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
            onChangePage={(e) => pagination(e)}
            paginationResetDefaultPage={reset}
            noDataText={t('Payment.thereareNoCdrCreated')}
            onRowClicked={(row, event) => {
              setSelectedPayment(row);
              setIsOpen(true);
            }}
            columns={[
              {
                name: t('Payment.Id'),
                cell: (row: any, index: number) => {
                  return index + 1;
                },
              },
              {
                name: t('Payment.startDate'),
                selector: (row: TransactionType) => row.startDate,
                grow: 1.6,
                sortable: true,
                cell: (row: TransactionType) =>
                  row?.timezone
                    ? i18n.language === 'en'
                      ? moment(row?.startDate)
                          .tz(row?.timezone)
                          .format('lll')
                          .toString()
                      : moment(row?.startDate)
                          .tz(row?.timezone)
                          .locale('tr')
                          .format('lll')
                          .toString()
                    : i18n.language === 'en'
                    ? moment(row.startDate).format('lll').toString()
                    : moment(row.startDate)
                        .locale('tr')
                        .format('lll')
                        .toString(),
              },
              {
                name: t('Payment.endDate'),
                selector: (row: TransactionType) => row.endDate,
                grow: 1.6,
                sortable: true,
                cell: (row: TransactionType) =>
                  row.inProgress
                    ? '-'
                    : row?.timezone
                    ? i18n.language === 'en'
                      ? moment(row.endDate)
                          .tz(row.timezone)
                          .format('lll')
                          .toString()
                      : moment(row.endDate)
                          .tz(row.timezone)
                          .locale('tr')
                          .format('lll')
                          .toString()
                    : i18n.language === 'en'
                    ? moment(row.endDate).format('lll').toString()
                    : moment(row.endDate).locale('tr').format('lll').toString(),
              },
              {
                name: t('Payment.sessionId'),
                selector: (row: TransactionType) =>
                  row.paymentProfile.paymentId !== null
                    ? row?.paymentProfile.paymentId
                    : '-',
              },
              {
                name: t('Payment.cdrLocation'),
                selector: (row: TransactionType) => row.cpLocation.title,
              },
              {
                name: t('Payment.tariff'),
                selector: (row: TransactionType) => row.serviceTariff?.title,
              },
              {
                name: t('Payment.idTagName'),
                selector: (row: TransactionType) => row.idTagName,
                cell: (row: TransactionType) =>
                  row?.idTagName != null ? row.idTagName : row.idTag,
              },
              {
                name: t('Payment.totalFee'),
                sortable: true,
                selector: (row: TransactionType) => row.paymentProfile.totalFee,
                cell: (row: TransactionType) => (
                  <div className='text-primary'>
                    {row.paymentProfile && row.paymentProfile.totalFee
                      ? `${row.paymentProfile.totalFee.toFixed(
                          2
                        )} ${getLabelCurrency(
                          row.paymentProfile.currency,
                          'symbol'
                        )}`
                      : `0 ${getLabelCurrency(
                          row.paymentProfile.currency,
                          'symbol'
                        )}`}
                  </div>
                ),
              },
            ]}
            data={data || []}
          />
        </Col>
      </Row>
      <PaymentDetail
        isOpen={isOpen}
        toggleHandle={() => setIsOpen(false)}
        selectedPayment={selectedPayment}
      />
    </Container>
  );
};
export default CDR;
