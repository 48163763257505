/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import visa from 'assets/images/svg/visa.svg';
import masterCard from 'assets/images/svg/masterCards.svg';
import { Form, Label, Row, Col, CardBody, Card, Button } from 'reactstrap';
import { DriverType } from 'declerations/DefaultTypes';
import CardInfoText from 'Components/Common/CardInfoText';
import Swal from 'sweetalert2';
import EditContext from 'Components/Contexts/EditContext';
import withReactContent from 'sweetalert2-react-content';
import { removePaymentMethod, getDriverById } from 'actions';
import { toast } from 'react-toastify';

const DriverPaymentInfo = (props: { driver: DriverType }) => {
  const { driver } = props;
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const editContext = useContext(EditContext);
  const [loading, setLoading] = useState(false);

  const removeCard = (consumerId: string, cardId: string) => {
    MySwal.fire({
      title: t('Driver.deleteThisPaymentMethod'),
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: t('no'),
      confirmButtonText: t('yes'),
    }).then((result) => {
      if (result.isConfirmed) {
        removePaymentMethod(consumerId, cardId)
          .then((res) => {
            getDriverById(driver._id).then((answer) => {
              if (answer.data && answer.data.consumer) {
                editContext.SetEditDriver(answer.data.consumer);
                toast.success(t('Driver.succesfully'));
                setLoading(true);
              } else {
                toast.error(t('Driver.notFound'));
              }
            });
          })
          .catch(() => toast.error(t('Driver.fail')));
      }
    });
  };
  return (
    <div>
      {driver?.cards?.length >= 1 ? (
        <div>
          {driver?.cards.map(
            (card: any, index: number) =>
              card?.isDefault && (
                <Card className='mt-2' key={index.toString()}>
                  <CardBody>
                    <Row>
                      <Col>
                        <ul className='list-group list-group-flush'>
                          <CardInfoText
                            title={t('DriverPaymentInfo.cardAlias')}
                            value={
                              driver.defaultCard.cardAlias != null
                                ? driver.defaultCard.cardAlias
                                : ' -'
                            }
                          />
                          <CardInfoText
                            title={t('DriverPaymentInfo.cardFamily')}
                            value={
                              driver.defaultCard.cardFamily != null
                                ? driver.defaultCard.cardFamily
                                : ' -'
                            }
                          />
                          <CardInfoText
                            title={t('DriverPaymentInfo.cardBankName')}
                            value={
                              driver.defaultCard.cardBankName != null
                                ? driver.defaultCard.cardBankName
                                : ' -'
                            }
                          />
                        </ul>
                      </Col>
                      <Col>
                        <ul className='list-group list-group-flush'>
                          <CardInfoText
                            title={t('DriverPaymentInfo.lastFourDigits')}
                            value={
                              driver.defaultCard.lastFourDigits != null
                                ? driver.defaultCard.lastFourDigits
                                : ' -'
                            }
                          />
                          <CardInfoText
                            title={t('DriverPaymentInfo.cardAssociation')}
                            value={
                              driver.defaultCard.cardAssociation ===
                              'MASTER_CARD' ? (
                                <img
                                  src={masterCard}
                                  style={{ height: '50px', width: '%80' }}
                                  alt='mastercard'
                                />
                              ) : (
                                <img
                                  src={visa}
                                  style={{ height: '50px', width: '80px' }}
                                  alt='visa'
                                />
                              )
                            }
                          />
                        </ul>
                        <div
                          className='d-flex justify-content-end mt-2'
                          style={{
                            fontSize: '14px',
                            fontWeight: '500',
                            color: 'gray',
                          }}
                        >
                          (Default Card)
                        </div>
                        <div
                          className='d-flex justify-content-end  m-0'
                          // eslint-disable-next-line react/jsx-no-comment-textnodes
                        >
                          <div
                            style={{
                              color: 'red',
                              cursor: 'pointer',
                              width: '90px',
                              marginRight: '0px',
                            }}
                            onClick={() => removeCard(driver._id, card._id)}
                          >
                            Remove Card
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )
          )}
          {driver?.cards.map(
            (card: any, index: number) =>
              !card.isDefault && (
                <Card className='mt-2' key={index.toString()}>
                  <CardBody>
                    <Row>
                      <Col>
                        <ul className='list-group list-group-flush'>
                          <CardInfoText
                            title={t('DriverPaymentInfo.cardAlias')}
                            value={
                              card.cardAlias != null ? card.cardAlias : ' -'
                            }
                          />
                          <CardInfoText
                            title={t('DriverPaymentInfo.cardFamily')}
                            value={
                              card.cardFamily != null ? card.cardFamily : ' -'
                            }
                          />
                          <CardInfoText
                            title={t('DriverPaymentInfo.cardBankName')}
                            value={
                              card.cardBankName != null
                                ? card.cardBankName
                                : ' -'
                            }
                          />
                        </ul>
                      </Col>
                      <Col>
                        <ul className='list-group list-group-flush'>
                          <CardInfoText
                            title={t('DriverPaymentInfo.lastFourDigits')}
                            value={
                              card.lastFourDigits != null
                                ? card.lastFourDigits
                                : ' -'
                            }
                          />
                          <CardInfoText
                            title={t('DriverPaymentInfo.cardAssociation')}
                            value={
                              card.cardAssociation === 'MASTER_CARD' ? (
                                <img
                                  src={masterCard}
                                  style={{ height: '50px', width: '%80' }}
                                  alt='mastercard'
                                />
                              ) : (
                                <img
                                  src={visa}
                                  style={{ height: '50px', width: '80px' }}
                                  alt='visa'
                                />
                              )
                            }
                          />
                        </ul>
                        <div
                          className='d-flex justify-content-end  m-0'
                          // eslint-disable-next-line react/jsx-no-comment-textnodes
                        >
                          <div
                            style={{
                              color: 'red',
                              cursor: 'pointer',
                              width: '90px',
                              marginRight: '0px',
                            }}
                            onClick={() => removeCard(driver._id, card._id)}
                          >
                            Remove Card
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )
          )}{' '}
        </div>
      ) : (
        <Row>
          <Col>
            <div>{t('DriverPaymentInfo.ThereisnoRegisteredCard')}</div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DriverPaymentInfo;
