/* eslint-disable */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useProfile } from '../Components/Hooks/UserHooks';
import { ApplicationProvider } from 'Components/Contexts/ApplicationContext';
import { DataProvider } from 'Components/Contexts/DataContext';
import { MenuItemProvider } from 'Components/Contexts/MenuItemContext';
import { EditProvider } from 'Components/Contexts/EditContext';
import { AutoRefreshProvider } from 'Components/Contexts/AutoRefreshContext';
const AuthProtected = (props) => {
  const { userProfile, loading } = useProfile();

  /*
    redirect is un-auth access protected routes via url
    */

  if (!userProfile && loading) {
    return (
      <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    );
  }

  return (
    <>
      <MenuItemProvider>
        <DataProvider>
          <ApplicationProvider>
            <AutoRefreshProvider>
              <EditProvider>{props.children}</EditProvider>
            </AutoRefreshProvider>
          </ApplicationProvider>
        </DataProvider>
      </MenuItemProvider>
    </>
  );
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {' '}
            <Component {...props} />{' '}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
