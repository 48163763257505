import { APIClient } from '../helpers/api_helper';

const api = new APIClient();
export const getProducts = (filter: object) => {
  const apiUrl = `/products`;

  return api.get(apiUrl, filter);
};

export const updateProducts = (params: { id: string } & object) => {
  const { id, ...otherParams } = params;
  const apiUrl = `/products/${id}`;
  return api.update(apiUrl, otherParams);
};

export const deleteProducts = (params: { id: string } & object) => {
  const { id, ...otherParams } = params;
  const apiUrl = `/products/${id}`;
  return api.delete(apiUrl, otherParams);
};
