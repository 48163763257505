import {
  ChargeStationType,
  ConnectorType,
  LocationType,
  RepairLogType,
  ChargingRatesType,
  DriverType,
  TransactionType,
  ApplicationsType,
  OcppTagType,
  EmployeeType,
  TaskConfigType,
  IntegrationTaskConfigType,
  RoleType,
} from 'declerations/DefaultTypes';
import React, { useEffect, useState, useMemo } from 'react';

const editContext = React.createContext<any>(null);

export const EditProvider = ({ children }: { children: any }) => {
  const [editConnector, setEditConnector] = useState<ConnectorType>();
  const [editChargeStation, setEditChargeStation] =
    useState<ChargeStationType>();
  const [editRepairLog, setEditRepairLog] = useState<RepairLogType>();
  const [editLocation, setEditLocation] = useState<LocationType>();
  const [editChargingRate, setEditChargingRate] = useState<ChargingRatesType>();
  const [editChargingRateHistory, setEditChargingRateHistory] = useState<any>();
  const [editDriver, setEditDriver] = useState<DriverType>();
  const [editTransaction, setEditTransaction] = useState<TransactionType>();
  const [transactionRefresh, setTransactionRefresh] = useState<number>(0);
  const [editApplication, setEditApplication] = useState<ApplicationsType>();
  const [editOcppTag, setEditOcppTag] = useState<OcppTagType>();
  const [editEmployee, setEditEmployee] = useState<EmployeeType>();
  const [editRole, setEditRole] = useState<RoleType>();
  const [editTaskConfig, setEditTaskConfig] = useState<TaskConfigType>();
  const [editIntegrationTaskConfigType, setEditIntegrationTaskConfigType] =
    useState<IntegrationTaskConfigType>();

  const SetEditChargeStation = async (station: ChargeStationType) => {
    return setEditChargeStation(station);
  };
  const SetEditTaskConfig = async (task: TaskConfigType) => {
    return setEditTaskConfig(task);
  };
  const SetEditRole = async (role: RoleType) => {
    return setEditRole(role);
  };
  const SetEditOcppTag = async (ocppTags: OcppTagType) => {
    return setEditOcppTag(ocppTags);
  };

  const SetEditTransaction = async (transaction: TransactionType) => {
    return setEditTransaction(transaction);
  };
  const SetEditChargingRate = async (tariff: ChargingRatesType) => {
    return setEditChargingRate(tariff);
  };

  const SetEditChargingRateHistory = async (chargingRateHistory: any) => {
    return setEditChargingRateHistory(chargingRateHistory);
  };

  const SetEditConnector = async (connector: ConnectorType) => {
    return setEditConnector(connector);
  };

  const SetEditRepaitLog = async (repairLog: RepairLogType) => {
    return setEditRepairLog(repairLog);
  };

  const SetEditLocation = async (location: LocationType) => {
    return setEditLocation(location);
  };

  const SetEditDriver = async (driver: DriverType) => {
    return setEditDriver(driver);
  };
  const SetTransactionRefresh = () => {
    return setTransactionRefresh(transactionRefresh + 1);
  };
  const SetEditApplication = async (application: ApplicationsType) => {
    return setEditApplication(application);
  };

  const SetEditEmployee = async (employee: EmployeeType) => {
    return setEditEmployee(employee);
  };

  const SetEditIntegrationTaskConfigType = async (
    integrationTaskConfigType: IntegrationTaskConfigType
  ) => {
    return setEditIntegrationTaskConfigType(integrationTaskConfigType);
  };

  const values = useMemo(
    () => ({
      editConnector,
      SetEditConnector,
      editChargeStation,
      SetEditChargeStation,
      editRepairLog,
      SetEditRepaitLog,
      editLocation,
      SetEditLocation,
      editChargingRate,
      SetEditChargingRate,
      editChargingRateHistory,
      SetEditChargingRateHistory,
      editDriver,
      SetEditDriver,
      editTransaction,
      SetEditTransaction,
      transactionRefresh,
      SetTransactionRefresh,
      editApplication,
      SetEditApplication,
      editOcppTag,
      SetEditOcppTag,
      editEmployee,
      SetEditEmployee,
      editTaskConfig,
      SetEditTaskConfig,
      editIntegrationTaskConfigType,
      SetEditIntegrationTaskConfigType,
      SetEditRole,
      editRole,
    }),
    [
      editConnector,
      editChargeStation,
      editRepairLog,
      editLocation,
      editChargingRate,
        editChargingRateHistory,
      editDriver,
      editTransaction,
      transactionRefresh,
      editApplication,
      editOcppTag,
      editEmployee,
      editTaskConfig,
      editIntegrationTaskConfigType,
      editRole,
    ]
  );
  return <editContext.Provider value={values}>{children}</editContext.Provider>;
};

export default editContext;
