import React from 'react';

export const getAmenityIcon = (name: string) => {
  switch (name) {
    case 'Car Wash':
    case 'CarWash':
    case 'Oto Yıkama':
      return (
        <i
          className='ri-car-washing-line ri-lg text-primary mx-1'
          data-rh={name}
        />
      );
    case 'Çocuk Parkı':
    case 'Children PlayGround':
      return (
        <i
          className='ri-open-arm-line ri-lg text-primary mx-1'
          data-rh={name}
        />
      );
    case 'Market':
      return (
        <i className='ri-store-2-line ri-lg text-primary mx-1' data-rh={name} />
      );
    case 'Restaurant/Cafe':
    case 'Meal/Cafe':
      return (
        <i className='ri-cup-line ri-lg text-primary mx-1' data-rh={name} />
      );
    case 'Tuvalet':
    case 'Toilet':
      return (
        <i
          className='ri-door-open-line ri-lg text-primary mx-1'
          data-rh={name}
        />
      );

    default:
      return (
        <i
          className='ri-open-arm-line ri-lg text-primary mx-1'
          data-rh={name}
        />
      );
  }
};

export default getAmenityIcon;
