import { APIClient } from '../helpers/api_helper';

const api = new APIClient();

export const getReports = (filter?: object) => {
  const apiUrl = `transactions/revenue`;
  return api.get(apiUrl, filter);
};
export const getReportsEmsp = (filter?: object) => {
  const apiUrl = `transactions/emsp-revenue`;
  return api.get(apiUrl, filter);
};
export default getReports;
