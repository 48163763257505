import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Button } from 'reactstrap';
import DataTable from 'Components/Common/DataTable';
import DataContext from 'Components/Contexts/DataContext';
import { useTranslation } from 'react-i18next';
import Container from 'Components/Common/Container';
import { Plus } from 'react-feather';
import ExportBlock from 'laxyExcel/ExportComponents';
import AutoRefresh from 'Components/Common/AutoRefresh';
import { AUTO_REFRESH_TIME } from 'helpers/config';
import { PermissionType } from '../../declerations/DefaultTypes';
import AddPermissions from './AddPermissions';

const Permissions = () => {
  const { t } = useTranslation();
  const context = useContext(DataContext);
  const [addIsOpen, setAddIsOpen] = useState(false);
  const [isActiveAutoRefresh, setIsActiveAutoRefresh] = useState(true);

  const [loading, setLoading] = useState<boolean>(true);

  const loadDataTable = () => {
    context
      .LoadPermissions()
      .then(() => {
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    loadDataTable();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isActiveAutoRefresh) {
        loadDataTable();
      }
    }, AUTO_REFRESH_TIME);
    return () => clearInterval(intervalId);
  }, [isActiveAutoRefresh]);

  const headers = [
    'user.email',
    'user.fname',
    'user.lname',
    'role.title',
    'scopeType',
  ].map((key) => ({
    key,
    value: `permissions.${key}`,
  }));
  return (
    <Container
      title={t('Roles')}
      rightSide={
        <AutoRefresh
          isActive={isActiveAutoRefresh}
          onPress={() => {
            setIsActiveAutoRefresh(!isActiveAutoRefresh);
          }}
        />
      }
    >
      <Row className='mb-2 d-flex justify-content-end'>
        <Col xl={3} sm={12} className=' d-flex justify-content-end'>
          <ExportBlock
            data={context.permissions}
            header={headers}
            name={t('Roles')}
          />
          <Button
            className='ms-1'
            aria-hidden='true'
            color='primary'
            onClick={() => {
              setAddIsOpen(!addIsOpen);
            }}
          >
            <Plus className='me-2 p-0' size='20' />

            {t('Add')}
          </Button>
        </Col>
      </Row>
      <div>
        <DataTable
          loading={loading}
          noDataText={t('Permission.thereArenoPermissionCreated')}
          columns={[
            {
              name: 'Email',
              selector: (row: PermissionType) => row.user.email,
            },
            {
              name: t('Permission.fname'),
              selector: (row: PermissionType) => row.user.fname,
            },
            {
              name: t('Permission.lname'),
              selector: (row: PermissionType) => row.user.lname,
            },
            {
              name: t('Permission.role'),
              selector: (row: PermissionType) => row.role.title,
            },
            {
              name: t('Permission.scopeType'),
              selector: (row: PermissionType) => row.scopeType,
            },
          ]}
          data={context.permissions || []}
        />
      </div>
      {addIsOpen && (
        <AddPermissions
          isOpen={addIsOpen}
          toggleHandle={() => {
            setAddIsOpen(false);
            context.LoadPermissions();
          }}
        />
      )}
    </Container>
  );
};
export default Permissions;
