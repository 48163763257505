import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import {
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap';
import { ChevronLeft } from 'react-feather';
import { getChargeStationDetails } from 'actions';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ChargeStationType } from 'declerations/DefaultTypes';
import EditContext from 'Components/Contexts/EditContext';
import EditAutoRefresh from 'Components/Contexts/AutoRefreshContext';
import classnames from 'classnames';
import Container from 'Components/Common/Container';
import AutoRefresh from 'Components/Common/AutoRefresh';
import { AUTO_REFRESH_TIME } from 'helpers/config';
import WebSocketHandler from '../../../../helpers/WebSocketHandler';

const Overview = lazy(() => import('./SubPages/Overview'));
const Sessions = lazy(() => import('./SubPages/Sessions'));
const Photos = lazy(() => import('./SubPages/Photos'));
const People = lazy(() => import('./SubPages/People'));
const Settings = lazy(() => import('./SubPages/Settings'));
const Logs = lazy(() => import('./SubPages/Logs/Logs'));
const MeterValues = lazy(() => import('./SubPages/MeterValues/MeterValues'));
const Repair = lazy(() => import('./SubPages/Repair/Repair'));
const Configuration = lazy(() => import('./SubPages/Configuration'));
const defaultTab = 'overview';

interface DetailFullPageProps {
  chargePoint: ChargeStationType;
}

const DetailFullPage = () => {
  const { params, path, url } = useRouteMatch();

  const location = useLocation();
  const { t } = useTranslation();
  const editContext = useContext(EditContext);
  const editAutoRefresh = useContext(EditAutoRefresh);
  const history = useHistory();
  const [data, setData] = useState([]);
  const chargePoint = editContext.editChargeStation;
  const id = chargePoint?.chargePointId;
  const [refreshProvider, setRefreshProvider] = useState(false);

  useEffect(() => editContext.SetEditChargeStation(), []);
  const isChargePointLoaded = (params as { id: string }).id === id;
  let cpId = '';
  const [animationNavTab, setanimationNavTab] = useState('overview');
  const TAB_ITEMS = [
    { id: 'overview', label: t('ChargeStationDetail.overview') },
    { id: 'sessions', label: t('ChargeStationDetail.sessions') },
    { id: 'photos', label: t('ChargeStationDetail.photos') },
    { id: 'people', label: t('ChargeStationDetail.people') },
    { id: 'logs', label: t('ChargeStationDetail.logs') },
    { id: 'meter-values', label: t('ChargeStationDetail.metervalues') },
    { id: 'repair', label: t('ChargeStationDetail.repair') },
    { id: 'configuration', label: t('ChargeStationDetail.configuration') },
    { id: 'settings', label: t('ChargeStationDetail.settings') },
  ];
  const animationNavToggle = (tab: any) => {
    if (animationNavTab !== tab) {
      setanimationNavTab(tab);
      editAutoRefresh.setTabNames(tab);
      // history.push(`/cpo/charge-station/${tab}`);
    }
  };
  const selectedTab = (() => {
    const parts = location.pathname.split('/').filter((x) => x);
    const currentTab = parts[parts.length - 1].toLowerCase();
    cpId = parts.length > 3 ? parts[parts.length - 2] : parts[parts.length - 1];
    switch (currentTab) {
      case 'overview':
      case 'sessions':
      case 'photos':
      case 'settings':
      case 'people':
      case 'logs':
      case 'meter-values':
      case 'configuration':
      case 'repair':
        return currentTab;
        break;
      default:
        return defaultTab;
        break;
    }
  })();

  const loadDataTable = () => {
    try {
      getChargeStationDetails(cpId).then((res) => {
        if (res.data) {
          const {
            chargePoint: newChargePoint,
          }: { chargePoint: ChargeStationType } = res.data;
          editContext.SetEditChargeStation(newChargePoint);
        }
      });
    } catch (e) {
      console.error('error when reloading charge station details', e);
    }
  };
  useEffect(() => {
    loadDataTable();
  }, [refreshProvider]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        editAutoRefresh.isActiveAutoRefresh &&
        editAutoRefresh.tabName === 'overview'
      ) {
        loadDataTable();
      }
    }, AUTO_REFRESH_TIME);
    return () => clearInterval(intervalId);
  }, [
    editAutoRefresh.isActiveAutoRefresh,
    id,
    chargePoint,
    editAutoRefresh.tabName,
  ]);

  useEffect(() => {
    if (!chargePoint?.chargePointId) return undefined;
    if (!isChargePointLoaded) return undefined;
    const wsh = WebSocketHandler.getInstance();
    const unsubscribe = wsh.subscribeToChargePointEvents(
      chargePoint.chargePointId,
      (response: { type: string; data: any }) => {
        if (response.type !== 'ERROR') {
          toast.success(response?.data?.parsedResponse?.status);
        } else {
          toast.error(t('ChargeStationDetail.oppsSomethingWentWrong'));
        }
      }
    );
    return unsubscribe;
  }, [chargePoint?.chargePointId, isChargePointLoaded]);

  const handleClick = () => {
    history.push('/cpo/charge-station');
  };
  return (
    <Container title=''>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex  justify-content-between mb-2  causten-regular '>
          <ChevronLeft
            cursor='pointer'
            style={{ color: 'grey' }}
            onClick={handleClick}
            className='btn btn-icon btn-topbar btn-ghost-secondary me-2'
          />

          <h2>{chargePoint?.name} </h2>
        </div>
        <div>
          <AutoRefresh
            isActive={editAutoRefresh.isActiveAutoRefresh}
            onPress={() => {
              editAutoRefresh.IsActiveAutoRefresh(
                !editAutoRefresh.isActiveAutoRefresh
              );
            }}
          />
        </div>
      </div>

      <div className='account-setting-wrapper'>
        <Nav className='nav nav-tabs  gap-2 mb-3'>
          {TAB_ITEMS.map((item, index: number) => (
            <NavItem key={index.toString()}>
              <NavLink
                style={{ cursor: 'pointer' }}
                className={classnames({
                  active: animationNavTab === item.id,
                })}
                onClick={() => {
                  animationNavToggle(item.id);
                  editAutoRefresh.setTabNames(item.id);
                }}
                to={`${url}/${item.id}`}
              >
                {item.label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        <Suspense
          fallback={
            <div>
              <Spinner />
            </div>
          }
        >
          {chargePoint && (
            <TabContent activeTab={animationNavTab} className='text-muted'>
              <TabPane tabId='overview' id='overview'>
                <Overview
                  refreshProvider={refreshProvider}
                  setRefreshProvider={setRefreshProvider}
                />
              </TabPane>
              <TabPane tabId='sessions' id='sessions'>
                <Sessions />
              </TabPane>
              <TabPane tabId='photos' id='photos'>
                <Photos />
              </TabPane>
              <TabPane tabId='people' id='people'>
                <People />
              </TabPane>
              <TabPane tabId='logs' id='logs'>
                <Logs />
              </TabPane>
              <TabPane tabId='repair' id='repair'>
                <Repair cpId={chargePoint?.chargePointId} />
              </TabPane>
              <TabPane tabId='meter-values' id='meter-values'>
                <MeterValues />
              </TabPane>
              <TabPane tabId='configuration' id='configuration'>
                <Configuration chargePoint={chargePoint?.chargePointId} />
              </TabPane>
              <TabPane tabId='settings' id='settings'>
                <Settings />
              </TabPane>
            </TabContent>
          )}
        </Suspense>
      </div>
    </Container>
  );
};

export default DetailFullPage;
