/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useContext } from 'react';
import { Col, Row, FormGroup } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { Turkish } from 'flatpickr/dist/l10n/tr';
import Select from 'react-select';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import numeral from 'numeral';
import * as Icon from 'react-feather';
import DataContext from 'Components/Contexts/DataContext';
import { useTranslation } from 'react-i18next';
import getSocketSvg from 'helpers/Socket';
import { getApplications } from '../../../../actions/applications';
import { getStatisticsCount } from '../../../../actions/chargeStations';
import CardWidget from '../../../../Components/Common/GIO/CardWidget';
import { getLabelCurrency } from '../../../../helpers/Currencies';

type StateType = {
  transactions: number;
  durationInHrs: number;
  evseSuspendedInHrs: number;
  powerConsumptionInKWh: number;
  endorsements: [
    {
      currency: string;
      endorsements: number;
      revenue: number;
    }
  ];
  drivers: number;
  newUsers: number;
  chargeStations: number;
  acChargeStations: number;
  dcChargeStations: number;
  previous: StateType;
  trxAppliedDate: string;
};

const StatsCards = () => {
  const { t } = useTranslation();

  const context = useContext(DataContext);

  const initialbaseDate = new Date();
  let initialStartDate: Date | string = new Date();
  let initialEndDate: Date | string = new Date(
    initialbaseDate.getFullYear(),
    initialbaseDate.getMonth(),
    initialbaseDate.getDate() + 1
  );
  initialStartDate = new Date(initialbaseDate.getFullYear(), 0, 1);
  initialEndDate = new Date(
    initialbaseDate.getFullYear(),
    initialbaseDate.getMonth(),
    initialbaseDate.getDate() + 1
  );
  initialStartDate.setUTCHours(23, 59, 59, 999);
  initialEndDate.setUTCHours(23, 59, 59, 999);
  initialStartDate = initialStartDate.toISOString();
  initialEndDate = initialEndDate.toISOString();

  const [filter, setFilter] = useState<{
    startDate: string;
    endDate: string;
    applicationId: string | undefined;
    period: string | undefined;
  }>({
    endDate: initialEndDate,
    startDate: initialStartDate,
    applicationId: 'null',
    period: 'thisYear',
  });
  const [state, setState] = useState<StateType | undefined>();
  const [readyToCalculate, setReadyToCalculate] = useState<boolean>(false);

  const [selectedRangeType, setSelectedRangeType] = useState('thisYear');
  const [rangeDate, setRangeDate] = useState([
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(),
  ]);
  const [applications, setApplications] = useState([]);

  const all = { name: 'null', _id: 'null' };

  const changeSelectedRangeType = (type: string) => {
    const baseDate = new Date();
    let startDate: Date | string = new Date();
    let endDate: Date | string = new Date(
      baseDate.getFullYear(),
      baseDate.getMonth(),
      baseDate.getDate() + 1
    );
    if (type === 'today') {
      startDate.setUTCHours(0, 0, 0, 0);
      endDate.setUTCHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate, period: 'today' });
    } else if (type === 'thisWeek') {
      startDate = new Date(
        baseDate.setDate(baseDate.getDate() - baseDate.getDay())
      );
      startDate.setUTCHours(23, 59, 59, 999);
      endDate = moment().endOf('week').add(1, 'days').toDate();
      endDate.setUTCHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate, period: 'thisWeek' });
    } else if (type === 'thisMonth') {
      startDate = new Date(baseDate.getFullYear(), baseDate.getMonth(), 1);
      endDate = new Date(
        baseDate.getFullYear(),
        baseDate.getMonth(),
        baseDate.getDate() + 1
      );
      startDate.setUTCHours(23, 59, 59, 999);
      endDate.setUTCHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate, period: 'thisMonth' });
    } else if (type === 'thisYear') {
      startDate = new Date(baseDate.getFullYear(), 0, 1);
      endDate = new Date(
        baseDate.getFullYear(),
        baseDate.getMonth(),
        baseDate.getDate() + 1
      );
      startDate.setUTCHours(23, 59, 59, 999);
      endDate.setUTCHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate, period: 'thisYear' });
    } else if (type === 'customDate') {
      startDate = moment(rangeDate[0]).toDate();
      endDate = moment(rangeDate[1]).add(1, 'days').toDate();
      startDate.setUTCHours(23, 59, 59, 999);
      endDate.setUTCHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate, period: 'customDate' });
    } else if (type === 'all') {
      setFilter({
        ...filter,
        endDate: 'null',
        startDate: 'null',
        period: 'all',
      });
    }
    setSelectedRangeType(type);
  };
  useEffect(() => {
    let cleanUp = false;

    getStatisticsCount({ ...filter }).then((response) => {
      if (!cleanUp) {
        setState(response?.data);
        setReadyToCalculate(true);
      }
    });
    if (applications.length <= 1) {
      getApplications().then((res) => {
        if (!cleanUp) {
          setApplications(res?.data?.applications);
        }
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [filter]);

  useEffect(() => {
    if (selectedRangeType === 'customDate') {
      changeSelectedRangeType('customDate');
    }
  }, [rangeDate]);

  return (
    <>
      <Row className='d-flex flex-wrap pt-4 pb-4'>
        <Col xl={6} className='d-flex flex-column justify-content-center'>
          <h1 className='mb-0 heading-gio'>{t('welcome')}</h1>
          <span>{t('welcome_desc')}</span>
        </Col>
        <Col className='mt-sm-3 mt-4 mt-md-0 mt-lg-0 mt-xl-0'>
          <Row className='justify-content-end flex-row justify-content-center'>
            <Col>
              <FormGroup className='m-0'>
                <label
                  style={{
                    color: 'rgba(0,0,0,0.87)',
                    fontWeight: '600',
                    fontSize: '14px',
                  }}
                  htmlFor='date-range'
                >
                  {t('Transaction.chooseDateRange')}
                </label>
                <select
                  id='date-range'
                  className='form-control form-select'
                  value={selectedRangeType}
                  onChange={(e) => changeSelectedRangeType(e.target.value)}
                >
                  <option style={{ color: 'black' }} value='all'>
                    {t('Transaction.all')}
                  </option>
                  <option style={{ color: 'black' }} value='today'>
                    {t('Transaction.today')}
                  </option>
                  <option style={{ color: 'black' }} value='thisWeek'>
                    {t('Transaction.thisWeek')}
                  </option>
                  <option style={{ color: 'black' }} value='thisMonth'>
                    {t('Transaction.thisMonth')}
                  </option>
                  <option style={{ color: 'black' }} value='thisYear'>
                    {t('Transaction.thisYear')}
                  </option>
                  <option style={{ color: 'black' }} value='customDate'>
                    {t('Transaction.customDate')}
                  </option>
                </select>
              </FormGroup>
            </Col>

            {selectedRangeType === 'customDate' && (
              <Col className='text-align'>
                <FormGroup className='m-0'>
                  <label
                    style={{
                      color: 'rgba(0,0,0,0.87)',
                      fontWeight: '600',
                      fontSize: '14px',
                    }}
                    htmlFor='range-picker'
                  >
                    {t('Transaction.chooseDateRange')}
                  </label>
                  <Flatpickr
                    value={rangeDate}
                    id='range-picker'
                    className='form-control'
                    onChange={(date) => {
                      if (date.length > 0) {
                        setRangeDate(date);
                      }
                    }}
                    options={{
                      mode: 'range',
                      maxDate: new Date(),
                      locale:
                        t('Transaction.locale') === 'Turkish'
                          ? Turkish
                          : undefined,
                    }}
                  />
                </FormGroup>
              </Col>
            )}
            <Col>
              <FormGroup>
                <label
                  style={{
                    color: 'rgba(0,0,0,0.87)',
                    fontWeight: '600',
                    fontSize: '14px',
                  }}
                  htmlFor='application'
                >
                  {t('Transaction.application')}
                </label>
                <Select
                  id='application'
                  options={[all, ...applications]}
                  getOptionLabel={(x) =>
                    x.name !== 'null' ? x.name : t('Transaction.all')
                  }
                  getOptionValue={(x) => x._id}
                  placeholder={t('all')}
                  onChange={(e) =>
                    setFilter({ ...filter, applicationId: e?._id })
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col md='6' lg='4' xl='2' className='reOrder_item'>
              <CardWidget
                label={t('Home.Transactions')}
                cardVersion='card_v2'
                cardType='transactions'
                badge='ri-arrow-up-circle-line text-success'
                icon={getSocketSvg('TransactionsIcon', 'inherit')}
                counter={state?.transactions || 0}
                previousCount={state?.previous?.transactions || 0}
                appliedDate={state?.previous?.trxAppliedDate || 0}
                readyToCalculate={readyToCalculate}
                url='#'
              />
            </Col>

            <Col md='6' lg='4' xl='2' className='reOrder_item'>
              <CardWidget
                label={t('Home.Drivers')}
                cardVersion='card_v2'
                cardType='drivers'
                badge='ri-arrow-up-circle-line text-success'
                icon={getSocketSvg('DriverIcon', 'inherit')}
                counter={state?.drivers || 0}
                previousCount={state?.previous?.drivers || 0}
                appliedDate={state?.previous?.trxAppliedDate || 0}
                readyToCalculate={readyToCalculate}
                url='#'
              />
            </Col>

            <Col md='6' lg='6' xl='3' className='reOrder_item'>
              <CardWidget
                label={t('Home.revenue')}
                cardVersion='card_v2'
                cardType='revenue'
                badge='ri-arrow-up-circle-line text-success'
                icon={getSocketSvg('RevenueIcon', 'inherit')}
                counter={state?.endorsements[0]?.revenue || 0}
                previousCount={state?.previous?.endorsements[0]?.revenue || 0}
                appliedDate={state?.previous?.trxAppliedDate || 0}
                readyToCalculate={readyToCalculate}
                suffix={getLabelCurrency(
                  state?.endorsements[0]?.currency || 'TRY',
                  'symbol'
                )}
                formattingFn={(val) => {
                  return `${numeral(val)
                    .format('0.0,')
                    .toString()} ${getLabelCurrency(
                    state?.endorsements[0]?.currency || 'TRY',
                    'symbol'
                  )}`;
                }}
                url='#'
              />
            </Col>

            <Col md='6' lg='6' xl='3' className='reOrder_item'>
              <CardWidget
                label={t('Home.energyUsage')}
                cardVersion='card_v2'
                cardType='energyUsage'
                badge='ri-arrow-up-circle-line text-success'
                icon={getSocketSvg('EnergyIcon', 'inherit')}
                counter={state?.powerConsumptionInKWh || 0}
                previousCount={state?.previous?.powerConsumptionInKWh || 0}
                appliedDate={state?.previous?.trxAppliedDate || 0}
                readyToCalculate={readyToCalculate}
                suffix=' kWh'
                decimals={2}
                url='#'
                formattingFn={(val) => {
                  return `${numeral(val).format('0.0,').toString()} kWh`;
                }}
              />
            </Col>

            <Col md='6' lg='4' xl='2' className='reOrder_item'>
              <CardWidget
                label={t('Home.acdcStations')}
                cardVersion='card_v2'
                cardType='acStation'
                counter2={state?.dcChargeStations || 0}
                badge='ri-arrow-up-circle-line text-success'
                icon={getSocketSvg('StationsIcon', 'inherit')}
                counter={state?.acChargeStations || 0}
                appliedDate={state?.previous?.trxAppliedDate || 0}
                counter3={state?.chargeStations || 0}
                previousCount={0}
                readyToCalculate={readyToCalculate}
                url='#'
              />
            </Col>
          </Row>

          {/* <Row>
            <Col>
              <CardWidget
                label={t('Home.Transactions')}
                badge='ri-arrow-up-circle-line text-success'
                icon={<Icon.FileText className='text-primary' />}
                counter={state?.transactions || 0}
                url='#'
              />
            </Col>
            <Col>
              <CardWidget
                label={t('Home.revenue')}
                badge='ri-arrow-up-circle-line text-success'
                icon={<Icon.DollarSign className='text-primary' />}
                counter={state?.endorsements[0]?.endorsements}
                suffix={getLabelCurrency(
                  state?.endorsements[0]?.currency || 'TRY',
                  'symbol'
                )}
                formattingFn={(val) => {
                  return `${numeral(val)
                    .format('0.0,')
                    .toString()} ${getLabelCurrency(
                      state?.endorsements[0]?.currency || 'TRY',
                      'symbol'
                    )}`;
                }}
                // value={
                //   state && state.endorsements
                //     ? `${numeral(state.endorsements[0].endorsements)
                //         .format('0.0,')
                //         .toString()} ${getLabelCurrency(
                //         state.endorsements[0].currency,
                //         'symbol'
                //       )}`
                //     : '0'
                // }
                url='#'
              />
            </Col>
            <Col>
              <CardWidget
                label={t('Home.energyUsage')}
                badge='ri-arrow-up-circle-line text-success'
                icon={<Icon.Zap className='text-primary' />}
                counter={state?.powerConsumptionInKWh || 0}
                suffix=' kWh'
                decimals={2}
                url='#'
                formattingFn={(val) => {
                  return `${numeral(val).format('0.0,').toString()} kWh`;
                }}
              />
            </Col>
          </Row> */}
          {/* <Row>
            <Col>
              <CardWidget
                label={t('Home.Drivers')}
                badge='ri-arrow-up-circle-line text-success'
                icon={<Icon.Users className='text-primary' />}
                counter={state?.drivers || 0}
                url='#'
              />
            </Col>
            <Col>
              <CardWidget
                label={t('Home.newUsers')}
                badge='ri-arrow-up-circle-line text-success'
                icon={<Icon.UserPlus className='text-primary' />}
                counter={state?.newUsers || 0}
                url='#'
              />
            </Col>
            <Col>
              <CardWidget
                label={t('Home.acStation')}
                badge='ri-arrow-up-circle-line text-success'
                icon={<i className='ri-charging-pile-2-line text-primary' />}
                counter={state?.acChargeStations || 0}
                url='#'
              />
            </Col>
            <Col>
              <CardWidget
                label={t('Home.dcStation')}
                badge='ri-arrow-up-circle-line text-success'
                icon={<i className='ri-charging-pile-line text-primary' />}
                counter={state?.dcChargeStations || 0}
                url='#'
              />
            </Col>
          </Row> */}
        </Col>
      </Row>
    </>
  );
};
export default StatsCards;
// export default withTranslation()(StatsCards);
