import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationENG from './locales/en.json';
import translationTR from './locales/tr.json';

// the translations
const DETECTION_OPTIONS = {
  order: ['navigator'],
};
const resources = {
  en: {
    translation: translationENG,
  },
  tr: {
    translation: translationTR,
  },
};

const language = localStorage.getItem('I18N_LANGUAGE');
if (!language) {
  localStorage.setItem('I18N_LANGUAGE', 'en');
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en', // use en if detected lng is not available
    detection: DETECTION_OPTIONS,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
