/* eslint-disable */
import ax from 'axios';
import { createBrowserHistory } from 'history';
import {
  SERVER_URL,
  DOMAIN_KEY,
  OCPP_REST_API_URL,
  GENERAL_SERVER_URL,
} from './config';
import { getRefreshToken, getToken, setRefreshToken, setToken } from './token';
import { removeToken } from './token';
import history from '../history';

import { toast } from 'react-toastify';
import i18n from 'i18next';

export const axios = ax.create({
  headers: { domainKey: DOMAIN_KEY },
});

// default
axios.defaults.baseURL = SERVER_URL;
// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';

// intercepting to capture errors

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common.Authorization = `${token}`;
};

class APIClient {
  constructor() {
    axios.defaults.headers.common.Authorization = getToken();
  }
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url, params) => {
    let response;

    const paramKeys = [];

    if (params) {
      Object.keys(params).map((key) => {
        if (params[key] && params[key] !== 'null') {
          paramKeys.push(`${key}=${params[key]}`);
        }
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join('&') : '';
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  getGeneral = (url, params) => {
    let response;

    const paramKeys = [];

    if (params) {
      Object.keys(params).map((key) => {
        if (params[key]) {
          paramKeys.push(`${key}=${params[key]}`);
        }
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join('&') : '';
      response = axios({
        method: 'GET',
        url: `${url}?${queryString}`,
        baseURL: GENERAL_SERVER_URL,
        params,
      });
    } else {
      response = axios({
        method: 'GET',
        url: url,
        baseURL: GENERAL_SERVER_URL,
        params,
      });
    }
    return response;
  };
  getGeneralTest = (url, params) => {
    let response;

    const paramKeys = [];

    if (params) {
      Object.keys(params).map((key) => {
        if (params[key]) {
          paramKeys.push(`${key}=${params[key]}`);
        }
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join('&') : '';
      response = axios({
        method: 'GET',
        url: `${url}?${queryString}`,
        baseURL: 'http://localhost:3050/dev/api/0.0/',
        params,
      });
    } else {
      response = axios({
        method: 'GET',
        url: url,
        baseURL: 'http://localhost:3050/dev/api/0.0/',
        params,
      });
    }
    return response;
  };

  updateGeneralTest = (url, params) => {
    return axios({
      method: 'PUT',
      url: url,
      baseURL: 'http://localhost:3050/dev/api/0.0/',
      data: params,
    });
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };

  /**
   * post given data to url
   */
  createGeneral = (url, data) => {
    let response;

    response = axios({
      method: 'POST',
      url: url,
      baseURL: GENERAL_SERVER_URL,
      data: data,
    });
    return response;
  };

  /**
   * post given data to url
   */
  createOcppRequest = (url, data) => {
    return axios({
      method: 'POST',
      url: url,
      baseURL: OCPP_REST_API_URL,
      data: data,
    }).then((res) => {
      return res.result;
    });
  };

  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.put(url, data);
  };

  /**
   * Delete
   */
  delete = (url, config = {}) => {
    return axios.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  const user = localStorage.getItem('JWT');
  if (!user) {
    return null;
  }
  return user;
};

axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (err) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    console.log('err', err, err.status, err.response);
    switch (err?.response?.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        return new Promise((resolve, reject) => {
          const originalReq = err.config;
          if (
            err?.response?.status === 401 &&
            err.config &&
            !err.config._retry
          ) {
            originalReq._retry = true;
            console.log('logout');
            let res = fetch(SERVER_URL + 'refresh-token', {
              method: 'GET',
              mode: 'cors',
              cache: 'no-cache',
              credentials: 'same-origin',
              headers: {
                'Content-Type': 'application/json, text/plain, /',
                Authorization: getRefreshToken(),
                domainKey: DOMAIN_KEY,
              },

              redirect: 'follow',
              referrer: 'no-referrer',
            })
              .then((res) => res.json())
              .then((res) => {
                console.log(res);
                originalReq.headers['Authorization'] = res.data.access_token;
                setRefreshToken(res.data.refresh_token);
                return setToken(res.data.access_token);
              })
              .then((res) => {
                return axios(originalReq);
              })
              .catch(async (err) => {
                console.log('error', err);
                await removeToken();
                history.push('/login');
                // dispatch({ type: 'login/logoutUser', payload: {} });
                return reject(err);
              });

            resolve(res);
          } else {
            console.log('error', err, err.response.status, err.response);
            if (err?.status === 403 || err?.response?.status === 409) {
              toast.error(i18n.t('youHaveNoPermission'));
            } else if (err?.response?.status === 404) {
              toast.error(i18n.t('notFound404'));
            } else if (
              err?.response?.status === 500 ||
              err?.response?.status === 400
            ) {
              return reject({ err: err, status: err?.response?.status });
              // toast.error(i18n.t('serverErrorPleaseContactSupport'));
            }
            //    if (error && error.response && error.response.status)
            // switch (error.response.status) {
            //   case 401:
            //     // removeToken();
            //     // will redirect to "login" when Unauthorised error will raise
            //     // navigateFromOutside.navigate('LoginScreen', 'Login');
            //     const dispatch = store.dispatch;
            //     dispatch({ type: "LOGOUT_WITH_JWT", payload: {} });
            //     break;
            //   default:
            //     _handleCommonError(error);
            //     break;
            // }
            //   _interceptorsResponseError(err)
          }

          return reject(err);
        });
      case 403:
      case 409:
        toast.error(i18n.t('youHaveNoPermission'));
        break;
      case 404:
        message = 'Sorry! the data you are looking for could not be found';
        break;
      default:
        message = err;
    }
    return Promise.reject(message);
  }
);

export { APIClient, setAuthorization, getLoggedinUser };
