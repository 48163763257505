import React from 'react';
import { Card, CardTitle, CardHeader, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';

type ConnectorRadialChartType = {
  connectors: number[];
  connector:
    | {
        charging: number;
        faulted: number;
        online: number;
        reserved: number;
        total: number;
        totalAC: number;
        totalDC: number;
      }
    | undefined;
};
const ChargerStatus = ({ connectors, connector }: ConnectorRadialChartType) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader style={{ padding: '5px 10px' }}>
        <CardTitle
          className='chargerStatusTitle
         noHighlighter p-0'
        >
          CHARGER STATUS
        </CardTitle>
      </CardHeader>
      <CardBody
        className='chargerStatus noHighlighter'
        style={{ padding: '5px 10px' }}
      >
        <div className='d-flex'>
          <div>
            <div className='text' style={{ color: '#80b250' }}>
              {t('ChargerStatus.online')}
            </div>
            <span className='number'>{connectors ? connectors[0] : ''}</span>
          </div>
          <div className='verticalLine'> </div>
        </div>
        <div className='d-flex'>
          <div>
            <div className='text' style={{ color: '#e53f37' }}>
              {t('ChargerStatus.charging')}
            </div>
            <span className='number'>{connectors ? connectors[1] : ''}</span>{' '}
          </div>
          <div className='verticalLine'> </div>
        </div>
        <div className='d-flex'>
          <div>
            <div className='text' style={{ color: '#f3b33e' }}>
              {t('ChargerStatus.reserved')}
            </div>
            <span className='number'>{connectors ? connectors[2] : ''}</span>{' '}
          </div>
          <div className='verticalLine'> </div>
        </div>
        <div className='d-flex'>
          <div>
            <div className='text' style={{ color: '#4499e1' }}>
              {t('ChargerStatus.faulted')}
            </div>
            <span className='number'>{connectors ? connectors[3] : ''}</span>{' '}
          </div>
          <div className='verticalLine'> </div>
        </div>
        <div className='d-flex'>
          <div>
            <div className='text' style={{ color: '#6d53b9' }}>
              {t('ChargerStatus.offline')}
            </div>
            <span className='number'>
              {connector
                ? connector.total -
                  (connector.charging +
                    connector.faulted +
                    connector.reserved +
                    connector.online)
                : ''}
            </span>{' '}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ChargerStatus;
