/* eslint-disable no-else-return */
import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Button, Card, CardBody } from 'reactstrap';
import DataTable from 'Components/Common/DataTable';
import DataContext from 'Components/Contexts/DataContext';
import { useTranslation } from 'react-i18next';
import Container from 'Components/Common/Container';
import moment from 'moment';
import EditContext from 'Components/Contexts/EditContext';
import SlidingModal from 'Components/Common/SlidingModal';
import CardInfoText from 'Components/Common/CardInfoText';
import AutoRefresh from 'Components/Common/AutoRefresh';
import { Edit3, Plus } from 'react-feather';
import ExportBlock from 'laxyExcel/ExportComponents';
import { getLabelCurrency } from 'helpers/Currencies';
import { AUTO_REFRESH_TIME } from 'helpers/config';
import { ApplicationsType } from 'declerations/DefaultTypes';
import AddCpo from './AddCpo/AddCpo';
import 'moment/locale/tr';
import 'moment/locale/en-nz';
import i18n from '../../i18n';

const isNull = (str: unknown) => str || '-';

const Cpos = () => {
  const { t } = useTranslation();
  const editContext = useContext(EditContext);
  const context = useContext(DataContext);
  const [isOpen, setIsOpen] = useState(false);
  const [addIsOpen, setAddIsOpen] = useState(false);
  const [isOpenEdit, setisOpenEdit] = useState(false);
  const [isActiveAutoRefresh, setIsActiveAutoRefresh] = useState(true);
  const [selectedApplication, setSelectedApplication] =
    useState<ApplicationsType | null>(null);

  const [loading, setLoading] = useState<boolean>(true);

  const loadDataTable = () => {
    context.LoadDomains().then(() => {
      context.LoadApplications();
      setLoading(false);
    });
  };

  useEffect(() => {
    loadDataTable();
  }, []);

  // Auto refresh
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isActiveAutoRefresh) {
        loadDataTable();
      }
    }, AUTO_REFRESH_TIME);
    return () => clearInterval(intervalId);
  }, [isActiveAutoRefresh]);

  // const findDomains = (row: any) => {
  //   const domain = (context?.domains &&
  //     context.domains.find((x: any) => x._id === row.domainIdPath[0])) || {
  //     name: '',
  //   };
  //   return `${domain.name}`;
  // };

  const mappers = {
    // domain: (row: any) => {
    //   return findDomains(row);
    // },
    createdAt: (row: any) => {
      if (i18n.language === 'en') {
        return `${moment(row?.createdAt).format('lll').toString()}`;
      } else {
        return `${moment(row?.createdAt)
          .locale('tr')
          .format('lll')
          .toString()}`;
      }
    },
    revenueSharePercentage: (row: any) => {
      if (row.revenueSharePercentage) {
        return `${row?.revenueSharePercentage}`;
      }
      return '-';
    },
    code: (row: any) => {
      if (row.code) {
        return `${row.code}`;
      }
      return '-';
    },
    creatorId: (row: any) => {
      if (row.creator.email) {
        return `${row.creator.email}`;
      }
      return '-';
    },
  };
  const headers = [
    'name',
    'currency',
    'creatorId',
    'createdAt',
    'revenueSharePercentage',
    'code',
  ].map((key) => ({
    key,
    value: `applications.${key}`,
    mapper: mappers[key as keyof typeof mappers],
  }));

  return (
    <Container
      title={t('ChargePointOperators')}
      rightSide={
        <div>
          <AutoRefresh
            isActive={isActiveAutoRefresh}
            onPress={() => {
              setIsActiveAutoRefresh(!isActiveAutoRefresh);
            }}
          />
        </div>
      }
    >
      <Row className='mb-2 d-flex justify-content-end'>
        <Col xl={3} sm={12} className=' d-flex justify-content-end'>
          <ExportBlock
            data={context.applications}
            header={headers}
            name={t('Authorization')}
          />
          <Button
            aria-hidden='true'
            className='ms-1'
            color='primary'
            onClick={() => {
              setisOpenEdit(false);
              setAddIsOpen(!addIsOpen);
            }}
          >
            <Plus className='me-2 p-0' size='20' />
            {t('Add')}
          </Button>
        </Col>
      </Row>
      <div>
        <DataTable
          pointerOnHover
          loading={loading}
          onRowClicked={(row: ApplicationsType) => {
            setIsOpen(true);
            setSelectedApplication(row);
          }}
          noDataText={t('thereareNoCpoCreated')}
          columns={[
            {
              name: t('Application.name'),
              selector: (row: ApplicationsType) => row.name,
              sortable: true,
            },
            // {
            //   name: t('Application.domain'),
            //   selector: (row: ApplicationsType) => row.domainIdPath,
            //   // eslint-disable-next-line consistent-return
            //   cell: (row: any) => {
            //     if (row) {
            //       const domain = (context.domains &&
            //         context.domains.find(
            //           (x: any) => x._id === row.domainIdPath[0]
            //         )) || { name: '' };
            //       return domain.name;
            //     }
            //     // return <span></span>;
            //   },
            // },
            {
              name: t('Application.currency'),
              cell: (row: any) => getLabelCurrency(row.currency),
            },
            {
              name: t('OCPPtags.creatorId'),
              selector: (row: ApplicationsType) =>
                row?.creator?.email ? row?.creator?.email : '-',
              sortable: true,
            },
            {
              name: t('Application.createAd'),
              cell: ({ createdAt }: { createdAt: any }) => {
                return i18n.language === 'en'
                  ? moment(createdAt).format('lll').toString()
                  : moment(createdAt).locale('tr').format('lll').toString();
              },
            },
            // {
            //   name: 'App Key',
            //   selector: (row: ApplicationsType) => row.appKey,
            //   sortable: true,
            // },

            {
              name: t('AddApplication.code'),
              selector: (row: ApplicationsType) => (row.code ? row.code : '-'),
              sortable: true,
            },
            {
              name: t('AddApplication.revenueSharePercentage'),
              selector: (row: ApplicationsType) =>
                row.revenueSharePercentage ? row.revenueSharePercentage : '-',
              sortable: true,
            },
            {
              name: t('ChargingRate.actions'),
              cell: (row: ApplicationsType) => {
                return (
                  <div>
                    <Edit3
                      className='mr-1'
                      cursor='pointer'
                      onClick={() => {
                        editContext.SetEditApplication(row);
                        setisOpenEdit(true);
                        setAddIsOpen(true);
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
          data={context.applications || []}
        />
      </div>
      {selectedApplication && (
        <SlidingModal isOpen={isOpen} toggleHandle={() => setIsOpen(false)}>
          <Card>
            <CardBody>
              <Row className='justify-content-center'>
                <Col xs={12} md={12}>
                  <ul className='list-group list-group-flush'>
                    <CardInfoText
                      title={t('Application.companyName')}
                      value={selectedApplication.companyName}
                    />
                    <CardInfoText
                      title={t('Application.phone')}
                      value={selectedApplication.phone}
                    />
                    <CardInfoText
                      title={t('E-mail')}
                      value={selectedApplication.email}
                    />
                    <CardInfoText
                      title={t('Application.addresses')}
                      value={selectedApplication.address}
                    />
                    <CardInfoText
                      title={t('Application.taxOffice')}
                      value={selectedApplication.taxOffice}
                    />
                    <CardInfoText
                      title={t('Application.taxNumber')}
                      value={selectedApplication.taxNumber}
                    />
                    <CardInfoText
                      title={t('Application.ibanNumber')}
                      value={selectedApplication.ibanNumber}
                    />
                    <CardInfoText
                      title={t('Application.code')}
                      value={selectedApplication.code}
                    />
                    <CardInfoText
                      title={t('Application.revenueSharePercentage')}
                      value={selectedApplication.revenueSharePercentage}
                    />
                  </ul>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </SlidingModal>
      )}

      <AddCpo
        isOpen={addIsOpen}
        isEditing={isOpenEdit}
        toggleHandle={() => {
          setAddIsOpen(false);
          setisOpenEdit(false);
          context.LoadDomains().then(() => {
            context.LoadApplications();
            setLoading(false);
          });
        }}
      />
    </Container>
  );
};

export default Cpos;
