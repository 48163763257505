/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Button, Row, Col, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import InputPasswordToggle from 'Components/Common/InputPasswordToggle';
import { Formik, Form } from 'formik';
import { editProfile } from 'actions/profile';
import i18n from 'i18next';
import * as yup from 'yup';

const formSchema = yup.object().shape({
  oldPassword: yup.string().required(i18n.t('Required')),
  newPassword: yup.string().required(i18n.t('Required')),
  retypeNewPassword: yup
    .string()
    .required(i18n.t('Required'))
    .oneOf(
      [yup.ref(`newPassword`), null],
      i18n.t('AccountSettings.passwordsMustMath')
    ),
});

const PasswordTabContent = () => {
  const [initialValues, setInitialValues] = useState({
    oldPassword: '',
    newPassword: '',
    retypeNewPassword: '',
  });
  const { t } = useTranslation();
  return (
    <div>
      {initialValues && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values, { resetForm }) => {
            const { oldPassword, newPassword, retypeNewPassword } = values;
            const data: { [k: string]: any } = {};
            if (newPassword !== retypeNewPassword) {
              toast.error(t('AccountSettings.passwordMustMatch'));
            }
            data.oldPassword = oldPassword;
            data.password = newPassword;
            editProfile(data)
              .then(() => {
                toast.success(t('AccountSettings.passwordUpdateSuccessfully'));
                // resetForm();
              })
              .catch((error) => {
                console.log(JSON.stringify(error.message));
                toast.error(
                  t('AccountSettings.thereIsAnErrorWhileUpdatingPassword')
                );
              });
          }}
          validationSchema={formSchema}
        >
          {({ values, touched, errors, handleChange, handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col sm='6'>
                    <FormGroup>
                      <InputPasswordToggle
                        label={t('AccountSettings.oldPassword')}
                        htmlFor='oldPassword'
                        name='oldPassword'
                        onChange={handleChange}
                        className={classnames('input-group-merge', {
                          // eslint-disable-next-line @typescript-eslint/dot-notation
                          'is-invalid': errors['oldPassword'],
                        })}
                        meta={{
                          touched: touched.oldPassword,
                          error: errors.oldPassword,
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm='6'>
                    <FormGroup>
                      <InputPasswordToggle
                        label={t('AccountSettings.newPassword')}
                        htmlFor='newPassword'
                        name='newPassword'
                        onChange={handleChange}
                        className={classnames('input-group-merge', {
                          'is-invalid': errors.newPassword,
                        })}
                        meta={{
                          touched: touched.newPassword,
                          error: errors.newPassword,
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm='6'>
                    <FormGroup>
                      <InputPasswordToggle
                        label={t('AccountSettings.retypeNewPassword')}
                        htmlFor='retypeNewPassword'
                        name='retypeNewPassword'
                        onChange={handleChange}
                        className={classnames('input-group-merge', {
                          'is-invalid': errors.retypeNewPassword,
                        })}
                        meta={{
                          touched: touched.retypeNewPassword,
                          error: errors.retypeNewPassword,
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className='mt-1 d-flex justify-content-end' sm='12'>
                    <Button color='secondary' className='me-1' outline>
                      {t('AccountSettings.cancel')}
                    </Button>
                    <Button type='submit' color='primary'>
                      {t('AccountSettings.saveChanges')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};
export default PasswordTabContent;
