import { APIClient } from '../helpers/api_helper';

const api = new APIClient();

export const getSocketTypes = () => {
  const apiUrl = `socket-types`;

  return api.get(apiUrl);
};

export default getSocketTypes;
