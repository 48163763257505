import React, { useState, useEffect, useContext } from 'react';
import Toggle from 'react-toggle';
import { Button, Col, Row } from 'reactstrap';
import DataTable from 'Components/Common/DataTable';
import * as Icons from 'react-feather';
import DataContext from 'Components/Contexts/DataContext';
import { useTranslation } from 'react-i18next';
import Container from 'Components/Common/Container';
import moment from 'moment';
import { OcppTagType } from 'declerations/DefaultTypes';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import EditContext from 'Components/Contexts/EditContext';
import { editOcppTag, deleteOcppTag } from 'actions';
import { toast } from 'react-toastify';
import ExportBlock from 'laxyExcel/ExportComponents';
import ApplicationContext from 'Components/Contexts/ApplicationContext';
import AutoRefresh from 'Components/Common/AutoRefresh';
import { AUTO_REFRESH_TIME } from 'helpers/config';
import i18n from 'i18n';

import AddAdminrfids from './AddAdminrfids/AddAdminrfids';

const Adminrfids = () => {
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const context = useContext(DataContext);
  const editContext = useContext(EditContext);
  const applicationContext = useContext(ApplicationContext);
  const { selectedApplication } = useContext(ApplicationContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [addIsOpen, setAddIsOpen] = useState(false);
  const [isActiveAutoRefresh, setIsActiveAutoRefresh] = useState(true);
  const [isOpenEdit, setisOpenEdit] = useState(false);
  const loadDataTable = () => {
    if (context.profile) {
      if (context.profile.allowedScopes.includes('Domain')) {
        if (!applicationContext.selectedApplication?._id) return;
      }
    }
    context
      .LoadOcppTags({ applicationId: selectedApplication?._id })
      .then(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    loadDataTable();
  }, [selectedApplication]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isActiveAutoRefresh) {
        loadDataTable();
      }
    }, 20000);

    return () => clearInterval(intervalId);
  }, [isActiveAutoRefresh, selectedApplication]);

  const headers = [
    'idTag',
    'creator.email',
    'expiryDateTime',
    'transactionState',
    'maxActiveTransactions',
    'tagName',
  ].map((key) => ({
    key,
    value: `ocppTags.${key}`,
  }));
  return (
    <Container
      title={t('Authorization')}
      rightSide={
        <AutoRefresh
          isActive={isActiveAutoRefresh}
          onPress={() => {
            setIsActiveAutoRefresh(!isActiveAutoRefresh);
          }}
        />
      }
    >
      <Row className='mb-2 d-flex justify-content-end'>
        <Col xl={3} sm={12} className=' d-flex justify-content-end'>
          <ExportBlock
            data={context.ocppTags}
            header={headers}
            name={t('Authorization')}
          />
          <Button
            className='ms-1'
            aria-hidden='true'
            color='primary'
            onClick={() => {
              setisOpenEdit(false);
              setAddIsOpen(!addIsOpen);
            }}
          >
            <Icons.Plus className='me-2 p-0' size='20' />
            {t('Add')}
          </Button>
        </Col>
      </Row>
      <div>
        <DataTable
          loading={loading}
          noDataText={t('OCPPtags.thereArenoOcpptagsCreated')}
          columns={[
            {
              name: t('OCPPtags.ID tag'),
              selector: (row: OcppTagType) => row.idTag,
            },
            {
              name: t('OCPPtags.creatorId'),
              selector: (row: OcppTagType) =>
                `${row?.creator?.fname} ${row?.creator?.lname}`,
            },
            {
              name: t('OCPPtags.expiryDateTime'),
              selector: (row: OcppTagType) => row.expiryDateTime,
              cell: ({ expiryDateTime }: { expiryDateTime: string }) => {
                return i18n.language === 'tr'
                  ? moment(expiryDateTime).locale('tr').format('lll').toString()
                  : moment(expiryDateTime).format('lll').toString();
              },
            },
            {
              name: t('OCPPtags.transactionState'),
              selector: (row: OcppTagType) => row.transactionState,
            },
            {
              name: t('OCPPtags.maxActiveTransactions'),
              selector: (row: OcppTagType) => row.maxActiveTransactions,
            },
            {
              name: t('OCPPtags.tagName'),
              selector: (row: OcppTagType) =>
                row?.tagName != null ? row.tagName : row.idTag,
            },
            {
              name: t('OCPPtags.enable'),
              selector: (row: OcppTagType) => row.isBlocked,
              cell: (row: OcppTagType) => {
                return (
                  <div className='m-atuo align-middle d-flex'>
                    {/* <Toggle /> */}
                    <Toggle
                      icons={false}
                      checked={!row.isBlocked}
                      onChange={(e: any) => {
                        return editOcppTag(row._id, {
                          isBlocked: !e.target.checked,
                        })
                          .then(() => {
                            context.LoadOcppTags({
                              applicationId: selectedApplication?._id,
                            });
                            toast.success(t('OCPPtags.toastSucces'));
                          })
                          .catch(() => toast.error(t('OCPPtags.toastErr')));
                      }}
                      className='switch-danger'
                    />
                  </div>
                );
              },
            },
            {
              name: t('ChargeStations.action'),
              cell: (row: OcppTagType) => {
                return (
                  <div>
                    <Icons.Edit3
                      className='mr-1'
                      cursor='pointer'
                      onClick={() => {
                        editContext.SetEditOcppTag(row);
                        setisOpenEdit(true);
                        setAddIsOpen(!addIsOpen);
                      }}
                    />
                    <Button
                      className='px-1'
                      outline
                      color='flat-primary'
                      disabled={row?.transactionState === 'Inactive'}
                      onClick={(e: any) => {
                        MySwal.fire({
                          title: t(
                            'OCPPtags.doYouWanttoChangeThisOCPPtagState'
                          ),
                          showConfirmButton: true,
                          showCancelButton: true,
                          cancelButtonText: t('no'),
                          confirmButtonText: t('yes'),
                          // eslint-disable-next-line consistent-return
                        }).then((result) => {
                          if (result.isConfirmed) {
                            return editOcppTag(row._id, {
                              transactionState: 'Inactive',
                            })
                              .then((res: any) => {
                                context.LoadOcppTags({
                                  applicationId: selectedApplication?._id,
                                });
                                toast.success(
                                  t('OCPPtags.changeStateProccessSuccessful')
                                );
                              })
                              .catch((err: any) => {
                                if (err?.response?.status === 400) {
                                  toast.error(t('OCPPtags.inprogressTrx'));
                                }
                                toast.error(
                                  t('OCPPtags.changeStateProccessFail')
                                );
                              });
                          }
                        });
                      }}
                    >
                      {row?.transactionState === 'Inactive' ? (
                        <Icons.ZapOff size={20} color='#bababa' />
                      ) : (
                        <Icons.Zap size={20} color='#ccbb44' />
                      )}
                    </Button>

                    <Icons.Trash2
                      color='#f00'
                      className='mr-1'
                      cursor='pointer'
                      onClick={() => {
                        MySwal.fire({
                          title: t('OCPPtags.doYouWanttoDeleteThisOCPPtag'),
                          showConfirmButton: true,
                          showCancelButton: true,
                          cancelButtonText: t('no'),
                          confirmButtonText: t('yes'),
                          // eslint-disable-next-line consistent-return
                        }).then((result) => {
                          if (result.isConfirmed) {
                            return deleteOcppTag(row._id).then((res) => {
                              if (res.data.deleted === true) {
                                context.LoadOcppTags({
                                  applicationId: selectedApplication?._id,
                                });
                                toast.success(
                                  t('OCPPtags.deleteProcessSuccessful')
                                );
                              } else {
                                toast.error(t('OCPPtags.deleteProcessFail'));
                              }
                            });
                          }
                        });
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
          data={context.ocppTags || []}
        />
      </div>

      {addIsOpen && (
        <AddAdminrfids
          isOpen={addIsOpen}
          isEditing={isOpenEdit}
          toggleHandle={() => {
            setAddIsOpen(false);
            setisOpenEdit(false);
            context.LoadOcppTags({ applicationId: selectedApplication?._id });
          }}
        />
      )}
    </Container>
  );
};
export default Adminrfids;
