import React, { useEffect, useState, useContext } from 'react';
import DataTable from 'Components/Common/DataTable';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import DataContext from 'Components/Contexts/DataContext';
import Container from 'Components/Common/Container';
import SearchInput from 'Components/Common/SearchInput';
import ExportBlock from 'laxyExcel/ExportComponents';
import { VehicleType } from '../../../declerations/DefaultTypes';
import VehiclesDetail from './VehicleDetail/VehiclesDetail';

const Vehicles = () => {
  const { t } = useTranslation();
  const context = useContext(DataContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVehicle, setselectedVehicle] = useState<VehicleType | null>(
    null
  );
  const [filtered, setFiltered] = useState('');
  useEffect(() => {
    context.LoadVehicles({
        limit: 1000,
      })
      .then(() => {
        setLoading(false);
      });
  }, []);
  const search = (rowData: VehicleType[] | undefined) => {
    if (!rowData) {
      return [];
    }
    return rowData.filter(
      (row: VehicleType) =>
        row.vehicleMake?.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
        row.vehicleModel?.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
        row.vehicleModelVersion?.toLowerCase().indexOf(filtered.toLowerCase()) >
          -1
    );
  };

  const headers = [
    'vehicleMake',
    'vehicleModel',
    'vehicleModelVersion',
    'drivetrainPowerHP',
    'batteryCapacityFull',
    'performanceTopspeed',
    'performanceAcceleration',
  ].map((key) => ({
    key,
    value: `vehicles.${key}`,
  }));
  return (
    <Container
      title={t('Vehicles')}
      rightSide={
        <ExportBlock
          data={context.vehicles}
          header={headers}
          name={t('Vehicles')}
        />
      }
    >
      <Row>
        <Col xl={3} sm={12} className='d-flex'>
          <SearchInput onChange={(text) => setFiltered(text)} />
        </Col>
      </Row>
      <DataTable
        pointerOnHover
        onRowClicked={(row: VehicleType, event: any) => {
          setIsOpen(true);
          setselectedVehicle(row);
        }}
        loading={loading}
        noDataText={t('Vehicle.noinformation')}
        columns={[
          {
            name: t('Vehicle.image'),
            cell: (row: VehicleType) => {
              return (
                <div>
                  <img
                    crossOrigin="anonymous"
                    src={row.image}
                    alt={row.vehicleModel}
                    style={{ height: '40px', width: '70px' }}
                  />
                </div>
              );
            },
          },
          {
            name: t('Vehicle.maker'),
            selector: (row: VehicleType) => row.vehicleMake,
            sortable: true,
          },
          {
            name: t('Vehicle.model'),
            selector: (row: VehicleType) => row.vehicleModel,
          },
          {
            name: t('Vehicle.version'),
            selector: (row: VehicleType) => row.vehicleModelVersion,
            sortable: true,
          },
          {
            sortable: true,
            selector: (row: VehicleType) => row.drivetrainPowerHP,
            name: t('Vehicle.power(hp)'),
            cell: (row: VehicleType) => `${row.drivetrainPowerHP} hp`,
          },
          {
            sortable: true,
            selector: (row: VehicleType) => row.batteryCapacityFull,
            name: t('Vehicle.batteryCapacity'),
            cell: (row: VehicleType) => `${row?.batteryCapacityFull} kW`,
          },
          {
            sortable: true,
            selector: (row: VehicleType) => row.performanceTopspeed,
            name: t('Vehicle.topSpeed'),
            cell: (row: VehicleType) => `${row?.performanceTopspeed} km`,
          },
          {
            sortable: true,
            selector: (row: VehicleType) => row.performanceAcceleration,
            name: '0-100 km\\h',
            cell: (row: VehicleType) =>
              `${row.performanceAcceleration} ${t('Vehicle.sec(s)')} `,
          },
        ]}
        data={search(context.vehicles) || []}
      />
      <VehiclesDetail
        isOpen={isOpen}
        toggleHandle={() => setIsOpen(false)}
        selectedVehicle={selectedVehicle}
      />
    </Container>
  );
};
export default Vehicles;
