import { APIClient } from '../helpers/api_helper';

const api = new APIClient();

export const getApplications = () => {
  const apiUrl = `applications`;
  return api.get(apiUrl);
};
export const getApplicationsLookup = () => {
  const apiUrl = `applications?lookup=1`;
  return api.get(apiUrl);
};

export const createApplication = (params: object) => {
  const apiUrl = 'applications';
  return api.create(apiUrl, params);
};
export const editApplication = (id: string, params: object) => {
  const apiUrl = `applications/${id}`;
  return api.update(apiUrl, params);
};
// export const updateProducts = (params) => {
//   const { id, ...otherParams } = params;
//   const apiUrl = `/products/${id}`;
//   return api.update(apiUrl, otherParams);
// };

// export const  deleteProducts = (params) => {
//   const { id, ...otherParams } = params;
//   const apiUrl = `/products/${id}`;
//   return deleteRequestWithToken(apiUrl, otherParams);
// };
