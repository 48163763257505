/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
} from 'reactstrap';
import MetaTags from 'react-meta-tags';

// redux
import { useSelector, useDispatch } from 'react-redux';

// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { DOMAIN_KEY, ASSET_BASE_URL } from '../../../helpers/config';
import AuthSlider from '../authCarousel';

import ParticlesAuth from '../ParticlesAuth';

// actions
import { loginUser } from '../../../store/auth/authSlice';

const CoverSignIn = ({ history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t('LoginPage.pleaseEnterYourEmail')),
      password: Yup.string().required(t('LoginPage.pleaseEnterYourPassword')),
    }),
    onSubmit: (values) => {
      return dispatch(loginUser({ user: values, history }));
    },
  });
  const { error } = useSelector((state) => ({
    error: state.Login.error,
  }));
  const signIn = (res, type) => {
    if (type === 'google' && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
    } else if (type === 'facebook' && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
    }
  };
  const capitalize = (s) => {
    return s.replace(/\b\w/g, (l) => l.toUpperCase());

    // return s.charAt(0).toUpperCase() + s.slice(1);
  };
  useEffect(() => {
    localStorage.setItem('I18N_LANGUAGE', i18next.language);
  }, [i18next.language]);
  return (
    <ParticlesAuth>
      <div className='auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100'>
        {/* <div className='bg-overlay'></div> */}

        <div className='auth-page-content overflow-hidden pt-lg-5'>
          <Container>
            <Row>
              <Col lg={12}>
                <Card className='overflow-hidden border-0'>
                  <Row className='g-0'>
                    <AuthSlider />

                    <Col lg={6}>
                      <div className='p-lg-5 p-4'>
                        <div>
                          <h5 className='text-primary'>
                            {t('LoginPage.welcomeBack')}
                          </h5>
                          <p className='text-muted'>
                            {i18next.language === 'en'
                              ? `${t('LoginPage.signintoContinueTo')}
                            ${capitalize(DOMAIN_KEY)}.`
                              : t('LoginPage.signintoContinueTo')}
                          </p>
                        </div>

                        <div className='mt-4'>
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                            action='#'
                          >
                            <div className='mb-3'>
                              <Label htmlFor='email' className='form-label'>
                                Email
                              </Label>
                              <Input
                                name='email'
                                className='form-control'
                                id='email'
                                type='email'
                                placeholder={t('LoginPage.enterEmail')}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ''}
                                invalid={
                                  !!(
                                    validation.touched.email &&
                                    validation.errors.email
                                  )
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type='invalid'>
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className='mb-3'>
                              <div className='float-end'>
                                <Link
                                  to='/forgot-password'
                                  className='text-muted'
                                >
                                  {t('LoginPage.forgotPassword')}
                                </Link>
                              </div>
                              <Label
                                className='form-label'
                                htmlFor='password-input'
                              >
                                {t('LoginPage.password')}
                              </Label>
                              <div className='position-relative auth-pass-inputgroup mb-3'>
                                <Input
                                  name='password'
                                  value={validation.values.password || ''}
                                  type={showPassword ? 'text' : 'password'}
                                  className='form-control pe-5'
                                  placeholder={t('LoginPage.enterPassword')}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    !!(
                                      validation.touched.password &&
                                      validation.errors.password
                                    )
                                  }
                                />
                                {validation.touched.password &&
                                validation.errors.password ? (
                                  <FormFeedback type='invalid'>
                                    {validation.errors.password}
                                  </FormFeedback>
                                ) : null}
                                <button
                                  className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted'
                                  type='button'
                                  id='password-addon'
                                >
                                  <i
                                    className='ri-eye-fill align-middle'
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                  />
                                </button>
                              </div>
                            </div>

                            {/* <div className='form-check'>
                              <Input
                                className='form-check-input'
                                type='checkbox'
                                value=''
                                id='auth-remember-check'
                              />
                              <Label
                                className='form-check-label'
                                htmlFor='auth-remember-check'
                              >
                                Remember me
                              </Label>
                            </div> */}

                            <div className='mt-4'>
                              <Button
                                color='success'
                                className='w-100'
                                type='submit'
                              >
                                {t('LoginPage.login')}
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <footer className='footer'>
          <Container>
            <Row>
              <Col lg={12}>
                <div className='text-center'>
                  <p className='mb-0'>
                    {/* &copy; {new Date().getFullYear()} Electroop. Crafted with */}
                    {/* <i className='mdi mdi-heart text-danger' /> */}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </ParticlesAuth>
  );
};

CoverSignIn.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default CoverSignIn;
