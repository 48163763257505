import React, { useState, useEffect } from 'react';
import { ArrowLeft } from 'react-feather';
import { CardTitle } from 'reactstrap';

interface SlidingModalProps {
  toggleHandle: (isOpen: boolean) => void;
  isOpen: boolean;
  children?: any;
  title?: any;
  size?: string;
}

const SlidingModal = (props: SlidingModalProps) => {
  const [displayModal, setDisplayModal] = useState<boolean>(false);

  const detechDown = (e: any) => {
    if (e.keyCode === 27) {
      e.preventDefault();
      // if (props.isOpen) {
      //   console.log(props.isOpen);
      props.toggleHandle(!props.isOpen);
      // }
    }
  };
  useEffect(() => {
    if (props.isOpen) {
      document.addEventListener('keyup', detechDown);
    } else {
      document.removeEventListener('keyup', detechDown);
    }

    return () => {
      document.removeEventListener('keyup', detechDown);
    };
  }, [props.isOpen]);

  return (
    <>
      <div
        className={`sliding-modal-${props.size} ${
          props.isOpen ? 'show' : ''
        } pt-3 pb-5`}
      >
        <div className=' d-flex flex-grow justify-content-eleven mb-2 causten-regular'>
          {/* <Button className='mr-2 p-1 bg-success' onClick={handleClick}> */}
          <ArrowLeft
            cursor='pointer'
            style={{ color: 'grey' }}
            className='btn btn-icon btn-topbar btn-ghost-secondary '
            onClick={() => props.toggleHandle(!props.isOpen)}
          />
          <h5 className='ml-auto mt-auto w-100 text-end'>{props.title}</h5>

          {/* </Button> */}
          {/* <PageHeader className="mr-1" title={t('Vehicle.vehicleDetail')} /> */}
        </div>
        <div className='pb-5'>{props.children}</div>
      </div>
      <div
        className={`sliding-overlay ${props.isOpen ? 'show' : ''}`}
        onClick={() => props.toggleHandle(!props.isOpen)}
        aria-hidden='true'
      />
    </>
  );
};

SlidingModal.defaultProps = {
  children: undefined,
  title: '',
  size: 'md',
};

export default SlidingModal;
