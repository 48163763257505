import React from 'react';
import {
  Col,
  Row,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import SlidingModal from 'Components/Common/SlidingModal';
import { VehicleType } from 'declerations/DefaultTypes';
import CardInfoText from 'Components/Common/CardInfoText';

interface VehicleDetailProps {
  isOpen: boolean;
  toggleHandle: (isOpen: boolean) => void;
  selectedVehicle: VehicleType | null;
}

const VehiclesDetail = ({
  isOpen,
  toggleHandle,
  selectedVehicle,
}: VehicleDetailProps) => {
  const { t } = useTranslation();
  return (
    <SlidingModal toggleHandle={toggleHandle} isOpen={isOpen}>
      <div>
        <Card>
          <CardHeader>
            <CardTitle>
              {selectedVehicle?.vehicleMake} {selectedVehicle?.vehicleModel}{' '}
              {selectedVehicle?.vehicleModelVersion}
            </CardTitle>
          </CardHeader>
          <CardBody>
            <Row className='m-2'>
              <Col sm='12'>
                <img
                  crossOrigin="anonymous"
                  alt={selectedVehicle?._id}
                  src={selectedVehicle?.image}
                  style={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '8px',
                  }}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Col xs={12} md={6}>
                <ul className='list-group list-group-flush'>
                  <CardInfoText
                    title={t('Vehicle.maker')}
                    value={selectedVehicle?.vehicleMake}
                  />

                  <CardInfoText
                    title={t('Vehicle.model')}
                    value={selectedVehicle?.vehicleModel}
                  />

                  <CardInfoText
                    title={t('Vehicle.version')}
                    value={
                      selectedVehicle?.vehicleModelVersion === null
                        ? '-'
                        : selectedVehicle?.vehicleModelVersion
                    }
                  />

                  <CardInfoText
                    title='0-100 km/h'
                    value={`${selectedVehicle?.performanceAcceleration} ${t(
                      'Vehicle.sec(s)'
                    )}`}
                  />

                  <CardInfoText
                    title={t('Vehicle.wlptRange')}
                    value={`${selectedVehicle?.rangeWLTP} km`}
                  />

                  <CardInfoText
                    title={t('Vehicle.realRange')}
                    value={`${selectedVehicle?.rangeReal} km`}
                  />

                  <CardInfoText
                    title={t('Vehicle.topSpeed')}
                    value={`${selectedVehicle?.performanceTopspeed} km/h`}
                  />
                </ul>
              </Col>
              <Col xs={12} md={6}>
                <ul className='list-group list-group-flush'>
                  <CardInfoText
                    title={t('Vehicle.batteryCapacity')}
                    value={`${selectedVehicle?.batteryCapacityFull}kWh`}
                  />

                  <CardInfoText
                    title={t('Vehicle.acPlug')}
                    value={
                      selectedVehicle?.chargePlug === null
                        ? '-'
                        : selectedVehicle?.chargePlug
                    }
                  />

                  <CardInfoText
                    title={t('Vehicle.acPower')}
                    value={`${selectedVehicle?.chargeStandardPower} kW`}
                  />

                  <CardInfoText
                    title={t('Vehicle.acPhase')}
                    value={selectedVehicle?.chargeStandardPhase}
                  />

                  <CardInfoText
                    title={t('Vehicle.dcPlug')}
                    value={
                      selectedVehicle?.fastChargePlug === null
                        ? '-'
                        : selectedVehicle?.fastChargePlug
                    }
                  />

                  <CardInfoText
                    title={t('Vehicle.dcPower')}
                    value={
                      selectedVehicle?.fastChargePowerMax === null
                        ? '-'
                        : `${selectedVehicle?.fastChargePowerMax} kW`
                    }
                  />
                </ul>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </div>
    </SlidingModal>
  );
};

export default VehiclesDetail;
