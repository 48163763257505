/* eslint-disable react/jsx-props-no-spreading */

import { FormGroup, InputGroup, InputGroupText, Input } from 'reactstrap';
import { ErrorMessage } from 'formik';
import Flatpickr from 'react-flatpickr';
import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import 'flatpickr/dist/themes/light.css';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const unboxError = (error: object | string): typeof error => {
  if (typeof error === 'object') {
    return unboxError(Array.from(Object.values(error))[0]);
  }
  return error;
};

export const renderField = ({
  input,
  field,
  label,
  hideLabel,
  type,
  meta: { touched, error, warning },
  className,
  addOnText,
  ...rest
}: {
  input: any;
  field: any;
  label: any;
  hideLabel: boolean;
  type: string;
  meta?: any;
  className: string;
  addOnText: string;
  rest: any;
}) => {
  return (
    <FormGroup>
      {hideLabel ? null : <label htmlFor='label'>{label}</label>}
      <input
        className={`${className || ''} ${touched && error ? 'is-invalid' : ''}`}
        {...field}
        {...input}
        placeholder={label}
        type={type}
        {...rest}
      />
      {touched &&
        ((error && (
          <span className='text-danger text-sm'>{unboxError(error)}</span>
        )) ||
          (warning && (
            <span className='text-warning text-sm'>{unboxError(warning)}</span>
          )))}
    </FormGroup>
  );
};
export const renderFieldAddOn = ({
  input,
  field,
  label,
  hideLabel,
  type,
  meta: { touched, error, warning },
  className,
  addOnText,
  ...rest
}: {
  input: any;
  field: any;
  label: any;
  hideLabel: boolean;
  type: string;
  meta: any;
  className: string;
  addOnText: string;
}) => (
  <FormGroup>
    {hideLabel ? null : <label htmlFor='label'>{label}</label>}
    <InputGroup>
      <InputGroupText>{addOnText}</InputGroupText>
      <Input
        className={`${className || ''} ${touched && error ? 'is-invalid' : ''}`}
        {...field}
        {...input}
        placeholder={label}
        type={type}
        {...rest}
      />
    </InputGroup>
    {touched &&
      ((error && (
        <span className='text-danger text-sm'>{unboxError(error)}</span>
      )) ||
        (warning && (
          <span className='text-warning text-sm'>{unboxError(warning)}</span>
        )))}
  </FormGroup>
);

export const renderSelectField = ({
  input,
  field,
  label,
  children,
  meta: { touched, error, warning },
  className,
  ...rest
}: {
  input: any;
  field: any;
  label: any;
  children: any;
  meta: any;
  className: string;
  rest: any;
}) => (
  <FormGroup>
    <label htmlFor='label'>{label}</label>
    <select
      className={`${className || ''} ${touched && error ? 'is-invalid' : ''}`}
      {...field}
      {...input}
      placeholder={label}
      {...rest}
    >
      {children}
    </select>
    {touched &&
      ((error && (
        <span className='text-danger text-sm'>{unboxError(error)}</span>
      )) ||
        (warning && (
          <span className='text-warning text-sm'>{unboxError(warning)}</span>
        )))}
  </FormGroup>
);

export const renderFlatpickrField = ({
  input,
  field,
  label,
  children,
  meta: { touched, error, warning },
  className,
  ...rest
}: {
  input: any;
  field: any;
  label: any;
  children: any;
  meta: any;
  className: string;
  rest: any;
}) => (
  <FormGroup>
    <label htmlFor='label'>{label}</label>
    <Flatpickr
      className={`${className || ''} ${touched && error ? 'is-invalid' : ''}`}
      {...field}
      {...input}
      placeholder={label}
      {...rest}
      //  onChange={(_, date) => input.onChange(date)}
    />
    {touched &&
      ((error && (
        <span className='text-danger text-sm'>{unboxError(error)}</span>
      )) ||
        (warning && (
          <span className='text-warning text-sm'>{unboxError(warning)}</span>
        )))}
  </FormGroup>
);
export const renderPhoneField = ({
  input,
  field,
  label,
  children,
  meta: { touched, error, warning },
  className,
  ...rest
}: {
  input: any;
  field: any;
  label: any;
  children: any;
  meta: any;
  className: string;
  rest: any;
}) => (
  <FormGroup>
    <label htmlFor='label'>{label}</label>
    <PhoneInput
      country='TR'
      defaultCountry='TR'
      international
      countryCallingCodeEditable={false}
      className={`${className || ''} ${touched && error ? 'is-invalid' : ''} `}
      {...field}
      {...input}
      placeholder={label}
      {...rest}
      // onChange={(date) => input.onChange(date)}
    />
    {touched &&
      ((error && (
        <span className='text-danger text-sm'>{unboxError(error)}</span>
      )) ||
        (warning && (
          <span className='text-warning text-sm'>{unboxError(warning)}</span>
        )))}
  </FormGroup>
);
export const renderTextArea = ({
  input,
  field,
  label,
  meta: { touched, error, warning },
  className,
  ...rest
}: {
  input: any;
  field: any;
  label: any;
  meta: any;
  className: string;
  rest: any;
}) => (
  <FormGroup>
    <label htmlFor='label'>{label}</label>
    <textarea
      className={`${className || ''} ${touched && error ? 'is-invalid' : ''}`}
      {...field}
      {...input}
      placeholder={label}
      {...rest}
    />
    {touched &&
      ((error && (
        <span className='text-danger text-sm'>{unboxError(error)}</span>
      )) ||
        (warning && (
          <span className='text-warning text-sm'>{unboxError(warning)}</span>
        )))}
  </FormGroup>
);

export const renderReactSelect = ({
  input,
  field,
  label,
  children,
  className,
  creatable,
  ...rest
}: {
  input: any;
  field: any;
  label: any;
  children: any;
  className: string;
  creatable: boolean;
  rest: any;
}) => {
  const { onBlur = () => {} } = input || rest || {};
  const SelectComponent = creatable ? CreatableSelect : Select;
  const name = (input || {}).name || (field || {}).name;
  return (
    <FormGroup>
      <label htmlFor='label'>{label}</label>
      <SelectComponent
        {...field}
        {...input}
        placeholder={label}
        {...rest}
        onBlur={() => onBlur()}
      />
      <ErrorMessage
        name={name}
        component='div'
        className='field-error text-danger'
      />
    </FormGroup>
  );
};
