/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataContext from 'Components/Contexts/DataContext';
import { LocationType } from 'declerations/DefaultTypes';
import Container from 'Components/Common/Container';
import { Button, Col, Row } from 'reactstrap';
import ApplicationContext from 'Components/Contexts/ApplicationContext';
import SearchInput from 'Components/Common/SearchInput';
import DataTable from 'Components/Common/DataTable';
import EditContext from 'Components/Contexts/EditContext';
import { Plus, Edit3 } from 'react-feather';
import * as Icons from 'react-feather';
import { getLabelCurrency } from 'helpers/Currencies';
import getAmenityIcon from 'helpers/Amenities';
import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css';
import ExportBlock from 'laxyExcel/ExportComponents';
import AutoRefresh from 'Components/Common/AutoRefresh';
import { AUTO_REFRESH_TIME } from 'helpers/config';
import LocationDetail from './LocationDetail';
import AddLocation from './AddLocation/AddLocation';
import i18n from '../../i18n';

const Locations = () => {
  const ReactHint = ReactHintFactory(React);

  const [loading, setLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState(false);
  const [addIsOpen, setAddIsOpen] = useState(false);
  const [isActiveAutoRefresh, setIsActiveAutoRefresh] = useState(true);
  const [isOpenEdit, setisOpenEdit] = useState(false);
  const [filtered, setFiltered] = useState('');
  const { t } = useTranslation();
  const context = useContext(DataContext);
  const applicationContext = useContext(ApplicationContext);
  const editContext = useContext(EditContext);

  const loadDataTable = () => {
    if (context.profile) {
      if (context.profile.allowedScopes.includes('Domain')) {
        if (!applicationContext.selectedApplication?._id) return;
      }
    }
    context
      .LoadLocations({
        applicationId: applicationContext.selectedApplication?._id,
      })
      .then(() => setLoading(false));
  };
  useEffect(() => {
    loadDataTable();
  }, [applicationContext.selectedApplication?._id, isOpen, addIsOpen]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isActiveAutoRefresh) {
        loadDataTable();
      }
    }, AUTO_REFRESH_TIME);
    return () => clearInterval(intervalId);
  }, [
    isActiveAutoRefresh,
    applicationContext.selectedApplication?._id,
    isOpen,
    addIsOpen,
    i18n.language,
  ]);

  const search = (rowData: LocationType[] | undefined) => {
    if (!rowData) {
      return [];
    }
    return rowData.filter(
      (row: LocationType) =>
        row?.title?.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
        row?.application?.name?.toLowerCase().indexOf(filtered.toLowerCase()) >
          -1
    );
  };

  const mappers = {
    amenities: (row: any) => {
      if (row.amenities.length > 0) {
        const array: any[] = [];
        // eslint-disable-next-line array-callback-return
        row.amenities?.map((amenity: any, index: any) => {
          typeof amenity === 'object'
            ? i18n.language === 'en'
              ? array.push(amenity[0]?.value)
              : array.push(amenity[1]?.value)
            : array.push('-');
        });
        return array;
      }
      return '-';
    },
    dayEnergyCost: (row: any) => {
      if (row?.dayEnergyCost) {
        return `${row?.dayEnergyCost}`;
      }
      return `-`;
    },
    code: (row: any) => {
      if (row?.code) {
        return `${row?.code}`;
      }
      return `-`;
    },
    revenueSharePercentage: (row: any) => {
      if (row?.revenueSharePercentage) {
        return `${row?.revenueSharePercentage}`;
      }
      return `-`;
    },
  };
  const headers = [
    'title',
    'code',
    'revenueSharePercentage',
    'application.name',
    'dayEnergyCost',
    'amenities',
  ].map((key) => ({
    key,
    value: `locations.${key}`,
    mapper: mappers[key as keyof typeof mappers],
  }));
  return (
    <Container
      title={t('Location.header')}
      rightSide={
        <AutoRefresh
          isActive={isActiveAutoRefresh}
          onPress={() => {
            setIsActiveAutoRefresh(!isActiveAutoRefresh);
          }}
        />
      }
    >
      <Row className='mb-2 d-flex justify-content-end'>
        <Col xl={3} sm={12} className='d-flex'>
          <SearchInput onChange={(text) => setFiltered(text)} />
        </Col>
        <Col xl={6} sm={12}>
          {' '}
        </Col>
        <Col
          xl={3}
          sm={12}
          className='d-flex justify-content-end '
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <ExportBlock
            data={context.locations}
            header={headers}
            name={t('Location.header')}
          />
          <Button
            className='ms-1'
            aria-hidden='true'
            color='primary'
            onClick={() => {
              setisOpenEdit(false);

              setAddIsOpen(!addIsOpen);
            }}
          >
            <Plus className='me-2 p-0' size='20' />
            {t('Add')}
          </Button>
        </Col>
      </Row>
      <DataTable
        pointerOnHover
        onRowClicked={(row, event) => {
          editContext.SetEditLocation(row);
          setIsOpen(true);
        }}
        loading={loading}
        noDataText={t('Location.thereArenoLocationCreated')}
        columns={[
          {
            name: t('Location.title'),
            selector: (row: LocationType) => row.title,
            sortable: true,
          },
          {
            name: t('Location.code'),
            selector: (row: LocationType) => (row.code ? row.code : '-'),
            sortable: true,
          },
          {
            name: t('Location.revenueSharePercentage'),
            selector: (row: LocationType) =>
              row.revenueSharePercentage ? row.revenueSharePercentage : '-',
            sortable: true,
          },
          {
            name: t('Location.application'),
            selector: (row: LocationType) => row.application?.name,
            sortable: true,
          },
          {
            name: t('Location.energycost'),
            selector: (row: LocationType) => row.dayEnergyCost,
            sortable: true,
            cell: (row: LocationType) =>
              row.dayEnergyCost
                ? row.application
                  ? `${row.dayEnergyCost} ${getLabelCurrency(
                      row?.application?.currency,
                      'symbol'
                    )}`
                  : row.dayEnergyCost
                : '-',
          },
          {
            name: t('Location.amenities'),
            selector: (row: LocationType) => row.amenities,
            sortable: true,
            // conditionalCellStyles: {
            //   style: {
            //     paddingRight: '40px',
            //   },
            // },
            cell: (row: LocationType) => (
              <div>
                {row.amenities?.map((amenity, index) => (
                  <Button
                    className='px-0'
                    outline
                    color='flat-primary'
                    data-rh={
                      typeof amenity === 'object'
                        ? amenity?.find(
                            (e: any) => e.lang === i18n.language.slice(0, 2)
                          )?.value || ''
                        : amenity
                    }
                    key={index}
                  >
                    {getAmenityIcon(
                      typeof amenity === 'object'
                        ? amenity?.find(
                            (e: any) => e.lang === i18n.language.slice(0, 2)
                          )?.value || ''
                        : amenity
                    )}
                  </Button>
                ))}
              </div>
            ),
          },
          {
            name: t('Location.action'),
            cell: (row: LocationType) => {
              return (
                <div>
                  <Edit3
                    className='mr-1'
                    cursor='pointer'
                    onClick={() => {
                      editContext.SetEditLocation(row);
                      setisOpenEdit(true);
                      setAddIsOpen(true);
                    }}
                  />
                  {/* <DollarSign
                    className='mr-1'
                    cursor='pointer'
                    onClick={() => {
                      this.toggleTariffModal(row._id, row.title);
                    }}
                  /> */}
                  {/* <Icons.Trash2
                    color='#f00'
                    className='mr-1'
                    cursor='pointer'
                    onClick={(e) => {}}
                  /> */}
                </div>
              );
            },
          },
        ]}
        data={search(context.locations) || []}
      />
      <ReactHint autoPosition events />
      {editContext.editLocation && (
        <LocationDetail
          isOpen={isOpen}
          toggleHandle={() => {
            setIsOpen(false);
            context.LoadLocations({
              applicationId: applicationContext.selectedApplication?._id,
            });
          }}
        />
      )}
      <AddLocation
        isOpen={addIsOpen}
        isEditing={isOpenEdit}
        toggleHandle={() => {
          setAddIsOpen(false);
          setisOpenEdit(false);
          context.LoadLocations({
            applicationId: applicationContext.selectedApplication?._id,
          });
        }}
      />
    </Container>
  );
};
export default Locations;
