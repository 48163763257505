import { APIClient } from '../helpers/api_helper';

const api = new APIClient();
export const getDomains = () => {
  const apiUrl = `domains`;

  return api.get(apiUrl);
};

export const createDomain = (params: object) => {
  const apiUrl = 'domains';
  return api.create(apiUrl, params);
};
export const editDomain = (id: string, params: object) => {
  const apiUrl = `domains/${id}`;
  return api.update(apiUrl, params);
};
