/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useContext } from 'react';
import { Col, Row, FormGroup } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { Turkish } from 'flatpickr/dist/l10n/tr';
import Select from 'react-select';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import numeral from 'numeral';
import * as Icon from 'react-feather';
import DataContext from 'Components/Contexts/DataContext';
import { useTranslation } from 'react-i18next';
import getSocketSvg from 'helpers/Socket';
import { getApplications } from '../../../actions/applications';
import { getStatisticsCount } from '../../../actions/chargeStations';
import CardWidget from '../../../Components/Common/CardWidget';
import { getLabelCurrency } from '../../../helpers/Currencies';

type StateType = {
  transactions: number;
  unPaidTransactions: number;
  durationInHrs: number;
  evseSuspendedInHrs: number;
  powerConsumptionInKWh: number;
  endorsements: [
    {
      currency: string;
      earnings: number;
      cpoMargin: number;
      dealerMargin: number;
      revenue: number;
    }
  ];
  drivers: number;
  newUsers: number;
  chargeStations: number;
  acChargeStations: number;
  dcChargeStations: number;
  previous: StateType;
  trxAppliedDate: string;
};
const StatsCards = () => {
  const context = useContext(DataContext);

  const { t } = useTranslation();
  const history = useHistory();

  const initialbaseDate = new Date();
  let initialStartDate: Date | string = new Date();
  let initialEndDate: Date | string = new Date(
    initialbaseDate.getFullYear(),
    initialbaseDate.getMonth(),
    initialbaseDate.getDate() + 1
  );
  initialStartDate = new Date(initialbaseDate.getFullYear(), 0, 1);
  initialEndDate = new Date(
    initialbaseDate.getFullYear(),
    initialbaseDate.getMonth(),
    initialbaseDate.getDate() + 1
  );
  initialStartDate.setUTCHours(23, 59, 59, 999);
  initialEndDate.setUTCHours(23, 59, 59, 999);
  initialStartDate = initialStartDate.toISOString();
  initialEndDate = initialEndDate.toISOString();

  const [filter, setFilter] = useState<{
    startDate: string;
    endDate: string;
    applicationId: string | undefined;
    period: string | undefined;
  }>({
    endDate: initialEndDate,
    startDate: initialStartDate,
    applicationId: 'null',
    period: 'thisYear',
  });
  const [state, setState] = useState<StateType | undefined>();
  const [readyToCalculate, setReadyToCalculate] = useState<boolean>(false);

  const [selectedRangeType, setSelectedRangeType] = useState('thisYear');
  const [rangeDate, setRangeDate] = useState([
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(),
  ]);
  const [applications, setApplications] = useState([]);

  const all = { name: 'null', _id: 'null' };

  const changeSelectedRangeType = (type: string) => {
    const baseDate = new Date();
    let startDate: Date | string = new Date();
    let endDate: Date | string = new Date(
      baseDate.getFullYear(),
      baseDate.getMonth(),
      baseDate.getDate() + 1
    );
    if (type === 'today') {
      startDate.setUTCHours(0, 0, 0, 0);
      endDate.setUTCHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate, period: 'today' });
    } else if (type === 'thisWeek') {
      startDate = new Date(
        baseDate.setDate(baseDate.getDate() - baseDate.getDay())
      );
      startDate.setUTCHours(23, 59, 59, 999);
      endDate = moment().endOf('week').add(1, 'days').toDate();
      endDate.setUTCHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate, period: 'thisWeek' });
    } else if (type === 'thisMonth') {
      startDate = new Date(baseDate.getFullYear(), baseDate.getMonth(), 1);
      endDate = new Date(
        baseDate.getFullYear(),
        baseDate.getMonth(),
        baseDate.getDate() + 1
      );
      startDate.setUTCHours(23, 59, 59, 999);
      endDate.setUTCHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate, period: 'thisMonth' });
    } else if (type === 'thisYear') {
      startDate = new Date(baseDate.getFullYear(), 0, 1);
      endDate = new Date(
        baseDate.getFullYear(),
        baseDate.getMonth(),
        baseDate.getDate() + 1
      );
      startDate.setUTCHours(23, 59, 59, 999);
      endDate.setUTCHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate, period: 'thisYear' });
    } else if (type === 'customDate') {
      startDate = moment(rangeDate[0]).toDate();
      endDate = moment(rangeDate[1]).add(1, 'days').toDate();
      startDate.setUTCHours(23, 59, 59, 999);
      endDate.setUTCHours(23, 59, 59, 999);
      startDate = startDate.toISOString();
      endDate = endDate.toISOString();
      setFilter({ ...filter, endDate, startDate, period: 'customDate' });
    } else if (type === 'all') {
      setFilter({
        ...filter,
        endDate: 'null',
        startDate: 'null',
        period: 'all',
      });
    }
    setSelectedRangeType(type);
  };
  useEffect(() => {
    let cleanUp = false;

    getStatisticsCount({ ...filter }).then((response) => {
      if (!cleanUp) {
        setState(response?.data);
        setReadyToCalculate(true);
      }
    });
    if (applications.length <= 1) {
      getApplications().then((res) => {
        if (!cleanUp) {
          setApplications(res?.data?.applications);
        }
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [filter]);

  useEffect(() => {
    if (selectedRangeType === 'customDate') {
      changeSelectedRangeType('customDate');
    }
  }, [rangeDate]);

  return (
    <Row>
      <Col>
        <Row className='page-title-box d-flex flex-wrap pb-0'>
          <Col xl={6} className='d-flex flex-column justify-content-center'>
            <h4 className='mb-0 headertitle'>{t('Overview')}</h4>
          </Col>
          <Col className='mt-sm-3 mt-4 mt-md-0 mt-lg-0 mt-xl-0 mb-0'>
            <Row className='justify-content-end flex-row justify-content-center'>
              <Col>
                <FormGroup className='m-0'>
                  <select
                    id='date-range'
                    className='form-control form-select'
                    value={selectedRangeType}
                    onChange={(e) => changeSelectedRangeType(e.target.value)}
                  >
                    <option style={{ color: 'black' }} value='thisYear'>
                      {t('Transaction.thisYear')}
                    </option>
                    <option style={{ color: 'black' }} value='all'>
                      {t('Transaction.fromBegining')}
                    </option>
                    <option style={{ color: 'black' }} value='today'>
                      {t('Transaction.today')}
                    </option>
                    <option style={{ color: 'black' }} value='thisWeek'>
                      {t('Transaction.thisWeek')}
                    </option>
                    <option style={{ color: 'black' }} value='thisMonth'>
                      {t('Transaction.thisMonth')}
                    </option>
                    <option style={{ color: 'black' }} value='customDate'>
                      {t('Transaction.customDate')}
                    </option>
                  </select>
                </FormGroup>
              </Col>

              {selectedRangeType === 'customDate' && (
                <Col className='text-align'>
                  <FormGroup className='m-0'>
                    <Flatpickr
                      value={rangeDate}
                      id='range-picker'
                      className='form-control'
                      onChange={(date) => {
                        if (date.length > 0) {
                          setRangeDate(date);
                        }
                      }}
                      options={{
                        mode: 'range',
                        maxDate: new Date(),
                        locale:
                          t('Transaction.locale') === 'Turkish'
                            ? Turkish
                            : undefined,
                      }}
                    />
                  </FormGroup>
                </Col>
              )}
              <Col>
                <FormGroup>
                  <Select
                    id='application'
                    options={[all, ...applications]}
                    getOptionLabel={(x) =>
                      x.name !== 'null' ? x.name : t('Transaction.allCPO')
                    }
                    getOptionValue={(x) => x._id}
                    placeholder={t('Transaction.allCPO')}
                    onChange={(e) =>
                      setFilter({ ...filter, applicationId: e?._id })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <CardWidget
              label={t('Home.Transactions')}
              onClick={() => history.push('/cpo/transaction')}
              badge='ri-arrow-up-circle-line text-success'
              icon={getSocketSvg('TransactionsIcon', 'inherit')}
              counter={state?.transactions || 0}
              previousCount={state?.previous?.transactions || 0}
              appliedDate={state?.previous?.trxAppliedDate || 0}
              readyToCalculate={readyToCalculate}
              url='#'
            />
          </Col>
          <Col>
            <CardWidget
              label={t('Home.revenue')}
              onClick={() => history.push('/cpo/transaction')}
              badge='ri-arrow-up-circle-line text-success'
              icon={getSocketSvg('RevenueIcon', 'inherit')}
              counter={state?.endorsements[0]?.revenue || 0}
              previousCount={state?.previous?.endorsements[0]?.revenue || 0}
              appliedDate={state?.previous?.trxAppliedDate || 0}
              readyToCalculate={readyToCalculate}
              decimals={2}
              suffix={getLabelCurrency(
                state?.endorsements[0]?.currency || 'TRY',
                'symbol'
              )}
              formattingFn={(val) => {
                return `${numeral(val)
                  .format('0.0,')
                  .toString()} ${getLabelCurrency(
                  state?.endorsements[0]?.currency || 'TRY',
                  'symbol'
                )}`;
              }}
              url='#'
            />
          </Col>
          <Col>
            <CardWidget
              label={t('Home.energyUsage')}
              badge='ri-arrow-up-circle-line text-success'
              icon={getSocketSvg('EnergyIcon', 'inherit')}
              onClick={() => history.push('/cpo/transaction')}
              counter={state?.powerConsumptionInKWh || 0}
              previousCount={state?.previous?.powerConsumptionInKWh || 0}
              appliedDate={state?.previous?.trxAppliedDate || 0}
              readyToCalculate={readyToCalculate}
              suffix=' kWh'
              decimals={2}
              url='#'
              formattingFn={(val) => {
                return `${numeral(val).format('0.0,').toString()} kWh`;
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CardWidget
              label={t('Home.Drivers')}
              onClick={() => history.push('/drivers')}
              badge='ri-arrow-up-circle-line text-success'
              icon={getSocketSvg('DriverIcon', 'inherit')}
              counter={state?.drivers || 0}
              previousCount={state?.previous?.drivers || 0}
              appliedDate={state?.previous?.trxAppliedDate || 0}
              readyToCalculate={readyToCalculate}
              url='#'
            />
          </Col>
          <Col>
            <CardWidget
              twocounter={state?.dcChargeStations || 0}
              counter3={state?.chargeStations || 0}
              onClick={() => history.push('/cpo/charge-station')}
              label={t('Home.acdcStations')}
              badge='ri-arrow-up-circle-line text-success'
              icon={getSocketSvg('StationsIcon', 'inherit')}
              appliedDate={state?.previous?.trxAppliedDate || 0}
              previousCount={0}
              readyToCalculate={readyToCalculate}
              counter={state?.acChargeStations || 0}
              url='#'
            />
          </Col>
          <Col>
            <CardWidget
              label={t('Home.unPaid')}
              onClick={() => history.push('/cpo/transaction')}
              badge='ri-arrow-up-circle-line text-success'
              icon={getSocketSvg('UnPaid', 'inherit')}
              counter={state?.unPaidTransactions || 0}
              previousCount={state?.previous?.unPaidTransactions}
              appliedDate={state?.previous?.trxAppliedDate || 0}
              readyToCalculate={readyToCalculate}
              url='#'
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default StatsCards;
