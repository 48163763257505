import React, { useEffect } from 'react';

// import Scss

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toggle/style.css'; // for ES6 modules
import ThemeSelector from './themes/themeSelector';

// imoprt Route
import Route from './Routes';

import { DOMAIN_KEY, ASSET_BASE_URL, THEME } from './helpers/config';

const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const App = () => {
  useEffect(() => {
    document.title = capitalize(DOMAIN_KEY);
    const favicon: any = document.getElementById('favicon');
    if (favicon !== null) {
      favicon.href = `${ASSET_BASE_URL}favicon.png`;
    }
  }, []);
  return (
    <ThemeSelector>
      <Route />
      <ToastContainer autoClose={2000} />
    </ThemeSelector>
  );
};



export default App;
