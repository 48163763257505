import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Container,
} from 'reactstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import DataContext from 'Components/Contexts/DataContext';
// import Container from 'Components/Common/Container';
import i18n from 'i18n';
import GeneralTabContent from './widgets/GeneralTabContent';
import PasswordTabContent from './widgets/PasswordTabContent';
import OcppTabContent from './widgets/OcppTabContent';
import ChargePointListContent from './widgets/ChargePointListContent';


const AccountSettings = () => {
  const [activeTab, setActiveTab] = useState('1');
  const { t } = useTranslation();
  const context = useContext(DataContext);
  const [initialValues, setInitialValues] = useState();

  const tabChange = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useEffect(() => {
    context
      .LoadProfile()
      .then(() => setInitialValues(context.profile))
      .then(
        i18n.changeLanguage(localStorage.getItem('I18N_LANGUAGE') as string)
      );
  }, []);
  return (
    <div className='page-content mt-5'>
      <Container className='p-0' fluid>
        <Col>
          <Card className='mt-xxl-n5'>
            <CardHeader>
              <Nav
                className='nav-tabs-custom rounded card-header-tabs border-bottom-0'
                role='tablist'
              >
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                      tabChange('1');
                    }}
                    type='button'
                  >
                    <i className='fas fa-home' />
                    {t('AccountSettings.general')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to='#'
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                      tabChange('2');
                    }}
                    type='button'
                  >
                    <i className='far fa-user' />
                    {t('AccountSettings.changePassword')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to='#'
                    className={classnames({ active: activeTab === '3' })}
                    type='button'
                    onClick={() => {
                      tabChange('3');
                    }}
                  >
                    <i className='far fa-user' />
                    {t('AccountSettings.ocppSetting')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to='#'
                    className={classnames({ active: activeTab === '4' })}
                    type='button'
                    onClick={() => {
                      tabChange('4');
                    }}
                  >
                    <i className='far fa-user' />
                    {t('AccountSettings.chargePointManagement')}
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody className='p-4'>
              <TabContent activeTab={activeTab}>
                <TabPane tabId='1'>
                  <GeneralTabContent initialValues={context.profile} />
                </TabPane>
                <TabPane tabId='2'>
                  <PasswordTabContent />
                </TabPane>
                <TabPane tabId='3'>
                  <OcppTabContent />
                </TabPane>
                <TabPane tabId='4'>
                  <ChargePointListContent />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </div>
  );
};

export default AccountSettings;
