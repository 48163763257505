import { APIClient } from '../helpers/api_helper';

const api = new APIClient();
export const getOcppTags = (filter?: object) => {
  const apiUrl = `keys/`;

  return api.get(apiUrl, filter);
};

export const createOcppTag = (params: object) => {
  const apiUrl = `keys`;
  return api.create(apiUrl, params);
};
export const editOcppTag = (id: string, params: object) => {
  const apiUrl = `keys/${id}`;
  return api.update(apiUrl, params);
};
export const deleteOcppTag = (id: string) => {
  const apiUrl = `keys/${id}`;
  return api.delete(apiUrl);
};
