import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, CardBody, Col } from 'reactstrap';
import Chart from 'react-apexcharts';
import { Icon } from 'ts-react-feather-icons';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

type ConnectorRadialChartType = {
  connectors: number[];
  connector:
    | {
        charging: number;
        faulted: number;
        online: number;
        reserved: number;
        total: number;
        totalAC: number;
        totalDC: number;
      }
    | undefined;
};

const ConnectorRadialChart = ({
  connectors,
  connector,
}: ConnectorRadialChartType) => {
  const { t } = useTranslation();
  const [data, setdata] = useState({
    last_days: [t('Home.lastMonth'), t('Home.lastYear')],
    chart_info: {
      online: connectors[0],
      pending: connectors[1],
      rejected: connectors[2],
      faulted: connectors[3],
      offline: connectors[4],
    },
  });

  const [series, setseries] = useState<number[]>(connectors);
  const [maxValue, setmaxValue] = useState(
    connectors.reduce((a, b) => a + b, 0) || 10
  );
  const [loading, setLoading] = useState(true);
  const valueToPercent = (val: number) => {
    return (val * 100) / maxValue;
  };
  const percentToValue = (val: number) => Math.round((val * maxValue) / 100);
  const [conData, setConData] = useState([]);
  const [options, setoptions] = useState<any>({
    labels: [
      t('ConnectorStatusCard.online'),
      t('ConnectorStatusCard.charging'),
      t('ConnectorStatusCard.reserved'),
      t('ConnectorStatusCard.faulted'),
      t('ConnectorStatusCard.offline'),
    ],

    colors: ['#25a0e2', '#00bd9d', '#878a99', '#ea5355', '#32ccff'],
    // colors: ['#28c76f', '#ff9f43', '#7266f0', '#ea5355', '#bababa'],
    stroke: {
      lineCap: 'round' as const,
    },
    chart: {
      height: 355,
      dropShadow: {
        enabled: true,
        blur: 3,
        left: 1,
        top: 1,
        opacity: 0.1,
      },
    },
  });
  useEffect(() => {
    const newMaxvalue =
      connectors.reduce((a, b) => a + b, 0) > 0
        ? connectors.reduce((a, b) => a + b, 0)
        : 10;
    setmaxValue(newMaxvalue);

    connectors.map((x, index) => {
      return valueToPercent(x);
    });
    setseries(
      connectors && connectors.length > 0
        ? connectors.map((x, index) => {
            return x;
            // return valueToPercent(x);
          })
        : []
    );
    setdata({
      last_days: [t('Home.lastMonth'), t('Home.lastYear')],
      chart_info: {
        online: connectors[0],
        pending: connectors[1],
        rejected: connectors[2],
        faulted: connectors[3],
        offline: connectors[4],
      },
    });
    setoptions({
      chart: {
        height: 355,
        dropShadow: {
          enabled: true,
          blur: 3,
          left: 1,
          top: 1,
          opacity: 0.1,
        },
      },
      legend: {
        show: false,
      },
      labels: [
        t('ConnectorStatusCard.online'),
        t('ConnectorStatusCard.charging'),
        t('ConnectorStatusCard.reserved'),
        t('ConnectorStatusCard.faulted'),
        t('ConnectorStatusCard.offline'),
      ],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
              name: {
                show: false,
              },
            },
            size: '45px',
            hollow: {
              size: '20px',
            },
            track: {
              strokeWidth: '100%',
              margin: 15,
            },
            dataLabels: {
              enabled: false,
              value: {
                fontSize: '1rem',
                colors: '#5e5873',
                fontWeight: '500',
                offsetY: 5,
                // formatter: function formatter(val: number) {
                //   return percentToValue(val).toString();
                // },
              },
              total: {
                show: false,
                label: t('Home.total'),
                fontSize: '1rem',
                colors: '#5e5873',
                fontWeight: '500',
                formatter: (props: any) => {
                  // eslint-disable-next-line react/prop-types
                  return props?.config?.series &&
                    // eslint-disable-next-line react/prop-types
                    props?.config?.series.length > 0
                    ? // eslint-disable-next-line react/prop-types
                      props?.config?.series
                        // eslint-disable-next-line react/prop-types
                        .map((x: number) => x)
                        .reduce((a: any, b: any) => a + b, 0)
                    : 0;
                },
              },
            },
          },
        },
      },
      colors: ['#28c76f', '#ff9f43', '#7266f0', '#ea5355', '#bababa'],
      // stroke: {
      //   lineCap: 'round' as const,
      // },
    });
    setLoading(false);
  }, [connectors, i18n.language]);

  return data !== null ? (
    <Card style={{ height: 360 }}>
      <CardHeader>
        <CardTitle tag='h4'> {t('ConnectorStatusCard.connectors')}</CardTitle>
      </CardHeader>
      <CardBody
        style={{
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: '500',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Col sm={12} md={6}>
          {connectors && connectors.length > 0 && !loading && (
            <Chart
              options={options}
              series={series}
              type='donut'
              height={250}
            />
          )}
        </Col>
        <Col
          sm={12}
          md={6}
          style={{
            height: 200,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div className='d-flex align-items-center justify-content-between mb-1'>
            <div className='d-flex align-items-center'>
              <Icon name='circle' size={15} color='#28c76f' />
              <span className=' ms-2' style={{ color: '#adb5bd' }}>
                {t('ConnectorStatusCard.online')}
              </span>
            </div>
            <span style={{ fontWeight: '600' }}>{data.chart_info.online}</span>
          </div>
          <div className='d-flex justify-content-between mb-1'>
            <div className='d-flex align-items-center'>
              <Icon name='circle' size={15} color='#ff9f43' />
              <span className=' ms-2' style={{ color: '#adb5bd' }}>
                {t('ConnectorStatusCard.charging')}
              </span>
            </div>
            <span style={{ fontWeight: '600' }}>{data.chart_info.pending}</span>
          </div>
          <div className='d-flex justify-content-between mb-1'>
            <div className='d-flex align-items-center'>
              <Icon name='circle' size={15} color='#7266f0' />
              <span className=' ms-2' style={{ color: '#adb5bd' }}>
                {t('ConnectorStatusCard.reserved')}
              </span>
            </div>
            <span style={{ fontWeight: '600' }}>
              {data.chart_info.rejected}
            </span>
          </div>
          <div className='d-flex justify-content-between  mb-1 '>
            <div className='d-flex align-items-center'>
              <Icon name='circle' size={15} color='#ea5355' />
              <span className=' ms-2' style={{ color: '#adb5bd' }}>
                {t('ConnectorStatusCard.faulted')}
              </span>
            </div>
            <span style={{ fontWeight: '600' }}>{data.chart_info.faulted}</span>
          </div>
          <div className='d-flex justify-content-between '>
            <div className='d-flex align-items-center'>
              <Icon name='circle' size={15} color='#bababa' />
              <span className=' ms-2' style={{ color: '#adb5bd' }}>
                {t('ConnectorStatusCard.offline')}
              </span>
            </div>
            <span style={{ fontWeight: '600' }}>
              {connector &&
                connector.total - connector.online &&
                connector.total -
                  (connector.charging +
                    connector.faulted +
                    connector.reserved +
                    connector.online)}
            </span>
          </div>
        </Col>
      </CardBody>
    </Card>
  ) : null;
};
export default ConnectorRadialChart;
