import i18n from '../i18n';

const currencies = [
  {
    value: 'USD',
    label: i18n.t('US dollar'),
    symbol: '$',
  },
  {
    value: 'EUR',
    label: i18n.t('Euro'),
    symbol: '€',
  },
  {
    value: 'TRY',
    label: i18n.t('Turkish lira'),
    symbol: '₺',
  },
  {
    value: 'GBP',
    label: i18n.t('Pounds sterling'),
    symbol: '£',
  },
  {
    value: 'JPY',
    label: i18n.t('Japanese yen'),
    symbol: '¥',
  },
];

const translateArr = [
  'US dollar',
  'Euro',
  'Turkish lira',
  'Pounds sterling',
  'Japanese yen',
];

i18n.on('languageChanged', () => {
  for (let index = 0; index < currencies.length; index += 1) {
    currencies[index].label = i18n.t(translateArr[index]);
  }
});

export const getLabelCurrency = (value: string, type?: string | undefined) => {
  const currency = currencies.find((x) => x.value === value);
  if (type === 'label') {
    return currency && currency.label ? currency.label : '';
  }
  if (type === 'symbol') {
    return currency && currency.label ? currency.symbol : '';
  }
  return currency && currency.label
    ? `${currency.label} (${currency.symbol})`
    : '';
};

export default currencies;
