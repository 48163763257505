/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useContext } from 'react';
import { renderField } from 'Components/Common/Fields';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Card, CardBody, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { Formik, Field, Form } from 'formik';
import { editProfile, getProfile } from 'actions/profile';
import i18n from 'i18next';
import * as Yup from 'yup';
import DataContext from 'Components/Contexts/DataContext';

const formSchema = Yup.object().shape({
  email: Yup.string().email(i18n.t('AccountSettings.emailIsWrong')),
  fname: Yup.string().min(1, 'Required').required(i18n.t('Required')),
});

const GeneralTabContent = ({
  initialValues,
}: {
  initialValues: { email: string; fname: string; lname: string };
}) => {
  const { t } = useTranslation();
  const [loading, setloading] = useState(true);
  const context = useContext(DataContext);
  useEffect(() => {
    if (initialValues) {
      setloading(false);
    }
  }, [initialValues]);

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <Row className='d-flex'>
          <Col>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={async (values) => {
                const { email, fname, lname } = values;
                const {
                  email: oldEmail,
                  fname: oldFname,
                  lname: oldLname,
                } = initialValues;
                setloading(true);
                const data = { email: '', fname: '', lname: '' };
                if (email !== oldEmail) {
                  data.email = email;
                }
                if (fname !== oldFname) {
                  data.fname = fname;
                }
                if (lname !== oldLname) {
                  data.lname = lname;
                }
                if (Object.keys(data).length === 0) {
                  return;
                }
                editProfile(data)
                  .then((response) => {
                    toast.success(
                      t('AccountSettings.profileUpdatedSuccessfully')
                    );
                    // getProfile().then(() => {
                    //   data =
                    //     response.data.tenant || response.data.consumer || {};
                    //   // dispatch({ type: 'SET_PROFILE', data: data });
                    //   // eslint-disable-next-line no-param-reassign
                    //   initialValues = data;
                    // });
                    context.LoadProfile();
                    setloading(false);
                  })
                  .catch((error: any) => {
                    setloading(false);
                    toast.error(
                      t('AccountSettings.thereIsAnErrorWhileUpdatingProfile')
                    );
                  });
              }}
              validationSchema={formSchema}
            >
              {({ values, touched, errors, resetForm, handleSubmit }) => {
                return (
                  <Form className='mt-2' onSubmit={handleSubmit}>
                    <Row>
                      <Col>
                        <Row>
                          <Col sm='6'>
                            <Field
                              component={renderField}
                              name='email'
                              label='Email'
                              className='form-control'
                              type='email'
                              meta={{
                                touched: touched.email,
                                error: errors.email,
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Field
                              component={renderField}
                              name='fname'
                              label={t('AccountSettings.fname')}
                              className='form-control'
                              type='text'
                              meta={{
                                touched: touched.fname,
                                error: errors.fname,
                              }}
                            />
                          </Col>

                          <Col sm='6'>
                            <Field
                              component={renderField}
                              name='lname'
                              label={t('AccountSettings.lname')}
                              className='form-control'
                              type='text'
                              meta={{
                                touched: touched.lname,
                                error: errors.lname,
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        className='mt-2 text-right justify-content-end d-flex'
                        sm='12'
                      >
                        <Button
                          color='secondary'
                          className='me-1'
                          outline
                          onClick={(e) => {
                            e.preventDefault();
                            resetForm();
                          }}
                        >
                          {t('AccountSettings.cancel')}
                        </Button>
                        <Button
                          type='submit'
                          color='primary'
                          disabled={loading}
                        >
                          {t('AccountSettings.saveChanges')}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default GeneralTabContent;
