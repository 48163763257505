import { useState } from 'react';
import { getLoggedinUser } from '../../helpers/api_helper';

const useProfile = () => {
  const userProfileSession = getLoggedinUser();
  const [loading] = useState(!userProfileSession);
  const [userProfile] = useState(userProfileSession);

  return { userProfile, loading };
};

export default useProfile;
export { useProfile };
