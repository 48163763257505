import React from 'react';
import { Container } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';

const Booking = () => {
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='BOOKINGS ' pageTitle='CPM' />
        Booking PAGE
      </Container>
    </div>
  );
};
export default Booking;
