import React from 'react';
import { Container as BContainer, Row, Col } from 'reactstrap';

interface ContainerProps {
  title: string | undefined;
  children: any | undefined;
  rightSide?: any;
  className?: string;
}

// display: flex;
//     flex-direction: row;
//     justify-content: flex-end;
//     align-items: flex-end;
const Container = ({
  title,
  rightSide,
  children,
  className,
}: ContainerProps) => {
  return (
    <div className={`${className} page-content`}>
      <BContainer fluid>
        <Row className='mb-2'>
          <Col>
            <h2 className='page-title'>{title}</h2>
          </Col>
          <Col> </Col>
          <Col className='d-flex  justify-content-end align-items-center '>
            {rightSide}
          </Col>
        </Row>

        {children}
      </BContainer>
    </div>
  );
};
Container.defaultProps = {
  rightSide: undefined,
  className: undefined,
};
export default Container;
