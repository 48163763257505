import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

interface SearchInputTypes {
  onFocus?: () => void;
  onChange: (e: string) => void;
  isFilter?: boolean;
}

const SearchInput = ({ onChange, onFocus, isFilter }: SearchInputTypes) => {
  const { t } = useTranslation();
  return isFilter ? (
    <div className='d-flex w-100'>
      <div className='form-icon right mt-auto w-100'>
        <Input
          onFocus={onFocus}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onChange(e.target.value);
          }}
          type='text'
          className='form-control form-control-icon search-input'
          id='iconrightInput'
          placeholder={t('search')}
        />
        <i className='ri-search-line' />
      </div>
    </div>
  ) : (
    <FormGroup className='d-flex w-100'>
      <div className='form-icon right mt-auto w-100'>
        <Input
          onFocus={onFocus}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onChange(e.target.value);
          }}
          type='text'
          className='form-control form-control-icon search-input'
          id='iconrightInput'
          placeholder={t('search')}
        />
        <i className='ri-search-line' />
      </div>
    </FormGroup>
  );
};

SearchInput.defaultProps = {
  onFocus: undefined,
  isFilter: false,
};

export default SearchInput;
