import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface ModalFormType {
  isOpen: boolean;
  size?: string | undefined;
  toggleModal: any;
  title?: string | undefined;
  children?: any | undefined;
  modalFooter?: any | undefined;
}

const ModalForm = ({
  isOpen,
  size,
  title,
  children,
  toggleModal,
  modalFooter,
}: ModalFormType) => {
  return (
    <Modal size={size || 'lg'} isOpen={isOpen}>
      <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>{modalFooter}</ModalFooter>
    </Modal>
  );
};

ModalForm.defaultProps = {
  size: 'lg',
  title: '',
  children: null,
  modalFooter: null,
};

export default ModalForm;
