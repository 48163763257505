import React, { useContext, useEffect, useState } from 'react';
import ModalForm from 'Components/Common/ModalForm';
import { Formik, Form, ErrorMessage } from 'formik';
import { Row, Col, Button, Label, FormGroup, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import EditContext from 'Components/Contexts/EditContext';
import { updatePrice } from 'actions';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import * as Yup from 'yup';
import Select from 'react-select';
import DataContext from 'Components/Contexts/DataContext';
import { ChargingRatesType } from 'declerations/DefaultTypes';

interface InitialValuesProps {
  applicationId: string;
  connectorId: string;
  chargePointId: string;
  rateId: string;
  applyParking: any;
}
const formSchema = Yup.object().shape({
  rateId: Yup.string().min(1, 'Required').required(i18n.t('Required')),
});

const UpdateTariff = ({
  isOpen,
  toggleModal,
}: {
  isOpen: boolean;
  toggleModal: any;
}) => {
  const { t } = useTranslation();
  const editContext = useContext(EditContext);
  const context = useContext(DataContext);
  const [check, setCheck] = useState();
  const [initialValues, setInitialValues] = useState<InitialValuesProps>({
    applicationId: '',
    connectorId: '',
    chargePointId: '',
    rateId: '',
    applyParking: '',
  });
  useEffect(() => {
    context.LoadChargingRates({
      applicationId: editContext?.editConnector?.applicationId,
    });
    const { applicationId, connectorId, chargePointId, rateId, applyParking } =
      editContext.editConnector;
    setInitialValues({
      applicationId,
      connectorId,
      chargePointId,
      rateId,
      applyParking,
    });
  }, []);

  return (
    <ModalForm
      isOpen={isOpen}
      toggleModal={toggleModal}
      size='md'
      title={t('AddChargingRate.editChargingRate')}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          const {
            chargePointId: id,
            connectorId,
            applicationId,
            ...newValues
          } = values;
          return updatePrice(id, connectorId, newValues)
            .then((response) => {
              toast.success(
                t('AddChargeStations.connectorRateChangedEditedSuccessfully')
              );
              return toggleModal();
            })
            .catch((err) =>
              toast.error(t('AddChargeStations.unabletoChangeConnectorRate'))
            );
        }}
        validationSchema={formSchema}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <label htmlFor='selec'>
                    {t('AddChargeStations.chargingRate')}
                  </label>
                  <Select
                    options={context.chargingRates}
                    value={context.chargingRates.find(
                      (x: ChargingRatesType) => x._id === values.rateId
                    )}
                    getOptionLabel={(x: ChargingRatesType) => x.title}
                    placeholder={t('Select')}
                    getOptionValue={(x: ChargingRatesType) => x._id}
                    onChange={(data) => {
                      setFieldValue('rateId', data?._id);
                    }}
                  />
                  <ErrorMessage
                    name='rateId'
                    component='div'
                    className='text-danger text-sm'
                  />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col>
                  <label htmlFor='applyParking' className='me-2'>
                    {t('AddChargeStations.applyParking')}
                  </label>
                  <FormGroup check inline>
                    <Input
                      className='form-check-input pt-1'
                      type='checkbox'
                      color='primary'
                      checked={values.applyParking}
                      onChange={(e: any) => {
                        setFieldValue('applyParking', e.target.checked);
                      }}
                    />
                    <Label>{t('AddChargeStations.yes')}</Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Input
                      className='form-check-input pt-1'
                      type='checkbox'
                      color='primary'
                      checked={!values.applyParking}
                      onChange={(e: any) => {
                        setFieldValue('applyParking', !e.target.checked);
                      }}
                    />
                    <Label>{t('AddChargeStations.no')}</Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col className='text-end'>
                  <Button
                    color='secondary'
                    type='button'
                    disabled={isSubmitting}
                    onClick={toggleModal}
                    className='me-1'
                  >
                    {t('AddChargeStations.cancel')}
                  </Button>
                  <Button color='primary' type='submit' disabled={isSubmitting}>
                    {t('AddChargeStations.edit')}
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </ModalForm>
  );
};

export default UpdateTariff;
