import React from 'react';

const InfoComp = () => {
  return (
    <div className='page-content'>
      <div className='container'>
        <h1 className='page-title-box'>
          Hello Welcome to Chargee Dashboard Element
        </h1>
        <div className='section'>
          <h2>Buttons</h2>
          <p style={{ fontSize: 17, marginBottom: 50 }}>
            Use Chargee custom button styles for actions in forms, dialogs, and
            more with support for multiple sizes, states, and more.
          </p>

          <div className='d-flex justify-content-between container'>
            <div>
              <h4>Primary Button</h4>
              <p>
                <mark>.gio_primary_button</mark>
              </p>
              <button type='button' className='gio_primary_button btn'>
                CONTACT
              </button>
              <br />
              <h4>Disabled Version</h4>
              <button type='button' disabled className='gio_primary_button btn'>
                CONTACT
              </button>
            </div>
            <div>
              <h4>Secondary Default Button</h4>
              <p>
                <mark>.gio_secondary_default_button</mark>
              </p>
              <button
                type='button'
                className='gio_secondary_default_button btn'
              >
                CONTACT
              </button>
              <br />
              <h4>Disabled Version</h4>
              <button
                type='button'
                disabled
                className='gio_secondary_default_button btn'
              >
                CONTACT
              </button>
            </div>
            <div>
              <h4>Secondary White Button</h4>
              <p>
                <mark>.gio_secondary_white_button</mark>
              </p>
              <button type='button' className='gio_secondary_white_button btn'>
                CONTACT
              </button>
              <br />
              <h4>Disabled Version</h4>
              <button
                type='button'
                disabled
                className='gio_secondary_white_button btn'
              >
                CONTACT
              </button>
            </div>
            <div>
              <h4>Secondary Dark Button</h4>
              <p>
                <mark>.gio_secondary_dark_button </mark>
              </p>
              <button type='button' className='gio_secondary_dark_button btn'>
                CONTACT
              </button>
              <br />
              <h4>Disabled Version</h4>
              <button
                type='button'
                disabled
                className='gio_secondary_dark_button btn'
              >
                CONTACT
              </button>
            </div>
          </div>
        </div>
        <div className='section'>
          <h2>Box Shadow</h2>
          <p style={{ fontSize: 17 }}>
            Use Chargee custom button styles for actions in forms, dialogs, and
            more with support for multiple sizes, states, and more.
          </p>

          <h4>Primary Shadow</h4>
          <p>
            <mark>.bg-shadow</mark>
          </p>
          <div className='box bg-shadow'>&nbsp;</div>
        </div>
        <div className='section'>
          <h2>Box Radius</h2>
          <p style={{ fontSize: 17 }}>
            Use Chargee custom button styles for actions in forms, dialogs, and
            more with support for multiple sizes, states, and more.
          </p>

          <div className='d-flex justify-content-between container'>
            <div>
              <h4>Full Radius</h4>
              <p>
                <mark>.radius-full</mark>
              </p>
              <div className='box_sqaure radius-full'>&nbsp;</div>
            </div>
            <div>
              <h4>Top Left to Bottom Right Radius</h4>
              <p>
                <mark>.radius-tl-br</mark>
              </p>
              <div className='box_sqaure radius-tl-br'>&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoComp;
