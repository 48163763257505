import React from 'react';
import { FormGroup } from 'reactstrap';

interface SelectBoxTypes {
  onChange: (e: string) => void;
  children: any;
  label: string | undefined;
  value: string | undefined;
}

const SelectBox = ({ onChange, children, label, value }: SelectBoxTypes) => {
  return (
    <FormGroup className='form-group'>
      {label && (
        <label htmlFor={label} className='label'>
          {label}
        </label>
      )}
      <select
        id={label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className='form-select mb-3'
        aria-label='Select'
      >
        {children}
      </select>
    </FormGroup>
  );
};

export default SelectBox;
