/* eslint no-param-reassign: ["off", { "props": false }] */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  layoutTypes,
  leftSidebarTypes,
  layoutModeTypes,
  layoutWidthTypes,
  layoutPositionTypes,
  topbarThemeTypes,
  leftsidbarSizeTypes,
  leftSidebarViewTypes,
} from '../../Components/constants/layout';

interface LayoutState {
  layoutType: string;
  leftSidebarType: string;
  layoutModeType: string;
  layoutWidthType: string;
  layoutPositionType: string;
  topbarThemeType: string;
  leftsidbarSizeType: string;
  leftSidebarViewType: string;
}

const initialState: LayoutState = {
  layoutType: layoutTypes.VERTICAL,
  leftSidebarType: leftSidebarTypes.DARK,
  layoutModeType: layoutModeTypes.LIGHTMODE,
  layoutWidthType: layoutWidthTypes.FLUID,
  layoutPositionType: layoutPositionTypes.FIXED,
  topbarThemeType: topbarThemeTypes.LIGHT,
  leftsidbarSizeType: leftsidbarSizeTypes.DEFAULT,
  leftSidebarViewType: leftSidebarViewTypes.DEFAULT,
};

const changeHTMLAttribute = (attribute: string, value: string) => {
  if (document.documentElement)
    document.documentElement.setAttribute(attribute, value);
  return true;
};

export const changeLayout = createAsyncThunk(
  'layout/changeLayout',
  async (layout: string) => {
    if (layout === 'twocolumn') {
      document.documentElement.removeAttribute('data-layout-width');
    } else if (layout === 'horizontal') {
      document.documentElement.removeAttribute('data-sidebar-size');
    }
    changeHTMLAttribute('data-layout', layout);
    return layout;
  }
);

export const changeLayoutMode = createAsyncThunk(
  'layout/changeLayoutMode',
  async (mode: string) => {
    changeHTMLAttribute('data-layout-mode', mode);
    return mode;
  }
);

export const changeSidebarTheme = createAsyncThunk(
  'layout/changeLeftSidebarTheme',
  async (theme: string) => {
    changeHTMLAttribute('data-sidebar', theme);
    return theme;
  }
);

export const changeLayoutWidth = createAsyncThunk(
  'layout/changeLayoutWidth',
  async (layoutWidth: string) => {
    if (layoutWidth === 'lg') {
      changeHTMLAttribute('data-layout-width', 'fluid');
    } else {
      changeHTMLAttribute('data-layout-width', 'boxed');
    }
    return layoutWidth;
  }
);

export const changeLayoutPosition = createAsyncThunk(
  'layout/changeLayoutPosition',
  async (layoutposition: string) => {
    changeHTMLAttribute('data-layout-position', layoutposition);
    return layoutposition;
  }
);
export const changeTopbarTheme = createAsyncThunk(
  'layout/changeTopbarTheme',
  async (topbarTheme: string) => {
    changeHTMLAttribute('data-topbar', topbarTheme);
    return topbarTheme;
  }
);
export const changeLeftsidebarSizeType = createAsyncThunk(
  'layout/changeLeftsidebarSizeType',
  async (leftsidebarSizetype: string) => {
    switch (leftsidebarSizetype) {
      case 'lg':
        changeHTMLAttribute('data-sidebar-size', 'lg');

        break;
      case 'md':
        changeHTMLAttribute('data-sidebar-size', 'md');
        break;
      case 'sm':
        changeHTMLAttribute('data-sidebar-size', 'sm');
        break;
      case 'sm-hover':
        changeHTMLAttribute('data-sidebar-size', 'sm-hover');
        break;
      default:
        changeHTMLAttribute('data-sidebar-size', 'lg');
    }
    return leftsidebarSizetype;
  }
);

export const changeLeftsidebarViewType = createAsyncThunk(
  'layout/changeLeftSidebarViewType',
  async (leftsidebarViewtype: string) => {
    changeHTMLAttribute('data-layout-style', leftsidebarViewtype);
    return leftsidebarViewtype;
  }
);

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    resetValue: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeLayout.fulfilled, (state, { payload }) => {
      state.layoutType = payload;
    });
    builder.addCase(changeLayoutMode.fulfilled, (state, { payload }) => {
      state.layoutModeType = payload;
    });
    builder.addCase(changeSidebarTheme.fulfilled, (state, { payload }) => {
      state.leftSidebarType = payload;
    });
    builder.addCase(changeLayoutWidth.fulfilled, (state, { payload }) => {
      state.layoutWidthType = payload;
    });
    builder.addCase(changeLayoutPosition.fulfilled, (state, { payload }) => {
      state.layoutPositionType = payload;
    });
    builder.addCase(changeTopbarTheme.fulfilled, (state, { payload }) => {
      state.topbarThemeType = payload;
    });
    builder.addCase(
      changeLeftsidebarSizeType.fulfilled,
      (state, { payload }) => {
        state.leftsidbarSizeType = payload;
      }
    );
    builder.addCase(
      changeLeftsidebarViewType.fulfilled,
      (state, { payload }) => {
        state.leftSidebarViewType = payload;
      }
    );
  },
});

export const { resetValue } = layoutSlice.actions;

export default layoutSlice.reducer;
