import React, { useEffect, useState, useMemo } from 'react';

import { ProfileType } from 'declerations/DefaultTypes';
import { getProfile } from 'actions';
import { Icon as FeatherIcon } from 'ts-react-feather-icons';

const menuItemContext = React.createContext<any>(null);

export const MenuItemProvider = ({ children }: { children: any }) => {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<ProfileType[] | undefined>();
  const values = useMemo(() => ({ profile }), [profile]);
  const menuItems = [
    {
      label: 'Menu',
      isHeader: true,
    },
    {
      id: 'overview',
      label: 'Overview',
      icon: <FeatherIcon name='home' />,
      link: '/overview',
    },
    {
      id: 'cpo',
      label: 'CPO',
      type: 'item',
      icon: <FeatherIcon name='battery-charging' />,
      // permissions: ['admin', 'person'],
      // link: '/cpo/charge-station',
      // stateVariables: true,
      // click: function (e: any) {
      //   e.preventDefault();
      //   setIsCpo(!isCpo);
      //   setIscurrentState('Cpo');
      //   updateIconSidebar(e);
      // },
      subItems: [
        {
          id: 'chargestation',
          label: 'Charge Stations',
          link: '/cpo/charge-station',
          parentId: 'Cpms',
        },
        {
          id: 'CharginRate',
          label: 'Tariffs.tariffs',
          link: '/cpo/tariffs',
        },
        {
          id: 'alarms',
          label: 'Alarms',
          type: 'item',
          link: '/cpo/alarms',
        },
        {
          id: 'charging-site',
          label: 'Charging Site',
          parentId: 'Cpms',
          link: '/cpo/charging-site',
        },
        {
          id: 'authorization',
          label: 'Authorization',
          parentId: 'Cpms',
          link: '/cpo/authorization',
        },
        {
          id: 'transaction',
          label: 'Transactions',
          type: 'item',
          link: '/cpo/transaction',
        },
        {
          id: 'report',
          label: 'Report',
          type: 'item',
          link: '/cpo/report',
        },
      ],
    },
    {
      id: 'cpms',
      label: 'E-MSP',
      type: 'item',
      icon: <FeatherIcon name='list' />,
      // permissions: ['admin', 'person'],
      // link: '/cpos',
      // stateVariables: true,
      // isChildItem: true,

      subItems: [
        {
          id: 'emra',
          label: 'Task Config',
          link: '/emra',
          parentId: 'Cpms',
        },
        {
          id: 'report',
          label: 'Report',
          type: 'item',
          link: '/report',
        },
        {
          id: 'cpos',
          label: 'Charge Point Operators',
          link: '/cpos',
          parentId: 'Cpms',
        },
        // {
        //   id: 'bookings',
        //   label: 'Bookings',
        //   link: '/bookings',
        //   parentId: 'Cpms',
        // },
        {
          id: 'payments',
          label: 'CDR',
          type: 'item',
          link: '/CDR',
        },
        // {
        //   id: 'session',
        //   label: 'session',
        //   link: '/session',
        // },
        {
          id: 'permission',
          label: 'Roles',
          type: 'item',
          link: '/roles',
        },

        {
          id: 'crm',
          label: 'CRM',
          // link: '/drivers',
          isChildItem: true,
          icon: <FeatherIcon name='battery-charging' />,
          parentId: 'cpms',
          // click: function (e: any) {
          //   e.preventDefault();
          //   setIsCrm(!isCrm);
          //   setIscurrentState('Crm');
          // },
          stateVariables: true,
          childItems: [
            {
              id: 'drivers',
              label: 'Drivers',
              link: '/drivers',
            },
            {
              id: 'vehicles',
              label: 'Vehicles',
              link: '/vehicles',
            },
            {
              id: 'employees',
              label: 'Employees',
              link: '/employees',
            },
          ],
        },
      ],
    },
  ];
  useEffect(() => {
    const setDataFunc = async () => {
      const asyncData = await getProfile();
      setProfile(asyncData?.data?.tenant || asyncData?.data?.consumer);
    };
    setDataFunc();
    setLoading(false);
  }, []);
  return (
    <>
      {' '}
      {!loading && (
        <menuItemContext.Provider value={values}>
          {children}
        </menuItemContext.Provider>
      )}
    </>
  );
};

export default menuItemContext;
