import flagus from '../assets/images/flags/us.svg';
import flagtr from '../assets/images/flags/turkey.svg';

const languages = {
  tr: {
    label: 'Türkçe',
    flag: flagtr,
  },
  en: {
    label: 'English',
    flag: flagus,
  },
  // 'en-Us': {
  //   label: 'English',
  //   flag: flagus,
  // },
};

export default languages;
