import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { DriverType } from 'declerations/DefaultTypes';
import CardInfoText from 'Components/Common/CardInfoText';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Spinner,
} from 'reactstrap';
import DataContext from 'Components/Contexts/DataContext';
import 'moment/locale/tr';
import 'moment/locale/en-nz';
import moment from 'moment';
import i18n from '../../../../../i18n';

const DriverInfo = (props: { driver: DriverType }) => {
  // const { driver, driverId, editData } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const { driver } = props;
  const context = useContext(DataContext);

  return driver ? (
    <Card className='mt-2 '>
      <CardHeader>
        <CardTitle>{t('Driver.driverInfo')}</CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <ul className='list-group list-group-flush'>
              <CardInfoText title={t('Driver.email')} value={driver.email} />
              <CardInfoText
                title={t('Driver.firstName')}
                value={driver.fname}
              />
              <CardInfoText title={t('Driver.lastName')} value={driver.lname} />

              <CardInfoText title={t('DriverAdd.phone')} value={driver.phone} />
              <CardInfoText
                title={t('Driver.dob')}
                value={
                  i18n.language === 'en'
                    ? moment(driver.dob).format('lll').toString()
                    : moment(driver.dob).locale('tr').format('lll').toString()
                }
              />
              <CardInfoText
                title={t('AddChargingRate.application')}
                value={driver.application?.name}
              />
            </ul>
          </Col>
          <Col>
            <ul className='list-group list-group-flush'>
              <CardInfoText
                title={t('DriverAdd.invoiceType')}
                value={t(driver.invoiceType)}
              />
              {driver.invoiceType === 'Company' ? (
                <>
                  <CardInfoText
                    title={t('DriverAdd.companyName')}
                    value={driver.companyName ? t(driver.companyName) : ''}
                  />
                  <CardInfoText
                    title={t('Driver.taxCity')}
                    value={driver.taxCity ? t(driver.taxCity) : ''}
                  />
                  <CardInfoText
                    title={t('DriverAdd.taxOffice')}
                    value={driver.taxOffice ? t(driver.taxOffice) : ''}
                  />
                  <CardInfoText
                    title={t('DriverAdd.taxNumber')}
                    value={
                      driver.invoiceType === 'Company' && driver.taxNumber
                        ? t(driver.taxNumber)
                        : driver.identityNumber
                        ? t(driver.identityNumber)
                        : ''
                    }
                  />
                </>
              ) : (
                <>
                  <CardInfoText
                    title={t('DriverAdd.identityNumber')}
                    value={
                      driver.identityNumber ? t(driver.identityNumber) : ''
                    }
                  />
                  <CardInfoText
                    title={t('DriverAdd.consumerType')}
                    value={
                      driver.consumerType === 'SERVICE'
                        ? t('DriverAdd.service')
                        : t('DriverAdd.personal')
                    }
                  />
                </>
              )}
            </ul>
          </Col>
        </Row>
      </CardBody>
    </Card>
  ) : (
    <Spinner />
  );
};

export default withTranslation()(DriverInfo);
