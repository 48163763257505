import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import { renderField } from 'Components/Common/Fields';
import { toast } from 'react-toastify';
import EditContext from 'Components/Contexts/EditContext';
import SlidingModal from 'Components/Common/SlidingModal';
import {
  Button,
  Col,
  Row,
  Spinner,
} from 'reactstrap';
import { editIntegrationTaskConfig } from 'actions';
import {IntegrationTaskConfigType} from "../../declerations/DefaultTypes";
import getValidationSchema from "../../helpers/YupHelpers";

interface AddEmployeeProps {
  isOpen: boolean;
  isEditing: boolean;
  toggleHandle: () => void;
}

const EditIntegrationTaskConfig = ({ isOpen, toggleHandle }: AddEmployeeProps) => {
  const editContext = useContext(EditContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [formSchema, setFormSchema] = useState(null);
  const [initialValues, setInitialValues] = useState<IntegrationTaskConfigType>({
    taskType: "",
    title: '',
    configData: {}
  });

  useEffect(() => {
    setLoading(true);
    const {
      title = '',
      taskType = '',
      configData = {},
    } = editContext.editIntegrationTaskConfigType ? editContext.editIntegrationTaskConfigType : {};

    setInitialValues({
      taskType,
      title,
      configData
    });

    const fields = [
      {
        name: 'title',
        label: t('integrationTaskConfigs.title'),
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Required']
          }
        ]
      }
    ]

    Object.keys(editContext.editIntegrationTaskConfigType?.configData).forEach((key, index) => {
      fields.push({
        name: `configData.${key}`,
        label: key,
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['Required']
          }
        ]
      });
    });

    const validator = getValidationSchema(fields);
    setFormSchema(validator);

    setLoading(false);
  }, [isOpen, editContext.editIntegrationTaskConfigType]);


  const getFormikElement = (type: any, key: any, obj: any) => {
    if ( type?.configData && Object.prototype.hasOwnProperty.call(type?.configData, obj)) {
      return type?.configData[obj];
    }
    return false;
  };

  return (
      <SlidingModal
          toggleHandle={toggleHandle}
          isOpen={isOpen}
          title={
            t('integrationTaskConfigs.editIntegrationTaskConfigs')
          }
          size='md'
      >
        {!loading && isOpen ? (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={async (values) => {
                  const { taskType, title, configData } = values;
                  return editIntegrationTaskConfig(taskType, { title, configData })
                        .then((response) => {
                          toast.success(t('integrationTaskConfigs.editedSuccessFully'));
                          return toggleHandle();
                        })
                        .catch((err) =>
                            toast.error(t('integrationTaskConfigs.unableToEdited'))
                        );
                }}
                validationSchema={formSchema}
            >
              {({
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                }) => {
                return (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col>
                          <Field
                              component={renderField}
                              name='title'
                              label={t('integrationTaskConfigs.title')}
                              className='form-control'
                              type='text'
                              meta={{
                                touched: touched.title,
                                error: errors.title,
                              }}
                          />
                        </Col>
                      </Row>

                      <h6>Dinamik parametreler</h6>
                      <div>
                        {initialValues &&
                            initialValues?.configData !== null &&
                            initialValues?.configData &&
                            Object.keys(initialValues?.configData).map(
                                (key, index) => (
                                    <div key={index.toString()}>
                                      <Field
                                          component={renderField}
                                          name={`configData[${key}]`}
                                          label={t(key)}
                                          className='form-control'
                                          type='text'
                                          meta={{
                                            touched: getFormikElement(touched, key, `${key}`),
                                            error: getFormikElement(errors, key, `${key}`),
                                          }}
                                      />
                                    </div>
                                )
                            )}
                      </div>

                      <Row className='mt-3'>
                        <Col className='text-end'>
                          <Button
                              color='secondary'
                              type='button'
                              disabled={isSubmitting}
                              onClick={toggleHandle}
                              className='me-1'
                          >
                            {t('integrationTaskConfigs.cancel')}
                          </Button>
                          <Button
                              color='primary'
                              type='submit'
                              disabled={isSubmitting}
                          >
                              { t('integrationTaskConfigs.edit')}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                );
              }}
            </Formik>
        ) : (
            <Spinner />
        )}
      </SlidingModal>
  );
};

export default EditIntegrationTaskConfig;
