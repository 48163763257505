import { APIClient } from '../helpers/api_helper';

const api = new APIClient();

export const login = (data: { email: string; password: string }) => {
  return api.create('login', data).catch((err) => {
    let message;
    const { response } = err;
    const { status } = response;
    if (response && status) {
      switch (status) {
        case 400:
          message = response?.data?.error;
          break;
        case 404:
          message = 'Sorry! the page you are looking for could not be found';
          break;
        case 500:
          message =
            'Sorry! something went wrong, please contact our support team';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = err[1];
          break;
      }
    }
    throw message;
  });
};

export default login;
