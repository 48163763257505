import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { renderField, renderSelectField } from 'Components/Common/Fields';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import EditContext from 'Components/Contexts/EditContext';
import SlidingModal from 'Components/Common/SlidingModal';
import i18n from 'i18next';
import DataContext from 'Components/Contexts/DataContext';
import {
  Button,
  Col,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap';
import { ApplicationsType } from 'declerations/DefaultTypes';
import moment from 'moment';
import classnames from 'classnames';
import InputPasswordToggle from 'Components/Common/InputPasswordToggle';
import { createEmployee, editEmployee } from 'actions';

const formSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .email(i18n.t('Must be proper email'))
      .min(1, 'Required')
      .required(i18n.t('Required')),
    fname: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    lname: Yup.string().min(1, 'Required').required(i18n.t('Required')),
    retypePassword: Yup.string().test(
      'retypePassword',
      i18n.t('AccountSettings.passwordsMustMath'),
      function (value) {
        const { password } = this.parent;
        // eslint-disable-next-line eqeqeq
        if (password === value || (!value && !password)) {
          return true;
        }
        return false;
      }
    ),
    password: Yup.string().test(
      'password',
      i18n.t('AccountSettings.passwordsMustMath'),
      function (value) {
        const { retypePassword } = this.parent;

        // eslint-disable-next-line eqeqeq
        if (retypePassword === value || (!value && !retypePassword)) {
          return true;
        }
        return false;
      }
    ),
  });

interface AddEmployeeProps {
  isOpen: boolean;
  isEditing: boolean;
  toggleHandle: () => void;
}

const AddEmployee = ({ isOpen, toggleHandle, isEditing }: AddEmployeeProps) => {
  const context = useContext(DataContext);
  const editContext = useContext(EditContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    email: '',
    password: '',
    fname: '',
    lname: '',
    retypePassword: '',
  });

  useEffect(() => {
    setLoading(true);
    if (isEditing) {
      const {
        email = '',
        password = '',
        fname = '',
        lname = '',
      } = isEditing ? editContext.editEmployee : {};

      setInitialValues({
        email,
        password: '',
        fname,
        lname,
        retypePassword: '',
      });
    } else {
      setInitialValues({
        email: '',
        password: '',
        fname: '',
        lname: '',
        retypePassword: '',
      });
    }
    setLoading(false);
  }, [isEditing, context.editEmployee]);

  return (
    <SlidingModal
      toggleHandle={toggleHandle}
      isOpen={isOpen}
      title={
        isEditing ? t('AddEmployes.editEmployee') : t('AddEmployes.addEmployee')
      }
      size='md'
    >
      {!loading && isOpen ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values) => {
            if (isEditing) {
              const { retypePassword, ...newValues } = values;
              const { _id: id } = editContext.editEmployee;
              if (retypePassword) {
                return editEmployee(id, { ...newValues })
                  .then((response) => {
                    toast.success(t('AddEmployes.employeeEditedSuccessFully'));
                    return toggleHandle();
                  })
                  .catch((err) =>
                    toast.error(t('AddEmployes.unableToEditedEmployee'))
                  );
              }
              return editEmployee(id, { ...newValues, password: undefined })
                .then((response) => {
                  toast.success(t('AddEmployes.employeeEditedSuccessFully'));
                  return toggleHandle();
                })
                .catch((err) =>
                  toast.error(t('AddEmployes.unableToEditedEmployee'))
                );
            }
            await setInitialValues({
              email: '',
              password: '',
              fname: '',
              lname: '',
              retypePassword: '',
            });
            return createEmployee(values)
              .then((response) => {
                toast.success(t('AddEmployes.employeeCreatedSuccessfully'));
                return toggleHandle();
              })
              .catch((err) =>
                toast.error(t('AddEmployes.unabletoCreateEmployee'))
              );
          }}
          validationSchema={formSchema}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldTouched,
          }) => {
            useMemo(
              () =>
                Object.keys(errors).forEach((fieldName) => {
                  setFieldTouched(fieldName);
                }),
              [i18n.language]
            );
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Field
                      component={renderField}
                      name='email'
                      label={t('AddEmployes.email')}
                      className='form-control'
                      type='email'
                      meta={{
                        touched: touched.email,
                        error: errors.email,
                      }}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={renderField}
                      name='fname'
                      label={t('AddEmployes.name')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.fname,
                        error: errors.fname,
                      }}
                    />
                  </Col>
                  <Col>
                    <Field
                      component={renderField}
                      name='lname'
                      label={t('AddEmployes.lname')}
                      className='form-control'
                      type='text'
                      meta={{
                        touched: touched.lname,
                        error: errors.lname,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <InputPasswordToggle
                        label={t('AddEmployes.password')}
                        htmlFor='password'
                        name='password'
                        onChange={handleChange}
                        className={classnames('input-group-merge', {
                          'is-invalid': errors.password,
                        })}
                        meta={{
                          touched: touched.password,
                          error: errors.password,
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <InputPasswordToggle
                        label={t('AccountSettings.retypeNewPassword')}
                        htmlFor='retypePassword'
                        name='retypePassword'
                        onChange={handleChange}
                        className={classnames('input-group-merge', {
                          'is-invalid': errors.retypePassword,
                        })}
                        meta={{
                          touched: touched.retypePassword,
                          error: errors.retypePassword,
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col className='text-end'>
                    <Button
                      color='secondary'
                      type='button'
                      disabled={isSubmitting}
                      onClick={toggleHandle}
                      className='me-1'
                    >
                      {t('AddEmployes.cancel')}
                    </Button>
                    <Button
                      color='primary'
                      type='submit'
                      disabled={isSubmitting}
                    >
                      {isEditing ? t('AddEmployes.edit') : t('AddEmployes.add')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Spinner />
      )}
    </SlidingModal>
  );
};

export default AddEmployee;
