import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SlidingModal from 'Components/Common/SlidingModal';
import { ConnectorType } from 'declerations/DefaultTypes';
import {
  Col,
  Row,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Badge,
  Button,
} from 'reactstrap';
import EditContext from 'Components/Contexts/EditContext';
import { Map, Info } from './Widgets';
import ConnectorCards from './Widgets/ConnectorCards';
import ChargeConnection from './SubPages/Overview/widgets/Connectors/ChargeConnection';

interface ChargeStationsDetailsProps {
  isOpen: boolean;
  toggleHandle: (isOpen: boolean) => void;
}

export const ChargeStationsDetails = ({
  isOpen,
  toggleHandle,
}: ChargeStationsDetailsProps) => {
  const { t } = useTranslation();
  const editContext = useContext(EditContext);
  const selectedChargeStation = editContext.editChargeStation;
  const history = useHistory();
  const openFullDetailPage = () => {
    history.push(`/cpo/charge-station/${selectedChargeStation.chargePointId}`);
  };

  const [connectionModal, setConnectionModal] = useState(false);

  return (
    <SlidingModal
      toggleHandle={toggleHandle}
      title={selectedChargeStation.name}
      isOpen={isOpen}
    >
      <div>
        <Card>
          <CardBody>
            {isOpen && <Map station={selectedChargeStation} />}
          </CardBody>
          <CardFooter>
            <Button
              color='primary'
              aria-hidden='true'
              onClick={() => openFullDetailPage()}
              className='w-100 btn btn-primary'
            >
              {t('openFullDetailsPage')}
            </Button>
          </CardFooter>
          <CardFooter>
            <Info station={selectedChargeStation} />
            <Row>
              <Col>
                <Button
                  className='w-100 m-auto mt-4'
                  color='primary'
                  onClick={() => setConnectionModal(!connectionModal)}
                >
                  <span className='align-middle '>
                    {t('ChargeConnection.ConnectToChargeStation')}
                  </span>
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>{t('ChargeStationDetail.connectors')}</CardTitle>
          </CardHeader>
          <CardBody>
            {selectedChargeStation?.connectors.map(
              (connector: ConnectorType, index: number) => (
                <ConnectorCards
                  key={index.toString()}
                  connectors={connector}
                  station={selectedChargeStation}
                />
              )
            )}
          </CardBody>
        </Card>
      </div>
      {connectionModal && (
        <ChargeConnection
          isOpen={connectionModal}
          toggleModal={() => setConnectionModal(!connectionModal)}
          chargePoint={selectedChargeStation}
        />
      )}
    </SlidingModal>
  );
};

export default ChargeStationsDetails;
