import { capitalCase } from 'change-case';
import moment from 'moment';
import ExcelJS from 'exceljs';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import download from 'downloadjs';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { font, boldFont } from './Roboto-Regular-normal';

const customTitleStripRegex = /[^A-Z0-9/.]/gi;

const TICK_DURATION = 15;
const delay = (ms = TICK_DURATION) =>
  // eslint-disable-next-line no-promise-executor-return
  new Promise((resolve) => setTimeout(resolve, ms));
export const lazyPDFGenerator = async (
  data: any,
  headers: any,
  name: string,
  size?: number[],
) => {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF('l', 'mm', size);
  doc.addFileToVFS('Roboto-Regular-normal.ttf', font);
  doc.addFont('Roboto-Regular-normal.ttf', 'Roboto', 'normal');
  doc.setFont('Roboto', 'normal');
  doc.addFileToVFS('Roboto-Regular-bold.ttf', boldFont);
  doc.addFont('Roboto-Regular-bold.ttf', 'Roboto', 'bold');
  const head = headers.map(({ value }: any) =>
    i18n.t(`${'ExcelExport'}.${value}`)
  );
  const body =
    data &&
    data.map((entry: any) =>
      headers.map(({ key: joinedKey, mapper }: { key: any; mapper: any }) => {
        if (mapper) {
          return mapper(entry);
        }
        const keys = joinedKey.split('.');
        return (
          keys.reduce((acc: any, key: any) => (acc || {})[key], entry) || ''
        );
      })
    );
  await delay();
  autoTable(doc, {
    head: [head],
    body,
    theme: 'grid',
    styles: {
      font: 'Roboto',
    },
  });
  await delay();
  doc.save(`${name}_${moment().format('YYYY-MM-DD_HH:mm:ss')}.pdf`);
};

export const lazyExcelGenerator = async (
  data: any,
  headers: any,
  name: string
) => {
  const fileName = `${name}_${moment().format('YYYY-MM-DD_HH:mm:ss')}.xlsx`;
  const excelTitle = 'ee';
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet(
    capitalCase(excelTitle, { stripRegexp: customTitleStripRegex })
  );
  sheet.columns = headers.map(({ key, value }: { key: any; value: any }) => ({
    header: i18n.t(`${'ExcelExport'}.${value}`),
    key,
    width: 20,
  }));
  await delay();
  const rows = data.map((entry: any) =>
    headers.map(({ key: joinedKey, mapper }: { key: any; mapper: any }) => {
      if (mapper) {
        return mapper(entry);
      }
      const keys = joinedKey.split('.');
      return keys.reduce((acc: any, key: any) => (acc || {})[key], entry) || '';
    })
  );
  await delay();
  sheet.addRows(rows);
  const buffer = await workbook.xlsx.writeBuffer();
  await delay();
  return download(new Blob([buffer]), fileName, 'application/vnd.ms-excel;');
};
