/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, UncontrolledTooltip, Spinner } from 'reactstrap';
import { renderField } from 'Components/Common/Fields';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import DataTable from 'Components/Common/DataTable';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import DataContext from 'Components/Contexts/DataContext';
import Container from 'Components/Common/Container';
import { ChargeStationType } from 'declerations/DefaultTypes';
import getSocketSvg from 'helpers/Socket';
import {
  getEmspEmras,
  getEmspChargePoints,
  editEmspChargePoints,
} from 'actions';

const formSchema = Yup.object().shape({
  authUrl: Yup.string(),
  baseUrl: Yup.string(),
  password: Yup.string(),
  username: Yup.string(),
  integrationURL: Yup.string(),
  integrationUsername: Yup.string(),
  integrationPassword: Yup.string(),
  dagSuffix: Yup.string(),
  stationNumber: Yup.string(),
  connectors: Yup.array().of(
    Yup.object().shape({
      socketNumber: Yup.string(),
    })
  ),
});
const TaskConfig = () => {
  const context = useContext(DataContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const [check, setCheck] = useState(false);
  const [mappedData, setMappedData] = useState<any>();

  const dataMapper = (emspChargePoints: any, MockDatas: any) => {
    const mapData = emspChargePoints;
    if (MockDatas && MockDatas?.stations && MockDatas?.stations?.length > 0) {
      mapData['authUrl'] =
        MockDatas['authUrl'] != null ? MockDatas['authUrl'] : '';
      mapData['baseUrl'] =
        MockDatas['baseUrl'] != null ? MockDatas['baseUrl'] : '';
      mapData['password'] =
        MockDatas['password'] != null ? MockDatas['password'] : '';
      mapData['username'] =
        MockDatas['username'] != null ? MockDatas['username'] : '';

      mapData['integrationURL'] = MockDatas['integrationURL'] != null ? MockDatas['integrationURL'] : '';
      mapData['integrationUsername'] = MockDatas['integrationUsername'] != null ? MockDatas['integrationUsername'] : '';
      mapData['integrationPassword'] = MockDatas['integrationPassword'] != null ? MockDatas['integrationPassword'] : '';
      mapData['dagSuffix'] = MockDatas['dagSuffix'] != null ? MockDatas['dagSuffix'] : '';

      mapData['type'] = MockDatas['type'] != null ? MockDatas['type'] : '';
      mapData &&
        mapData?.chargePoints?.length > 0 &&
        mapData?.chargePoints.forEach((e: any) => {
          if (MockDatas?.stations && MockDatas?.stations?.length > 0) {
            MockDatas.stations.forEach((emra: any) => {
              if (e.chargePointId === emra.cpId) {
                e.stationNumber = emra.cpVal;
                emra.s.forEach((emraCon: any) => {
                  e.connectors.forEach((con: any) => {
                    if (con.connectorId == emraCon.sId) {
                      con.socketNumber = emraCon.sVal;
                    }
                  });
                });
              }
            });
            setMappedData(mapData);
            setCheck(true);
          }
        });
    } else {
      mapData['authUrl'] = '';
      mapData['baseUrl'] = '';
      mapData['password'] = '';
      mapData['username'] = '';
      mapData['integrationURL'] = '';
      mapData['integrationUsername'] = '';
      mapData['integrationPassword'] = '';
      mapData['dagSuffix'] = '';
      mapData['type'] = 'EPDK';

      setMappedData(mapData);
      setCheck(true);
    }
  };

  useEffect(() => {
    const populateArray = async () => {
      const chargePoints = await getEmspChargePoints();
      const emra = await getEmspEmras();
      setLoading(false);
      dataMapper(chargePoints?.data, emra?.data);
    };

    populateArray();
    return () => {};
  }, []);

  return (
    <Container title={t('Emra EPDK Config')}>
      {check ? (
        <div>
          <Formik
            enableReinitialize
            initialValues={mappedData}
            validationSchema={formSchema}
            onSubmit={async (values: any) => {
              let postStructure: any = {};
              postStructure = {
                authUrl: values.authUrl == undefined ? '' : values.authUrl,
                baseUrl: values.baseUrl == undefined ? '' : values.baseUrl,
                password: values.password == undefined ? '' : values.password,
                type: values.type == undefined ? '' : values.type,
                username: values.username == undefined ? '' : values.username,
                integrationURL: values.integrationURL == undefined ? '' : values.integrationURL,
                integrationUsername: values.integrationUsername == undefined ? '' : values.integrationUsername,
                integrationPassword: values.integrationPassword == undefined ? '' : values.integrationPassword,
                dagSuffix: values.dagSuffix == undefined ? '' : values.dagSuffix,
              };
              postStructure = { ...postStructure, stations: [] };
              values.chargePoints.forEach((val: any, i: number) => {
                postStructure.stations.push({
                  cpId: val.chargePointId,
                  cpVal: val.stationNumber,
                });
                if (val.connectors.length > 0) {
                  postStructure.stations[i] = {
                    ...postStructure.stations[i],
                    s: [],
                  };
                  val?.connectors?.forEach((con: any, index: number) => {
                    if (
                      con.connectorId != 'null' &&
                      con.socketNumber != 'null'
                    ) {
                      postStructure?.stations[i]['s'].push({
                        sId: con?.connectorId,
                        sVal: con?.socketNumber,
                      });
                    }
                  });
                }
              });
              editEmspChargePoints(postStructure)
                .then(() => {
                  toast.success(t('TaskConfig.successfully'));
                  context.LoadEmspChargePoints().then(() => {
                    setLoading(false);
                  });
                })
                .catch(() => toast.error(t('TaskConfig.failed')));
            }}
          >
            {({
              values,
              touched,
              errors,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  {check && (
                    <>
                      <Row>
                        <Col>
                          <Field
                            component={renderField}
                            name='authUrl'
                            label={t('TaskConfig.authUrl')}
                            className='form-control'
                            type='text'
                            meta={{
                              touched: touched.authUrl,
                              error: errors.authUrl,
                            }}
                          />
                        </Col>
                        <Col>
                          <Field
                            component={renderField}
                            name='baseUrl'
                            label={t('TaskConfig.baseUrl')}
                            className='form-control'
                            type='text'
                            meta={{
                              touched: touched.baseUrl,
                              error: errors.baseUrl,
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xl={6} lg={12} sm={12}>
                          <Field
                            component={renderField}
                            name='username'
                            label={t('TaskConfig.userName')}
                            className='form-control'
                            type='text'
                            meta={{
                              touched: touched.username,
                              error: errors.username,
                            }}
                          />
                        </Col>
                        <Col xl={6} lg={12} sm={12}>
                          <Field
                            component={renderField}
                            name='password'
                            label={t('Login.password')}
                            className='form-control'
                            type='text'
                            meta={{
                              touched: touched.password,
                              error: errors.password,
                            }}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={6} lg={12} sm={12}>
                          <Field
                              component={renderField}
                              name='integrationURL'
                              label={t('TaskConfig.integrationURL')}
                              className='form-control'
                              type='text'
                              meta={{
                                touched: touched.integrationURL,
                                error: errors.integrationURL,
                              }}
                          />
                        </Col>
                        <Col xl={6} lg={12} sm={12}>
                          <Field
                              component={renderField}
                              name='dagSuffix'
                              label={t('TaskConfig.dagSuffix')}
                              className='form-control'
                              type='text'
                              meta={{
                                touched: touched.dagSuffix,
                                error: errors.dagSuffix,
                              }}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={6} lg={12} sm={12}>
                          <Field
                              component={renderField}
                              name='integrationUsername'
                              label={t('TaskConfig.integrationUsername')}
                              className='form-control'
                              type='text'
                              meta={{
                                touched: touched.integrationUsername,
                                error: errors.integrationUsername,
                              }}
                          />
                        </Col>
                        <Col xl={6} lg={12} sm={12}>
                          <Field
                              component={renderField}
                              name='integrationPassword'
                              label={t('TaskConfig.integrationPassword')}
                              className='form-control'
                              type='text'
                              meta={{
                                touched: touched.integrationPassword,
                                error: errors.integrationPassword,
                              }}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  {check &&
                    mappedData.chargePoints &&
                    mappedData.chargePoints.length > 0 && (
                      <DataTable
                        paginationPerPage={100}
                        pagination={false}
                        loading={loading}
                        noDataText={t(
                          'ChargeStations.thereArenoChargeStationCreated'
                        )}
                        columns={[
                          {
                            sortable: false,
                            maxWidth: '32px',
                            width: '32px',
                            minWidth: '32px',
                            cell: (row: ChargeStationType, _id: any) => {
                              return (
                                <div>
                                  <span
                                    id={`chargeStation${row._id}`}
                                    style={{ padding: 8, cursor: 'pointer' }}
                                  >
                                    <span
                                      style={{
                                        padding: 4,
                                        display: 'inline-block',
                                        backgroundColor: (() => {
                                          if (!row.isOnline) {
                                            return '#dd3333';
                                          }
                                          return '#22dd22';
                                        })(),
                                        borderRadius: '50%',
                                      }}
                                    />
                                  </span>
                                  <UncontrolledTooltip
                                    placement='top'
                                    target={`chargeStation${row._id}`}
                                  >
                                    {t(
                                      `ChargeStations.${(() => {
                                        if (!row.isOnline) {
                                          return 'chargeStationOffline';
                                        }
                                        return 'chargeStationOnline';
                                      })()}`
                                    )}
                                  </UncontrolledTooltip>
                                </div>
                              );
                            },
                          },
                          {
                            name: t('ChargeStations.CS Id'),
                            selector: (row: ChargeStationType) =>
                              row.chargePointId,
                            sortable: true,
                            cell: (props: ChargeStationType, _id: any) => {
                              return (
                                <div className='m-2'>
                                  <div className='mb-2 d-flex flex-column'>
                                    <span>
                                      {t('Transaction.CP Id')} :{' '}
                                      {props.chargePointId}
                                    </span>
                                    <span>
                                      {`${t(
                                        'TransactionDetail.chargePointName'
                                      )} : ${props.name}`}
                                    </span>
                                  </div>

                                  <Field
                                    type='text'
                                    className='form-control form-control-sm'
                                    name={`chargePoints.[${_id}].stationNumber`}
                                    style={{}}
                                  />
                                  <ErrorMessage
                                    name={`chargePoints[${_id}].stationNumber`}
                                  />
                                </div>
                              );
                            },
                          },
                          {
                            name: t('ChargeStations.connectors'),
                            grow: 2,
                            cell: (props: ChargeStationType, _id: any) => {
                              const { connectors, chargePointId } = props;
                              return connectors && connectors.length > 0 ? (
                                <div className='d-flex'>
                                  {connectors.map((x, index) => (
                                    <div className='m-2' key={index.toString()}>
                                      <div className='d-flex justify-content-between'>
                                        <div className='mb-2 d-flex flex-column'>
                                          <span>
                                            {`${t(
                                              'ChargeStations.connectorId'
                                            )} : ${x.connectorId}`}
                                          </span>
                                          <span>
                                            {`${t(
                                              'ChargeStations.socketType'
                                            )} : ${x.socketType}`}
                                          </span>
                                        </div>
                                        <div className='ml-0'>
                                          <span
                                            key={index.toString()}
                                            className='m-1  justify-content-end'
                                            id={`connectors-${_id}-${index}`}
                                          >
                                            {getSocketSvg(
                                              x.socketType,
                                              (() => {
                                                if (
                                                  x.status &&
                                                  x.status.status
                                                ) {
                                                  switch (x.status.status) {
                                                    case 'Available':
                                                      return '#44aa44';
                                                    case 'Preparing':
                                                      return '#44aa44';
                                                    case 'Finishing':
                                                      return '#44aa44';
                                                    case 'Reserved':
                                                      return '#44aa44';
                                                    case 'SuspendedEvse':
                                                      return '#dd3333';
                                                    case 'SuspendedEV':
                                                      return '#dd3333';
                                                    case 'Unavailable':
                                                      return '#dd3333';
                                                    case 'Faulted':
                                                      return '#dd3333';
                                                    case 'Charging':
                                                      return '#ccbb44';
                                                    default:
                                                      return '#dd3333';
                                                  }
                                                }
                                                if (!x.isOnline) {
                                                  return '#dd3333';
                                                }
                                                if (
                                                  x.transactionState ===
                                                  'Active'
                                                ) {
                                                  return '#ccbb44';
                                                }
                                                return '#44aa44';
                                              })()
                                            )}

                                            <UncontrolledTooltip
                                              placement='top'
                                              target={`connectors-${_id}-${index}`}
                                            >

                                              {x.status && x.status.status
                                                ? x.status.status === 'Faulted'
                                                  ? `${x.socketType} ${x.status.status} ${x.status.vendorErrorCode}`
                                                  : `${x.socketType} ${x.status.status}`
                                                : `${x.socketType} ${t(
                                                    `ChargeStations.${(() => {
                                                      if (!x.isOnline) {
                                                        return 'connectorOffline';
                                                      }
                                                      if (
                                                        x.transactionState ===
                                                        'Active'
                                                      ) {
                                                        return 'connectorCharging';
                                                      }
                                                      return 'connectorAvailable';
                                                    })()}`
                                                  )}`}
                                            </UncontrolledTooltip>
                                          </span>
                                        </div>
                                      </div>
                                      <Field
                                        type='text'
                                        className='form-control form-control-sm'
                                        name={`chargePoints[${_id}].connectors[${index}].socketNumber`}
                                      />
                                      <ErrorMessage
                                        name={`chargePoints[${_id}].connectors[${index}].socketNumber`}
                                      />
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div />
                              );
                            },
                          },
                        ]}
                        data={mappedData.chargePoints}
                      />
                    )}
                  <Row className='mt-3'>
                  <Col className='text-end'>
                      {/* <Button
                        color='secondary'
                        type='button'
                        disabled={isSubmitting}
                        className='me-1'
                      >
                        {t('AddApplication.cancel')}
                      </Button> */}
                      <Button
                        color='primary'
                        type='submit'
                        disabled={isSubmitting}
                      >
                        {t('ChargeConnection.submit')}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </div>
      ) : (
        <Spinner />
      )}
    </Container>
  );
};
export default TaskConfig;
