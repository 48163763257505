import { APIClient } from '../helpers/api_helper';

const api = new APIClient();
export const getStations = (filter: object) => {
  const apiUrl = `stations/public`;

  return api.get(apiUrl, filter);
};

export const updateStation = (params: { id: string } & object) => {
  const { id, ...otherParams } = params;
  const apiUrl = `stations/public/${id}`;
  return api.update(apiUrl, otherParams);
};
