import React from 'react';
import { Button , Row , Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { getSyncBrands } from 'actions';
import { toast } from 'react-toastify';

const ChargePointListContent = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Row>
        <Col xl={3} sm={12} className='d-flex'>
          <Button
            className='me-2'
            aria-hidden='true'
            color='primary'
            onClick={() => {
              getSyncBrands()
                .then(() => {
                  toast.success(t('AccountSettings.ListToastSucces'));
                })
                .catch(() => {
                  toast.error(t('AccountSettings.listToastErr'));
                });
            }}
          >
            {t('AccountSettings.updateChargeStations')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ChargePointListContent;