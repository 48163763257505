import React from 'react';
import { Col } from 'reactstrap';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from 'react-router-dom';
import FallbackImage from 'Components/Common/FallbackImage';
import { ASSET_BASE_URL } from 'helpers/config';

import LogoWhite from '../../../assets/images/logoWhite.png';

// Import Images
import logoLight from '../../../assets/images/logo-light.png';

const AuthSlider = () => {
  return (
    // <Col lg={6}>
    <Col lg={6} className='p-0 auth-one-bg__gioev d-none  d-sm-none d-md-block'>
      {/* <div className='p-lg-5 p-4 auth-one-bg h-100'> */}
      <div className='p-0 auth-one-bg auth-one-bg__gioev--radius h-100'>
        <div className='bg-overlay' />
        <div className='position-relative h-100 d-flex flex-column'>
          <Link to='/dashboard' className='d-block'>
            {/* <h1 className='login-text'>İYİ<br/>YOLCULUKLAR</h1> */}
            {/* <FallbackImage
                className='d-block'
                src={`${ASSET_BASE_URL}loginImg.png`}
                alt='loginImg'
                fallback={LogoWhite}
                style={{
                  objectFit: 'contain',
                  margin: '0 auto',
                  width: '30%',
                }}
              /> */}
            {/* <img src={LogoWhite} alt='' height='30' /> */}
            {/* <img src={logoLight} alt='' height='18' /> */}
          </Link>
          <div className='mt-auto p-5'>
            {/* <div className='mb-3'>
              <i className='ri-double-quotes-l display-4 text-success' />
            </div> */}

            {/* <Carousel
              showThumbs={false}
              autoPlay
              showArrows={false}
              showStatus={false}
              infiniteLoop
              className='carousel carousel--gioev slide'
              id='qoutescarouselIndicators'
            >
              <div className='carousel-inner text-center h-100'>
                <div className='item d-flex flex-column'>
                  <i className='ri-double-quotes-l text-white' />
                  <p className='carousel-text'>
                    The Ultimate Platform For EV Charge Station Management
                  </p>
                </div>
              </div>
              <div className='carousel-inner text-center h-100'>
                <div className='item d-flex flex-column'>
                  <i className='ri-double-quotes-l text-white' />
                  <p className='carousel-text'>
                    Cloud Based, Limitlessly Scaleable, Ultra Secure, Flexible
                    Tariff Management, White-Label Enabled, Flexible New Feature
                    Integration and more.
                  </p>
                </div>
              </div>
              <div className='carousel-inner text-center h-100'>
                <div className='item d-flex flex-column'>
                  <i className='ri-double-quotes-l text-white' />
                  <p className='carousel-text'>
                    You Focus On Your Business, We Handle the Infrastructure
                  </p>
                </div>
              </div>
            </Carousel> */}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default AuthSlider;
