import { APIClient } from '../helpers/api_helper';

const api = new APIClient();

export const getAmenities = () => {
  const apiUrl = `amenities`;

  return api.get(apiUrl);
};

export default getAmenities;
