/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  FormGroup,
  Container,
  Spinner,
} from 'reactstrap';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import InputPasswordToggle from 'Components/Common/InputPasswordToggle';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { resetPassword, verifyToken } from '../../../actions/forgotPassword';
import SuccessMessage from './SuccessMessage';
import AuthSlider from '../../AuthenticationInner/authCarousel';
import ParticlesAuth from '../../AuthenticationInner/ParticlesAuth';

const formSchema = Yup.object().shape({
  email: Yup.string(),
  token: Yup.string(),
  password: Yup.string().required(i18n.t('Required')),
  retypePassword: Yup.string()
    .required(i18n.t('Required'))
    .oneOf(
      [Yup.ref(`password`), null],
      i18n.t('AccountSettings.passwordsMustMath')
    ),
});

const ResetPassword = () => {
  const history = useHistory();
  const [seconds, setSeconds] = useState(120);
  const [button, setButton] = useState(false);
  const { t } = useTranslation();
  const query = useLocation().search;
  const location = new URLSearchParams(query).get('token');
  const [loading, setLoading] = useState(false);
  const [control, setControl] = useState(true);
  const [initialValues, setInitialValues] = useState({
    password: '',
    retypePassword: '',
    email: '',
    token: '',
  });
  const goLogin = () => {
    setLoading(true);
    setTimeout(() => {
      history.push('/login');
    }, 1000);
  };
  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        setButton(!button);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if (location) {
      verifyToken({ token: location }).then((res) => {
        setInitialValues({
          ...initialValues,
          email: res?.data?.done?.email,
          token: res?.data?.done?.token,
        });
        setControl(false);
        // setEmail(res.data.done.email);
        // setToken(res.data.done.token);
      });
    }
  }, []);

  return (
    <ParticlesAuth>
      <div className='auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100'>
        <div className='auth-page-content overflow-hidden pt-lg-5'>
          <Container>
            <Row>
              <Col lg={12}>
                <Card className='overflow-hidden border-0'>
                  <Row className='g-0'>
                    <AuthSlider />
                    <Col lg={6}>
                      {/* {console.log(initialValues)} */}
                      <div className='p-lg-5 p-4'>
                        <div className='mt-4'>
                          <div className='auth-page-content'>
                            <Row className=''>
                              <Col>
                                <Card className='mt-4'>
                                  <CardBody className='p-4'>
                                    {loading === false ? (
                                      <div>
                                        <div className='text-center mt-2'>
                                          <h5 className='text-primary'>
                                            {t(
                                              'ForgotPassword.pleaseEnterInformation'
                                            )}
                                          </h5>
                                        </div>
                                        <div className='p-2'>
                                          {!control ? (
                                            <Formik
                                              initialValues={{
                                                ...initialValues,
                                                password: '',
                                                retypePassword: '',
                                              }}
                                              onSubmit={async (values) => {
                                                const {
                                                  retypePassword,
                                                  ...newValues
                                                } = values;
                                                resetPassword(newValues)
                                                  .then((res) => {
                                                    if (res) {
                                                      // goLogin();
                                                      setLoading(true);
                                                    }
                                                  })
                                                  .catch((e) =>
                                                    toast.error(
                                                      t(
                                                        'ForgotPassword.somethingWentWrong'
                                                      )
                                                    )
                                                  );
                                              }}
                                              validationSchema={formSchema}
                                            >
                                              {({
                                                touched,
                                                errors,
                                                handleSubmit,
                                                handleChange,
                                                isSubmitting,
                                              }) => {
                                                return (
                                                  <Form onSubmit={handleSubmit}>
                                                    <Row>
                                                      <FormGroup>
                                                        <InputPasswordToggle
                                                          label={t(
                                                            'AccountSettings.newPassword'
                                                          )}
                                                          htmlFor='password'
                                                          name='password'
                                                          onChange={
                                                            handleChange
                                                          }
                                                          className={classnames(
                                                            'input-group-merge',
                                                            {
                                                              'is-invalid':
                                                                errors.password,
                                                            }
                                                          )}
                                                          meta={{
                                                            touched:
                                                              touched.password,
                                                            error:
                                                              errors.password,
                                                          }}
                                                        />
                                                      </FormGroup>
                                                    </Row>
                                                    <Row>
                                                      <FormGroup>
                                                        <InputPasswordToggle
                                                          label={t(
                                                            'AccountSettings.retypeNewPassword'
                                                          )}
                                                          htmlFor='retypePassword'
                                                          name='retypePassword'
                                                          onChange={
                                                            handleChange
                                                          }
                                                          className={classnames(
                                                            'input-group-merge',
                                                            {
                                                              'is-invalid':
                                                                errors.retypePassword,
                                                            }
                                                          )}
                                                          meta={{
                                                            touched:
                                                              touched.retypePassword,
                                                            error:
                                                              errors.retypePassword,
                                                          }}
                                                        />
                                                      </FormGroup>
                                                    </Row>
                                                    <Row className='mt-3'>
                                                      <Col className='text-end'>
                                                        <Button
                                                          className='btn btn-success w-100'
                                                          color='primary'
                                                          type='submit'
                                                          disabled={
                                                            isSubmitting
                                                          }
                                                        >
                                                          {t(
                                                            'ForgotPassword.confirm'
                                                          )}
                                                        </Button>
                                                      </Col>
                                                    </Row>
                                                  </Form>
                                                );
                                              }}
                                            </Formik>
                                          ) : (
                                            <Spinner />
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <div>
                                        <SuccessMessage />
                                      </div>
                                    )}
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                            {loading === false ? (
                              <>
                                {' '}
                                {seconds > 0 ? (
                                  <Button
                                    className='w-100 text-decoration-underline'
                                    disabled
                                    color='white'
                                  >
                                    {`${t(
                                      'ForgotPassword.iwantMailAgain'
                                    )} ${seconds} ${t('seconds')}`}
                                  </Button>
                                ) : (
                                  <Button
                                    className='w-100 text-decoration-underline'
                                    color='white'
                                    type='submit'
                                  >
                                    {t('ForgotPassword.iwantMailAgain')}
                                  </Button>
                                )}
                              </>
                            ) : (
                              <Button
                                className='w-100 text-decoration-underline'
                                color='white'
                                onClick={() => {
                                  history.push('/login');
                                }}
                              >
                                {t('ForgotPassword.backtoLogin')}
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </ParticlesAuth>
  );
};
export default ResetPassword;
