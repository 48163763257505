import { APIClient } from '../helpers/api_helper';

const api = new APIClient();

export const getEmployees = (params?: object) => {
  const apiUrl = `employees/`;

  return api.get(apiUrl);
};

export const createEmployee = (params: object) => {
  const apiUrl = `employees`;
  return api.create(apiUrl, params);
};
export const editEmployee = (id: string, params: object) => {
  const apiUrl = `employees/${id}`;
  return api.update(apiUrl, params);
};
