import React from 'react';
import { Alert, Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import changePasswordSuccess from '../../../assets/lottie/changePasswordSuccess.json';

const SuccessMessage = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: changePasswordSuccess,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const { t } = useTranslation();
  return (
    <Row>
      <Col lg={12}>
        <Card className='overflow-hidden border-0'>
          <Row className='justify-content-center g-0'>
            <Col lg={12}>
              {/* <div className='p-lg-5 p-4 text-center'>
                <div className='avatar-lg mx-auto mt-2'>
                  <div className='avatar-title bg-light text-success display-3 rounded-circle'>
                    <i className='ri-checkbox-circle-fill' />
                  </div>
                </div>
                
              </div> */}
              <Lottie options={defaultOptions} height={300} width={400} />
              <div className='mt-4 text-center'>
                <div className='mt-4 pt-2'>
                  <h4>{t('ForgotPassword.wellDone')}</h4>
                  <p className='text-muted mx-4'>
                    {t('ForgotPassword.successfully')}
                  </p>
                  <div className='mt-4'>
                    {/* <Link
                      to='/auth-signin-basic'
                      className='btn btn-success w-100'
                    >
                      <h5 style={{ color: 'gray' }}>
                        {t('ForgotPassword.backtoLogin')}
                      </h5>
                    </Link> */}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
export default SuccessMessage;
