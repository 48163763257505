import React, { useState, useEffect, useContext } from 'react';
import * as Icons from 'react-feather';
import { Edit3 } from 'react-feather';
import DataTable from 'Components/Common/DataTable';
import DataContext from 'Components/Contexts/DataContext';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import Container from 'Components/Common/Container';
import { getIntegrationTasks } from 'actions';
import { IntegrationTasks } from 'declerations/DefaultTypes';
import EditContext from '../../Components/Contexts/EditContext';
import 'moment/locale/tr';
import 'moment/locale/en-nz';

const IntegrationTask = () => {
  const { t } = useTranslation();
  const context = useContext(DataContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);
  const [reset, setReset] = useState<boolean>(false);
  const [PerRows, setPerRows] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [data, setData] = useState<IntegrationTasks[] | undefined>();

  const [filter, setFilter] = useState<{
    limit: number | undefined;
    offset: number | undefined;
  }>({
    limit: PerRows,
    offset: 0,
  });

  const loadDataTable = () => {
    setLoading(true);
    getIntegrationTasks(filter).then((res) => {
      setLoading(false);
      setCount(res.data?.integrationTasks?.total_entries);
      setData(res.data?.integrationTasks?.dag_runs);
    });
  };

  const handlePerRowsChange = (e: number) => {
    setPerRows(e);
    setReset(!reset);
    setFilter({
      ...filter,
      offset:
        PerRows && pageNumber === 0 ? pageNumber * e : (pageNumber - 1) * e,
      limit: e,
    });
  };
  const pagination = (e: number) => {
    setPageNumber(e);
    const control = count / PerRows;
    const flag = Math.ceil(control);
    if (e <= flag) {
      setFilter({
        ...filter,
        offset: PerRows && (e - 1) * PerRows,
        limit: PerRows,
      });
    } else if (e === flag) {
      setFilter({
        ...filter,
        offset: count,
        limit: PerRows,
      });
    }
  };

  useEffect(() => {
    loadDataTable();
  }, [filter]);

  return (
    <Container title={t('Integration Tasks')}>
      <div>
        <DataTable
          loading={loading}
          paginationServer
          paginationTotalRows={count}
          paginationPerPage={PerRows}
          onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
          onChangePage={(e) => pagination(e)}
          paginationResetDefaultPage={reset}
          noDataText={t('integrationTaskConfigs.thereArenoCreated')}
          columns={[
            {
              name: t('integrationTasks.dag_id'),
              cell: (row: IntegrationTasks) =>
                t(`integrationTasks.${row.dag_id.split('_')[0]}`),
            },
            // {
            //   name: t('integrationTasks.dag_run_id'),
            //   selector: (row: any) => row.dag_run_id,
            // },
            {
              name: t('integrationTasks.state'),
              selector: (row: IntegrationTasks) => row.state,
            },
            {
              name: t('integrationTasks.note'),
              selector: (row: IntegrationTasks) => row.note,
            },
            {
              name: t('integrationTasks.end_date'),
              cell: (row: IntegrationTasks) =>
                moment(row.end_date).format('lll').toString(),
            },
          ]}
          data={data || []}
        />
      </div>
    </Container>
  );
};
export default IntegrationTask;
