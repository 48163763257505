import React, { useContext, useEffect, useState } from 'react';
import {
  ConnectorType,
  ChargingRatesType,
  ChargeStationType,
} from 'declerations/DefaultTypes';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import CardInfoText from 'Components/Common/CardInfoText';
import { useTranslation } from 'react-i18next';
import DataContext from 'Components/Contexts/DataContext';
import { Edit3, Wifi } from 'react-feather';
import EditContext from 'Components/Contexts/EditContext';
import UpdateTariff from './UpdateTariff';
import ChargeConnection from '../SubPages/Overview/widgets/Connectors/ChargeConnection';

const ConnectorCards = ({
  connectors,
  station,
}: {
  connectors: ConnectorType;
  station: ChargeStationType;
}) => {
  const { t } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const context = useContext(DataContext);
  const editContext = useContext(EditContext);

  const [connectionModal, setConnectionModal] = useState(false);
  const [selectedConnector, setSelectedConnector] = useState<
    ConnectorType | undefined
  >();

  const handleConnectionModal = (connector: ConnectorType) => {
    setSelectedConnector(connector);
    setConnectionModal(!connectionModal);
  };

  useEffect(() => {
    context.LoadChargingRates();
  }, [connectors]);

  useEffect(() => {
    editContext.SetEditChargeStation(
      context.chargeStations?.find(
        (x: ChargeStationType) => x.chargePointId === station.chargePointId
      )
    );
  }, [context.chargeStations]);

  const toggleHandle = () => {
    setEditModal(false);
    context
      .LoadChargeStations({
        applicationId: editContext?.editChargeStation?.applicationId,
      })
      .then(() => {
        editContext.SetEditChargeStation(
          context.chargeStations?.find(
            (x: ChargeStationType) => x.chargePointId === station.chargePointId
          )
        );
      });
  };

  useEffect(() => {
    if (selectedConnector) {
      const newSelectedConnector = connectors
        ? connectors &&
          [connectors].find((x) => x._id === selectedConnector._id)
        : selectedConnector;
      setSelectedConnector(newSelectedConnector);
    }
    return () => {};
  }, [connectors]);

  // eslint-disable-next-line @typescript-eslint/no-shadow

  return (
    <>
      <Card className='ribbon-box border shadow-none overflow-hidden'>
        <CardBody>
          <div className='ribbon ribbon-info ribbon-shape trending-ribbon'>
            <span className='trending-ribbon-text'>
              Connector {connectors.connectorId}
            </span>
            <div className='ri-flashlight-fill text-white align-bottom float-end ms-1' />
          </div>
          <Row className='mt-4'>
            <Col xs={12} md={12}>
              <ul className='list-group list-group-flush'>
                <CardInfoText
                  title={t('ChargeStations.connectorId')}
                  value={connectors.connectorId}
                />
                <CardInfoText
                  title={t('ChargeStations.transactionState')}
                  value={connectors.transactionState}
                />
                <CardInfoText
                  title={t('ChargeStations.level')}
                  value={connectors.level}
                />
                <CardInfoText
                  title={t('ChargeStations.socketType')}
                  value={connectors.socketType}
                />
                <CardInfoText
                  title={t('ChargeStations.maxPowerKW')}
                  value={connectors.maxPowerKW}
                />

                <CardInfoText
                  title={t('ChargeStations.chargingRate')}
                  value={
                    <div className='d-flex align-center '>
                      {
                        (
                          context.chargingRates?.find(
                            (x: ChargingRatesType) =>
                              x._id === connectors.rateId
                          ) || {}
                        ).title
                      }
                      <Edit3
                        cursor='pointer'
                        size={18}
                        className='text-primary ms-2'
                        onClick={() => {
                          editContext
                            .SetEditConnector({
                              connectorId: connectors.connectorId,
                              rateId: connectors.rateId,
                              chargePointId: station.chargePointId,
                              applicationId: (station.application || {})._id,
                            })
                            .then(() => {
                              setEditModal(true);
                            });
                          // this.toggleConnectorRateModal();
                        }}
                      />
                    </div>
                  }
                />
                <Button
                  className='w-50 m-auto mt-4'
                  color='primary'
                  onClick={() => {
                    handleConnectionModal(connectors);
                  }}
                >
                  <span className='align-middle '>
                    {t('ChargeConnection.connect')}
                  </span>
                </Button>
              </ul>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {editModal && (
        <UpdateTariff toggleModal={toggleHandle} isOpen={editModal} />
      )}
      {connectionModal && selectedConnector && (
        <ChargeConnection
          isOpen={connectionModal}
          toggleModal={handleConnectionModal}
          chargePoint={{ ...station, connectors: [selectedConnector] }}
        />
      )}
    </>
  );
};

export default ConnectorCards;
