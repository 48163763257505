import { APIClient } from '../helpers/api_helper';

const api = new APIClient();
export const getDrivers = (filter?: object) => {
  const apiUrl = `consumers`;
  return api.get(apiUrl, filter);
};
export const getDriverById = (id: string) => {
  const apiUrl = `consumers/${id}`;
  return api.get(apiUrl);
};
export const createDriver = (params: object) => {
  const apiUrl = `consumers`;
  return api.create(apiUrl, params);
};
export const sendOtpToDriver = (params: object) => {
  const apiUrl = `consumers/check-start`;
  return api.create(apiUrl, params);
};
export const deleteDriver = (id?: string) => {
  const apiUrl = `consumers/${id}`;
  return api.delete(apiUrl);
};
export const verifyOtpToDriver = (params: object) => {
  const apiUrl = `consumers/verify-start`;
  return api.create(apiUrl, params);
};
export const editDriver = (id: string, params: object) => {
  const apiUrl = `consumers/${id}`;
  return api.update(apiUrl, params);
};
