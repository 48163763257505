import { APIClient } from '../helpers/api_helper';

const api = new APIClient();

export const getPermissions = () => {
  const apiUrl = `permissions`;
  return api.get(apiUrl);
};

export const createPermission = (params: object) => {
  const apiUrl = `permissions`;
  return api.create(apiUrl, params);
};

export const getRoles = (filter?: object) => {
  const apiUrl = `roles`;

  return api.get(apiUrl, filter);
};

export const createRoles = (params: object) => {
  const apiUrl = `roles`;
  return api.create(apiUrl, params);
};
export const editRole = (id: string, params: object) => {
  const apiUrl = `roles/${id}`;
  return api.update(apiUrl, params);
};

export const deleteRole = (id: string) => {
  const apiUrl = `roles/${id}`;
  return api.delete(apiUrl);
};
