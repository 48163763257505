/* eslint-disable react/prop-types */
// ** React Imports
import React, { Fragment, useState } from 'react';

// ** Third Party Components
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Eye, EyeOff } from 'react-feather';
import {
  InputGroup,
  Input,
  InputGroupText,
  Label,
  FormGroup,
} from 'reactstrap';

const unboxError = (error) => {
  if (typeof error === 'object') {
    return unboxError(Array.from(Object.values(error))[0]);
  }
  return error;
};
const InputPasswordToggle = (props) => {
  // ** Props
  const {
    label,
    hideIcon,
    showIcon,
    visible,
    className,
    htmlFor,
    placeholder,
    iconSize,
    inputClassName,
    meta: { touched, error, warning },
    name,
    ...rest
  } = props;

  // ** State
  const [inputVisibility, setInputVisibility] = useState(visible);

  // ** Renders Icon Based On Visibility
  const renderIcon = () => {
    const size = iconSize || 14;

    if (inputVisibility === false) {
      return hideIcon || <Eye size={size} />;
    }
    return showIcon || <EyeOff size={size} />;
  };

  return (
    <FormGroup>
      {label ? <Label for={htmlFor}>{label}</Label> : null}
      <InputGroup
        className={classnames({
          [className]: className,
        })}
      >
        <InputGroupText
          style={{ cursor: 'pointer' }}
          onClick={() => setInputVisibility(!inputVisibility)}
        >
          {renderIcon()}
        </InputGroupText>
        <Input
          type={inputVisibility === false ? 'password' : 'text'}
          placeholder={placeholder || label}
          className={classnames({
            [inputClassName]: inputClassName,
          })}
          /*eslint-disable */
          {...(label && htmlFor
            ? {
                id: htmlFor,
              }
            : {})}
          name={name}
          {...rest}
        />
      </InputGroup>
      {touched &&
        ((error && (
          <span className='text-danger text-sm'>{unboxError(error)}</span>
        )) ||
          (warning && (
            <span className='text-warning text-sm'>{unboxError(warning)}</span>
          )))}
    </FormGroup>
  );
};

export default InputPasswordToggle;

// ** PropTypes
InputPasswordToggle.propTypes = {
  hideIcon: PropTypes.node,
  showIcon: PropTypes.node,
  visible: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  iconSize: PropTypes.number,
  inputClassName: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  meta: PropTypes.object,
  label(props, propName, componentName) {
    // ** If label is defined and htmlFor is undefined throw error
    if (props[propName] && props['htmlFor'] === 'undefined') {
      throw new Error('htmlFor prop is required when label prop is present');
    }
  },
  htmlFor(props, propName, componentName) {
    // ** If htmlFor is defined and label is undefined throw error
    if (props[propName] && props['label'] === 'undefined') {
      throw new Error('label prop is required when htmlFor prop is present');
    }
  },
};

// ** Default Props
InputPasswordToggle.defaultProps = {
  visible: false,
};
