/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useContext, lazy, Suspense } from 'react';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import SlidingModal from 'Components/Common/SlidingModal';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import EditContext from 'Components/Contexts/EditContext';
import CdrDetail from 'pages/CPM/CDR/CdrDetail';
import { getDriverById } from 'actions';
import {
  ChargeStationType,
  TransactionType,
  VehicleType,
} from 'declerations/DefaultTypes';
import DataContext from 'Components/Contexts/DataContext';
import { useTranslation } from 'react-i18next';
import { getChargeStationDetails } from 'actions/chargeStations';

const Overview = lazy(() => import('./Widgets/Overview/Overview'));
const EnergyUsage = lazy(() => import('./Widgets/EnergyUsage/EnergyUsage'));
const Payment = lazy(() => import('./Widgets/Payment/Payment'));
const Vehicle = lazy(() => import('./Widgets/Vehicle/Vehicle'));
const defaultTab = 'overview';

interface TransactionsDetailsProps {
  isOpen: boolean;
  toggleHandle: (isOpen: boolean) => void;
  selectedPayment: any;
}
const TransactionDetail = ({
  isOpen,
  toggleHandle,
  selectedPayment,
}: TransactionsDetailsProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = useState<ChargeStationType>();
  const editContext = useContext(EditContext);
  const context = useContext(DataContext);
  const selectedTransaction = editContext.editTransaction;
  const TAB_ITEMS = [
    { id: 'overview', label: t('TransactionDetail.overview') },
    { id: 'payment', label: t('TransactionDetail.payment') },
    { id: 'energyusage', label: t('TransactionDetail.energyUsage') },
    { id: 'vehicles', label: t('TransactionDetail.vehicle') },
    {
      id: 'chargedetailrecords',
      label: t('TransactionDetail.chargeDetailRecords'),
    },
  ];
  useEffect(() => {
    getChargeStationDetails(selectedTransaction.chargePointId).then((res) => {
      setData(res.data.chargePoint);
    });
  }, [editContext.editTransaction, editContext.transactionRefresh]);
  const [animationNavTab, setanimationNavTab] = useState('overview');
  const animationNavToggle = (tab: any) => {
    if (animationNavTab !== tab) {
      setanimationNavTab(tab);
    }
  };

  return (
    <Suspense fallback={<div>Yükleniyor...</div>}>
      <SlidingModal
        toggleHandle={toggleHandle}
        isOpen={isOpen}
        size='xl'
        title={`# ${selectedTransaction.transactionId.toString()}`}
      >
        <Nav tabs className='nav-tabs mb-3'>
          {TAB_ITEMS.map((item) => (
            <NavItem>
              <NavLink
                style={{ cursor: 'pointer' }}
                className={classnames({ active: animationNavTab === item.id })}
                onClick={() => {
                  animationNavToggle(item.id);
                }}
              >
                <span>{item.label}</span>
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={animationNavTab} className='text-muted'>
          <TabPane tabId='overview' id='overview'>
            {data && (
              <Overview
                transaction={editContext.editTransaction}
                mapData={data}
              />
            )}
          </TabPane>
          <TabPane tabId='payment' id='payment'>
            {data && <Payment transaction={editContext.editTransaction} />}
          </TabPane>
          <TabPane tabId='energyusage' id='energyusage'>
            <EnergyUsage transaction={editContext.editTransaction} />
          </TabPane>
          <TabPane tabId='vehicles' id='vehicles'>
            <Vehicle consumerID={selectedTransaction.consumer?._id} />
          </TabPane>
          <TabPane tabId='chargedetailrecords' id='chargedetailrecords'>
            <CdrDetail selectedPayment={selectedPayment} />
          </TabPane>
        </TabContent>
      </SlidingModal>
    </Suspense>
  );
};

export default TransactionDetail;
