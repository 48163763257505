import { APIClient } from '../helpers/api_helper';

const api = new APIClient();
export const getLocations = (filter?: object) => {
  const apiUrl = `locations/`;

  return api.get(apiUrl, filter);
};
export const getLocationById = (id: string, params: object) => {
  const apiUrl = `locations/${id}`;
  return api.get(apiUrl, params);
};
export const createLocation = (params: object) => {
  const apiUrl = `locations`;
  return api.create(apiUrl, params);
};
export const editLocation = (id: string, params: object) => {
  const apiUrl = `locations/${id}`;
  return api.update(apiUrl, params);
};
