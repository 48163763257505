import React from 'react';
import { Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from 'react-router-dom';
import FallbackImage from 'Components/Common/FallbackImage';
import { ASSET_BASE_URL } from 'helpers/config';

import LogoWhite from '../../assets/images/logoWhite.png';

// Import Images
import logoLight from '../../assets/images/logo-light.png';

const AuthSlider = () => {
  const { t } = useTranslation();

  return (
    <Col lg={6}>
      <div className='p-lg-5 p-4 auth-one-bg h-100'>
        <div className='bg-overlay' />
        <div className='position-relative h-100 d-flex flex-column'>
          <div className='mb-4'>
            <Link to='/dashboard' className='d-block'>
              <FallbackImage
                className='d-block'
                src={`${ASSET_BASE_URL}loginImg.png`}
                alt='loginImg'
                fallback={LogoWhite}
                style={{
                  objectFit: 'contain',
                  margin: '0 auto',
                  width: '100%',
                }}
              />
              {/* <img src={LogoWhite} alt='' height='30' /> */}
              {/* <img src={logoLight} alt='' height='18' /> */}
            </Link>
          </div>
          <div className='mt-auto'>
            <div className='mb-3'>
              <i className='ri-double-quotes-l display-4 text-success' />
            </div>

            <Carousel
              showThumbs={false}
              autoPlay
              showArrows={false}
              showStatus={false}
              infiniteLoop
              className='carousel slide'
              id='qoutescarouselIndicators'
            >
              <div className='carousel-inner text-center text-white-50 pb-5'>
                <div className='item'>
                  <p className='fs-15 fst-italic'>
                    {t('LoginPage.carouselText1')}
                  </p>
                </div>
              </div>
              <div className='carousel-inner text-center text-white-50 pb-5'>
                <div className='item'>
                  <p className='fs-15 fst-italic'>
                    {t('LoginPage.carouselText2')}
                  </p>
                </div>
              </div>
              <div className='carousel-inner text-center text-white-50 pb-5'>
                <div className='item'>
                  <p className='fs-15 fst-italic'>
                    {t('LoginPage.carouselText3')}
                  </p>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default AuthSlider;
